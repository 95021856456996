import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loadScript from '../../../Share/utils/loadScript';
import { tfvLog, logApiLevel } from '../../../Share/utils';
import { goolgeCalendarInitDone } from '../../actions/user';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const GOOGLE_API_URL = 'https://apis.google.com/js/api.js';
const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
];
const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly';

const propTypes = {
  onGoolgeCalendarInitDone: PropTypes.func,
};

const defaultProps = {
  onGoolgeCalendarInitDone: () => {},
};
class GoogleCalendarInit extends Component {
  componentDidMount() {
    loadScript(GOOGLE_API_URL, this.loadClient);
  }

  loadClient = () => {
    window.gapi.load('client:auth2', this.initGoogleClient);
  };

  initGoogleClient = () => {
    window.gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(() => {
        tfvLog('Init google client done');
        this.props.onGoolgeCalendarInitDone();
      })
      .catch(error => {
        tfvLog(JSON.stringify(error, null, 2), logApiLevel.error);
      });
  };

  render() {
    return <div />;
  }
}

GoogleCalendarInit.propTypes = propTypes;
GoogleCalendarInit.defaultProps = defaultProps;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onGoolgeCalendarInitDone: () => {
    dispatch(goolgeCalendarInitDone());
  },
});

export default connect(null, mapDispatchToProps)(GoogleCalendarInit);
