import React from 'react';
import PropTypes from 'prop-types';
import Translate from '../Translate';

const propTypes = {
  className: PropTypes.string,
  selected: PropTypes.string,
  onTabClick: PropTypes.func,
};

const defaultProps = {
  className: 'tk-calendar__tabs-wrapper',
  selected: 'monthly',
  onTabClick: () => { },
};

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.tabs = [
      { key: 'month', name: Translate({ content: 'calendar.monthly' }) },
      { key: 'week', name: Translate({ content: 'calendar.weekly' }) },
    ];
  }

  render() {
    const { className, selected, onTabClick } = this.props;
    return (
      <ul className={className}>
        {
          this.tabs.map((tab) => (
            <li key={tab.key}>
              <button className={`tk-calendar__tabs-button ${selected === tab.key ? 'selected' : ''}`}
                onClick={() => { onTabClick(tab.key) }} tabIndex={0}>
                {tab.name}
              </button>
            </li>
          ))
        }
      </ul>
    );
  }
}

Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

export default Tabs;