import React from 'react';
import PropTypes from 'prop-types';
import { ValidatorComponent } from 'react-form-validator-core';
import classNames from 'classnames';
import './style.scss';

const propTypes = {
  errorMessages: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  validators: PropTypes.arrayOf(PropTypes.string),
  validatorListener: PropTypes.func,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
};

const defaultProps = {
  errorMessages: [],
  validators: ['matchRegexp:(.*)'],
  validatorListener: () => {},
  className: '',
  ariaLabel: '',
};

class TagInput extends ValidatorComponent {
  render() {
    const {
      placeholder,
      value,
      confirmationEmailList,
      deleteTag,
      validators,
      validatorListener,
      errorMessages,
      className,
      onBlur,
      onKeyUp,
      ariaLabel,
      ...rest
    } = this.props;

    const { isValid } = this.state;
    const controlClassName = classNames({
      'tv-form__control': true,
      'tv-form__control--error': !isValid,
      [className]: !!className,
    });

    return (
      <React.Fragment>
        <div className="container">
          {confirmationEmailList &&
            confirmationEmailList.map((tag, index) => (
              <div className="tag">
                {tag}
                <button
                  onClick={() => deleteTag(index)}
                  aria-label="Stäng"
                  tabIndex={0}
                >
                  x
                </button>
              </div>
            ))}
          <input
            className={controlClassName}
            value={value}
            placeholder={placeholder}
            aria-label={ariaLabel}
            ref={r => {
              this.input = r;
            }}
            {...rest}
            onBlur={() => {
              this.input.classList.remove('add-outline');
              onBlur();
            }}
            onKeyUp={e => {
              if (e.key === 'Tab') {
                this.input.classList.add('add-outline');
              }
              onKeyUp();
            }}
          />
        </div>
        <span className="tv-form__error">
          {typeof isValid === 'boolean' && !isValid
            ? this.getErrorMessage()
            : ''}
        </span>
      </React.Fragment>
    );
  }
}

TagInput.propTypes = propTypes;
TagInput.defaultProps = defaultProps;

export default TagInput;
