import moment from 'moment';
import * as multisoftApi from '../api';
import * as googleApi from '../api/googleApi';
import { broadCast, subscribe } from './eventBus';
import {
  UPDATE_USER_PROFILE,
  UPDATE_USER_CALENDAR,
  UPDATE_USER_EVENTS,
  UPDATE_IS_SYNC_WITH_GOOGLE_CALENDAR,
  UPDATE_GOOGLE_CALENDAR_EVENT,
  UPDATE_USER_CONTACT_PERSONS,
  GET_USER_DATA,
  SET_REPORT_DATASOURCE,
  SET_USER_AGREEMENT,
  UPDATE_SPECIFC_USER_CONTACT_PERSONS,
  DELETE_SPECIFC_USER_CONTACT_PERSONS,
  SET_BOOK_DIRECT_SERVICE_ID,
  UPDATE_INDIVIDUAL_USER_LOGGED,
} from './ActionTypes';
import {
  tfvLog,
  showToastMessage,
  convertEvent,
  logApiLevel,
} from '../../Share/utils';
import { Translate } from '../../Share/components';
import { showLoadingOverlay, hiddenLoadingOverlay } from './app';
import { processSingleTableData } from './dataSource';

subscribe('updateUserEvents', (dispatch, events) => {
  dispatch({
    type: UPDATE_USER_EVENTS,
    events,
  });
});

export function getCustomer() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { token } = state.authentication;
      const response = await multisoftApi.getCustomer(token);

      const userData = {
        profile: {
          customerIdentifier:
            response.data.CustomerInformation.CustomerIdentifier,
          customerName: response.data.CustomerInformation.CustomerName,
          customerNumber: response.data.CustomerInformation.CustomerNumber,
          department: response.data.CustomerInformation.Department,
          organisationNumber:
            response.data.CustomerInformation.OrganisationNumber,
          phoneNumberDirect:
            response.data.CustomerInformation.PhoneNumberDirect,
          phoneNumberSwitchboard:
            response.data.CustomerInformation.PhoneNumberSwitchboard,
          customerAddress: response.data.CustomerInformation.CustomerAddress,
          CanCreateAutoAssignOrder:
            response.data.CustomerInformation.CanCreateAutoAssignOrder,
          ContactPersonOrdererRequiresEmail:
            !!response.data.CustomerInformation
              .ContactPersonOrdererRequiresEmail,
          ContactPersonUponInterpretationRequiresEmail:
            !!response.data.CustomerInformation
              .ContactPersonUponInterpretationRequiresEmail,
          DoShowLMANumber: response.data.CustomerInformation.DoShowLMANumber,
          CustomerEmail: response.data.CustomerInformation.CustomerEmail,
          ValidCustomerInvoice:
            response.data.CustomerInformation.ValidCustomerInvoice,
        },
      };

      dispatch({
        type: UPDATE_USER_PROFILE,
        userData,
      });
    } catch (error) {
      tfvLog(error, logApiLevel.error);
    }
  };
}

subscribe('newTokenCreated', (dispatch, events) => {
  dispatch(getCustomer());
});

export function updateUserCalendar(calendar) {
  return dispatch =>
    dispatch({
      type: UPDATE_USER_CALENDAR,
      calendar,
    });
}

export function getGoolgeCalendarEvents(from, to) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { isSyncWithGoogleCalendar } = state.user;

      if (isSyncWithGoogleCalendar && googleApi.isSignInGoogle()) {
        const rawGoogleEvents = await googleApi.getGoogleEvents(from, to);
        const googleEvents = convertEvent(rawGoogleEvents);

        dispatch({
          type: UPDATE_GOOGLE_CALENDAR_EVENT,
          googleEvents,
        });
      }
    } catch (error) {
      tfvLog(error, logApiLevel.error);
    }
  };
}

export function goolgeCalendarInitDone() {
  return async dispatch => {
    try {
      const dateFrom = moment().startOf('month');
      const dateTo = moment().add(1, 'years');

      dispatch(getGoolgeCalendarEvents(dateFrom, dateTo));
    } catch (error) {
      tfvLog(error, logApiLevel.error);
    }
  };
}

export function updateIsSyncWithGoolgeCalendar(isSyncWithGoogleCalendar) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_IS_SYNC_WITH_GOOGLE_CALENDAR,
        isSyncWithGoogleCalendar,
      });

      if (isSyncWithGoogleCalendar) {
        await googleApi.signIn();
        tfvLog('Google sign in success!');
        showToastMessage(
          'success',
          Translate({ content: 'settings.loginGoogleSuccessfully' }),
        );

        const dateFrom = moment().startOf('month');
        const dateTo = moment().add(1, 'years');
        dispatch(getGoolgeCalendarEvents(dateFrom, dateTo));
      } else {
        await googleApi.signOut();
        dispatch({
          type: UPDATE_GOOGLE_CALENDAR_EVENT,
          googleEvents: [],
        });
      }
    } catch (error) {
      tfvLog(error, logApiLevel.error);
    }
  };
}

subscribe('userLoginWithAnotherAccount', (dispatch, events) => {
  dispatch(updateIsSyncWithGoolgeCalendar(false));
});

export function fetchContactPersons(cb) {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    try {
      const response = await multisoftApi.fetchContactPersons(token);
      if (response.data && !response.data.Errors) {
        let contactPersons = [];

        if (response.data.CustomerContactPersons) {
          contactPersons = response.data.CustomerContactPersons.map(
            (item, i) => ({
              ...item,
              value: item.ContactPersonIdentifier,
              name: `${item.FirstName} ${item.LastName}${
                item.Title ? ` - ${item.Title}` : ''
              }`,
            }),
          );
        }

        dispatch({
          type: UPDATE_USER_CONTACT_PERSONS,
          contactPersons,
        });

        if (response.data.CustomerContactPersons) {
          let individualUserLogged = false;
          let user = response.data.CustomerContactPersons.filter(
            person => person.IsCurrentlyLoggedIn === true,
          );
          if (user.length > 0) {
            individualUserLogged = true;
          }
          dispatch({
            type: UPDATE_INDIVIDUAL_USER_LOGGED,
            user: {
              isLogged: individualUserLogged,
              user: user[0],
            },
          });
        }
      } else {
        const errorResponse =
          response.data && response.data.Errors ? response.data.Errors : {};
        throw new Error(
          `getContactPersons error ${JSON.stringify(errorResponse)}`,
        );
      }
    } catch (error) {
      tfvLog(error);
    } finally {
      if (cb) cb();
    }
  };
}

/**
 * Returns a list of active services for the customer, together with contract, invoice receivers and available competences that are connected to each service.
 * @param {string} params.skillIndentifier id of selected language in Create Order form
 * @param {Date} params.startTime start time value of the first session in Create Order form
 * @param {Date} params.endTime end time value of the first session in Create Order form
 * @param {Object} params.assignment assignment object of previous session in Create Order Form
 */
export function fetchServices(params, callback) {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;

    try {
      dispatch(showLoadingOverlay());
      let services = [];

      if (params.language != '-' && params.language != '') {
        const response = await multisoftApi.fetchServices(
          token,
          params.language,
          params.startTime,
          params.endTime,
        );

        services = response.data.Services;
      }
      const bookDirectService = services.find(
        item => item.IsDefaultServiceForAutoAssignOrder === true,
      );
      if (bookDirectService) {
        dispatch({
          type: SET_BOOK_DIRECT_SERVICE_ID,
          serviceId: bookDirectService.ServiceIdentifier,
        });
      } else {
        dispatch({
          type: SET_BOOK_DIRECT_SERVICE_ID,
          serviceId: '',
        });
      }
      // const nonStandardServices = (services || []).filter(
      //   s => s.IsStandardContract === false,
      // );

      // const allowSelectServices =
      //   nonStandardServices.length > 0 ? nonStandardServices : services;
      const prevAssignment = params.assignment ? params.assignment : {};
      if (callback) {
        callback(services, prevAssignment);
      }
    } catch (error) {
      tfvLog(error);
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

function filterReportsDataByAgreement(
  reportDataSourceIds,
  dataSources,
  reportId,
  selectedAgreement,
  dispatch,
  tables,
  isTableLeveldataSource = false,
) {
  return new Promise(() =>
    reportDataSourceIds.forEach(async dataSourceId => {
      if (isTableLeveldataSource) {
        const processData = tables.map(tableItem => {
          const filteredData = (
            dataSources.sources[dataSourceId].groupedData[
              tableItem.groupColumns
            ] || []
          ).filter(x =>
            selectedAgreement.id === 0
              ? true
              : x.agreementId === selectedAgreement.id,
          );
          return processSingleTableData(
            tableItem,
            filteredData,
            tables,
            reportId,
            dispatch,
          );
        });
        dispatch({
          type: SET_REPORT_DATASOURCE,
          dataReadyFlags: tables.map(x => true),
          reportId,
          processData,
        });
      } else {
        const filteredData = dataSources.sources[
          dataSourceId
        ].crossFilterData.filter(x =>
          selectedAgreement.id === 0
            ? true
            : x.agreementId === selectedAgreement.id,
        );
        const processData = tables.map(x =>
          processSingleTableData(x, filteredData, tables, reportId, dispatch),
        );
        dispatch({
          type: SET_REPORT_DATASOURCE,
          reportId,
          dataReadyFlags: tables.map(x => true),
          processData,
        });
      }
      dispatch(hiddenLoadingOverlay());
    }),
  );
}

export function onSelectedAggremenChanged(
  selectedAgreement,
  reportId,
  dataSources,
) {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: SET_USER_AGREEMENT,
      selectedAgreement,
    });
    dispatch(showLoadingOverlay());
    if (reportId !== 0) {
      const { tables, isTableLeveldataSource } =
        state.reportDetails.reports[reportId];
      const reportDataSourceIds =
        state.reportDetails.reports[reportId].dataSources || [];

      await filterReportsDataByAgreement(
        reportDataSourceIds,
        state.dataSources,
        reportId,
        selectedAgreement,
        dispatch,
        tables,
        isTableLeveldataSource,
      );
      const nonProcessedReports = Object.values(
        state.reportDetails.reports,
      ).filter(reportItem => reportItem.id !== reportId);
      nonProcessedReports.forEach(async reportItem => {
        await filterReportsDataByAgreement(
          reportDataSourceIds,
          state.dataSources,
          reportItem.id,
          selectedAgreement,
          dispatch,
          reportItem.tables,
          reportItem.isTableLeveldataSource,
        );
      });
    }
    if (dataSources.length > 0) {
      dataSources.forEach(dataSourceId => {
        const reportsHavingSameDataSource =
          Object.values(state.reportDetails.reports).filter(reportItem =>
            reportItem.dataSources.includes(dataSourceId),
          ) || [];
        reportsHavingSameDataSource.forEach(async reportItem => {
          await filterReportsDataByAgreement(
            [dataSourceId],
            state.dataSources,
            reportItem.id,
            selectedAgreement,
            dispatch,
            reportItem.tables,
            reportItem.isTableLeveldataSource,
          );
        });
      });
      const nonProcessedDataSources = Object.values(
        state.dataSources.sources,
      ).filter(sourceItem => !dataSources.includes(sourceItem.id));
      nonProcessedDataSources.forEach(dataSource => {
        const reportsHavingSameDataSource =
          Object.values(state.reportDetails.reports).filter(reportItem =>
            reportItem.dataSources.includes(dataSource.id),
          ) || [];
        reportsHavingSameDataSource.forEach(async reportItem => {
          await filterReportsDataByAgreement(
            [dataSource.id],
            state.dataSources,
            reportItem.id,
            selectedAgreement,
            dispatch,
            reportItem.tables,
            reportItem.isTableLeveldataSource,
          );
        });
      });
    }
  };
}

export function fetchSpecificContactPersons(contactPersonIdentifier, cb) {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    try {
      const response = await multisoftApi.fetchContactPersons(
        token,
        contactPersonIdentifier,
      );
      if (response.data && !response.data.Errors) {
        let contactPersons = [];

        if (response.data.CustomerContactPersons) {
          contactPersons = response.data.CustomerContactPersons.map(
            (item, i) => ({
              ...item,
              value: item.ContactPersonIdentifier,
              name: `${item.FirstName} ${item.LastName}${
                item.Title ? ` - ${item.Title}` : ''
              }`,
            }),
          );
        }

        dispatch({
          type: UPDATE_SPECIFC_USER_CONTACT_PERSONS,
          contactPerson: contactPersons.length > 0 ? contactPersons[0] : null,
        });
      } else {
        const errorResponse =
          response.data && response.data.Errors ? response.data.Errors : {};
        throw new Error(
          `getContactPersons error ${JSON.stringify(errorResponse)}`,
        );
      }
    } catch (error) {
      tfvLog(error);
    } finally {
      if (cb) cb();
    }
  };
}

export function deleteContactPersons(identifier) {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    try {
      dispatch(showLoadingOverlay());
      const response = await multisoftApi.deletContactPerson(token, identifier);
      dispatch({
        type: DELETE_SPECIFC_USER_CONTACT_PERSONS,
        contactPersonIdentifier: identifier,
      });
      dispatch(hiddenLoadingOverlay());
    } catch (error) {
      tfvLog(error);
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function createUpdateContactPerson(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    try {
      dispatch(showLoadingOverlay());
      const response = await multisoftApi.createUpdateContactPerson({
        token,
        ...data,
      });
      broadCast('SuccessfullyContactPersonCreate', {
        type: data.type,
        ContactPersonIdentifier: response.data.ContactPersonIdentifier,
      });
      dispatch(
        fetchSpecificContactPersons(response.data.ContactPersonIdentifier, () =>
          dispatch(hiddenLoadingOverlay()),
        ),
      );
    } catch (error) {
      tfvLog(error);
      dispatch(hiddenLoadingOverlay());
    }
  };
}
