import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { iconForTheServiceType, statusStringTraslate } from '../../utils';

const propTypes = {
  event: PropTypes.shape({}),
  date: PropTypes.shape({}),
};

const defaultProps = {
  event: {},
  date: {},
};

class SingleEvent extends Component {
  render() {
    const { event, date, onEventClick } = this.props;
    var timeFromTo =
      moment(event.assignment.DatetimeFrom).format('HH:mm') +
      ' - ' +
      moment(event.assignment.DatetimeTo).format('HH:mm');
    var eventClass = '';

    switch (event.orderStatus) {
      case 'accepted':
        eventClass = 'tk-weekly-calendar__event-accepted';
        break;
      case 'rejected':
        eventClass = 'tk-weekly-calendar__event-rejected';
        break;
      case 'working':
        eventClass = 'tk-weekly-calendar__event-working';
        break;
      case 'fullfilled':
        eventClass = 'tk-weekly-calendar__event-fullfilled';
        break;
      case 'submitted':
        eventClass = 'tk-weekly-calendar__event-submitted';
        break;
      case 'cancelled':
        eventClass = 'tk-weekly-calendar__event-cancelled';
        break;
      case 'late-cancelled':
          eventClass = 'tk-weekly-calendar__event-late-cancelled';
          break;
      case 'available':
        eventClass = 'tk-weekly-calendar__event-available';
        break;
    }
    return (
      <div
        className={`tk-assignment-list-box__event-single ${eventClass}`}
        onClick={e => {
          e.stopPropagation();
          onEventClick(null, event.assignment.OrderIdentifier);
        }}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.stopPropagation();
            onEventClick(null, event.assignment.OrderIdentifier);
          }
        }}
        tabIndex={0}
      >
        <div className="tk-assignment-list-box__event-single_container1">
          <div className="header">
            <div className="skill">{event.skill}</div>
            {event.assignment?.NumberOfUnreadNotifications > 0 && (
              <div>
                <i className="fa fa-bell" />
              </div>
            )}
          </div>
        </div>
        <div className="tk-assignment-list-box__event-single_container2">
          <div className="fields">
            <i className="fa mr-2 fa-clock"></i>
            {timeFromTo}
          </div>
          <div className="fields">
            <i className="fa mr-2 fa-user"></i>
            {event.assignment.ContactPerson.ContactPersonName}
          </div>
          <div className="fields">
            <i
              className={` mr-2 ${iconForTheServiceType(event.assignment)}`}
            ></i>
            {event.assignment.ArticleName}
          </div>
          <div className="fields text-capitalize">
            <i className="fa mr-2 fa-briefcase"></i>
            {statusStringTraslate(event.orderStatus)}
          </div>
        </div>
      </div>
    );
  }
}

SingleEvent.propTypes = propTypes;
SingleEvent.defaultProps = defaultProps;

export default SingleEvent;
