import React from 'react';
import PropTypes from 'prop-types';
import IfComponent from '../IfComponent';
import CloseButton from '../CloseButton';
import FocusTrap from 'focus-trap-react';

import { preventScrollWhenVisible, removeContextualClass } from '../../utils';

const propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  headerText: PropTypes.string,
  onCloseClicked: PropTypes.func,
  onOverlayClick: PropTypes.func,
  headerRender: PropTypes.node,
  ieOptionClassName: PropTypes.string,
  ariaLabel: PropTypes.string,
  contactPersonUponInterpretationFocus: PropTypes.bool,
  contactPersonFocus: PropTypes.bool,
  paused: PropTypes.bool,
};

const defaultProps = {
  isVisible: false,
  children: null,
  className: '',
  headerClassName: '',
  headerRender: null,
  headerText: '',
  ieOptionClassName: '',
  onCloseClicked: () => {},
  onOverlayClick: () => {},
  ariaLabel: 'polite',
  contactPersonUponInterpretationFocus: false,
  contactPersonFocus: false,
  paused: false,
};

class Modal extends React.Component {
  state = {};
  trapRef = React.createRef();
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isVisible) {
      const { isVisible } = nextProps;
      preventScrollWhenVisible(isVisible);
    } else {
      removeContextualClass();
    }

    return null;
  }

  componentDidMount(nextProps) {
    const { isVisible } = this.props;
    preventScrollWhenVisible(isVisible);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.contactPersonUponInterpretationFocus ||
      prevProps.contactPersonFocus
    ) {
      this.onPause();
    }
  }

  onPause = () => {
    if (this.trapRef.current) {
      this.trapRef.current.focusTrap.pause();
    }
  };

  onHandlerClose = (e, fn) => {
    removeContextualClass();
    fn(e);
  };

  render() {
    const {
      className,
      headerClassName,
      headerText,
      children,
      isVisible,
      onCloseClicked,
      onOverlayClick,
      ieOptionClassName,
      headerRender,
      ariaLabel,
      paused,
    } = this.props;
    return (
      <IfComponent
        condition={isVisible}
        whenTrue={
          <FocusTrap
            focusTrapOptions={{
              escapeDeactivates: false,
              allowOutsideClick: true,
              preventScroll: true,
            }}
            ref={this.trapRef}
            paused={paused}
          >
            <div className={`tv-modal__wrapper tv-display-flex ${className}`}>
              <div
                className="tv-modal__overlay"
                onClick={e => {
                  this.onHandlerClose(e, onOverlayClick);
                }}
                role="button"
                tabIndex="-1"
                aria-label="modal overlay"
              />
              <div
                className={`tv-modal__holder ${ieOptionClassName}`}
                aria-live={ariaLabel}
              >
                <CloseButton
                  onClick={e => {
                    this.onHandlerClose(e, onCloseClicked);
                  }}
                  className="tv-modal__btn-close tv-buttons__type icon-close"
                />
                <IfComponent
                  condition={isVisible}
                  whenTrue={
                    headerRender ? (
                      <>{headerRender}</>
                    ) : (
                      <div className={headerClassName}>{headerText}</div>
                    )
                  }
                />
                <>{children}</>
              </div>
            </div>
          </FocusTrap>
        }
        whenFalse={null}
      />
    );
  }
}

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
