import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOverlay } from '../../Share/components';

const propTypes = {
  children: PropTypes.element.isRequired,
};

const defaultProps = {};

const EmptyLayout = props => (
  <React.Fragment>
    <div className="tv-bg-login-page">
      <div className="tv-login-page__form-container">{props.children}</div>
    </div>
    <LoadingOverlay />
  </React.Fragment>
);

EmptyLayout.propTypes = propTypes;
EmptyLayout.defaultProps = defaultProps;

export default EmptyLayout;
