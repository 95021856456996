import React from 'react';
import { useEffect } from 'react';

const ChatPage = () => {
  useEffect(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/5e298ea5daaca76c6fcf7e7b/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        fontSize: 35,
        gap: 10,
        fontWeight: 'bold',
      }}
    >
      <div>
        <i
          className="fa fa-comment"
          style={{ color: '#014a8e', fontSize: 40 }}
        />
      </div>
      <div style={{ color: '#5d6c73;' }}>Chatta med oss</div>
    </div>
  );
};

export default ChatPage;
