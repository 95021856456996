import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  IfComponent,
  AssignmentStatus,
  TFVButton,
  Attachment,
  Translate,
  Rating,
  ArrowButton,
  LeftDrawerSlide,
  Modal,
  Dialog,
} from '../../../Share/components';
import NotificationItem from './NotificationItem';
import './style.scss';

const propTypes = {
  notifications: PropTypes.shape(PropTypes.array),
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
};

const defaultProps = {
  notifications: [],
  onClose: () => {},
  isVisible: false,
};

const AssignmentDetail = ({ notifications, onClose, isVisible }) => (
  <>
    {isVisible && (
      <LeftDrawerSlide
        isVisible={isVisible}
        hideClose
        hideExternalScroll
        onClose={onClose}
        className="tk-notification-detail__drawer"
      >
        <Dialog
          className="tk-notification-detail__container"
          isVisible
          onClose={onClose}
        >
          <div className="tk-assignment-list-box_header text-center">
            {Translate({ content: 'notification.events' })}
          </div>
          <div className="tk-assignment-list-box_body p-2">
            <div className="tv-workassignment__detail-wrapper d-flex flex-column h-100">
              {notifications &&
                notifications.map(item => (
                  <NotificationItem
                    HeadLine={item.Headline}
                    Datetime={moment(item.DatetimeSent).format(
                      'YYYY-MM-DD HH:mm',
                    )}
                    isNew
                  />
                ))}
            </div>
          </div>
        </Dialog>
      </LeftDrawerSlide>
    )}
  </>
);

AssignmentDetail.propTypes = propTypes;
AssignmentDetail.defaultProps = defaultProps;

export default AssignmentDetail;
