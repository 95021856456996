import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import TimePicker from './TimePicker';
import './rc-time-picker/assets/index.scss';

const propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleClick: PropTypes.func,
  handleValueChange: PropTypes.func,
  defaultValue: PropTypes.shape({}),
  durationCalculate: PropTypes.shape({}),
  allowNextDay: PropTypes.bool,
  startFromDayBegin: PropTypes.bool,
  rest: PropTypes.shape({}),
  timeLimit: PropTypes.number,
  isDisableTime: PropTypes.bool,
  fromBookNowModal: PropTypes.bool,
  ariaLabel: PropTypes.string,
  isTimeValid: PropTypes.bool,
};

const defaultProps = {
  className: '',
  isDisabled: false,
  handleClick: () => {},
  handleValueChange: () => {},
  defaultValue: moment(),
  durationCalculate: moment(),
  allowNextDay: false,
  startFromDayBegin: false,
  rest: {},
  timeLimit: 30,
  isDisableTime: false,
  fromBookNowModal: false,
  ariaLabel: '',
  isTimeValid: true,
};

const TFVTimePickerV2 = ({
  className,
  isDisabled,
  handleClick,
  defaultValue,
  durationCalculate,
  allowNextDay,
  isDisableTime,
  startFromDayBegin,
  handleValueChange,
  timeLimit,
  ariaLabel,
  isTimeValid,
  ...rest
}) => {
  return (
    <TimePicker
      {...rest}
      defaultValue={defaultValue}
      durationCalculate={durationCalculate}
      showSecond={false}
      allowNextDay={allowNextDay}
      startFromDayBegin={startFromDayBegin}
      disabled={isDisabled}
      className={className}
      minuteStep={timeLimit}
      onChange={e => {
        handleValueChange(e);
      }}
      isDisableTime={isDisableTime}
      ariaLabel={ariaLabel}
      isTimeValid={isTimeValid}
    />
  );
};

TFVTimePickerV2.propTypes = propTypes;
TFVTimePickerV2.defaultProps = defaultProps;

export default TFVTimePickerV2;
