import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import TimesheetHeader from './TimesheetHeader';
import {
  IfComponent,
  Translate,
  Modal,
  Row,
  Col,
} from '../../../Share/components';
import { assignmentTimeFormat, isIE } from '../../../Share/utils/';
import {
  getNearest5MinutesInterval,
  convertStringToDate,
} from '../../../Share/utils/dateUtil';
import InterpretationTime from './InterpretationTime';
import CreateDeviation from '../CreateDeviation';
import SuccessMessage from '../NewAssignment/SuccessMessage';

import './style.scss';
import { getAttachment, setOrderRating, createDeviation } from '../../actions';
import { status, PAGE_TYPES } from '../../../Share/constants';
import * as api from '../../api';
import moment from 'moment';

const propTypes = {
  localWorkAssignment: PropTypes.shape({}),
  assignmentDetail: PropTypes.shape({}),
  onOrderRated: PropTypes.func,
};

const defaultProps = {
  assignmentDetail: {},
  onOrderRated: () => {},
  localWorkAssignment: {},
};

const toHHMMSS = secs => {
  const secNum = parseInt(secs, 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return hours > 0 ? `${hours} tim ${minutes} minuter` : `${minutes} minuter`;
};

class TimeSheet extends Component {
  constructor(props) {
    super(props);
    this.originalTitle = document.title;
    const baseStartTime = getNearest5MinutesInterval();
    const baseEndTime = getNearest5MinutesInterval();
    this.state = {
      stateKey: new Date().getTime(),
      travelTime: {
        startHour: baseStartTime,
        endHour: baseEndTime,
        location: '',
        distance: '',
      },
      travelTimeHome: {
        startHour: baseEndTime,
        endHour: baseEndTime,
        location: '',
        distance: '',
      },
      interpretationTime: {
        startHour: baseStartTime,
        endHour: baseStartTime,
        breakTime: '0',
      },
      signature: {
        SignatureContentSize: 0,
        SignatureContentType: null,
        SignatureFile: null,
        SignatureFilename: null,
      },
      showDeviationModal: false,
      showSuccessMessage: false,
      deviationNumber: '',
      isDeviation: false,
    };
    this.componentRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { assignmentDetail } = nextProps;
    if (
      assignmentDetail &&
      assignmentDetail.status === status.fullfilled.name &&
      nextProps.state.assignmentDetail !== prevState.assignmentDetail
    ) {
      const localDataTimeFrom = nextProps.localWorkAssignment[
        assignmentDetail.WorkAssignmentIdentifier
      ]
        ? nextProps.localWorkAssignment[
            assignmentDetail.WorkAssignmentIdentifier
          ].startWorkingTime
        : assignmentDetail.DatetimeOrderFrom;
      const localDataTimeTo = nextProps.localWorkAssignment[
        assignmentDetail.WorkAssignmentIdentifier
      ]
        ? nextProps.localWorkAssignment[
            assignmentDetail.WorkAssignmentIdentifier
          ].fullfilledTime
        : assignmentDetail.DatetimeOrderTo;

      const interpretationStartTime = assignmentDetail.IsPerformedRemotely
        ? localDataTimeFrom
        : assignmentDetail.DatetimeOrderFrom;
      const interpretationEndTime = assignmentDetail.IsPerformedRemotely
        ? localDataTimeTo
        : assignmentDetail.DatetimeOrderTo;
      return {
        interpretationTime: {
          startHour: convertStringToDate(interpretationStartTime),
          endHour: convertStringToDate(interpretationEndTime),
          breakTime: '0',
        },
        travelTime: {
          startHour: convertStringToDate(interpretationStartTime),
          endHour: convertStringToDate(interpretationStartTime),
          location: '',
          distance: '',
        },
        travelTimeHome: {
          startHour: convertStringToDate(interpretationEndTime),
          endHour: convertStringToDate(interpretationEndTime),
          location: '',
          distance: '',
        },
        assignmentDetail: nextProps.location.state.assignmentDetail,
      };
    }
    // change condition when api to complete
    if (
      assignmentDetail &&
      assignmentDetail.TimeReport &&
      assignmentDetail.TimeReport.DatetimeOriginalFrom
    ) {
      const attachments = assignmentDetail.Expenses
        ? assignmentDetail.Expenses.map(x => ({
            amount: x.AmountWithVAT,
            file: {
              name: x.AttachmentMetadata
                ? x.AttachmentMetadata.AttachmentFilename
                : '',
              attachmentReference: x.AttachmentMetadata
                ? x.AttachmentMetadata.AttachmentReference
                : null,
            },
          }))
        : [];
      return {
        travelTime: {
          startHour: convertStringToDate(
            assignmentDetail.TimeReport.DatetimeTravelToAssignmentFrom,
          ),
          endHour: convertStringToDate(
            assignmentDetail.TimeReport.DatetimeTravelToAssignmentTo,
          ),
          location: '',
          distance: assignmentDetail.TimeReport.DistanceToAssignment
            ? parseFloat(assignmentDetail.TimeReport.DistanceToAssignment)
            : 0,
        },
        travelTimeHome: {
          startHour: convertStringToDate(
            assignmentDetail.TimeReport.DatetimeTravelFromAssignmentFrom,
          ),
          endHour: convertStringToDate(
            assignmentDetail.TimeReport.DatetimeTravelFromAssignmentTo,
          ),
          location: '',
          distance: assignmentDetail.TimeReport.DistanceFromAssignment
            ? parseFloat(assignmentDetail.TimeReport.DistanceFromAssignment)
            : 0,
        },
        interpretationTime: {
          startHour: convertStringToDate(
            assignmentDetail.TimeReport.DatetimeOriginalFrom,
          ),
          endHour: convertStringToDate(
            assignmentDetail.TimeReport.DatetimeOriginalTo,
          ),
          breakTime: (Number(assignmentDetail.TimeReport.Downtime) / 60)
            .toFixed(2)
            .toString(),
        },
        parkingTicket: {
          file: '',
          imagePreviewUrl: '',
          receipt: '',
          amount: '',
          expenseType: 1,
        },
        attachments,
        rate: 0,
        comment: null,
        feedback: '',
        isTravelByOwnCar: false,
        showModal: false,
        file: '',
        imagePreviewUrl: '',
        isDisabled: true,
      };
    }
    return null;
  }

  async componentDidMount() {
    const { assignmentDetail } = this.props;
    document.title =
      'Tidrapport ' + assignmentDetail.OrderNumber + ' - TFV Kundportal';

    const res = await api.getTimeReportSignature(
      this.props.token,
      assignmentDetail.OrderIdentifier,
    );
    if (res && res.data && res.data.Signature) {
      const signature = {
        SignatureContentSize: res.data.Signature.SignatureContentSize,
        SignatureContentType: res.data.Signature.SignatureContentType,
        SignatureFile: res.data.Signature.SignatureFile,
        SignatureFilename: res.data.Signature.SignatureFilename,
      };
      this.setState({ signature });
    }
  }

  async componentWillUnmount() {
    document.title = this.originalTitle;
  }

  setShowDeviationModal = modalState => {
    this.setState({
      showDeviationModal: modalState,
    });
  };

  handleSubmit = (
    rateValue,
    commentValue,
    ratingReasonValue,
    isDeviation = false,
  ) => {
    const { onOrderRated, assignmentDetail } = this.props;
    if (isDeviation) {
      this.setState({
        isDeviation,
      });
    }
    onOrderRated(
      assignmentDetail.OrderIdentifier,
      rateValue,
      commentValue !== '' ? commentValue : null,
      rateValue <= 2 ? ratingReasonValue : null,
      this.rateFeedBack,
    );
  };

  rateFeedBack = feedback => {
    const { isDeviation } = this.state;
    if (feedback.IsSuccess && isDeviation) {
      this.setState({
        showDeviationModal: true,
      });
    }
    return null;
  };

  navigateOnDeviationSuccess = () => {
    const { from } = this.props;
    if (from === PAGE_TYPES.DASHBOARD) {
      window.location.href = '/';
    } else if (from === PAGE_TYPES.ASSIGNMENT) {
      window.location.href = '/my-assignment';
    }
    return null;
  };

  deviationFeedBack = DeviationNumber => {
    this.setState({
      showSuccessMessage: true,
      deviationNumber: DeviationNumber,
    });
  };

  onDownload = file => {
    const { getAttachment } = this.props;
    if (file) {
      getAttachment(file?.AttachmentMetadata?.AttachmentReference);
    }
  };
  render() {
    const {
      travelTime,
      travelTimeHome,
      interpretationTime,
      stateKey,
      signature,
      showDeviationModal,
      showSuccessMessage,
      attachments,
      deviationNumber,
    } = this.state;
    // eslint-disable-next-line react/prop-types

    const { assignmentDetail, ratingReasons, mapOnCreateDeviation } =
      this.props;
    const { showModal, onHandleModal } = this.props;
    const formatteDate = `${moment(assignmentDetail.DatetimeFrom)
      .locale('sv')
      .format('DD MMMM YYYY')},${assignmentTimeFormat(
      assignmentDetail.DatetimeFrom,
      assignmentDetail.DatetimeTo,
    )}`;
    return (
      <Modal
        ref={this.componentRef}
        isVisible={showModal}
        className="tv-workassignment__modal timesheet__modal"
        onCloseClicked={() => {
          onHandleModal();
        }}
        onOverlayClick={() => {
          onHandleModal();
        }}
        headerClassName={`tv-workassignment__modal-header ${
          isIE ? 'ie-fix-width' : ''
        }`}
        headerText={
          showDeviationModal || showSuccessMessage
            ? Translate({
                content: 'report.sendDeviation',
              })
            : Translate({
                content: 'timeReport.title',
              })
        }
        ieOptionClassName={isIE ? 'ie-fix-height' : ''}
      >
        <div>
          {!showSuccessMessage && !showDeviationModal && (
            <div className="timesheet">
              <Row>
                <TimesheetHeader
                  date={formatteDate}
                  title={Translate({ content: 'timesheet.timesheetReport' })}
                  timesheetId={assignmentDetail.OrderNumber}
                  description={assignmentDetail.AddressPerformingEffective}
                  orderIdentifier={assignmentDetail.OrderIdentifier}
                  ratingCustomer={assignmentDetail.RatingCustomer}
                  handleOrderRate={this.handleSubmit}
                  ratingReasons={ratingReasons}
                  canBeRated={assignmentDetail.CanBeRated}
                  comment={assignmentDetail.RatingCustomerComment}
                  onCreateDeviation={(val, callback) => {
                    mapOnCreateDeviation(val, callback);
                  }}
                  assignment={assignmentDetail}
                />
                <IfComponent
                  condition={!assignmentDetail.IsPerformedRemotely}
                  whenTrue={
                    <React.Fragment>
                      <div className="d-flex flex-column flex-md-row justify-content-between w-100">
                        <InterpretationTime
                          isDisabled
                          startHour={interpretationTime.startHour}
                          endHour={interpretationTime.endHour}
                          breakTime={interpretationTime.breakTime}
                        />

                        {/* <div className="timesheet__travel_time ">
                          <span className="timesheet__travel_time-title">
                            {Translate({
                              content: 'timesheet.travelTime',
                            })}
                          </span>
                          <div className="row read-only-data">
                            <div className="d-flex justify-content-between">
                              <label>
                                <Translate content="timesheet.travelToTime" />
                              </label>
                              <span>
                                {toHHMMSS(
                                  assignmentDetail?.TimeReport
                                    ?.TraveltimeToAssignment || 0,
                                )}
                              </span>
                            </div>
                            <div className="d-flex justify-content-between">
                              <label>
                                <Translate content="timesheet.travelHomeTime" />
                              </label>
                              <span>
                                <span>
                                  {toHHMMSS(
                                    assignmentDetail?.TimeReport
                                      ?.TraveltimeFromAssignment || 0,
                                  )}
                                </span>
                              </span>
                            </div>
                            <div className="d-flex justify-content-between">
                              <label>Avstånd i km </label>
                              <span>
                                <span>
                                  {(assignmentDetail?.TimeReport
                                    ?.TravelDistanceFrom || 0) +
                                    (assignmentDetail?.TimeReport
                                      ?.TravelDistanceTo || 0)}{' '}
                                  km
                                </span>
                              </span>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      {attachments && attachments?.length > 0 && (
                        <>
                          <div className="d-flex flex-column flex-md-row justify-content-between w-100 mb-3 timesheet__divider">
                            <div className="timesheet__travel_time ">
                              <span className="timesheet__travel_time-title">
                                Övriga utgifter
                              </span>
                              {assignmentDetail?.Expenses?.filter(
                                y => y.ExpenseType === 2,
                              ).map(x => (
                                <AttachmentFiles
                                  file={x}
                                  key={
                                    x?.AttachmentMetadata
                                      ?.AttachmentReference || '1'
                                  }
                                  onDownload={this.onDownload}
                                />
                              ))}
                            </div>
                            <div className="timesheet__travel_time ">
                              <span className="timesheet__travel_time-title">
                                Parkering Kvitton
                              </span>
                              {assignmentDetail?.Expenses?.filter(
                                y => y.ExpenseType === 1,
                              ).map(x => (
                                <AttachmentFiles
                                  file={x}
                                  key={
                                    x?.AttachmentMetadata
                                      ?.AttachmentReference || '1'
                                  }
                                  onDownload={this.onDownload}
                                />
                              ))}
                            </div>
                          </div>
                        </>
                      )}{' '}
                    </React.Fragment>
                  }
                  whenFalse={
                    <div className="d-flex w-100">
                      <React.Fragment>
                        <InterpretationTime
                          isDisabled
                          startHour={interpretationTime.startHour}
                          endHour={interpretationTime.endHour}
                          breakTime={interpretationTime.breakTime}
                        />
                      </React.Fragment>
                    </div>
                  }
                />
                {signature.SignatureContentSize > 0 && (
                  <div className="d-flex timesheet__divider w-100">
                    <div className="timesheet__signature timesheet__travel_time">
                      <span className="timesheet__travel_time-title">
                        {Translate({ content: 'timeSheet.clientSignature' })}
                      </span>
                      <img
                        src={`data:${signature.SignatureContentType};base64,${signature.SignatureFile}`}
                        alt=""
                      />
                    </div>
                  </div>
                )}
              </Row>

              <Row>
                <Col className="pl-0">
                  <button
                    onClick={() => {
                      onHandleModal();
                    }}
                    className="timesheet-button_cancel"
                  >
                    <Translate content="timesheet.cancel" />
                  </button>
                </Col>
                <Col className="pr-0">
                  <ReactToPrint
                    trigger={() => (
                      <button className="timesheet-button_submit">
                        <Translate content="reportQuality.printButton" />
                      </button>
                    )}
                    content={() => this.componentRef.current}
                  />
                </Col>
              </Row>
            </div>
          )}
          {showSuccessMessage && (
            <SuccessMessage
              mode="deviation"
              onClose={() => {
                this.setState({
                  showSuccessMessage: false,
                });
                onHandleModal();
                this.navigateOnDeviationSuccess();
              }}
              visible={showSuccessMessage}
              deviationNumber={deviationNumber}
            />
          )}

          {showDeviationModal && (
            <CreateDeviation
              assignment={assignmentDetail}
              isGeneral={false}
              onCloseClicked={() => this.setShowDeviationModal(false)}
              onDeviationSuccess={createdDeviationNumber => {
                this.setShowDeviationModal(false);
                this.deviationFeedBack(createdDeviationNumber);
              }}
            />
          )}
        </div>
      </Modal>
    );
  }
}

TimeSheet.propTypes = propTypes;
TimeSheet.defaultProps = defaultProps;

const mapStateToProps = state => ({
  // assignmentDetail: state.workAssignment.assignmentDetail,
  localWorkAssignment: state.localWorkAssignment,
  token: state.authentication.token,
  ratingReasons: state.workAssignment.ratingReasons,
});

const mapDispatchToProps = dispatch => ({
  getAttachment: e => {
    dispatch(getAttachment(e));
  },
  mapOnCreateDeviation: (data, callback) => {
    dispatch(createDeviation(data, callback));
  },
  onOrderRated: (orderIdentifier, rate, comment, reason, callback) => {
    dispatch(setOrderRating(orderIdentifier, rate, comment, reason, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeSheet);

const AttachmentFiles = ({ file, onDownload }) => (
  <>
    <div
      className="timesheet__attached-file__container"
      onClick={() => onDownload(file)}
    >
      <div className="timesheet__attached-file__amount">
        {file.AmountWithVAT || 0} kr
      </div>
      <div className="timesheet__attached-file__content">
        <i className="fas fa-receipt mr-3 " />
        <div className="timesheet__attached-file__name">
          {file.AttachmentMetadata?.AttachmentFilename || '-'}
        </div>
      </div>{' '}
    </div>
  </>
);
