import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isIE } from '../../../Share/utils';

import {
  Translate,
  TFVButton,
  TextField,
  Modal,
} from '../../../Share/components';
import './style.scss';

const propTypes = {
  onHandleConfirm: PropTypes.func,
  showModal: PropTypes.bool,
};

const defaultProps = {
  onHandleConfirm: () => {},
  showModal: false,
};

class InfoModal extends Component {
  handleChange = event => {
    event.persist();
  };

  render() {
    const { showModal, onHandleConfirm } = this.props;
    return (
      <React.Fragment>
        <Modal
          className="tv-single-assign-form__modal"
          onCloseClicked={() => {
            onHandleConfirm();
          }}
          onOverlayClick={() => {
            onHandleConfirm();
          }}
          isVisible={showModal}
          headerClassName="tv-interperet-now__header-text"
          headerText={Translate({
            content: 'singleAssignment.dateRangePickerTodayWarningNotify',
          })}
          ieOptionClassName={isIE ? 'ie-fix-height' : ''}
        >
          <div className="tv-single-assign-form__modal-wrap">
            <div className="tv-row tv-single-assign-form__label tv-single-assign-form__label--contact-note">
              <div className="tv-col-12">
                <Translate content="singleAssignment.dateRangePickerTodayWarning" />
              </div>
            </div>
            <br />
            <div className="tv-single-assign-form__modal-buttons tv-display-flex">
              <TFVButton
                type="primary"
                buttonType="submit"
                text={Translate({
                  content:
                    'singleAssignment.dateRangePickerTodayWarningConfirm',
                })}
                onClick={() => {
                  onHandleConfirm();
                }}
              />
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

InfoModal.propTypes = propTypes;
InfoModal.defaultProps = defaultProps;

export default InfoModal;
