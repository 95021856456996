import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-form-validator-core';
import { TFVButton, TextField, Translate } from '../../../Share/components';
import { setNewPassword } from '../../actions/authentication';
import './style.scss';
import decrypt from '../../../Share/utils/cryptoUtil';
import { passwordPolicyCheck } from '../../../Share/utils';

class SetNewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerNumber: '',
      password: '',
      retypePassword: '',
      accessToken: '',
      errorMessage: '',
      isSubmitButtonClick: false,
    };
  }

  componentDidMount() {
    // Add validation for ValidatorForm
    ValidatorForm.addValidationRule('isReTypePasswordMatch', value => {
      if (value && value === '') {
        return true;
      }
      if (!this.state.isSubmitButtonClick) return true;
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule('passwordPolicy', value => {
      if (value && value === '') {
        return true;
      }
      if (!this.state.isSubmitButtonClick) return true;
      const { isValid, errorMessage } = passwordPolicyCheck(value);
      this.setState({ errorMessage });
      return isValid;
    });
    this.handleParseUrl();
  }

  handleParseUrl = () => {
    const params = new URLSearchParams(this.props.location.search);
    const isSetNewPasswordFromEmailLink = params.get('data');

    if (isSetNewPasswordFromEmailLink) {
      const encodedData = decodeURIComponent(
        this.props.location.search.split('=')[1],
      );
      const encryptedData = encodedData.replace(/\s/g, '+');

      if (encryptedData) {
        const plaintextData = decrypt(encryptedData);
        const paramsParser = new URLSearchParams(plaintextData);

        const customerNumber = paramsParser.get('customer_id');
        const accessToken = paramsParser.get('token');

        if (customerNumber) {
          this.setState({ customerNumber, accessToken });
        }
      }
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleValidate = () => {
    const { isValid } = passwordPolicyCheck(this.state.password || '');
    return isValid && this.state.retypePassword === this.state.password;
  };

  handleSubmit = e => {
    // e.preventDefault();
    this.setState({ isSubmitButtonClick: true });
    if (this.handleValidate()) {
      const { customerNumber, password, accessToken } = this.state;
      this.props.onSetNewPassword(customerNumber, password, accessToken);
    }
  };

  handleValidatorListener = () => {
    this.form.isFormValid().then(isValid => {
      this.setState({ submitDisabled: !isValid });
    });
  };
  render() {
    const { errorMessage } = this.state;
    return (
      <div className="tv-login__wrapper tv-display-flex">
        <div className=" tv-login__form tv-login__form-wrapper">
          <div className="tv-set-new-password__title">
            <Translate content="login.newPassword" />
          </div>
          <div className="tv-set-new-password__description">
            {/* <Translate content="login.createYourNewPassword" /> <br /> */}
            <Translate content="login.passwordPolicyText" />
          </div>
          <div className="tv-set-new-password__form tv-set-new-password__form-wrapper">
            <ValidatorForm
              ref={node => (this.form = node)}
              onSubmit={() => {}}
              // onError={this.handleValidatorListener}
            >
              <label htmlFor='password' className="tv-login__form-interpreter-id-label tv-login__form-label">
                <Translate content="login.enterNewPassword" />
              </label>
              <TextField
                type="password"
                // validatorListener={this.handleValidatorListener}
                onChange={this.handleChange}
                className="tx-input-text tv-login__form-interpreterId-input"
                name="password"
                id="password"
                value={this.state.password}
                placeholder={Translate({ content: 'login.enterNewPassword' })}
                validators={['required', 'passwordPolicy']}
                errorMessages={['', errorMessage]}
              />

              <label htmlFor='retypePassword' className="tv-login__form-interpreter-id-label tv-login__form-label">
                <Translate content="login.retypeNewPassword" />
              </label>
              <TextField
                type="password"
                // validatorListener={this.handleValidatorListener}
                onChange={this.handleChange}
                className="tx-input-text tv-login__form-interpreterId-input"
                name="retypePassword"
                id="retypePassword"
                value={this.state.retypePassword}
                placeholder={Translate({ content: 'login.retypeNewPassword' })}
                validators={['required', 'isReTypePasswordMatch']}
                errorMessages={[
                  Translate({
                    content: 'validator.isNotValid',
                    params: {
                      name: Translate({
                        content: 'general.repeatPassword',
                      }),
                    },
                  }),
                  Translate({
                    content: 'validator.passwordMatch',
                  }),
                ]}
              />

              <TFVButton
                type="primary"
                buttonType="submit"
                text={Translate({ content: 'login.changePassword' })}
                className="tv-set-new-password__form-submit"
                onClick={this.handleSubmit}
                // disabled={this.state.submitDisabled}
              />
            </ValidatorForm>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSetNewPassword: (customerNumber, plainTextPassword, accessToken) => {
    dispatch(setNewPassword(customerNumber, plainTextPassword, accessToken));
  },
});

export default connect(null, mapDispatchToProps)(SetNewPassword);
