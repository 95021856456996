import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Avatar from '../Avatar';
import {
  onUpdateSearchByOrder,
  getCustomer,
  logout,
  autoAssignOpeningHours,
  displayNewBooking,
} from '../../actions';
import './style.scss';
import history from '../../../Share/utils/history';
import { Translate, Logo, TFVButton } from '../../../Share/components';
import BookNowModal from '../BookNowModal';
import NotificationContainer from '../NotificationContainer';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { preventScrollWhenVisible } from '../../../Share/utils';

const propTypes = {
  interpreterId: PropTypes.string,
  mapOnUpdateSearchByOrder: PropTypes.func,
  profile: PropTypes.shape({}),
  bookDirectOpeningHours: PropTypes.shape({}),
  displayKPIReports: PropTypes.bool,
  showBookDirectModal: PropTypes.bool,
  onGetCustomer: PropTypes.func,
  onLogout: PropTypes.func,
  getAutoAssignOpeningHours: PropTypes.func,
  onClickNavigationButton: PropTypes.func,
  CanCreateAutoAssignOrder: PropTypes.bool,
  onCreateNewBooking: PropTypes.func,
};

const defaultProps = {
  interpreterId: '',
  mapOnUpdateSearchByOrder: () => {},
  profile: {},
  displayKPIReports: false,
  showBookDirectModal: false,
  CanCreateAutoAssignOrder: false,
  onGetCustomer: () => {},
  bookDirectOpeningHours: {},
  getAutoAssignOpeningHours: () => {},
  onLogout: () => {},
  onClickNavigationButton: () => {},
  onCreateNewBooking: () => {},
};

const INITAL_STATES = {
  searchOrder: '',
  isSearchInMenu: false,
  bookNowModalVisible: false,
};

class RightStatus extends Component {
  constructor(props) {
    super(props);
    this.state = INITAL_STATES;
    history.listen(location => {
      if (location.pathname !== '/search-result') {
        if (this.ismounted) {
          this.setState({
            isSearchInMenu: false,
          });
        }
      }
    });
  }

  componentDidMount() {
    this.ismounted = true;
    this.checkNewEmergencyBooking(this.props.location.pathname);
    // document.addEventListener('keydown', this.handleNewBooking);
  }

  componentDidUpdate(prevProps) {
    const { showBookDirectModal, getAutoAssignOpeningHours } = this.props;
    if (
      prevProps.showBookDirectModal !== showBookDirectModal &&
      showBookDirectModal
    ) {
      getAutoAssignOpeningHours({
        DateFrom: moment().format('YYYY-MM-DD'),
        DateTo: moment().format('YYYY-MM-DD'),
      });
    }
  }

  componentWillUnmount() {
    this.ismounted = false;
    // document.removeEventListener('keydown', this.handleNewBooking);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.searchOrder === '' && !prevState.isSearchInMenu) {
      return {
        searchOrder: '',
      };
    }
    return null;
  }

  // eslint-disable-next-line react/sort-comp
  handleUpdateOrder = event => {
    this.setState({
      searchOrder: event.target.value,
      isSearchInMenu: true,
    });
  };

  handleBookNowModal = () => {
    const { bookNowModalVisible } = this.state;
    this.setState({
      bookNowModalVisible: !bookNowModalVisible,
    });
  };

  handleSubmitSearch = () => {
    const { mapOnUpdateSearchByOrder } = this.props;
    this.setState({
      isSearchInMenu: false,
    });
    mapOnUpdateSearchByOrder(this.state.searchOrder);
    history.push('/search-result');
  };

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleSubmitSearch();
    }
  }
  onClickNavigationButton = event => {
    this.props.onClickNavigationButton(event);
    preventScrollWhenVisible(true);
  };

  checkNewEmergencyBooking = path => {
    if (path === '/new-emergency-booking') {
      this.props.handleBookDirectModal();
    }
  };

  // handleNewBooking = event => {
  //   if (event.shiftKey && event.key.toLowerCase() === 'n') {
  //     this.showNewBooking();
  //   }
  // };

  showNewBooking = () => {
    const currentPath = this.props.location.pathname;
    if (currentPath !== '/') {
      history.push('/');
    }
    this.props.onCreateNewBooking();
  };

  render() {
    const { searchOrder } = this.state;
    const {
      showBookDirectModal,
      handleBookDirectModal,
      CanCreateAutoAssignOrder,
      bookDirectOpeningHours,
      navigationRef,
    } = this.props;
    const setNavLinkIsActive = (match, location) =>
      match || /submit-time/.test(location.pathname);
    return (
      <div className="tv-right-status" id="navigation" ref={navigationRef}>
        <Logo />
        <div className="flex-grow-1 tv-bookDirect-button-nav__wrapper direct-booking-wrapper">
          {/* <p className="mb-0 show-direct-book">Boka tolk innevarande dag</p>
          <FaLongArrowAltRight
            className="top-arrow show-direct-book"
            aria-label="top arrow"
          /> */}
          {CanCreateAutoAssignOrder && (
            <button
              className="booking-button tv-display-mobile-hide"
              onClick={handleBookDirectModal}
              tabIndex={0}
              title="Boka tolk innevarande dag"
            >
              <i className="fa fa-plus mr-2" />
              Akut Tolk
            </button>
          )}
        </div>
        <div className="tv-bookDirect-button-nav__wrapper ml-3 quick-booking-wrapper">
          {/* <p className="mb-0 show-direct-book">Boka tolk övrig tid</p>
          <FaLongArrowAltRight
            className="top-arrow show-direct-book"
            aria-label="top arrow"
          /> */}
          <button
            className="booking-button tv-display-mobile-hide"
            onClick={this.showNewBooking}
            tabIndex={0}
            title="Boka tolk övrig tid"
          >
            <i className="fa fa-plus mr-2" />
            Ny bokning
          </button>
        </div>
        <div className=" navigation">
          <nav className="navbar-expand">
            <div className="navbar-nav">
              <NavLink
                onClick={this.onClickNavItem}
                isActive={setNavLinkIsActive}
                exact
                activeClassName="nav-active"
                to="/"
                className="nav-item"
              >
                <Translate content="sideBar.dashboard" />
              </NavLink>
              {this.props.displayKPIReports ? (
                <NavLink
                  isActive={setNavLinkIsActive}
                  exact
                  activeClassName="nav-active"
                  to="/reports-dashboard/"
                  className="nav-item"
                >
                  {Translate({
                    content: 'report.statistics',
                  })}
                </NavLink>
              ) : null}

              <NavLink
                isActive={setNavLinkIsActive}
                exact
                activeClassName="nav-active"
                to="/translation"
                className="nav-item"
              >
                {Translate({
                  content: 'sideBar.translations',
                })}
              </NavLink>
              <NavLink
                isActive={setNavLinkIsActive}
                exact
                activeClassName="nav-active"
                to="/my-assignment"
                className="nav-item"
              >
                {Translate({
                  content: 'sideBar.myAssignment',
                })}
              </NavLink>
              <NavLink
                isActive={setNavLinkIsActive}
                exact
                activeClassName="nav-active"
                to={{
                  pathname: '/report-quality',
                  state: { isGeneral: true },
                }}
                className="nav-item"
              >
                <Translate content="sideBar.reportQuality" />
              </NavLink>
              <NavLink
                isActive={setNavLinkIsActive}
                exact
                activeClassName="nav-active"
                to="/contact-tfv"
                className="nav-item"
              >
                <span>
                  <Translate content="sideBar.contactTFV" />
                </span>
              </NavLink>
            </div>
          </nav>
        </div>

        {showBookDirectModal && !isEmpty(bookDirectOpeningHours) && (
          <BookNowModal
            isVisible={showBookDirectModal}
            onCloseClicked={handleBookDirectModal}
            bookDirectOpeningHours={bookDirectOpeningHours}
          />
        )}

        <NotificationContainer />
        <div className=" mobile-nav position-relative text-right">
          <Avatar
            interpreterId={this.props.interpreterId}
            profile={this.props.profile}
            getCustomer={this.props.onGetCustomer}
            logout={this.props.onLogout}
            visibleName={false}
          />
          <button
            onClick={this.onClickNavigationButton}
            className="menu-button fa fa-bars"
            aria-label="menu button"
          />
        </div>
      </div>
    );
  }
}

RightStatus.propTypes = propTypes;
RightStatus.defaultProps = defaultProps;

const mapStateToProps = state => ({
  interpreterId: state.authentication.interpreterId,
  profile: state.user.profile,
  searchOrder: state.workAssignment.searchOrder,
  bookDirectOpeningHours: state.workAssignment.bookDirectOpeningHours,
});

const mapDispatchToProps = dispatch => ({
  mapOnUpdateSearchByOrder: searchOrder => {
    dispatch(onUpdateSearchByOrder(searchOrder));
  },
  onGetCustomer: () => {
    dispatch(getCustomer());
  },
  onLogout: () => {
    dispatch(logout());
  },
  getAutoAssignOpeningHours: dateObj => {
    dispatch(autoAssignOpeningHours(dateObj));
  },
  onCreateNewBooking: () => {
    dispatch(displayNewBooking());
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RightStatus),
);
