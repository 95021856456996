import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SideBar, RightStatus, MobileNavigation } from '../components';
import { LoadingOverlay, Logo } from '../../Share/components';
import {
  fetchArticles,
  fetchEducationSkills,
  fetchSkills,
  getNotificationList,
} from '../actions/common';
import { createUpdateWebSession } from '../../Share/utils/browserUtil';
import {
  fetchTranslationSkills,
  onUpdateSearchByOrder,
  setCustomerKPIReportsDispalyRight,
} from '../actions';
import history from '../../Share/utils/history';
import { showChat } from '../../Share/utils';
import SkipLinks from '../components/SkipLinks';

const propTypes = {
  children: PropTypes.element.isRequired,
  fetchArticles: PropTypes.func.isRequired,
  fetchSkills: PropTypes.func.isRequired,
  checkCustomerAllowedToAccessKPIReport: PropTypes.func.isRequired,
  onUpdateSearchByOrder: PropTypes.func.isRequired,
  displayKPIReports: PropTypes.bool,
  searchOrder: PropTypes.string,
  CanCreateAutoAssignOrder: PropTypes.bool,
  customerNumber: PropTypes.number,
  fetchTranslationSkills: PropTypes.func.isRequired,
  fetchEducationSkills: PropTypes.func,
};

const defaultProps = {
  displayKPIReports: false,
  searchOrder: '',
  CanCreateAutoAssignOrder: false,
  fetchEducationSkills: () => {},
};

const INITAL_STATES = {
  visibleMobileNavigation: false,
  showBookDirectModal: false,
  displaySkipLinks: false,
  firstLinkFocused: true,
  secondLinkFocused: false,
  firstTabNavigation: false,
  activeFocusedElement: null,
  firstTimeClicked: false,
};

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = INITAL_STATES;
    history.listen(location => {
      if (location.pathname !== '/search-result') {
        this.props.onUpdateSearchByOrder('');
      }
    });

    this.navigationRef = React.createRef();
    this.mainContentRef = React.createRef();
  }
  componentDidMount() {
    // this.props.fetchArticles();
    this.props.fetchSkills();
    this.props.fetchTranslationSkills();
    this.props.checkCustomerAllowedToAccessKPIReport();
    this.props.getNotificationList();
    this.props.fetchEducationSkills();
    const vh = document.body.scrollHeight;
    const rightWrapper = document.querySelector('.tv-right-pannel-wrapper');
    if (rightWrapper) {
      rightWrapper.style.setProperty('--vh', `${vh}px`);
    }

    createUpdateWebSession(this.props.customerNumber);
    showChat();

    document.addEventListener('keydown', this.handleFirstTabPress);
    document.addEventListener('click', this.handleOutsideClick);
    // document.addEventListener('keydown', this.handleKeyPress);
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeFocusedElement } = this.state;
    if (prevState.activeFocusedElement !== activeFocusedElement) {
      document.addEventListener('keydown', this.handleFocusElementTabPress);
      document.addEventListener('click', this.handleOutsideClick);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleFirstTabPress);
    document.removeEventListener('keydown', this.handleFocusElementTabPress);
    document.removeEventListener('click', this.handleOutsideClick);
    // document.removeEventListener('keydown', this.handleKeyPress);
  }

  hideSkipLinks = () => {
    this.setState({
      firstLinkFocused: false,
      secondLinkFocused: false,
      displaySkipLinks: false,
    });
  };

  handleOutsideClick = event => {
    if (event.type === 'click') {
      const { activeFocusedElement, displaySkipLinks } = this.state;
      if (activeFocusedElement !== null) {
        activeFocusedElement.style.outline = 'none';
        this.setState({
          activeFocusedElement: null,
        });
      }
      if (displaySkipLinks === true) {
        this.setState({
          displaySkipLinks: false,
          firstLinkFocused: false,
          secondLinkFocused: false,
        });
      }
      this.setState({
        firstTimeClicked: true,
      });
    }
  };

  handleFocusElementTabPress = event => {
    const { activeFocusedElement } = this.state;
    if (event.keyCode === 9 && activeFocusedElement !== null) {
      event.preventDefault();
      activeFocusedElement.style.outline = 'none';
      const focusableElements = activeFocusedElement.querySelectorAll(
        'button, input, select, textarea',
      );

      let firstVisibleElement;
      for (let i = 0; i < focusableElements.length; i++) {
        const element = focusableElements[i];
        const elementStyle = window.getComputedStyle(element);
        if (
          elementStyle.display !== 'none' &&
          elementStyle.visibility !== 'hidden'
        ) {
          firstVisibleElement = element;
          break;
        }
      }

      if (firstVisibleElement) {
        setTimeout(() => {
          firstVisibleElement.focus();
        });
        this.setState({
          activeFocusedElement: null,
        });
      }
    }
  };

  handleFirstTabPress = event => {
    const {
      firstLinkFocused,
      secondLinkFocused,
      displaySkipLinks,
      firstTabNavigation,
      firstTimeClicked,
    } = this.state;

    if (event.shiftKey && event.keyCode == 9 && displaySkipLinks === false) {
      const activeElement = document.activeElement;
      if (
        activeElement.firstElementChild &&
        activeElement.firstElementChild.id === 'logo-image'
      ) {
        event.preventDefault();
        this.setState({ displaySkipLinks: true, secondLinkFocused: true });
      }
    } else if (
      event.shiftKey &&
      event.keyCode == 9 &&
      displaySkipLinks === true
    ) {
      if (secondLinkFocused === true) {
        this.setState({ firstLinkFocused: true, secondLinkFocused: false });
      } else if (firstLinkFocused === true) {
        this.setState({ displaySkipLinks: false, firstLinkFocused: false });
      }
    } else if (
      event.keyCode === 9 &&
      displaySkipLinks === false &&
      firstTabNavigation === false &&
      firstTimeClicked === false
    ) {
      this.setState({ displaySkipLinks: true, firstTabNavigation: true });
    } else if (event.keyCode === 9 && displaySkipLinks === true) {
      if (firstLinkFocused === true) {
        this.setState({ firstLinkFocused: false, secondLinkFocused: true });
      } else if (secondLinkFocused === true) {
        this.setState({ displaySkipLinks: false, secondLinkFocused: false });
      }
    }
  };

  onClickNavigationButton = event => {
    this.setState({
      ...this.state,
      visibleMobileNavigation: !this.state.visibleMobileNavigation,
    });
  };

  handleBookDirectModal = () => {
    this.setState({
      ...this.state,
      showBookDirectModal: !this.state.showBookDirectModal,
    });
  };

  // handleKeyPress = event => {
  //   if (event.shiftKey && event.key.toLowerCase() === 'q') {
  //     this.setState({
  //       ...this.state,
  //       showBookDirectModal: true,
  //     });
  //   }
  // };

  handleEnterKeyPress = id => {
    const { firstLinkFocused, secondLinkFocused } = this.state;
    let focusElemet = null;
    let outlineColor = null;
    if (firstLinkFocused) {
      focusElemet = this.navigationRef.current;
      outlineColor = 'red';
    } else if (secondLinkFocused) {
      focusElemet = this.mainContentRef.current;
      outlineColor = 'green';
    }
    if (focusElemet) {
      focusElemet.style.outline = `2px solid ${outlineColor}`;
      focusElemet.style.outlineOffset = '-4px';
      focusElemet.style.borderRadius = '12px';
      this.setState({
        activeFocusedElement: focusElemet,
      });
    }
    this.hideSkipLinks();
  };

  render() {
    const { displayKPIReports, CanCreateAutoAssignOrder } = this.props;
    const {
      visibleMobileNavigation,
      showBookDirectModal,
      displaySkipLinks,
      firstLinkFocused,
      secondLinkFocused,
    } = this.state;
    const mobileWrapperClass = visibleMobileNavigation ? 'mobile-wrapper' : '';

    return (
      <React.Fragment>
        <div className="tv-container">
          <div className="tv-display-flex">
            <div
              className={`tv-right-pannel-wrapper ${mobileWrapperClass}`}
              style={{ width: '100%' }}
            >
              <RightStatus
                onClickNavigationButton={this.onClickNavigationButton}
                displayKPIReports={displayKPIReports}
                handleBookDirectModal={this.handleBookDirectModal}
                showBookDirectModal={showBookDirectModal}
                CanCreateAutoAssignOrder={CanCreateAutoAssignOrder}
                navigationRef={this.navigationRef}
              />
              <div
                className="tv-right-content"
                id="main-content"
                ref={this.mainContentRef}
              >
                {this.props.children}
              </div>
            </div>
            {visibleMobileNavigation}
            {visibleMobileNavigation && (
              <MobileNavigation
                visible={visibleMobileNavigation}
                onClickNavItem={this.onClickNavigationButton}
                displayKPIReports={displayKPIReports}
                handleBookDirectModal={this.handleBookDirectModal}
                showBookDirectModal={showBookDirectModal}
                CanCreateAutoAssignOrder={CanCreateAutoAssignOrder}
              />
            )}
          </div>
        </div>
        <LoadingOverlay />
        {displaySkipLinks === true ? (
          <SkipLinks
            firstLinkFocused={firstLinkFocused}
            secondLinkFocused={secondLinkFocused}
            handleEnterKeyPress={this.handleEnterKeyPress}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

const mapStateToProps = state => ({
  displayKPIReports: state.authentication.displayKPIReports,
  CanCreateAutoAssignOrder: state.user.profile.CanCreateAutoAssignOrder,
  skills: state.skill.skills || [],
  customerNumber: state.authentication.customerNumber,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchArticles: () => {
    dispatch(fetchArticles());
  },
  fetchSkills: () => {
    dispatch(fetchSkills());
  },
  fetchTranslationSkills: () => {
    dispatch(fetchTranslationSkills());
  },
  checkCustomerAllowedToAccessKPIReport: () => {
    dispatch(setCustomerKPIReportsDispalyRight());
  },
  onUpdateSearchByOrder: e => {
    dispatch(onUpdateSearchByOrder(e));
  },
  getNotificationList: () => {
    dispatch(
      getNotificationList(
        null,
        false,
        moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm'),
      ),
    );
  },
  fetchEducationSkills: () => {
    dispatch(fetchEducationSkills());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
