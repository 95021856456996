import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const NotificationIcon = ({ count, onClick, ariaExpanded, ariaControls }) => (
  <div className="tv-notification-icon__container">
    <button
      type="button"
      className="tv-notification-icon__button"
      onClick={onClick}
      aria-label="Notiser"
      aria-expanded={ariaExpanded}
      aria-controls={ariaControls}
    >
      <i className={`fa ${count !== 0 ? 'tv-notification-icon-active' : 'tv-notification-icon-inactive'}`} />
    </button>
    {count !== 0 && (
      <div className="tv-notification-icon__count">
        { count }
      </div>
    )}
  </div>
);

NotificationIcon.propTypes = {
  count: PropTypes.number,
  onClick: PropTypes.func,
  ariaExpanded: PropTypes.string,
  ariaControls: PropTypes.string,
};

NotificationIcon.defaultProps = {
  count: 0,
  onClick: () => {},
  ariaExpanded: '',
  ariaControls: '',
};

export default NotificationIcon;
