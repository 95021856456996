import FocusTrap from 'focus-trap-react';
import { clone } from 'lodash/core';
import moment from 'moment';
import React, { useRef, useState, useEffect } from 'react';
import { ValidatorForm } from 'react-form-validator-core';
import { useSelector } from 'react-redux';
import {
  DropdownList,
  TFVButton,
  TFVDateRangePicker,
  Translate,
} from '../../../Share/components';
import MultiSelectDropdown from '../../../Share/components/MultiSelect';
import { createDefaultContactPerson } from '../../../Share/utils';
import ContactPersonEdit from '../ContactPersonEdit';
import StyledDropzone from './StyledDropzone';

const NewTranslationForm = ({
  handleSubmit,
  setFormData,
  DELEVEYYPLANS,
  formData,
  setAttachments,
  attachments,
  emailTranslationRef,
  setShowCancelTranslationModal,
  MODEOFDELIVERY,
  errorMessage,
  setErrorMessage,
}) => {
  const [
    showContactPersonUponInterpertationError,
    setShowContactPersonUponInterpertationError,
  ] = useState(false);
  const [moveForward, setMoveForward] = useState(true);
  const [defaultContactPerson, setDefaultContactPerson] = useState({});
  const [showContactPersonModal, setShowContactPersonModal] = useState(false);
  const skills = useSelector(state => state.orderTranslation.translationSkills);
  const contactPersons = useSelector(state => state.user.contactPersons);
  let languages = skills.map((item, i) => ({
    name: item.SkillName,
    value: item.SkillIdentifier,
  }));

  const changeHandler = (event, name) => {
    if (name == 'sourceLanguage') {
      setFormData({ ...formData, sourceLanguage: event.target.value });
    }
    if (name == 'targetLanguages') {
      setFormData({
        ...formData,
        targetLanguages: [...event.target.value],
      });
    }
    if (name == 'deliveryPlan') {
      setFormData({ ...formData, deliveryPlan: event.target.value });
    }
    if (name == 'contactPersonIdentifierOrderer') {
      setFormData({
        ...formData,
        contactPersonIdentifierOrderer: event.target.value,
      });
    }
    if (name == 'modeoFDelivery') {
      setFormData({ ...formData, modeoFDelivery: event.target.value });
    }
    if (name == 'description') {
      setFormData({ ...formData, description: event.target.value });
    }
    if (name == 'needAQuote') {
      setFormData({ ...formData, needAQuote: event.target.checked });
    }
  };

  const getValuefromList = (filterText, list) => {
    const currentUser = list.filter(x => x.IsCurrentlyLoggedIn === true);
    if (filterText === null || filterText === '') {
      if (currentUser.length > 0) {
        setFormData({
          ...formData,
          contactPersonIdentifierOrderer: {
            name: currentUser[0].name,
            value: currentUser[0].value,
          },
        });
        return currentUser[0];
      }
      return null;
    }

    const filteredItems = list.filter(x => x.value === filterText);

    return filteredItems ? filteredItems[0] : null;
  };

  const getValuefromMultiSelect = data => {
    return data ? data : null;
  };

  const handleSessionDateChange = (e, element) => {
    const { deliveryDate } = formData;
    const { key } = deliveryDate.ranges;
    element.onToggleMenu();
    if (key && e[key]) {
      const { startDate, endDate } = e[key];
      if (startDate && endDate) {
        handleSessionChange(
          {
            startDate: moment(startDate),
            endDate: moment(endDate),
            key,
          },
          'ranges',
        );
      }
    }
  };

  const handleSessionChange = (e, key) => {
    const dateRange = clone(formData.deliveryDate); // use clone() to fix object reference issue
    dateRange[key] = e;
    setFormData({ ...formData, deliveryDate: dateRange });
  };

  const handleContactPersonModal = (emailRequired, type = '', identifier) => {
    let editableContactPerson = defaultContactPerson;
    if (identifier && identifier !== '') {
      let matchingContactPersonsList = contactPersons.filter(
        x => x.value === identifier,
      );
      matchingContactPersonsList =
        matchingContactPersonsList.length > 0
          ? matchingContactPersonsList[0]
          : {};

      editableContactPerson = {
        ...editableContactPerson,
        ...matchingContactPersonsList,
        editMode: true,
      };
    }
    setShowContactPersonModal(true);
    setDefaultContactPerson({
      ...editableContactPerson,
      identifier,
      emailRequired,
      type,
    });
  };

  const handleContactPersonModalClose = modalState => {
    if (
      defaultContactPerson.type === 'ContactPersonUponInterpretation' &&
      modalState === 'success'
    ) {
      setShowContactPersonUponInterpertationError(false);
      setMoveForward(true);
    }
    setShowContactPersonModal(false);
    setDefaultContactPerson(createDefaultContactPerson());
  };

  const textAreaRef = useRef(null);

  return (
    <>
      {showContactPersonModal && (
        <ContactPersonEdit
          {...defaultContactPerson}
          showModal={showContactPersonModal}
          inOrderProcess
          onHandleModal={handleContactPersonModalClose}
        />
      )}
      <div className="tv-modal__wrapper tv-display-flex tv-route-leaving-translation">
        <div className="tv-modal__overlay"></div>
        <FocusTrap
          focusTrapOptions={{
            escapeDeactivates: false,
            allowOutsideClick: true,
          }}
        >
          <div className="tv-modal__container">
            <div className="tv-modal__container__header">
              <div className="tv-modal__container__header-title">
                <Translate content="emailTranslation.orderTranslation" />
              </div>
              <button
                aria-label={Translate({ content: 'general.close' })}
                className="tv-buttons__type icon-close"
                onClick={() => setShowCancelTranslationModal(true)}
              />
            </div>
            <hr className="tv-modal__container__divider" />
            <div>
              <ValidatorForm ref={emailTranslationRef} onSubmit={handleSubmit}>
                {/* row 1 */}
                <div className="tv-modal__container__body">
                  <div className="tv-modal__container__section">
                    <div className="tv-modal__container__card">
                      <div className="d-flex flex-row">
                        <label>
                          <Translate content="singleEmailTranslation.sourceLanguage" />
                        </label>
                        <div className="tooltip-icon">
                          <i
                            class="fa fa-question-circle ml-2 mt-1"
                            aria-hidden="true"
                          ></i>
                          <span className="tooltip-text">
                            Språket som ska översättas från.
                          </span>
                        </div>
                      </div>
                      <DropdownList
                        name="sourceLanguage"
                        className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                        options={languages}
                        onChange={e => changeHandler(e, 'sourceLanguage')}
                        value={getValuefromList(
                          formData.sourceLanguage.value,
                          languages,
                        )}
                        validators={['required']}
                        errorMessages={[
                          Translate({
                            content: 'validator.isRequired',
                            params: {
                              name: Translate({
                                content:
                                  'singleEmailTranslation.sourceLanguage',
                              }),
                            },
                          }),
                        ]}
                        ariaLabel={Translate({
                          content: 'singleEmailTranslation.sourceLanguage',
                        })}
                      />
                    </div>
                    <div className="tv-modal__container__card col-6 pr-0">
                      <div className="d-flex flex-row">
                        <label>
                          <Translate content="singleEmailTranslation.targetLanguages" />
                        </label>

                        <div className="tooltip-icon">
                          <i
                            class="fa fa-question-circle ml-2 mt-1"
                            aria-hidden="true"
                          ></i>
                          <span className="tooltip-text">
                            Språket som ska översättas till.
                          </span>
                        </div>
                      </div>
                      <MultiSelectDropdown
                        name="targetLanguages"
                        className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                        options={languages}
                        onChange={e => changeHandler(e, 'targetLanguages')}
                        value={getValuefromMultiSelect(
                          formData.targetLanguages,
                        )}
                        validators={['required']}
                        errorMessages={[
                          Translate({
                            content: 'validator.isRequired',
                            params: {
                              name: Translate({
                                content:
                                  'singleEmailTranslation.targetLanguage',
                              }),
                            },
                          }),
                        ]}
                        ariaLabel={Translate({
                          content: 'singleEmailTranslation.targetLanguages',
                        })}
                      />
                    </div>
                  </div>

                  {/* row 2 */}
                  <div className="tv-modal__container__section">
                    <div className="tv-modal__container__card">
                      <label>
                        <Translate content="singleEmailTranslation.modeOfDelivery" />
                      </label>
                      <DropdownList
                        name="modeOfDelivery"
                        className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                        options={MODEOFDELIVERY}
                        onChange={e => changeHandler(e, 'modeoFDelivery')}
                        value={getValuefromList(
                          formData.modeoFDelivery.value,
                          MODEOFDELIVERY,
                        )}
                        validators={['required']}
                        errorMessages={[
                          Translate({
                            content: 'validator.isRequired',
                            params: {
                              name: Translate({
                                content:
                                  'singleEmailTranslation.modeOfDelivery',
                              }),
                            },
                          }),
                        ]}
                        ariaLabel={Translate({
                          content: 'singleEmailTranslation.modeOfDelivery',
                        })}
                      />
                    </div>
                    <div className="tv-modal__container__card col-6 pr-0">
                      <label>
                        <Translate content="singleEmailTranslation.contactPersonOrderer" />
                      </label>
                      <DropdownList
                        name="contactPersonIdentifierOrderer"
                        className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                        options={contactPersons}
                        onChange={e =>
                          changeHandler(e, 'contactPersonIdentifierOrderer')
                        }
                        value={getValuefromList(
                          formData.contactPersonIdentifierOrderer.value,
                          contactPersons,
                        )}
                        validators={['required']}
                        errorMessages={[
                          Translate({
                            content: 'validator.isRequired',
                            params: {
                              name: Translate({
                                content:
                                  'singleEmailTranslation.contactPersonOrderer',
                              }),
                            },
                          }),
                        ]}
                        footer={
                          <span
                            onClick={e => {
                              e.stopPropagation();
                              handleContactPersonModal(
                                showContactPersonUponInterpertationError,
                                'ContactPersonUponInterpretation',
                              );
                            }}
                            className="tv-dropdown__footer-conactperson-add"
                          >
                            + Lägg till beställare
                          </span>
                        }
                        ariaLabel={Translate({
                          content:
                            'singleEmailTranslation.contactPersonOrderer',
                        })}
                      />
                      {showContactPersonUponInterpertationError && (
                        <span className="tv-form__error text-justify">
                          E-post krävs.
                          <span
                            onClick={e => {
                              e.stopPropagation();
                              handleContactPersonModal(
                                showContactPersonUponInterpertationError,
                                'ContactPersonUponInterpretation',
                                formData.contactPersonIdentifierOrderer.value,
                              );
                            }}
                            className="contact-person-email-edit"
                          >
                            + Lägg till här
                          </span>
                        </span>
                      )}
                    </div>
                  </div>

                  {/* row 3 */}
                  <div className="tv-modal__container__section">
                    <div className="tv-modal__container__card">
                      <label>
                        <Translate content="singleEmailTranslation.deliveryPlans" />
                      </label>
                      <DropdownList
                        name="deliveryPlans"
                        className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                        options={DELEVEYYPLANS}
                        onChange={e => changeHandler(e, 'deliveryPlan')}
                        value={getValuefromList(
                          formData.deliveryPlan.value,
                          DELEVEYYPLANS,
                        )}
                        validators={['required']}
                        errorMessages={[
                          Translate({
                            content: 'validator.isRequired',
                            params: {
                              name: Translate({
                                content: 'singleEmailTranslation.deliveryPlans',
                              }),
                            },
                          }),
                        ]}
                        ariaLabel={Translate({
                          content: 'singleEmailTranslation.deliveryPlans',
                        })}
                        popupSettings={{ width: 'auto' }}
                      />
                    </div>
                    {formData.deliveryPlan.value ==
                    'Leveransdatum enligt överenskommelse' ? (
                      <div className="tv-modal__container__card col-6 pr-0">
                        <label>
                          <Translate content="singleEmailTranslation.deliveryDate" />
                        </label>
                        <div className="tv-single-assign-form__input">
                          <TFVDateRangePicker
                            displayFormat="YYYY-MM-DD"
                            ranges={formData.deliveryDate.ranges}
                            onChange={handleSessionDateChange}
                            enableDefaultOrderTranslation
                            isDateUpdated="true"
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {/* row 4 */}
                  <div className="tv-modal__container__full">
                    <label>
                      <Translate content="singleEmailTranslation.description" />
                    </label>
                    <div className=" tv-single-assign-form__input">
                      <textarea
                        ref={textAreaRef}
                        value={formData.description}
                        className="tv-text-area"
                        onChange={e => changeHandler(e, 'description')}
                        aria-label={Translate({
                          content: 'singleEmailTranslation.description',
                        })}
                        onKeyUp={e => {
                          if (e.key === 'Tab') {
                            textAreaRef.current.classList.add(
                              'textarea-outline',
                            );
                          }
                        }}
                        onBlur={() => {
                          if (textAreaRef) {
                            textAreaRef.current.classList.remove(
                              'textarea-outline',
                            );
                          }
                        }}
                      />
                    </div>
                  </div>

                  {/* row 5 */}
                  <div className="tv-modal__container__full">
                    <div
                      className="tv-modal__container__full-box"
                      style={{ alignItems: 'center' }}
                    >
                      <span>
                        <input
                          checked={formData.needAQuote ? 'checked' : null}
                          type="checkbox"
                          value={formData.needAQuote}
                          onChange={e => changeHandler(e, 'needAQuote')}
                          aria-label={Translate({
                            content: 'singleEmailTranslation.needAQuote',
                          })}
                        />
                      </span>
                      <div className="tv-modal__container__full-box-label">
                        <Translate content="singleEmailTranslation.needAQuote" />
                      </div>
                    </div>
                  </div>

                  <StyledDropzone
                    attachments={attachments}
                    setAttachments={setAttachments}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                  />
                </div>

                <div className="tv-route-leaving-guard-modal__buttons-translation tv-display-flex px-3">
                  <TFVButton
                    type="line-primary"
                    buttonType="button"
                    className="w-50 mr-2"
                    text={Translate({
                      content: 'emailTranslation.close',
                    })}
                    onClick={() => setShowCancelTranslationModal(true)}
                  />
                  <TFVButton
                    type="secondary"
                    buttonType="submit"
                    className="w-50 ml-2"
                    text={Translate({
                      content: 'emailTranslation.continue',
                    })}
                  />
                </div>
              </ValidatorForm>
            </div>
          </div>
        </FocusTrap>
      </div>
    </>
  );
};

export default NewTranslationForm;
