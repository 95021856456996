import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Avatar from '../Avatar';
import './style.scss';
import history from '../../../Share/utils/history';
import { Translate, TFVButton } from '../../../Share/components';
import CloseButton from '../../../Share/components/CloseButton';
import FocusTrap from 'focus-trap-react';
import {
  removeContextualClass,
  removeContextualClassForBooknow,
} from '../../../Share/utils';

const propTypes = {
  visible: PropTypes.bool,
  onClickNavItem: PropTypes.func,
  CanCreateAutoAssignOrder: PropTypes.bool,
};

const defaultProps = {
  visible: false,
  CanCreateAutoAssignOrder: false,
  onClickNavItem: () => {},
};

class MobileNavigation extends Component {
  constructor(props) {
    super(props);

    this.navigationRef = React.createRef();
    this.setNavigationRef = this.setNavigationRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  setNavigationRef(node) {
    this.navigationRef = node;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onClickNavItem = () => {
    this.props.onClickNavItem();
    removeContextualClassForBooknow();
  };

  handleClickOutside(event) {
    if (
      this.navigationRef &&
      !this.navigationRef.current.contains(event.target) &&
      this.props.visible
    ) {
      this.onClickNavItem();
    }
  }

  render() {
    const {
      visible,
      displayKPIReports,
      handleBookDirectModal,
      CanCreateAutoAssignOrder,
    } = this.props;
    const visibleClass = visible ? 'd-block' : '';

    const setNavLinkIsActive = (match, location) =>
      match || /submit-time/.test(location.pathname);

    return (
      <FocusTrap
        focusTrapOptions={{
          escapeDeactivates: false,
          allowOutsideClick: true,
        }}
      >
        <div
          className={`tv-mobile-navigation ${visibleClass}`}
          ref={this.navigationRef}
        >
          <div className="border-bottom d-flex pt-1 pb-3 w-100">
            <div className="tv-left-pannel__logo">
              <a href="/">
                <img src="/images/logo.png" alt="logo image" />
              </a>
            </div>
            <span className="float-right nav-active nav-item-mobile">
              {' '}
              <CloseButton
                onClick={e => {
                  const mobileNav = document.querySelector(
                    '.tv-mobile-navigation',
                  );
                  mobileNav.classList.remove('d-block');
                  this.onClickNavItem();
                  removeContextualClass();
                }}
                className="tk-dialog__btn-close tv-buttons__type icon-close"
              />
            </span>
          </div>
          <div className="d-flex flex-column h-100 justify-content-center w-100">
            {CanCreateAutoAssignOrder && (
              <TFVButton
                type="line-primary"
                text="Akut Tolk"
                className="tv-bookDirect-button"
                onClick={handleBookDirectModal}
              />
            )}
            <NavLink
              onClick={this.onClickNavItem}
              isActive={setNavLinkIsActive}
              exact
              activeClassName="nav-active"
              to="/"
              className="nav-item-mobile"
            >
              <Translate content="sideBar.dashboard" />
            </NavLink>
            {displayKPIReports ? (
              <NavLink
                onClick={this.onClickNavItem}
                isActive={setNavLinkIsActive}
                exact
                activeClassName="nav-active"
                to="/reports-dashboard/"
                className="nav-item-mobile"
              >
                {Translate({
                  content: 'report.statistics',
                })}
              </NavLink>
            ) : null}
            <NavLink
              onClick={this.onClickNavItem}
              isActive={setNavLinkIsActive}
              exact
              activeClassName="nav-active"
              to="/translation"
              className="nav-item-mobile"
            >
              {Translate({
                content: 'sideBar.translations',
              })}
            </NavLink>
            <NavLink
              onClick={this.onClickNavItem}
              isActive={setNavLinkIsActive}
              exact
              activeClassName="nav-active"
              to="/my-assignment"
              className="nav-item-mobile"
            >
              {Translate({
                content: 'sideBar.myAssignment',
              })}
            </NavLink>
            <NavLink
              onClick={this.onClickNavItem}
              isActive={setNavLinkIsActive}
              exact
              activeClassName="nav-active"
              to={{
                pathname: '/report-quality',
                state: { isGeneral: true },
              }}
              className="nav-item-mobile"
            >
              <Translate content="sideBar.reportQuality" />
            </NavLink>
            <NavLink
              onClick={this.onClickNavItem}
              isActive={setNavLinkIsActive}
              exact
              activeClassName="nav-active"
              to="/contact-tfv"
              className="nav-item-mobile"
            >
              <span>
                <Translate content="sideBar.contactTFV" />
              </span>
            </NavLink>
          </div>
        </div>
      </FocusTrap>
    );
  }
}

MobileNavigation.propTypes = propTypes;
MobileNavigation.defaultProps = defaultProps;

export default MobileNavigation;
