import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Translate, TFVButton } from '../../../Share/components';
import history from '../../../Share/utils/history';

const propTypes = {
  assignment: PropTypes.shape({}),
};

const defaultProps = {
  assignment: {},
};

class SelectAssignment extends Component {
  state = { isChoosing: false };
  handleSubmit = () => {
    const { isSingle } = this.state;
    if (isSingle) {
      history.push('/create-single-assignment');
    } else {
      history.push('/advanced-order-form');
    }
  };

  handleChange = type => {
    if (type === 'single') {
      this.setState({
        isSingle: true,
        isChoosing: true,
      });
    }
    if (type === 'multi') {
      this.setState({
        isSingle: false,
        isChoosing: true,
      });
    }
  };

  render() {
    const { isSingle, isChoosing } = this.state;
    return (
      <React.Fragment>
        <h2 className="tv-single-assign-form__title">
          <NavLink to={{ pathname: '/' }} className="tv-buttons__element-link">
            <div className="tv-single-assign-form__back-corner tv-display-flex">
              <Translate content="chooseAssignment.backToDashboard" />
            </div>
          </NavLink>
        </h2>
        <div className="tv-chooseassignment__wrapper tv-display-flex">
          <div className="tv-chooseassignment__container">
            <div className="tv-chooseassignment__heading tv-display-flex">
              <div className="tv-chooseassignment__heading-text">
                <Translate content="chooseAssignment.whichTypeOfAssignment" />
              </div>
            </div>
            <div className="tv-chooseassignment__content tv-display-flex">
              <div className="tv-chooseassignment-single__wrapper">
                <div
                  className={`tv-chooseassignment-single__button ${
                    isSingle && isChoosing
                      ? 'tv-chooseassignment-single__button--active'
                      : ''
                  }`}
                  onClick={() => {
                    this.handleChange('single');
                  }}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex="-1"
                >
                  <div className="tv-chooseassignment__icon tv-chooseassignment__icon-single icon-single" />
                  <div className="tv-chooseassignment__text-button tv-display-flex">
                    <Translate content="chooseAssignment.singleAssignment" />
                  </div>
                </div>
                <div className="tv-chooseassignment__text">
                  <Translate content="chooseAssignment.singleAssignmentDesc" />
                </div>
              </div>
              <div className="tv-chooseassignment-multi__wrapper">
                <div
                  className={`tv-chooseassignment-multi__button ${
                    !isSingle && isChoosing
                      ? 'tv-chooseassignment-multi__button--active'
                      : ''
                  }`}
                  onClick={() => {
                    this.handleChange('multi');
                  }}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex="-1"
                >
                  <div className="tv-chooseassignment__icon tv-chooseassignment__icon-single icon-multi" />
                  <div className="tv-chooseassignment__text-button tv-display-flex">
                    <Translate content="chooseAssignment.multipleAssignment" />
                  </div>
                </div>
                <div className="tv-chooseassignment__text">
                  <Translate content="chooseAssignment.multiAssignmentDesc" />
                </div>
              </div>
            </div>
            <div className="tv-chooseassignment__buttons tv-display-flex">
              <TFVButton
                type={isChoosing ? 'primary' : 'disable'}
                text={Translate({
                  content: 'chooseAssignment.nextStep',
                })}
                onClick={this.handleSubmit}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SelectAssignment.propTypes = propTypes;
SelectAssignment.defaultProps = defaultProps;

export default SelectAssignment;
