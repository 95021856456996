import React from 'react';
import ReactStars from 'react-rating-stars-component';
import PropTypes from 'prop-types';
import './styles.scss';
import { isIE } from '../../../Share/utils';

const StarGroup = props => {
  const { handleChange, defaultRate, activeColor, size, count,classNames } = props;
  return (
    <ReactStars
      count={count}
      classNames={classNames}
      value={defaultRate}
      onChange={handleChange}
      color={isIE?"#ffe7ce":"#FFF"}
      size={size}
      activeColor={activeColor}
    />
  );
};

StarGroup.propTypes = {
  defaultRate: PropTypes.number,
  handleChange: PropTypes.func,
  activeColor: PropTypes.string,
  size: PropTypes.number,
  count: PropTypes.number,
};

StarGroup.defaultProps = {
  count: 5,
  defaultRate: 0,
  handleChange: () => {},
  activeColor: '#ffd700',
  size: 25,
};

export default StarGroup;
