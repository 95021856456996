import React from 'react';
import { Prompt } from 'react-router-dom';
import {
  Modal,
  TFVButton,
  Translate,
  RouteLeavingModal,
} from '../../components';

import './style.scss';

export class RouteLeavingGuard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      lastLocation: null,
      confirmedNavigation: false,
    };
  }

  showModal = location => {
    if (this.props.onShowModal) {
      this.props.onShowModal();
    }
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });
  };
  closeModal = callback => {
    if (this.props.onCloseModal) {
      this.props.onCloseModal();
    }
    this.setState(
      {
        modalVisible: false,
      },
      callback,
    );
  };
  handleBlockedNavigation = nextLocation => {
    const { confirmedNavigation } = this.state;
    const { shouldBlockNavigation } = this.props;
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      this.showModal(nextLocation);
      return false;
    }

    return true;
  };
  handleConfirmNavigationClick = () => {
    if (this.props.onConfirm) {
      this.props.onConfirm();
      // this.closeModal();
    }
    // else {
    this.closeModal(() => {
      const { navigate } = this.props;
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState(
          {
            confirmedNavigation: true,
          },
          () => {
            // Navigate to the previous blocked location with your navigate function
            navigate(lastLocation.pathname);
          },
        );
      }
    });
    // }
  };
  render() {
    const { when } = this.props;
    const { modalVisible, lastLocation } = this.state;
    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <RouteLeavingModal
          isVisible={modalVisible}
          onCloseClicked={() => {
            this.closeModal();
          }}
          onOverlayClick={() => {
            this.closeModal();
          }}
          onConfirmClick={() => {
            this.handleConfirmNavigationClick();
          }}
          message={Translate({ content: 'RouteLeavingGuard.cancelMessage' })}
        />
      </>
    );
  }
}
export default RouteLeavingGuard;
