/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Dialog, TFVTimePickerV2 } from '../../../Share/components';
import { generateUniqueId } from '../../../Share/utils';
import { clone } from 'lodash';

function timeStringToMoment(timeString, typeKey) {
  if (!timeString)
    return typeKey === 'end' ? moment().add(1, 'hours') : moment();
  const [hours, minutes] = timeString.split(':');
  return moment().set('hour', hours).set('minute', minutes);
}
function calculateTimeDifference(startTime, endTime) {
  const duration = moment.duration(endTime.diff(startTime));
  const minutes = duration.asMinutes();

  return {
    minutes,
  };
}
function TimeSlotEditor(props) {
  const [isEditMode, setEditMode] = useState(false);
  const [timeDifference, setTimeDifference] = useState({ minutes: 0 });
  const [startTime, setStartTime] = useState(
    timeStringToMoment(props.TimeFrom, 'start'),
  );
  const [endtTime, setEndTime] = useState(
    timeStringToMoment(props.TimeTo, 'end'),
  );
  const [validationFailed, setValidationFailed] = useState({
    isValid: true,
    errorMessage: '',
  });
  useEffect(() => {
    if (props.editMode) {
      setEditMode(true);
      props.onEditMode(true);
    }
  }, [props.editMode]);
  useEffect(() => {
    const [from, to] = [
      timeStringToMoment(props.TimeFrom, 'start'),
      timeStringToMoment(props.TimeTo, 'end'),
    ];
    setTimeDifference(calculateTimeDifference(from, to));
    setStartTime(from);
    setEndTime(to);
  }, [props.TimeFrom, props.TimeTo]);

  const handleSubmitTimeSlotValue = () => {
    let [from, to] = [startTime, endtTime];
    if (endtTime.diff(startTime) < 0) {
      [from, to] = [endtTime, startTime];
    }
    const timeSlotObj = {
      id: props.id,
      timeFrom: from.format('HH:mm'),
      timeTo: to.format('HH:mm'),
      isAvailable: props.IsAvailable,
    };
    const { isValid, errorMessage } = props.validateTimeSlot(timeSlotObj);
    if (isValid) {
      props.onSetTimeSlot(timeSlotObj);
      setEditMode(false);
      props.onEditMode(false);
    }
    setValidationFailed({ isValid, errorMessage });
  };

  const handleCancelEditTimeSlot = () => {
    const [from, to] = [
      timeStringToMoment(props.TimeFrom, 'start'),
      timeStringToMoment(props.TimeTo, 'end'),
    ];
    setTimeDifference(calculateTimeDifference(from, to));
    setValidationFailed({ isValid: true, errorMessage: '' });
    setStartTime(from);
    setEndTime(to);
    setEditMode(false);
    props.onEditMode(false);
    props.onCancelEdit();
  };

  const handletimePickerValuChange = (changedValue, typeKey) => {
    if (typeKey === 'start') {
      setStartTime(changedValue);
      const changedEndTime = timeStringToMoment(
        changedValue.format('HH:mm'),
      ).add(timeDifference.minutes, 'minutes');
      setEndTime(changedEndTime);
    } else if (typeKey === 'end') {
      setEndTime(changedValue);
      setTimeDifference(calculateTimeDifference(startTime, changedValue));
    }
  };

  return (
    <React.Fragment>
      <div
        className={`d-flex flex-wrap w-100 tv-defaultAvailabilitySetting__timeSlotContainer  ${
          !validationFailed.isValid ? 'error' : ''
        }`}
        onKeyDown={e => {
          e.stopPropagation();
        }}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div className="d-flex justify-content-around w-75 mt-1">
          {!isEditMode ? (
            <React.Fragment>
              <div
                className="timSlot-diaplay-item w-50 mr-1"
                onKeyDown={e => {
                  setEditMode(true);
                  props.onEditMode(true);
                }}
                onClick={e => {
                  setEditMode(true);
                  props.onEditMode(true);
                }}
              >
                {startTime.format('HH:mm')}
              </div>
              <div
                className="timSlot-diaplay-item w-50"
                onKeyDown={e => {
                  setEditMode(true);
                  props.onEditMode(true);
                }}
                onClick={e => {
                  setEditMode(true);
                  props.onEditMode(true);
                }}
              >
                {endtTime.format('HH:mm')}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TFVTimePickerV2
                key={`${generateUniqueId()}-TFVTimePickerV2-start`}
                defaultValue={startTime}
                startFromDayBegin={true}
                handleValueChange={e => {
                  handletimePickerValuChange(e, 'start');
                }}
              />
              <TFVTimePickerV2
                key={`${generateUniqueId()}-TFVTimePickerV2-end`}
                allowNextDay
                defaultValue={endtTime}
                durationCalculate={startTime}
                isNotRanged={false}
                handleValueChange={e => {
                  handletimePickerValuChange(e, 'end');
                }}
              />
            </React.Fragment>
          )}
        </div>
        <div className="d-flex justify-content-center mt-1">
          {!isEditMode ? (
            !props.disabled ? (
              <React.Fragment>
                <div
                  title="Delete"
                  className="tv-defaultAvailabilitySetting__timeSlotContainer-delete"
                  onKeyDown={e => props.onDeleteTimeSlot()}
                  onClick={() => props.onDeleteTimeSlot()}
                />
                <div
                  title="Edit"
                  className="tv-defaultAvailabilitySetting__timeSlotContainer-button tv-defaultAvailabilitySetting__timeSlotContainer-button-submit"
                  onKeyDown={e => {
                    setEditMode(true);
                    props.onEditMode(true);
                  }}
                  onClick={e => {
                    setEditMode(true);
                    props.onEditMode(true);
                  }}
                >
                  &#9998;
                </div>
              </React.Fragment>
            ) : null
          ) : (
            <React.Fragment>
              <div
                title="Set"
                className="tv-defaultAvailabilitySetting__timeSlotContainer-button tv-defaultAvailabilitySetting__timeSlotContainer-button-submit"
                onKeyDown={() => {
                  handleSubmitTimeSlotValue();
                }}
                onClick={e => {
                  handleSubmitTimeSlotValue();
                }}
              >
                &#10003;
              </div>
              <div
                title="Cancel"
                className="tv-defaultAvailabilitySetting__timeSlotContainer-button tv-defaultAvailabilitySetting__timeSlotContainer-button-cancel"
                onKeyDown={e => {
                  handleCancelEditTimeSlot();
                }}
                onClick={e => {
                  handleCancelEditTimeSlot();
                }}
              >
                &#10006;
              </div>
            </React.Fragment>
          )}
        </div>
        {validationFailed.isValid ? null : (
          <div className="d-flex w-100 error-message">
            {validationFailed.errorMessage}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

TimeSlotEditor.propTypes = {
  onCancelEdit: PropTypes.func,
  onSetTimeSlot: PropTypes.func,
  validateTimeSlot: PropTypes.func,
  onEditMode: PropTypes.func,
  disabled: PropTypes.bool,
};

TimeSlotEditor.defaultProps = {
  onCancelEdit: () => {},
  onSetTimeSlot: () => {},
  validateTimeSlot: () => {},
  onEditMode: () => {},
  disabled: false,
};

export default TimeSlotEditor;
