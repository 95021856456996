import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/core';
import { Redirect, NavLink } from 'react-router-dom';
import SingleAssignmentFormPreview from './SingleAssignmentFormPreview';
import MultipleAssignmentTab from './MultipleAssignmentTab';
import { Translate, TFVButton, IfComponent } from '../../../Share/components';

import { createMultiNewOrder } from '../../actions';

const propTypes = {
  assignments: PropTypes.arrayOf(PropTypes.shape({})),
  onCreateNewOrder: PropTypes.func,
};

const defaultProps = {
  assignments: [],
  onCreateNewOrder: () => {},
};

class PreviewMultipleAssignment extends Component {
  state = {
    isDirty: true,
  };
  constructor(props) {
    super(props);
    this.state = { assignments: props.assignments };
    this.formAssignment = null;
  }

  handleSubmit = () => {
    this.setState({ isDirty: false }, () => {
      const { assignments } = this.props;
      this.props.onCreateNewOrder(assignments);
    });
  };

  hiddenAllForm = assignments => {
    (assignments || []).forEach((assign, i) => {
      assignments[i].isShow = false;
    });
    return assignments;
  };

  handleEditForm = i => {
    const assignments = this.hiddenAllForm(this.state.assignments);
    assignments[i].isShow = true;
    this.setState({ assignments });
  };

  render() {
    const { assignments } = this.state;
    const { isDirty } = this.state;

    if (isEmpty(assignments) && isDirty) {
      return (
        <Redirect
          to={{
            pathname: '/advanced-order-form',
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <div className="tv-multiple-assign-form__wrapper tv-display-flex padding-top-30">
          {/* <div className="tv-multiple-assign-form__panel-left">
                        {assignments.map((assignment, i) => (
                            <MultipleAssignmentTab
                                {...assignment.form}
                                total={assignments.length}
                                isSelected={assignment.isShow}
                                isError={!assignment.isValid}
                                onDeleteClick={() => this.handleDeleteForm(i)}
                                onClick={() => this.handleEditForm(i)}
                            />
                        ))}
                    </div> */}
          <div className="tv-multiple-assign-form__panel-right tv-right-content">
            {assignments.map((assignment, i) => (
              <IfComponent
                key={`assignment_${i}`}
                condition={assignment.isShow}
                whenTrue={
                  <SingleAssignmentFormPreview
                    {...assignment.form}
                    showEditButton={true}
                    for="multiple"
                    isDirty={isDirty}
                    changeIsDirty={(status, callback) => {
                      this.setState({ isDirty: status }, callback);
                    }}
                  />
                }
              />
            ))}

            <div className="tv-single-assign-form__action-container tv-display-flex">
              <NavLink
                to={{
                  pathname: '/',
                  state: {
                    prevPath: this.props.location.pathname,
                  },
                }}
                className="tv-buttons__element-link"
              >
                <TFVButton
                  type="line-primary"
                  buttonType="submit"
                  className="tv-single-assign-form__button-save"
                  text={Translate({
                    content: 'general.cancel',
                  })}
                />
              </NavLink>
              <TFVButton
                type="primary"
                className="tv-single-assign-form__button-preview"
                text={Translate({
                  content: 'previewAssignment.confirmNow',
                })}
                onClick={this.handleSubmit}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

PreviewMultipleAssignment.propTypes = propTypes;
PreviewMultipleAssignment.defaultProps = defaultProps;

const mapStateToProps = state => {
  const assignments = state.workAssignment.assignmentTemp;
  return {
    assignments,
  };
};

const mapDispatchToProps = dispatch => ({
  onCreateNewOrder: assignments => {
    dispatch(createMultiNewOrder(assignments));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreviewMultipleAssignment);
