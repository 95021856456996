import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  RadialGauge, ArcGauge,
} from '@progress/kendo-react-gauges';
import './styles.scss';
import { Translate } from '../../../Share/components';
import { connect } from 'react-redux';

function summationBasedOnKey(dataList, key) {
  return dataList.reduce(
    (acc, current) => acc + (current[key] ? current[key] : 0),
    0,
  ) || 0;
}
const getPercentage = (interpreterType, dataList = []) => {
  const filteredDataList = dataList.filter((x) => {
    for (let index = 0; index < interpreterType.associatedTypes.length; index++) {
      const type = interpreterType.associatedTypes[index];
      if (x.interpretationType.includes(type)) {
        return true;
      }
    }
    return false;
  });
  return parseFloat(((summationBasedOnKey(filteredDataList, 'completed') / summationBasedOnKey(filteredDataList, 'noOfAssignments')) || 1));
};
const DeliveryReliabilityWidget = ({
  fulfillmentData, labelContent, dataRedyFlag, location,
}) => {
  const interpreterType = [
    { type: 'Kontakttolk', associatedTypes: ['Kontakt', 'Simultan'] },
    { type: 'Distanstolk', associatedTypes: ['Skype', 'Telefon', 'Video'] },

  ];
  return (
    <div className="auto-row-2">
      <div className="exact-col-1" style={{ height: '40px' }}>
        <NavLink to="/reports/10" style={{}}>
          <span
            className="k-icon k-i-more-horizontal"
            style={{
              float: 'right',
              paddingTop: '10px',
              color: '#322878',
              fontSize: '1.3rem',
            }}
          />
        </NavLink>
      </div>
      <div className="exact-col-1">
        <div className="dashboard__display-card round-border">
          <div
            className="dashboard__display-card-header"
            style={{ float: 'left' }}
          >
            <span style={{ float: 'left' }}>{Translate({ content: 'report.deilveryReliability' })}
            </span>
            <span
              style={{
                float: 'right',
                paddingRight: '10px',
                fontSize: '2.5rem',
              }}
            >
              {/* {(totalCompleted / totalOrders) * 100} */}
            </span>
          </div>
          <div style={{
            display: 'flex', width: '100%', height: 'max-content', maxHeight: '100%', flexDirection: 'row', justifyContent: 'space-around',
          }}
          >

            {interpreterType.map((x, i) => (
              <GaugeContainer key={`${i}_devGauge`} value={x} dataList={[...fulfillmentData]} />

            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

DeliveryReliabilityWidget.propTypes = {};

const mapStateToProps = (state, props) => {
  const dataReadyFlags = [state.reportDataSources[1].dataReadyFlags[0], state.reportDataSources[10].dataReadyFlags[1]];
  return ({
    dataReadyFlag: dataReadyFlags[1] || false,
    fulfillmentData: [...state.reportDataSources[10].crossFilterData[1] || []],
  });
};

export default connect(mapStateToProps, null)(DeliveryReliabilityWidget);

const GaugeContainer = props => (
  <React.Fragment>
    <div style={{ display: 'flex' }} className="tv-radialgauge-container">
      <RadialGauge
        style={{ display: 'flex', margin: '10px' }}
        shape="arrow"
        renderAs="canvas"
        scale={{
          labels: { format: 'p', visible: true },
          majorTicks: { visible: true },
          minorUnit: 0.05,
          majorUnit: 0.2,
          max: 1,
          min: 0,
          ranges: [
            { from: 0.8, to: 1, color: '#ffc700' },
          ],
        }}
        pointer={{ value: getPercentage(props.value, props.dataList) }}
      />
      <div className="tv-radialgauge-inner_detail">
        <span>{`${getPercentage(props.value, props.dataList).toLocaleString('sv-Se', { style: 'percent', maximumFractionDigits: 1 })}`}</span>
        <span>{props.value.type}</span>
      </div>
    </div>
  </React.Fragment>

);
