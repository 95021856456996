import React from 'react';
import { isIE } from '../../utils';

export default function StarComponent({ style, value }) {
  const starArray = [];
  for (let i = 1; i <= parseInt(value); i++) {
    starArray.push(0);
  }
  return isIE ? (
    <div className="stars">
      {starArray.map(x => (
        <>★</>
      ))}
    </div>
  ) : (
    <div className="stars" style={{ ...style }} />
  );
}
