import moment from 'moment';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';

import { isEqual } from 'lodash/core';
import * as api from '../api';
import { showLoadingOverlay, hiddenLoadingOverlay } from './app';
import {
  timeJob,
  statusMapping,
  status,
  TIME_RANGE_GET_DATA,
  subStatus,
} from '../../Share/constants';
import { broadCast } from './eventBus';
import { tfvLog } from '../../Share/utils';
import {
  UPDATE_MY_ASSIGNMENT_LIST,
  UPDATE_JOBTIMER_MY_ASSIGNMENT,
  UPDATE_DURATION,
  RESET_DATE_CALENDAR,
  RESET_DURATION,
  SET_DASHBOARD_DATERANGE,
  DISPLAY_NEW_BOOKING,
} from './ActionTypes';
import {
  checkTimeForCalled,
  currentDate,
  getFirstDayAndLastDayOnMonthlyCalendarView,
  // convertFromBackendTzToLocalTz,
} from '../../Share/utils/dateUtil';

export function resetCalendar() {
  return dispatch => {
    dispatch({
      type: RESET_DURATION,
    });
    dispatch({
      type: RESET_DATE_CALENDAR,
    });
  };
}

const getOrderStatus = item => {
  if (item.ConsolidatedOrderStatus.StatusIdentifier === 'cancelled') {
    if (item.ConsolidatedOrderStatus.SubStatusIdentifier === 'late-cancelled') {
      return statusMapping[item.ConsolidatedOrderStatus.SubStatusIdentifier];
    }
    return statusMapping[item.ConsolidatedOrderStatus.StatusIdentifier];
  }
  return statusMapping[item.ConsolidatedOrderStatus.StatusIdentifier];
};

function convertCalendarEvents(response) {
  if (response.data.Orders) {
    const ordersUnGrouped = [];
    response.data.Orders.forEach(item => {
      const groupNumber =
        item.OrderGroup.OrderGroupIdentifier || item.OrderIdentifier;
      const OrderStatus = getOrderStatus(item);
      const subStatusIdentifier =
        item.ConsolidatedOrderStatus?.ConsolidatedOrderStatusMessages &&
        item.ConsolidatedOrderStatus.ConsolidatedOrderStatusMessages.length > 0
          ? item.ConsolidatedOrderStatus.ConsolidatedOrderStatusMessages[0]
              .StatusMessageIdentifier
          : subStatus.unknownStatus;
      ordersUnGrouped.push({
        ...item,
        groupNumber,
        subStatusIdentifier,
        OrderStatus,
      });
    });

    const orders = [];
    const ordersGrouped = groupBy(ordersUnGrouped, value => value.groupNumber);
    ordersUnGrouped.forEach(item => {
      Object.keys(ordersGrouped).forEach(key => {
        if (isEqual(item, ordersGrouped[key][0])) {
          orders.push(...ordersGrouped[key]);
        }
      });
    });
    return orders;
  }
  return [];
}

export function checkUpdateCalendarEvent() {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    const { from, to } = state.user.calendar.range;
    const { fromDuration, toDuration } = state.user.duration;
    const { assignmentList } = state.workAssignment;
    if (from.diff(fromDuration, 'days') < 0) {
      try {
        dispatch(showLoadingOverlay());
        const fromDate = fromDuration
          .clone()
          .subtract(TIME_RANGE_GET_DATA, 'days');
        const response = await api.searchOrders(token, fromDate, fromDuration);
        const orders = convertCalendarEvents(response);
        // ToDo: Duplicate possibility
        dispatch({
          type: UPDATE_MY_ASSIGNMENT_LIST,
          assignmentList: [...orders, ...assignmentList],
        });

        const duration = {
          fromDuration: fromDate.clone(),
          toDuration,
        };

        dispatch({
          type: UPDATE_DURATION,
          duration,
        });
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(updateCalendar());
        dispatch(hiddenLoadingOverlay());
      }
    }

    if (to.diff(toDuration, 'days') > 0) {
      try {
        dispatch(showLoadingOverlay());
        const toDate = toDuration.clone().add(TIME_RANGE_GET_DATA, 'days');
        const response = await api.searchOrders(token, toDuration, toDate);
        const orders = convertCalendarEvents(response);

        dispatch({
          type: UPDATE_MY_ASSIGNMENT_LIST,
          assignmentList: [...orders, ...assignmentList],
        });

        const duration = {
          fromDuration,
          toDuration: toDate.clone(),
        };

        dispatch({
          type: UPDATE_DURATION,
          duration,
        });
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(updateCalendar());
        dispatch(hiddenLoadingOverlay());
      }
    }
  };
}

export function updateCalendar() {
  return (dispatch, getState) => {
    const state = getState();
    const { assignmentList } = state.workAssignment;
    const uniqueList = uniqBy(assignmentList, item => item.OrderIdentifier);
    const dispatchResult = events => {
      broadCast('updateUserEvents', [dispatch, events]);
    };
    const events = [];
    uniqueList.forEach(assignment => {
      events.push({
        type:
          assignment.OrderStatus === status.available.name
            ? 'availableRequest'
            : 'tfv',
        identifier: assignment.OrderIdentifier,
        from: moment(assignment.DatetimeFrom),
        to: moment(assignment.DatetimeTo),
        category: assignment.Category,
        title: assignment.Category,
        skill: assignment.Resource.IsBasedOnSkillSubstitute
          ? assignment.SkillSubstitute
          : assignment.Skill,
        orderStatus: assignment.OrderStatus,
        isMessageService: assignment.IsMessageService,
        isTranslation: assignment.IsTranslation,
        assignment,
      });
    });

    // filter out cancelled and reject event
    const filteredEvent = events.filter(
      e => e.orderStatus !== status.losted.name,
    );
    // .filter(e => (e.orderStatus !== status.cancelled.name))
    // .filter(e => (e.orderStatus !== status.rejected.name))
    dispatchResult(filteredEvent);
  };
}

export function getDashboardData(searchValue = '') {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    const { timeMyAssignment } = state.jobTimer;
    const { from, to } = state.user.calendar.range;

    const extraDayMakeSureGetAllDataForAMonth = 1;
    const dateFrom = from
      .clone()
      .subtract(extraDayMakeSureGetAllDataForAMonth, 'days');
    const dateTo = to.clone().add(extraDayMakeSureGetAllDataForAMonth, 'days');

    const isCallMyAssignment = checkTimeForCalled(
      timeMyAssignment,
      timeJob.JOB_TIMER_MY_ASSIGNMENT,
    );
    if (isCallMyAssignment) {
      try {
        dispatch(showLoadingOverlay());
        const response = await api.searchOrders(
          token,
          dateFrom,
          dateTo,
          null,
          searchValue,
        );
        const orders = convertCalendarEvents(response);
        dispatch({
          type: UPDATE_MY_ASSIGNMENT_LIST,
          assignmentList: orders,
        });
        const timeAssignment = currentDate();
        dispatch({
          type: UPDATE_JOBTIMER_MY_ASSIGNMENT,
          timeMyAssignment: timeAssignment,
        });

        // Update duration
        const duration = {
          fromDuration: dateFrom,
          toDuration: dateTo,
        };

        dispatch({
          type: UPDATE_DURATION,
          duration,
        });
      } catch (error) {
        tfvLog(error);
      } finally {
        dispatch(updateCalendar());
        dispatch(hiddenLoadingOverlay());
      }
    } else {
      dispatch(updateCalendar());
    }
  };
}

export function ChangeDashboardDatePeriod(datePeriod) {
  return (dispatch, getState) => {
    const { start, end } = datePeriod;
    const state = getState();

    const newRange = {
      from: start,
      to: end,
    };
    dispatch({
      type: SET_DASHBOARD_DATERANGE,
      range: newRange,
    });
  };
}

export function searchOrder(orderNumber = '', onComplete, input) {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    const { calendar } = state.user;
    const { assignmentListSource } = state.workAssignment;
    try {
      const response = await api.searchOrders(
        token,
        null,
        null,
        orderNumber,
        '',
        null,
      );
      if (response.data && !response.data.Errors) {
        if (response.data.Orders === null && onComplete) {
          onComplete(4);
          return 0;
        }
        const orders = convertCalendarEvents(response);
        // eslint-disable-next-line no-use-before-define
        const updateList = addOrUpdateAssignment(assignmentListSource, orders);
        dispatch({
          type: UPDATE_MY_ASSIGNMENT_LIST,
          assignmentList: updateList,
        });
        const updatedCalendar = calculateAssignmentRelatedRange(orders[0]);
        dispatch({
          type: 'UPDATE_USER_CALENDAR',
          calendar: {
            ...calendar,
            ...updatedCalendar,
          },
        });
        dispatch(updateCalendar());
        if (onComplete) {
          onComplete(0, orders, input);
        }
      } else {
        tfvLog(response.data.Errors);
      }
    } catch (error) {
      console.log(error);
    }
  };
}

function addOrUpdateAssignment(oldList, newData = []) {
  let returnData = [];
  let isExistInOldList = false;
  if (!(newData && newData?.length > 0)) return oldList;
  returnData = oldList.map(x => {
    if (newData[0].OrderIdentifier !== x.OrderIdentifier) return { ...x };
    isExistInOldList = true;
    return { ...newData[0] };
  });
  if (!isExistInOldList) returnData.push({ ...newData[0] });
  return returnData;
}

function calculateAssignmentRelatedRange(order) {
  const dateWeek = moment(order.DatetimeFrom);

  return {
    week: {
      year: parseInt(dateWeek.format('gggg'), 10),
      number: parseInt(dateWeek.format('ww'), 10),
    },
    month: {
      year: dateWeek.year(),
      number: dateWeek.month(),
    },
    range: {
      from: getFirstDayAndLastDayOnMonthlyCalendarView(
        dateWeek.year(),
        dateWeek.month(),
      ).firstOfMonthlyCalendarView,
      to: getFirstDayAndLastDayOnMonthlyCalendarView(
        dateWeek.year(),
        dateWeek.month(),
      ).lastOfMonthCalendarView,
    },
  };
}

export function displayNewBooking() {
  return (dispatch) => {
    dispatch({
      type: DISPLAY_NEW_BOOKING,
      display: true,
    })
  }
}

export function hideNewBooking() {
  return (dispatch) => {
    dispatch({
      type: DISPLAY_NEW_BOOKING,
      display: false,
    })
  }
}
