import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ValidatorForm } from 'react-form-validator-core';
import 'react-tabs/style/react-tabs.css';
import './style.scss';
import { TFVButton, TextField, Translate } from '../../../Share/components';
import YourProfile from './YourProfile';
import history from '../../../Share/utils/history';
import {
  validateCurrentPassword,
  resetPassword,
} from '../../actions/authentication';
import { TFV_EMAIL_INFO } from '../../../Share/constants';
import ReleaseMessageList from '../../../Share/components/ReleaseMessage/ReleaseMessageList';
import ManageUsers from '../ManageUsers';
import { passwordPolicyCheck } from '../../../Share/utils';

const TAB_CONTACT_TFV = 2;

class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      password: '',
      repeatPassword: '',
      newPassword: '',
      submitDisabled: true,
      errorMessage: '',
      isSubmitButtonClick: false,
      selectedTabIndex: 0,
    };
  }

  handleToggleSwitchChange = () => {
    const { isChecked } = this.state;
    this.setState({
      isChecked: !isChecked,
    });
  };
  componentDidMount() {
    document.title = 'Kontoinställningar - TFV Kundportal';
    this.handleDefaultTabDisplay();
    ValidatorForm.addValidationRule('isPasswordMatch', value => {
      if (value && value === '') {
        return true;
      }
      if (!this.state.isSubmitButtonClick) return true;
      if (this.state.newPassword !== this.state.repeatPassword) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule('passwordPolicy', value => {
      if (value && value === '') {
        return true;
      }
      if (!this.state.isSubmitButtonClick) return true;
      const { isValid, errorMessage } = passwordPolicyCheck(value);
      this.setState({ errorMessage });
      return isValid;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedTabIndex !== this.state.selectedTabIndex) {
      switch (this.state.selectedTabIndex) {
        case 0:
          document.title = 'Kontoinställningar - TFV Kundportal';
          break;
        case 1:
          document.title = 'Behandling av personuppgifter - TFV Kundportal';
          break;
        case 2:
          document.title = 'Hantera användare – TFV Kundportal';
          break;
        default:
          document.title = 'Kontoinställningar - TFV Kundportal';
          break;
      }
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleValidatorListener = value => {
    this.form.isFormValid().then(isValid => {
      this.setState({ submitDisabled: !isValid });
    });
  };

  resetNewPassword = () => {
    const { onResetPassword } = this.props;
    onResetPassword(this.state.newPassword);
  };
  handleValidate = () => {
    const { isValid } = passwordPolicyCheck(this.state.newPassword || '');

    return (
      isValid &&
      this.state.repeatPassword === this.state.newPassword &&
      this.state.password !== ''
    );
  };
  handleSubmit = e => {
    const { onValidateCurrentPassword } = this.props;
    this.setState({ isSubmitButtonClick: true });
    const isValid = this.handleValidate();
    if (isValid) {
      onValidateCurrentPassword(this.state.password, this.resetNewPassword);
    }
  };

  handleMailClick = e => {
    e.preventDefault();
    window.location.href = `mailto:${TFV_EMAIL_INFO}`;
  };

  handleDefaultTabDisplay = () => {
    const {
      location: { state },
    } = this.props;
    const { from } = state || {
      from: { pathname: '/', tabIndex: 0 },
    };
    let defaultTabIndex = from.tabIndex;

    const redirectFromNavBarContactTFV = from.pathname === '/contact-tfv';
    if (redirectFromNavBarContactTFV) {
      defaultTabIndex = TAB_CONTACT_TFV;
    }

    this.setState({ selectedTabIndex: defaultTabIndex }, () => {
      document.title = 'Kontoinställningar - TFV Kundportal';
    });
  };

  render() {
    return (
      <div className="tv-settings__wrapper">
        <Tabs
          className="tv-settings__tabs-wrapper"
          selectedIndex={this.state.selectedTabIndex}
          onSelect={index => this.setState({ selectedTabIndex: index })}
        >
          <TabList>
            <Tab tabIndex="0">
              <div>
                <div className="tv-settings__tab-text-main">
                  <Translate content="settings.yourAccountPreferences" />
                </div>
              </div>
            </Tab>
            <Tab tabIndex="0">
              <div>
                <div className="tv-settings__tab-text-main">
                  <Translate content="settings.privacyAndTerms" />
                </div>
              </div>
            </Tab>
            <Tab tabIndex="0">
              <div>
                <div className="tv-settings__tab-text-main">
                  <Translate content="settings.manageUsers" />
                </div>
              </div>
            </Tab>
          </TabList>

          <TabPanel className="react-tabs__tab-panel   px-0  align-items-center">
            <div className="tv-settings__panel-wrapper w-100">
              <div className="tv-settings__panel-title tv-display-mobile-hide">
                <Translate content="settings.accountSettings" />
              </div>
              <YourProfile />
              <div className="tv-settings__panel-title">
                <Translate content="settings.changePassword" />
              </div>
              <div className="tv-settings__panel-description">
                <Translate content="login.passwordPolicyText" />
              </div>
              <div className="tv-settings__account-wrapper">
                <ValidatorForm
                  ref={node => {
                    this.form = node;
                  }}
                  onSubmit={() => {}}
                  // onError={this.handleValidatorListener}
                >
                  <div className="row col-lg-6 col-md-12 px-0">
                    <div className="col-md-12">
                      <label htmlFor='password' className="tv-settings__password-label ">
                        <Translate content="settings.currentPassword" />
                      </label>
                      <TextField
                        type="password"
                        // validatorListener={this.handleValidatorListener}
                        onChange={this.handleChange}
                        className="tx-input-text tx-input-text--border tv-settings__password-field"
                        name="password"
                        id="password"
                        aria-label={Translate({
                          content: 'settings.currentPassword',
                        })}
                        placeholder={Translate({
                          content: 'settings.currentPassword',
                        })}
                        value={this.state.password}
                        validators={['required']}
                        errorMessages={[
                          Translate({
                            content: 'validator.isRequired',
                            params: {
                              name: Translate({
                                content: 'general.currentPassword',
                              }),
                            },
                          }),
                          Translate({
                            content: 'validator.isNotValid',
                            params: {
                              name: Translate({
                                content: 'general.currentPassword',
                              }),
                            },
                          }),
                        ]}
                      />
                    </div>
                    <div className=" col-md-12">
                      <label htmlFor='newPassword' className="tv-settings__password-label">
                        <Translate content="settings.newPassword" />
                      </label>
                      <TextField
                        type="password"
                        onChange={this.handleChange}
                        className="tx-input-text tx-input-text--border tv-settings__password-field"
                        name="newPassword"
                        id="newPassword"
                        aria-label={Translate({
                          content: 'settings.newPassword',
                        })}
                        placeholder={Translate({
                          content: 'settings.newPassword',
                        })}
                        value={this.state.newPassword}
                        validators={['required', 'passwordPolicy']}
                        errorMessages={[
                          'Nytt lösenord är obligatoriskt',
                          this.state.errorMessage,
                        ]}
                      />
                    </div>
                    <div className=" col-md-12">
                      <label htmlFor='repeatPassword' className="tv-settings__password-label">
                        <Translate content="settings.repeatPassword" />
                      </label>
                      <TextField
                        type="password"
                        onChange={this.handleChange}
                        className="tx-input-text tx-input-text--border tv-settings__password-field"
                        name="repeatPassword"
                        id="repeatPassword"
                        aria-label={Translate({
                          content: 'settings.repeatPassword',
                        })}
                        placeholder={Translate({
                          content: 'settings.repeatPassword',
                        })}
                        value={this.state.repeatPassword}
                        validators={['required', 'isPasswordMatch']}
                        errorMessages={[
                          Translate({
                            content: 'validator.isNotValid',
                            params: {
                              name: Translate({
                                content: 'general.repeatPassword',
                              }),
                            },
                          }),
                          Translate({
                            content: 'validator.passwordMatch',
                          }),
                        ]}
                      />
                    </div>

                    <div className="col-md-12 mt-4">
                      <TFVButton
                        type="secondary"
                        buttonType="submit"
                        text={Translate({
                          content: 'settings.saveNewPassword',
                        })}
                        onClick={this.handleSubmit}
                        // disabled={this.state.submitDisabled}
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="row"></div>
                </ValidatorForm>
              </div>
            </div>
          </TabPanel>

          <TabPanel className="react-tabs__tab-panel  px-0  align-items-center">
            <div className="tv-settings__panel-wrapper w-100">
              <div className="tv-settings__panel-title tv-display-mobile-hide">
                <Translate content="termsAndCondition.title" />
              </div>

              <div className="d-flex flex-column">
                <div className="tc-body-text">
                  Du som är i kontakt med Tolkförmedling Väst ska känna dig
                  trygg med att dina personuppgifter behandlas på ett säkert och
                  lagligt sätt.
                </div>
                <div className="tc-body-text">
                  Läs mer om behandling av personuppgifter på förbundets{' '}
                  <a
                    href="https://www.tolkformedlingvast.se/om-tfv/behandling-av-personuppgifter.html"
                    target="_blank"
                  >
                    webbplats.
                  </a>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel className="react-tabs__tab-panel px-0  align-items-center">
            <ManageUsers />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

Setting.propTypes = {
  onResetPassword: PropTypes.func.isRequired,
  onValidateCurrentPassword: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onValidateCurrentPassword: (plainTextPassword, callback) => {
    dispatch(validateCurrentPassword(plainTextPassword, callback));
  },
  onResetPassword: (plainTextPassword, callback) => {
    dispatch(resetPassword(plainTextPassword, callback));
  },
});

export default connect(null, mapDispatchToProps)(Setting);
