import FocusTrap from 'focus-trap-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  AssignmentStatus,
  Attachment,
  Translate,
} from '../../../Share/components';
import CloseButton from '../../../Share/components/CloseButton';

function DetailPanel({
  setShowDetailPanel,
  translationDetail,
  setIsSelected,
  setTranslationDetail,
  handleGetAttachment,
}) {
  const skills = useSelector(state => state.orderTranslation.translationSkills);
  let targetSkill = {};
  if (skills) {
    targetSkill = skills.find(
      skill =>
        skill.SkillIdentifier === translationDetail.SkillIdentifierTarget,
    );
  }
  useEffect(() => {
    const originalTitle = document.title;
    document.title = translationDetail.OrderNumber + ' - TFV Kundportal';
    return () => {
      document.title = originalTitle;
    };
  }, [translationDetail,]);
  const attachments = translationDetail.Attachments || [];

  const attachmentElement = attachments.map(item => (
    <Attachment
      key={item.AttachmentReference}
      attachmentId={item.AttachmentReference}
      fileName={item.AttachmentFilename}
      type={item.AttachmentContentType}
      onGetAttachment={handleGetAttachment}
    />
  ));

  const onCloseClick = () => {
    setShowDetailPanel(false);
    setIsSelected(false);
    setTranslationDetail({});
  };

  useEffect(() => {
    const onEscapePress = e => {
      if (e.key === 'Escape') {
        onCloseClick();
      }
    };
    document.addEventListener('keydown', onEscapePress);
    return () => {
      document.removeEventListener('keydown', onEscapePress);
    };
  }, []);

  return (
    <FocusTrap
      focusTrapOptions={{
        escapeDeactivates: false,
        allowOutsideClick: true,
        preventScroll: true,
      }}
    >
      <div className="tv_translation__detail-panel" aria-live="polite">
        <div className="tv_translation__detail-panel__header">
          <div className="tv_translation__detail-panel__header-title">
            {translationDetail.OrderNumber}
          </div>
          <CloseButton
            className="tk-close-btn tv-buttons__type icon-close"
            onClick={onCloseClick}
          />
        </div>
        <hr style={{ margin: 0 }} />

        <div className="p-3">
          <AssignmentStatus
            className="tv-workassignment__detail-status tv-display-flex"
            status={translationDetail.OrderStatus}
            statusName={
              translationDetail.OrderStatus === 'late-cancelled'
                ? 'Sent avbokad'
                : translationDetail.ConsolidatedOrderStatus.StatusName
            }
          >
            <div className="d-flex">{`${translationDetail.ArticleName} ${
              translationDetail.IsSpecialAssignment ? '(specialuppdrag)' : ''
            }`}</div>
          </AssignmentStatus>
        </div>
        <hr style={{ margin: 0 }} />

        <div className="tv_translation__detail-panel__body">
          <div className="tv_translation__detail-panel__body-item">
            <i className="fa fa-plane" aria-hidden="true" />
            {translationDetail.ModeOfTransport}
          </div>
          {translationDetail.DateDelivery && (
            <div className="tv_translation__detail-panel__body-item">
              <i className="fa fa-calendar" aria-hidden="true" />
              {moment(translationDetail.DateDelivery).format('YYYY-MM-DD')}
            </div>
          )}
          <div className="tv_translation__detail-panel__body-item">
            <i className="fa fa-globe" aria-hidden="true" />
            <Translate content="singleEmailTranslation.sourceLanguage" />
            {` : ${translationDetail.Skill}`}
          </div>
          <div className="tv_translation__detail-panel__body-item">
            <i className="fa fa-language" aria-hidden="true" />
            <Translate content="singleEmailTranslation.targetLanguages" />
            {` : ${targetSkill && targetSkill.SkillName}`}
          </div>

          <div className="tv_translation__detail-panel__body-item">
            <i className="fa fa-plane" aria-hidden="true" />
            <Translate content="singleEmailTranslation.contactPersonOrderer" />
            {` : ${translationDetail.ContactPersonOrderer.ContactPersonOrdererName}`}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              marginTop: '10px',
            }}
          >
            {attachmentElement}
          </div>

          {/* <div className="tv_translation__detail-panel__body-item">
          <i class="fa fa-quote-left" aria-hidden="true" />
          <Translate content="singleEmailTranslation.needAQuote" /> :{' '}
          {translationDetail.needAQuote == true ? 'Ja' : 'Nej'}
        </div> */}
          {/* <div className="align-items-start tv_translation__detail-panel__body-item">
          <i class="fa fa-file" aria-hidden="true" />
          <div className="tv_translation__attachement">
            {translationDetail.Attachments.map(item => (
              <div className="tv_translation__attachement">
                <div className="tv_translation__attachement__file-path">
                  {item.AttachmentFilename}
                </div>
                <div className="tv_translation__attachement__text">
                  Dokumentet omfattas av sekretess:{' '}
                  {item.isDocumentSubjectToConfidentiality == true
                    ? 'Ja'
                    : 'Nej'}
                </div>
                <div className="tv_translation__attachement__text">
                  Önskemål om stämplat dokument:{' '}
                  {item.requestForStampedDocument == true ? 'Ja' : 'Nej'}
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div> */}
        </div>
      </div>
    </FocusTrap>
  );
}

export default DetailPanel;
