module.exports = {
  faqData: {
    rowOne: [
      {
        id: 'rowTwo-columnOne',
        question: 'Hur fungerar telefontolkning?',
        answer: `När du bokar telefontolkning kommer du att få ett telefonnummer till tolken när bokningen är tillsatt dock tidigast 24 timmar innan uppdragsstart.

        ||Det är du som tolkanvändare som ringer upp tolken vid uppdragets start.
        
        ||När du använder telefontolk så är det några saker som är extra viktigt att tänka på:

        || - Använd ordentlig högtalarutrustning och kontrollera att utrustningen fungerar som den ska i god tid före tolkningen

        || - Informera tolken om vilka som befinner sig i rummet

        || - Tala extra klart och tydligt och tala en i taget

        || - Minimera störande bakgrundsljud

        || - Stäm av start- och sluttid med tolken
        
        `,
      },
      {
        id: 'rowOne-columnTwo',
        question: 'Vad gör jag om tolken inte svarar?',
        answer: `Om tolk inte svarar på utsatt tid för uppdraget ska ni genast ringa oss på 0770-813 000.

          ||Det ger oss chansen att lösa problemet. Vi har alltid andra kontaktvägar till tolk och kanske har det hänt något med tolkens telefon eller ni har fått fel telefonnummer.`,
      },
    ],
    rowTwo: [
      {
        id: 'rowThree-columnOne',
        question: 'Vad innebär det att lämna feedback på uppdrag och varför ska vi göra det?',
        answer: `Ni har möjlighet att skicka feedback på alla utförda uppdrag. Att vi får återkoppling är viktigt för oss och handlar i slutändan om att matcha rätt tolk till rätt uppdrag.

          ||Det är lika viktigt för oss att få in positiv feedback som negativ feedback. Ni betygsätter uppdraget på en skala 1-5. 1-2 stjärnor utreds av TFV, 3-5 stjärnor är positivt. Det är det samlade intrycket av tolkningen i dess helhet som ska bedömas, och det är i första hand tolkanvändaren som ska lämna feedback.
          
          ||Tack för att ni ger oss feedback!

          `,
      },
      {
        id: 'rowTwo-columnTwo',
        question: 'Hur långt fram måste jag boka för att få tolk?',
        answer: `Det är något som varierar från språk till språk. Är det tolk på plats, ett mindre språk, krav på exempelvis en specifik tolk eller kön så minskar antalet tillgängliga tolkar och då kan du behöva boka med ännu bättre framförhållning.

        ||Boka alltid tolk i så god tid som möjligt.`,
      },
    ],
    rowThree: [
      {
        id: 'rowFour-columnOne',
        question: 'Vart kan jag hitta tolkens telefonnummer?',
        answer: `24 timmar innan bokningens utförande dyker tolkens telefonnummer upp på bokningen inne i kundportalen.

        ||Även ett mail med tolkens uppgifter går ut till den e-post som registrerades vid bokningen.`,
      },
    ],
  },
};
