import React, { Component, useEffect, useRef, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  getDashboardData,
  updateUserCalendar,
  getNotificationList,
  getAttachment,
  checkUpdateCalendarEvent,
  resetCalendar,
  setOrderRating,
  onUpdateNewAssignmentsTemp,
  updateMyAssignmentFilters,
  GetRatingReasons,
  getDeviations,
  fetchContactPersons,
} from '../../actions';
import {
  Calendar,
  Translate,
  ReleaseMessage,
  RatingSummary,
  LeftDrawerSlide,
  AssignmentListBox,
  RouteLeavingGuard,
  RouteLeavingModal,
  Dialog,
} from '../../../Share/components';
import AssignmentDetail from '../AssignmentDetail';
import NotificationDetail from '../NotificationDetail';
import './style.scss';
import { status, FILTER_TYPES, PAGE_TYPES } from '../../../Share/constants';
import history from '../../../Share/utils/history';
import { QuickOrder } from '../QuickOrder';
import StatusFilter from '../StatusFilter';
import { subscribe, unSubscribe } from '../../actions/eventBus';
import {
  convertAssignmentToOrder,
  generateEditOrderDateSession,
  isIE,
} from '../../../Share/utils';
import { currentDate } from '../../../Share/utils/dateUtil';
import OrderSearchBar from './OrderSearchBar';
import CopyBooking from '../AssignmentDetail/CopyBooking';
import { withRouter } from 'react-router-dom';
import { isArray } from 'lodash';

const propTypes = {
  availableRequests: PropTypes.shape({}),
  dateRange: PropTypes.shape({}),
  onGetDashboardData: PropTypes.func,
  onUpdateUserCalendar: PropTypes.func,
  onGetNotifications: PropTypes.func,
  onGetAttachment: PropTypes.func,
  assignmentDetail: PropTypes.shape({}),
  assignmentList: PropTypes.arrayOf(PropTypes.shape({})),
  assignmentNotifications: PropTypes.arrayOf(PropTypes.shape({})),
  onCheckUpdateCalendarEvent: PropTypes.func,
  onResetCalendar: PropTypes.func,
  onOrderRated: PropTypes.func.isRequired,
  onSwitchToDetailOrder: PropTypes.func,
  mapOnFilterDeviation: PropTypes.func,
  executeGetContactPersons: PropTypes.func,
  displayBooking: PropTypes.bool,
};

const defaultProps = {
  availableRequests: {
    count: 0,
    items: [],
  },
  onCheckUpdateCalendarEvent: () => {},
  onResetCalendar: () => {},
  onGetDashboardData: () => {},
  onUpdateUserCalendar: () => {},
  onGetNotifications: () => {},
  onGetAttachment: () => {},
  // onOrderRated: () => {},
  assignmentDetail: {},
  dateRange: {},
  assignmentList: [],
  assignmentNotifications: [],
  onSwitchToDetailOrder: () => {},
  mapOnFilterDeviation: () => {},
  executeGetContactPersons: () => {},
  displayBooking: false,
};

const INITAL_STATES = {
  showDetail: false,
  messageService: false,
  showNotification: false,
  showQuickOrder: false,
  quickOrderDate: null,
  showStatusFilter: false,
  showAssigmentList: false,
  showInterpretNow: false,
  assignmentDetail: {},
  selectedDateInfo: {
    assignmentList: [],
    day: moment(),
  },
  sameDateAssignments: [],
  disableNextButton: false,
  disablePreviousButton: false,
  windowWidth: window.innerWidth,
  isDirty: false,
  visibleRouteLeavingModal: false,
  isWeeklyViewData: false,
  showCopyBookingModal: false,
  fieldList: [
    {
      name: 'SkillIdentifier',
      value: false,
      label: 'Language',
      isMessageService: false,
    },
    {
      name: 'SkillSubstituteIdentifier',
      value: false,
      label: 'Alternative Language',
      isMessageService: false,
    },
    {
      name: 'ServiceIdentifier',
      value: false,
      label: 'Type of Interpreter',
      isMessageService: false,
    },
    {
      name: 'MeetingUrl',
      value: false,
      label: 'Video Link',
      isMessageService: false,
    },
    {
      name: 'DigitalMeetingPlatformIdentifier',
      value: false,
      label: 'Platform',
      isMessageService: false,
    },
    {
      name: 'ContactPersonIdentifier',
      value: false,
      label: 'Interpreter User',
      isMessageService: false,
    },
    {
      name: 'ContactPersonOrdererIdentifier',
      value: false,
      label: 'Customer',
      isMessageService: false,
    },
    {
      name: 'GenderIdPreferred',
      value: false,
      label: 'Gender',
      isMessageService: false,
    },
    {
      name: 'CompetenceLevelIdentifier',
      value: false,
      label: 'Competence',
      isMessageService: false,
    },
    {
      name: 'requestedInterpreter',
      value: false,
      label: 'Specific Interpreter',
      isMessageService: false,
    },
    {
      name: 'AdditionalRequirements',
      value: false,
      label: 'Additional Requirements',
      isMessageService: false,
    },
    {
      name: 'CustomerReference',
      value: false,
      label: 'Booking Reference',
      isMessageService: false,
    },
    {
      name: 'assignmentDescription',
      value: false,
      label: 'Information for Interpreter',
      isMessageService: false,
    },
    { name: 'message', value: false, label: 'Message', isMessageService: true },
    {
      name: 'recipientName',
      value: false,
      label: 'Recipient Name',
      isMessageService: true,
    },
    {
      name: 'phoneNumber',
      value: false,
      label: 'Phone Number',
      isMessageService: true,
    },
  ],
  messageServiceFieldList: [],
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = INITAL_STATES;
  }

  componentDidMount() {
    document.title = 'Översikt - TFV Kundportal';
    window.addEventListener('load', this.handleLoad);
    this.props.onResetCalendar();
    this.props.onGetDashboardData();
    this.props.getRateReasons();
    this.props.executeGetContactPersons();
    window.addEventListener('resize', () => {
      this.setHeightVariable();
    });
    this.orderSuccess = subscribe('SuccessFullOrderCreation', () => {
      this.props.onResetCalendar();
      this.props.onGetDashboardData();
    });
    this.cancelevent = subscribe('SuccessFullCancellOrder', response => {
      this.setState({
        ...this.state,
        showAssignemntList: false,
        showAssigmentList: false,
        showDetail: false,
      });
      this.props.onResetCalendar();
      this.props.onGetDashboardData();
    });

    if (this.props.displayBooking) {
      this.onCreateOrder(null);
    }
  }
  componentWillUnmount() {
    unSubscribe('SuccessFullCancellOrder', this.cancelevent);
    unSubscribe('SuccessFullOrderCreation', this.orderSuccess);
  }
  setHeightVariable() {
    const vh = document.body.scrollHeight;

    const rightWrapper = document.querySelector('.tv-right-pannel-wrapper');
    if (rightWrapper) {
      rightWrapper.style.setProperty('--vh', `${vh}px`);
    }
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  componentDidUpdate(prevProps) {
    const { skills } = prevProps;
    if (
      (!isArray(skills) || (isArray(skills) && skills.length === 0)) &&
      isArray(this.props.skills) &&
      this.props.skills.length > 0
    ) {
      this.checkNewBooking(this.props.location.pathname);
    }
    if (this.props.displayBooking !== prevProps.displayBooking) {
      if (this.props.displayBooking) {
        this.onCreateOrder(null);
      }
    }
  }

  onShowDetail = item => {
    this.setState({
      ...this.state,
      sameDateAssignments: [],
      disableNextButton: false,
      disablePreviousButton: false,
      showDetail: true,
      showNotification: false,
      assignmentDetail: item,
      isEventDetail: false,
      activeAssignmentId: '',
    });
  };

  onTabChange = () => {
    this.setState({
      showNotification: false,
      showDetail: false,
      showAssigmentList: false,
    });
  };

  handleEventClick = (type, id) => {
    if (type === 'MESSAGE') {
      const tempAssignment = {
        isMessageServiceAssignment: true,
        AdditionalRequirements: null,
        AddressPerformingEffective: 'Colombo 10, 111 51, Colombo',
        ArticleName: 'Message Service',
        AssignmentStatus: 'Förfrågan',
        Attachments: null,
        CanBeRated: false,
        CanBeUpdated: false,
        CanCancelOrder: true,
        CanCreateDeviation: false,
        CancellationStatus: null,
        Category: 'Sjukvård',
        CompetenceLevel: null,
        CompetenceLevelIdentifier: null,
        ConsolidatedOrderStatus: {
          ConsolidatedOrderStatusMessages: null,
          StatusIdentifier: 'open',
          StatusName: 'Öppen',
          SubStatusIdentifier: 'searching-for-interpreter',
        },
        ContactPerson: {
          ContactPersonDirectPhoneNumber: '0094715699600',
          ContactPersonEmail: 'venuka.s@aventude.com',
          ContactPersonFirstName: 'Aventude',
          ContactPersonIdentifier: '3f41e956-d2ba-4e2d-b8f9-728c996246c0',
          ContactPersonLastName: 'Contact Person',
          ContactPersonMobilePhoneNumber: '0094715699600',
          ContactPersonName: 'Aventude Contact Person',
          ContactPersonTitle: null,
        },
        ContactPersonOrderer: {
          ContactPersonOrdererDirectPhoneNumber: '0094715699600',
          ContactPersonOrdererEmail: 'venuka.s@aventude.com',
          ContactPersonOrdererFirstName: 'Aventude',
          ContactPersonOrdererIdentifier:
            '3f41e956-d2ba-4e2d-b8f9-728c996246c0',
          ContactPersonOrdererLastName: 'Contact Person',
          ContactPersonOrdererMobilePhoneNumber: '0094715699600',
          ContactPersonOrdererName: 'Aventude Contact Person',
          ContactPersonOrdererTitle: null,
        },
        Customer: {
          CustomerCityDistrict: null,
          CustomerDirectPhoneNumber: null,
          CustomerName: 'TEST Aventude Testkund',
          CustomerSwitchboardPhoneNumber: null,
        },
        CustomerOrderNumber: null,
        CustomerReference: null,
        DatetimeCompleted: null,
        DatetimeFrom: '2022-12-24 10:10:00',
        DatetimeSubmitted: null,
        DatetimeTo: '2022-12-24 10:40:00',
        DoRequireCompetenceLevelOrHigher: null,
        DoRequireCorrectGender: null,
        DoRequireExactCompetenceLevel: null,
        Expenses: null,
        GenderIdPreferred: null,
        HasDeviation: false,
        IsAutoAssignOrder: false,
        IsAwaitingCustomerConfirmation: false,
        IsDeviatingAddressPerforming: true,
        IsHomeAddress: false,
        IsPerformedRemotely: true,
        IsPhoneAssignment: true,
        LMANumber: null,
        MeetingDetails: {
          DigitalMeetingPlatformDescription: null,
          DigitalMeetingPlatformIdentifier: null,
          DigitalMeetingPlatformName: null,
          DoRequireBankIdVerification: 'False',
          MeetingPassCode: null,
          MeetingPhoneNumber: null,
          MeetingUrl: null,
        },
        NotesResource: null,
        NumberOfUnreadNotifications: 1,
        OrderGroup: { OrderGroupDescription: null, OrderGroupIdentifier: null },
        OrderIdentifier: '9ad6e8a7-809a-4d07-949b-3a3541071d98',
        OrderNumber: 'FK87873',
        OrderStatus: 'available',
        OrderStatusIdentifier: '24b0e823-0c3d-47f4-a82f-db0a75b37449',
        PhoneNumberOrder: null,
        PostalAddressPerforming: {
          AddressRow1: 'Colombo 10',
          AddressRow2: null,
          Attention: null,
          CareOf: null,
          City: 'Colombo',
          IsHomeAddress: false,
          PostalCode: 11151,
        },
        PreferredResourceCustomer: null,
        PreferredResourceLevel: null,
        RatingCustomer: null,
        RatingCustomerComment: null,
        ReplacedByDatetimeFrom: null,
        ReplacedByDatetimeTo: null,
        ReplacedByOrderIdentifier: null,
        ReplacedByOrderNumber: null,
        ReplacesDatetimeFrom: null,
        ReplacesDatetimeTo: null,
        ReplacesOrderIdentifier: null,
        ReplacesOrderNumber: null,
        RequestGroup: { ResourceRequestGroupIdentifier: null },
        Resource: {
          IsAppointed: false,
          IsAwaitingCustomerConfirmation: false,
          IsAwaitingResourceRequestResponse: true,
          IsBasedOnSkillSubstitute: null,
          IsInhouseCallcenterResource: false,
          ResourceAgencyNumber: null,
          ResourceAgencyNumberAppointed: null,
          ResourceCompetenceLevelName: null,
          ResourceEmailAddress: null,
          ResourceFullName: null,
          ResourceGenderName: null,
          ResourceNumber: null,
          ResourceNumberAppointed: null,
        },
        ServiceIdentifier: '1',
        Skill: 'Albanska',
        SkillIdentifier: '2f26ecc0-ec8c-4119-a81f-87beddf2dd19',
        SkillSubstitute: '',
        SkillSubstituteIdentifier: null,
        SpecificTravelInstructions: null,
        TimeReport: {
          DatetimeAdjustedFrom: null,
          DatetimeAdjustedTo: null,
          DatetimeOriginalFrom: null,
          DatetimeOriginalTo: null,
          DatetimeTravelFromAssignmentFrom: null,
          DatetimeTravelFromAssignmentTo: null,
          DatetimeTravelToAssignmentFrom: null,
          DatetimeTravelToAssignmentTo: null,
          Downtime: null,
          DowntimeAdjusted: null,
          TimeReportComment: null,
          TravelDistanceFrom: null,
          TravelDistanceTo: null,
          TraveltimeFromAssignment: null,
          TraveltimeFromAssignmentAdjusted: null,
          TraveltimeToAssignment: null,
          TraveltimeToAssignmentAdjusted: null,
        },
        UnreadNotifications: [
          {
            DatetimeSent: '12/20/2022 5:40:18 AM',
            Headline: 'Information om din bokning',
            Message: 'Här kommer TFVs egna anpassade mall att finnas.',
            NotificationIdentifier: '5523a011-3065-473c-a3dd-bf89063ff29b',
            NotificationTypeIdentifier: 'Assignment',
          },
        ],
        assignmentDescription: '',
        requestedInterpreter: null,
        groupNumber: '9ad6e8a7-809a-4d07-949b-3a3541071d98',
        subStatusIdentifier: 'unknown-status',
        messageService: {
          language: 'Arabiska',
          date: 'October 12, 2022',
          time: '08:00 AM',
          message: 'Sample message to translate',
          recipientName: 'Yasmika Dissanayake',
          phoneNumber: '+94766664935',
        },
      };

      this.setState({
        ...this.state,
        sameDateAssignments: [],
        assignmentDetail: tempAssignment,
        messageService: type === 'MESSAGE',
        // showDetail: true,
        isEventDetail: true,
        // showQuickOrder: false,
        activeAssignmentId: 0,
        disableNextButton: true,
        disablePreviousButton: true,
        isWeeklyViewData: true,
      });
    } else {
      const { assignmentList } = this.props;
      const assignmentDetail = assignmentList.find(
        x => x.OrderIdentifier === id,
      );

      const { DatetimeFrom } = assignmentDetail;
      const Date = moment(DatetimeFrom).format('DD-MM-YYYY');

      const sameDateAssignments = assignmentList.filter(
        item => moment(item.DatetimeFrom).format('DD-MM-YYYY') === Date,
      );
      const Index = sameDateAssignments.findIndex(
        x => x.OrderIdentifier === assignmentDetail.OrderIdentifier,
      );

      this.setState({
        ...this.state,
        sameDateAssignments,
        assignmentDetail,
        messageService: type === 'MESSAGE',
        // showDetail: true,
        isEventDetail: true,
        // showQuickOrder: false,
        activeAssignmentId: assignmentDetail.OrderIdentifier,
        disableNextButton: Index === sameDateAssignments.length - 1,
        disablePreviousButton: Index === 0,
        isWeeklyViewData: true,
      });
    }

    const { isDirty } = this.state;
    if (isDirty) {
      this.setState({ visibleRouteLeavingModal: true });
    } else {
      this.setState({ showDetail: true });
    }
  };

  redirectToTimeSheet = () => {
    const { assignmentDetail } = this.state;

    history.push({
      pathname: '/time-sheet',
      state: {
        from: this.props.location,
        assignmentDetail,
      },
    });
  };

  handleClickTimeReport = () => {
    this.redirectToTimeSheet();
  };

  handleDrawerClose = () => {
    this.setState({
      showDetail: false,
    });
  };

  handleQuickOrderDrawerClose = () => {
    this.setState({
      showQuickOrder: false,
    });
  };

  handleStatusFilterDrawerClose = () => {
    this.setState({
      showStatusFilter: false,
    });
  };
  handleAssignmentListDrawerClose = () => {
    this.setState({
      showAssigmentList: false,
    });
  };

  handleDrawerNextPrevious = value => {
    const { activeAssignmentId, sameDateAssignments } = this.state;
    this.setState({
      ...this.state,
      disableNextButton: false,
      disablePreviousButton: false,
    });

    if (sameDateAssignments.length && activeAssignmentId) {
      const Index = sameDateAssignments.findIndex(
        x => x.OrderIdentifier === activeAssignmentId,
      );

      if (
        Index + value <= sameDateAssignments.length - 1 &&
        Index + value >= 0
      ) {
        const assignmentDetail = sameDateAssignments[Index + value];
        this.setState({
          ...this.state,
          assignmentDetail,
          showDetail: true,
          isEventDetail: true,
          activeAssignmentId: assignmentDetail.OrderIdentifier,
          disableNextButton: Index + value === sameDateAssignments.length - 1,
          disablePreviousButton: Index + value === 0,
        });
      }
    }
  };

  handleChangeEventCalendar = event => {
    const { onUpdateUserCalendar, onCheckUpdateCalendarEvent } = this.props;
    onUpdateUserCalendar(event);
    onCheckUpdateCalendarEvent();
  };

  handleFilter = value => {
    const { updateMyAssignmentFilter } = this.props;
    updateMyAssignmentFilter(FILTER_TYPES.STATUS_FILTER, value);
  };

  resetQuickOrderDate = () => {
    this.setState({
      quickOrderDate: null,
    });
  };

  handleEditOrder = assignment => {
    const { skills } = this.props;
    const convertedOrder = convertAssignmentToOrder(
      assignment,
      this.props.profile,
      skills,
    );

    this.props.onSwitchToDetailOrder({
      ...convertedOrder,
      dateRange: generateEditOrderDateSession(
        assignment.DatetimeFrom,
        assignment.DatetimeTo,
      ),
      isEdit: true,
      OrderIdentifier: assignment.OrderIdentifier,
      OrderNumber: assignment.OrderNumber,
    });
    this.RouteLeavingGuardHandler(true);

    this.setState({
      showQuickOrder: true,
      showDetail: false,
      showAssigmentList: false,
    });
  };

  RouteLeavingGuardHandler = value => {
    this.setState({ isDirty: value });
  };
  RouteLeavingModalHandler = (eventList, dateItem) => {
    const { isDirty } = this.state;
    this.setState({
      // showAssigmentList: true,
      showDetail: false,
      // showQuickOrder: false,
      selectedDateInfo: {
        day: dateItem,
        assignmentList: eventList,
      },
    });
    if (isDirty) {
      this.setState({ visibleRouteLeavingModal: true });
    } else {
      this.setState({ showAssigmentList: true });
    }
  };

  handlerNotification = notifications => {
    const { onGetNotifications } = this.props;
    const { assignmentDetail } = this.state;
    onGetNotifications(assignmentDetail.OrderIdentifier, () => {
      this.setState({
        showNotification: true,
      });
    });
  };

  handleShowCopyBookingModal = assignment => {
    this.setState({
      showCopyBookingModal: true,
    });
  };

  handleHideCopyBookingModal = () => {
    this.setState({
      showCopyBookingModal: false,
    });
  };

  handleFieldChange = (event, field, label, index) => {
    this.state.fieldList[index] = {
      name: field,
      value: event.target.checked,
      label: label,
    };
  };

  // handleCopyOrder = () => {
  // for (let key in this.state.fieldList) {
  //   if (!this.state.fieldList[key].value) {
  //     if (this.state.fieldList[key].name == 'ContactPersonIdentifier') {
  //       this.state.assignmentDetail.ContactPerson.ContactPersonIdentifier = null
  //     }
  //     if (this.state.fieldList[key].name == 'ContactPersonOrdererIdentifier') {
  //       this.state.assignmentDetail.ContactPersonOrderer.ContactPersonOrdererIdentifier = null
  //     }
  //     if (this.state.fieldList[key].name == 'MeetingUrl') {
  //       this.state.assignmentDetail.MeetingDetails.MeetingUrl = null
  //     }
  //     if (this.state.fieldList[key].name == 'DigitalMeetingPlatformIdentifier') {
  //       this.state.assignmentDetail.MeetingDetails.DigitalMeetingPlatformIdentifier = null
  //     }
  //     if (this.state.fieldList[key].name == 'requestedInterpreter') {
  //       this.state.assignmentDetail.requestedInterpreter = null
  //       this.state.assignmentDetail.PreferredResourceCustomer = null
  //     }
  //     if (this.state.fieldList[key].name == 'AdditionalRequirements') {
  //       this.state.assignmentDetail.AdditionalRequirements = null
  //       this.state.assignmentDetail.additionalRequirements = null
  //     }
  //     if (this.state.fieldList[key].name == 'assignmentDescription') {
  //       this.state.assignmentDetail.assignmentDescription = null
  //       this.state.assignmentDetail.NotesResource = null
  //     }
  //     for (let item in this.state.assignmentDetail) {
  //       if (this.state.fieldList[key].name == item) {
  //         this.state.assignmentDetail[item] = null
  //       }
  //     }
  //   }
  // }

  //   const { skills } = this.props;
  //   const convertedOrder = convertAssignmentToOrder(
  //     this.state.assignmentDetail,
  //     this.props.profile,
  //     skills,
  //   );
  //   this.props.onSwitchToDetailOrder({
  //     ...convertedOrder,
  //     dateRange: generateEditOrderDateSession(
  //       this.state.assignmentDetail.DatetimeFrom,
  //       this.state.assignmentDetail.DatetimeTo,
  //     ),
  //     isCopy: true
  //   });
  //   this.RouteLeavingGuardHandler(true);

  //   this.setState({
  //     showCopyBookingModal: false,
  //     showQuickOrder: true,
  //     showDetail: false,
  //     showAssigmentList: false,
  //   });
  // };

  handleCopyOrder = assignment => {
    const { skills } = this.props;
    const convertedOrder = convertAssignmentToOrder(
      assignment,
      this.props.profile,
      skills,
    );
    this.props.onSwitchToDetailOrder({ ...convertedOrder, isCopyOrder: true });
    this.RouteLeavingGuardHandler(true);

    this.setState({
      showQuickOrder: true,
      showDetail: false,
      showAssigmentList: false,
    });
  };

  checkNewBooking = path => {
    if (path === '/new-booking') {
      this.onCreateOrder(null);
    }
  };

  handleLoad = () => {
    document.title = 'Översikt - TFV Kundportal';
  };
  onCreateOrder = date => {
    this.RouteLeavingGuardHandler(true);

    this.setState({
      quickOrderDate: date,
      showQuickOrder: true,
      showAssigmentList: false,
      showDetail: false,
    });
  };

  render() {
    const {
      assignmentNotifications,
      onOrderRated,
      profile,
      statusFilter,
      mapOnFilterDeviation,
    } = this.props;
    const { customerName } = profile;
    const {
      showDetail,
      showNotification,
      showQuickOrder,
      quickOrderDate,
      showStatusFilter,
      assignmentDetail,
      showAssigmentList,
      sameDateAssignments,
      disableNextButton,
      disablePreviousButton,
      selectedDateInfo,
      isDirty,
      visibleRouteLeavingModal,
      isWeeklyViewData,
      showCopyBookingModal,
    } = this.state;
    const visibleNextPreviousButtons = sameDateAssignments.length > 1;
    const showSidePanel = !(
      showStatusFilter ||
      showQuickOrder ||
      showAssigmentList ||
      showDetail
    );
    return (
      <React.Fragment>
        <RouteLeavingGuard
          when={isDirty}
          navigate={path => history.push(path)}
          shouldBlockNavigation={location => {
            if (isDirty) {
              return true;
            }
            return false;
          }}
        />
        <RouteLeavingModal
          isVisible={visibleRouteLeavingModal}
          onCloseClicked={() => {
            this.setState({ visibleRouteLeavingModal: false });
          }}
          onOverlayClick={() => {
            this.setState({ visibleRouteLeavingModal: false });
          }}
          onConfirmClick={() => {
            if (isWeeklyViewData) {
              this.setState({
                isWeeklyViewData: false,
                showDetail: true,
                showQuickOrder: false,
                visibleRouteLeavingModal: false,
              });
            } else {
              this.setState({
                showAssigmentList: true,
                showQuickOrder: false,
                visibleRouteLeavingModal: false,
              });
            }
            this.RouteLeavingGuardHandler(false);
          }}
        />
        <div className="tv-workassignment-calendar__wrapper">
          <ReleaseMessage />

          <div className="tv-display-mobile-show tabs">
            <div className="tk-calendar__main-title mt-auto d-flex">
              <div>
                <div style={{ fontSize: '15px' }}>
                  {Translate({ content: 'dashboard.welcome' })}
                </div>{' '}
                {customerName}{' '}
              </div>
              <CurrentTime className="align-self-end" />
            </div>

            <RatingSummary />
          </div>
          <div className="tv_dashboard__main-panel w-100 row mt-3 mt-md-0 mx-0">
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 px-0 pr-xl-3 tv-display-mobile-hide tabs d-flex flex-column"
              style={{
                visibility: showSidePanel
                  ? 'initial'
                  : isIE
                  ? 'initial'
                  : 'hidden',
              }}
            >
              {/* <SearchBox
                  placeholder={Translate({
                    content: 'myfilter.search',
                  })}
                  onBlur={this.searchHandler}
              /> */}
              <OrderSearchBar onEventClick={this.handleEventClick} />
              <StatusFilter
                onClick={this.handleFilter}
                selectedFilter={statusFilter}
              />
              <RatingSummary />
              <div className="tk-calendar__main-title mt-auto">
                <div>
                  {Translate({ content: 'dashboard.welcome' })} {customerName}{' '}
                </div>
                <CurrentTime />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 px-0 pl-1 mb-1">
              <Calendar
                onTabChange={this.onTabChange}
                onEventClick={this.handleEventClick}
                selectedEvent={assignmentDetail}
                onUpdateUserCalendar={this.handleChangeEventCalendar}
                onAssignmentSummuryPillClicked={(eventList, dateItem) => {
                  this.RouteLeavingModalHandler(eventList, dateItem);

                  // this.setState({
                  //   showAssigmentList: true,
                  //   showDetail: false,
                  //   showQuickOrder: false,
                  //   selectedDateInfo: {
                  //     day: dateItem,
                  //     assignmentList: eventList,
                  //   },
                  // });
                }}
                selectedDate={showAssigmentList ? selectedDateInfo.day : null}
                quickOrderDateShow={showQuickOrder}
                onCreateOrder={this.onCreateOrder}
                onFilter={() => {
                  this.setState({
                    showStatusFilter: true,
                  });
                }}
                displayListItemContactPerson
                visiblePrefix={false}
                filterParamater={statusFilter}
              />
            </div>
          </div>

          {showCopyBookingModal && (
            <CopyBooking
              onClose={this.handleHideCopyBookingModal}
              fieldChange={this.handleFieldChange}
              copyOrder={this.handleCopyOrder}
              assignmentDetail={this.state.assignmentDetail}
              fieldList={this.state.fieldList}
            />
          )}

          <LeftDrawerSlide
            isVisible={showStatusFilter}
            hideExternalScroll
            onClose={() => {
              this.setState({
                showStatusFilter: false,
              });
            }}
            hideClose
          >
            <Dialog
              className="tk-assignment-creation__container tk-calendar-mobile-filter-wrapper"
              isVisible
              onClose={() => {
                this.setState({
                  showStatusFilter: false,
                });
              }}
            >
              <div className="tv-drawer__title-mobile">
                {Translate({ content: 'dashboard.filterAssignments' })}
              </div>
              <OrderSearchBar
                key="mobile"
                onBlur={() => {
                  this.setState({
                    showStatusFilter: false,
                  });
                }}
                onEventClick={this.handleEventClick}
              />
              <StatusFilter
                onClick={this.handleFilter}
                selectedFilter={statusFilter}
              />
              <div className="d-flex tv-drawer_buttons">
                <button
                  className="tv-buttons__element tv-buttons__element--line-primary mr-2"
                  onClick={this.handleStatusFilterDrawerClose}
                >
                  {Translate({ content: 'dashboard.cancel' })}
                </button>
                <button
                  className="tv_dashboard__add-button"
                  onClick={this.handleStatusFilterDrawerClose}
                >
                  {Translate({ content: 'dashboard.filter' })}
                </button>
              </div>
            </Dialog>
          </LeftDrawerSlide>

          {showQuickOrder && (
            <QuickOrder
              onResetDate={this.resetQuickOrderDate}
              date={quickOrderDate}
              onClose={() => {
                this.handleQuickOrderDrawerClose();
                this.props.onSwitchToDetailOrder({});
              }}
              showQuickOrder={showQuickOrder}
              RouteLeavingGuardHandler={this.RouteLeavingGuardHandler}
            />
          )}

          <LeftDrawerSlide
            isVisible={showAssigmentList}
            hideClose
            hideExternalScroll
            onClose={() => {
              this.setState({
                showAssigmentList: false,
              });
            }}
            className="tk-assignment-detail__drawer"
          >
            <AssignmentListBox
              assignmentList={selectedDateInfo.assignmentList}
              onEventClick={this.handleEventClick}
              newBooking={e => {
                this.setState({
                  showAssigmentList: false,
                  showQuickOrder: true,
                });
              }}
              dateItem={selectedDateInfo.day}
              onClose={e => this.handleAssignmentListDrawerClose(e)}
              childButton={
                <div className="d-flex tv-drawer_buttons list_drawer">
                  <button
                    className="tv-buttons__element tv-buttons__element--line-primary mr-2"
                    onClick={this.handleAssignmentListDrawerClose}
                  >
                    <Translate content="general.close" />
                  </button>

                  {selectedDateInfo.day > currentDate() && (
                    <button
                      className="tv_dashboard__add-button tv-display-mobile-show tabs"
                      onClick={e => {
                        this.setState({
                          showAssigmentList: false,
                          showQuickOrder: true,
                          quickOrderDate: selectedDateInfo.day,
                        });
                      }}
                    >
                      <i className="fa fa-plus mr-2" />
                      <Translate content="calendar.newBooking" />
                    </button>
                  )}
                </div>
              }
            />
          </LeftDrawerSlide>

          {showNotification && (
            <div>
              Notification
              <NotificationDetail
                isVisible={showNotification}
                onClose={() => {
                  this.setState({
                    showNotification: false,
                    // assignmentDetail: {},
                  });
                }}
                notifications={assignmentNotifications}
                className="tk-notification-detail__drawer"
              />
            </div>
          )}
          {showDetail && (
            <AssignmentDetail
              isVisible={showDetail}
              onClose={() => {
                this.setState({
                  showDetail: false,
                  assignmentDetail: {},
                });
              }}
              onClickMoreNotification={this.handlerNotification}
              assignment={assignmentDetail}
              from={PAGE_TYPES.DASHBOARD}
              status={status.getByStatusString(assignmentDetail.OrderStatus)}
              handleGetAttachment={this.props.onGetAttachment}
              handleOrderRate={onOrderRated}
              handleClickTimeReport={this.handleClickTimeReport}
              onCopyOrder={this.handleCopyOrder}
              onEditOrder={this.handleEditOrder}
              visibleNextPreviousButtons={visibleNextPreviousButtons}
              disablePreviousButton={disablePreviousButton}
              disableNextButton={disableNextButton}
              onPreviousNextClicked={val => {
                this.handleDrawerNextPrevious(val);
              }}
              onFilterDeviation={(val, callback) => {
                mapOnFilterDeviation(val, callback, false);
              }}
              onCopyBooking={this.handleShowCopyBookingModal}
            />
          )}
          <div className="tv-workassignment__list__mobile-fixed-bottom">
            <button
              onClick={() => {
                this.setState({
                  showStatusFilter: true,
                });
              }}
              className="tk-calendar__btn-filter"
            >
              {Translate({ content: 'calendar.filter' })}
            </button>
            <button
              className="tk-calendar__btn-newbook"
              onClick={() => {
                this.setState({
                  quickOrderDate: currentDate().add(1, 'day'),
                  showQuickOrder: true,
                  showAssigmentList: false,
                  showDetail: false,
                });
              }}
            >
              <i className="fa fa-plus mr-2" />{' '}
              {Translate({
                content: 'dashboard.addNew',
              })}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = propTypes;
Dashboard.defaultProps = defaultProps;

const mapStateToProps = state => ({
  assignmentNotifications: state.notification.assignmenNotificationList,
  assignmentList: state.workAssignment.assignmentList,
  assignmentDetail: state.workAssignment.assignmentDetail,
  statusFilter: state.workAssignment.statusFilter,
  profile: state.user.profile,
  skills: state.skill.skills || [],
  dateRange: state.user.calendar.range,
  displayBooking: state.dashboard.displayNewBooking,
});

const mapDispatchToProps = dispatch => ({
  onGetDashboardData: searchValue => {
    dispatch(getDashboardData(searchValue));
  },
  onUpdateUserCalendar: c => {
    dispatch(updateUserCalendar(c));
  },
  onGetNotifications: (orderIdentifier, successCallback) => {
    dispatch(
      getNotificationList(orderIdentifier, false, null, successCallback),
    );
  },
  onGetAttachment: attachmentIdentifier => {
    dispatch(getAttachment(attachmentIdentifier));
  },
  onSwitchToDetailOrder: assignment => {
    dispatch(onUpdateNewAssignmentsTemp(assignment));
  },
  onCheckUpdateCalendarEvent: () => {
    dispatch(checkUpdateCalendarEvent());
  },
  onOrderRated: (orderIdentifier, rate, comment) => {
    dispatch(setOrderRating(orderIdentifier, rate, comment));
  },
  onResetCalendar: () => {
    dispatch(resetCalendar());
  },
  getRateReasons: () => {
    dispatch(GetRatingReasons());
  },
  updateMyAssignmentFilter: (e, filterType) => {
    dispatch(updateMyAssignmentFilters(e, filterType));
  },
  mapOnFilterDeviation: (data, callback, setData) => {
    dispatch(getDeviations(data, callback, setData));
  },
  executeGetContactPersons: () => {
    dispatch(fetchContactPersons());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

const CurrentTime = ({ className }) => {
  const timeRef = useRef();
  useEffect(() => {
    const getTime = setInterval(() => {
      timeRef.current.innerText = moment().format('HH:mm');
    }, 3600);
    return () => {
      clearInterval(getTime);
    };
  }, []);

  return (
    <>
      <div className={`mt-2 ${className}`}>
        <i className="fa fa-clock mr-2" />
        <span ref={timeRef}>{moment().format('HH:mm')}</span>
      </div>
    </>
  );
};
