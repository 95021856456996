import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-form-validator-core';
import { toast } from 'react-toastify';
import './style.scss';

import {
  Translate,
  TFVButton,
  TextField,
  Modal,
  IfComponent,
} from '../../../Share/components';
import { isIE } from '../../../Share/utils';
import { deleteContactPersons, createUpdateContactPerson } from '../../actions';
import { connect } from 'react-redux';

const propTypes = {
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  Email: PropTypes.string,
  Title: PropTypes.string,
  PhoneNumberDirect: PropTypes.string,
  PhoneNumberMobile: PropTypes.string,
  onChange: PropTypes.func,
  onHandleModal: PropTypes.func,
  onHandleConfirm: PropTypes.func,
  showModal: PropTypes.bool,
  emailRequired: PropTypes.bool,
  editMode: PropTypes.bool,
  inOrderProcess: PropTypes.bool,
  type: PropTypes.string,
};

const defaultProps = {
  FirstName: '',
  LastName: '',
  Email: '',
  Title: '',
  PhoneNumberDirect: '',
  PhoneNumberMobile: '',
  identifier: '',
  inOrderProcess: false,
  onChange: () => {},
  onHandleModal: () => {},
  onHandleConfirm: () => {},
  showModal: false,
  editMode: false,
  emailRequired: false,
  type: '',
};
const INITAL_STATE = {
  ContactPersonIdentifier: null,
  FirstName: undefined,
  LastName: undefined,
  Email: undefined,
  Title: undefined,
  PhoneNumberDirect: undefined,
  PhoneNumberMobile: undefined,
  showDeleteModal: false,
  showEmailConfirmationModal: false,
};

class ContactPersonEdit extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITAL_STATE, ...props };
  }
  handleChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
  };

  handleShowEmailConfirmationModal = () => {
    this.setState({ showEmailConfirmationModal: true });
  };

  handleHideEmailConfirmationModal = () => {
    this.setState({ showEmailConfirmationModal: false });
  };

  handleSubmit = () => {
    const { PhoneNumberDirect, PhoneNumberMobile, Email } = this.state;
    if ([PhoneNumberMobile, PhoneNumberDirect, Email].join('').trim() === '') {
      toast.error(
        Translate({
          content: 'validator.mustHaveValue',
          params: {
            field: `${Translate({
              content: 'singleAssignment.email',
            })},${Translate({
              content: 'singleAssignment.phoneNumberDirect',
            })}, ${Translate({
              content: 'singleAssignment.phoneNumberMobile',
            })}`,
          },
        }),
      );
    } else if (!Email) {
      this.handleShowEmailConfirmationModal();
    } else {
      this.props.onCreateUpdateContactPerson({
        ...this.state,
        type: this.props.type,
      });
      this.props.onHandleModal('success');
    }
  };

  EmailValidations() {
    const validations = ['isEmail'];
    if (this.props.emailRequired) {
      validations.push('required');
    }
    return validations;
  }
  handleDeleteContactPerson = identifier => {
    const { onDeleteContactPerson, onHandleModal } = this.props;
    if (identifier) {
      onDeleteContactPerson(identifier);
      onHandleModal();
    }
  };
  handleHideDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  handleConfirmAddUserWithoutEmail = () => {
    this.handleHideEmailConfirmationModal();
    this.props.onCreateUpdateContactPerson({
      ...this.state,
      type: this.props.type,
    });
    this.props.onHandleModal('success');
  };
  render() {
    const {
      showModal,
      validatorListener,
      editMode,
      onHandleModal,
      inOrderProcess,
      emailRequired,
    } = this.props;
    const {
      ContactPersonIdentifier,
      FirstName,
      LastName,
      Email,
      showDeleteModal,
      Title,
      PhoneNumberDirect,
      PhoneNumberMobile,
      showEmailConfirmationModal,
    } = this.state;
    return (
      <React.Fragment>
        <Modal
          className="edit-contact-person-modal tv-workassignment__modal"
          onCloseClicked={() => {
            onHandleModal();
          }}
          onOverlayClick={() => {
            onHandleModal();
          }}
          isVisible={showModal}
          headerClassName={`tv-workassignment__modal-header ${
            isIE ? 'ie-fix-width' : ''
          }`}
          headerText={Translate({
            content: editMode
              ? 'singleAssignment.editContactPerson'
              : 'singleAssignment.createNewContactPerson',
          })}
          ieOptionClassName={isIE ? 'ie-fix-height' : ''}
        >
          <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
            <div className="d-flex flex-column  border-top-0">
              <div className="quick-order-cont tv-contactperson__edit-modal text-left align-content-around d-flex h-100 w-100 flex-wrap pr-2">
                <>
                  {' '}
                  <div className="form-group col-12 col-md-6 mb-2">
                    <label htmlFor="FirstName">
                      {`${Translate({
                        content: 'singleAssignment.firstName',
                      })} *`}
                    </label>
                    <div className=" tv-single-assign-form__input">
                      <TextField
                        aria-label={Translate({
                          content: 'singleAssignment.firstName',
                        })}
                        validatorListener={validatorListener}
                        onChange={this.handleChange}
                        placeholder={Translate({
                          content: 'singleAssignment.firstNamePlaceHolder',
                        })}
                        className="tv-single-assign-form__control"
                        name="FirstName"
                        id="FirstName"
                        autoFocus
                        value={FirstName}
                        validators={['required']}
                        maxLength={300}
                        errorMessages={[
                          Translate({
                            content: 'validator.isRequired',
                            params: {
                              name: Translate({
                                content: 'singleAssignment.firstName',
                              }),
                            },
                          }),
                        ]}
                      />
                    </div>
                  </div>
                  <div className="form-group col-12 col-md-6 mb-2">
                    <label htmlFor="LastName">
                      {`${Translate({
                        content: 'singleAssignment.lastName',
                      })} *`}
                    </label>
                    <div className=" tv-single-assign-form__input">
                      <TextField
                        aria-label={Translate({
                          content: 'singleAssignment.lastName',
                        })}
                        validatorListener={validatorListener}
                        onChange={this.handleChange}
                        placeholder={Translate({
                          content: 'singleAssignment.lastNamePlaceHolder',
                        })}
                        className="tv-single-assign-form__control"
                        name="LastName"
                        id="LastName"
                        value={LastName}
                        validators={['required']}
                        maxLength={300}
                        errorMessages={[
                          Translate({
                            content: 'validator.isRequired',
                            params: {
                              name: Translate({
                                content: 'singleAssignment.lastName',
                              }),
                            },
                          }),
                        ]}
                      />
                    </div>
                  </div>
                </>

                <div className="form-group col-12 col-md-6 mb-2">
                  <label htmlFor="Email">
                    {`${Translate({
                      content: 'singleAssignment.contactPersonEmail',
                    })}`}
                  </label>
                  <div className=" tv-single-assign-form__input">
                    <TextField
                      aria-label={Translate({
                        content: 'singleAssignment.contactPersonEmail',
                      })}
                      validatorListener={validatorListener}
                      onChange={this.handleChange}
                      placeholder={Translate({
                        content: 'singleAssignment.emailPlaceHolder',
                      })}
                      value={Email}
                      // validators={this.EmailValidations()}
                      validators={['isEmail']}
                      maxLength={300}
                      errorMessages={[
                        Translate({
                          content: 'validator.isNotValid',
                          params: {
                            name: Translate({
                              content: 'singleAssignment.email',
                            }),
                          },
                        }),
                        Translate({
                          content: 'validator.isRequired',
                          params: {
                            name: Translate({
                              content: 'singleAssignment.email',
                            }),
                          },
                        }),
                      ]}
                      className="tv-single-assign-form__control"
                      name="Email"
                      id="Email"
                    />
                  </div>
                </div>
                <>
                  {' '}
                  <div className="form-group col-12 col-md-6 mb-2">
                    <label htmlFor="Title">
                      <Translate content="singleAssignment.title" />
                    </label>
                    <div className=" tv-single-assign-form__input">
                      <TextField
                        aria-label={Translate({
                          content: 'singleAssignment.title',
                        })}
                        validatorListener={validatorListener}
                        onChange={this.handleChange}
                        placeholder={Translate({
                          content: 'singleAssignment.titlePlaceHolder',
                        })}
                        className="tv-single-assign-form__control"
                        name="Title"
                        id="Title"
                        value={Title}
                        maxLength={100}
                      />
                    </div>
                  </div>
                </>
                <div className="form-group col-12 col-md-6 mb-2">
                  <label htmlFor="PhoneNumberDirect">
                    {`${Translate({
                      content: 'singleAssignment.phoneNumberDirect',
                    })} *`}
                  </label>
                  <div className=" tv-single-assign-form__input">
                    <TextField
                      aria-label={Translate({
                        content: 'singleAssignment.phoneNumberDirect',
                      })}
                      validatorListener={validatorListener}
                      onChange={this.handleChange}
                      placeholder={Translate({
                        content:
                          'singleAssignment.phoneNumberDirectPlaceHolder',
                      })}
                      validators={['matchRegexp:^[0-9 ()+-]+$', 'required']}
                      className="tv-single-assign-form__control"
                      name="PhoneNumberDirect"
                      id="PhoneNumberDirect"
                      value={PhoneNumberDirect}
                      maxLength={30}
                      errorMessages={[
                        Translate({
                          content: 'validator.matchRegexp:^[0-9 ()+-]+$',
                          params: {
                            name: Translate({
                              content: 'singleAssignment.phoneNumberMobile',
                            }),
                          },
                        }),
                        Translate({
                          content: 'validator.isRequired',
                          params: {
                            name: Translate({
                              content: 'singleAssignment.phoneNumberDirect',
                            }),
                          },
                        }),
                      ]}
                    />
                  </div>
                </div>
                <div className="form-group col-12 col-md-6 mb-2">
                  <label htmlFor="PhoneNumberMobile">
                    <Translate content="singleAssignment.phoneNumberMobile" />
                  </label>
                  <div className=" tv-single-assign-form__input">
                    <TextField
                      aria-label={Translate({
                        content: 'singleAssignment.phoneNumberMobile',
                      })}
                      validatorListener={validatorListener}
                      onChange={this.handleChange}
                      placeholder={Translate({
                        content:
                          'singleAssignment.phoneNumberMobilePlaceHolder',
                      })}
                      validators={['matchRegexp:^[0-9 ()+-]+$']}
                      className="tv-single-assign-form__control"
                      name="PhoneNumberMobile"
                      id="PhoneNumberMobile"
                      value={PhoneNumberMobile}
                      maxLength={30}
                      errorMessages={[
                        Translate({
                          content: 'validator.matchRegexp:^[0-9 ()+-]+$',
                          params: {
                            name: Translate({
                              content: 'singleAssignment.phoneNumberMobile',
                            }),
                          },
                        }),
                      ]}
                    />
                  </div>
                </div>
                {editMode && !inOrderProcess && (
                  <div
                    className="form-group  tv-samll-text col-12  mb-2 tv-contactperson__delete"
                    onClick={() => this.setState({ showDeleteModal: true })}
                    aria-label="Radera användare"
                    tabIndex={0}
                    role="button"
                    onKeyDown={e =>
                      e.key === 'Enter'
                        ? this.setState({ showDeleteModal: true })
                        : null
                    }
                  >
                    <i className="fa fa-trash" /> Radera användare
                  </div>
                )}

                {/* <div className="form-group text-center tv-samll-text col-12  mb-2">
                  <IfComponent
                    condition={emailRequired}
                    whenTrue={
                      <div className="contact-description">
                        *{' '}
                        <Translate content="singleAssignment.contactPersonEmailRequiredText" />
                      </div>
                    }
                    whenFalse={
                      <div className="contact-description">
                        *{' '}
                        <Translate content="singleAssignment.contactPersonDescriptionText" />
                      </div>
                    }
                  />
                </div> */}
              </div>
            </div>
            <div className="d-flex flex-column tv-contactperson__edit-modal-actions">
              <div className="tv-workassignment__detail-buttons tv-display-flex">
                <TFVButton
                  type="secondary"
                  buttonType="submit"
                  className="mx-auto"
                  text={Translate({
                    content: 'singleAssignment.confirmCreateNewContact',
                  })}
                />
              </div>
            </div>
          </ValidatorForm>
        </Modal>
        <Modal
          onCloseClicked={this.handleHideDeleteModal}
          isVisible={showDeleteModal}
          className="tv-route-leaving-guard-modal tv-delete-user-modal"
          headerClassName="tv-cancelassignment_modal__header"
        >
          <div className="tv-route-leaving-guard-modal-area">
            <div className="tv-route-leaving-guard-modal-area-title"></div>
            <div
              className="tv-route-leaving-guard-modal-area-text mt-3 mb-4"
              style={{ width: '284px' }}
            >
              {`Användaren ${FirstName || ''} ${
                LastName || ''
              } kommer att raderas. Detta går inte att
              ångra.`}
            </div>
          </div>
          <div className="tv-route-leaving-guard-modal__buttons tv-display-flex">
            <TFVButton
              type="line-primary"
              onClick={() => {
                this.handleHideDeleteModal();
              }}
              text="Avbryt"
              className="tv-route-leaving-guard-modal-area-backBtn"
            />
            <TFVButton
              type="secondary"
              text="Radera"
              onClick={() => {
                this.handleHideDeleteModal();
                this.handleDeleteContactPerson(ContactPersonIdentifier);
              }}
              className="tv-route-leaving-guard-modal-area-submitBtn"
            />
          </div>
        </Modal>

        <Modal
          onCloseClicked={this.handleHideEmailConfirmationModal}
          isVisible={showEmailConfirmationModal}
          className="tv-route-leaving-guard-modal tv-delete-user-modal"
          headerClassName="tv-cancelassignment_modal__header"
        >
          <div className="tv-route-leaving-guard-modal-area">
            <div className="tv-route-leaving-guard-modal-area-title"></div>
            <div
              className="tv-route-leaving-guard-modal-area-text mt-3 mb-4"
              style={{ width: '284px' }}
            >
              <Translate content="singleAssignment.isEmailNotWant" />

              <Translate content="singleAssignment.emailNotAddedMeans" />
            </div>
          </div>
          <div className="tv-route-leaving-guard-modal__buttons tv-display-flex">
            <TFVButton
              type="line-primary"
              onClick={this.handleHideEmailConfirmationModal}
              text="Nej"
              className="tv-route-leaving-guard-modal-area-backBtn"
            />
            <TFVButton
              type="secondary"
              onClick={this.handleConfirmAddUserWithoutEmail}
              text="Ja"
              className="tv-route-leaving-guard-modal-area-submitBtn"
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

ContactPersonEdit.propTypes = propTypes;
ContactPersonEdit.defaultProps = defaultProps;
const mapDispatchToProps = (dispatch, ownProps) => ({
  onDeleteContactPerson: identifier => {
    dispatch(deleteContactPersons(identifier));
  },
  onCreateUpdateContactPerson: data => {
    dispatch(createUpdateContactPerson(data));
  },
});
export default connect(null, mapDispatchToProps)(ContactPersonEdit);
