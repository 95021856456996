import moment from 'moment';
import { FILTER_TYPES, RATING_VALUES, status } from '../../Share/constants';
import * as actionTypes from '../actions/ActionTypes';

const INITIAL_STATE = {
  orderTranslationTemp: [],
  translationSkills: [],
  statusFilter: [
    status.available.name,
    status.accepted.name,
    status.fullfilled.name,
    status.cancelled.name,
    status.late_cancelled.name,
    status.rejected.name,
  ],
  rateFilter: [
    RATING_VALUES.NOT_RATED,
    RATING_VALUES.ONE,
    RATING_VALUES.TWO,
    RATING_VALUES.THREE,
    RATING_VALUES.FOUR,
    RATING_VALUES.FIVE,
  ],
  selectedType: { name: 'Välj', value: '' },
  duration: {
    from: moment().startOf('month').subtract(1, 'month'),
    to: moment().endOf('month').add(1, 'month'),
  },
  translationList: [],
  showResultModal: false,
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_TRANSLATION_SKILLS: {
      const { skills } = action;
      return {
        ...state,
        translationSkills: [...skills]
      }
    }

    case actionTypes.UPDATE_ORDER_TRANSLATION_FILTERS: {
      const { filterType, value } = action;

      switch (filterType) {
        case FILTER_TYPES.STATUS_FILTER:
          return {
            ...state,
            statusFilter: [...value],
          };
        case FILTER_TYPES.RATING_FILTER:
          return {
            ...state,
            rateFilter: [...value],
          };
        case FILTER_TYPES.TYPE_FILTER:
          return {
            ...state,
            selectedType: value,
          };
        default:
          break;
      }
      return {
        ...state,
      };
    }

    case actionTypes.UPDATE_ORDER_TRANSLATION_DATA: {
      const { translationList } = action;
      return {
        ...state,
        translationList: [...translationList]
      }
    }
    case actionTypes.DISPLAY_SUCCESS_MODAL: {
      const { display } = action;
      return {
        ...state,
        showResultModal: display,
      }
    }
    default:
      return state;
  }
};

export default app;
