import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-form-validator-core';
import { toast } from 'react-toastify';

import {
  Translate,
  TFVButton,
  TextField,
  Modal,
  IfComponent,
} from '../../../Share/components';
import { isIE } from '../../../Share/utils';
import './style.scss';
import Scrollbars from 'react-custom-scrollbars';

const propTypes = {
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  Email: PropTypes.string,
  Title: PropTypes.string,
  PhoneNumberDirect: PropTypes.string,
  PhoneNumberMobile: PropTypes.string,
  onChange: PropTypes.func,
  onHandleModal: PropTypes.func,
  onHandleConfirm: PropTypes.func,
  showModal: PropTypes.bool,
  emailRequired: PropTypes.bool,
  editMode: PropTypes.bool,
};

const defaultProps = {
  FirstName: '',
  LastName: '',
  Email: '',
  Title: '',
  PhoneNumberDirect: '',
  PhoneNumberMobile: '',
  identifier: '',
  onChange: () => {},
  onHandleModal: () => {},
  onHandleConfirm: () => {},
  showModal: false,
  editMode: false,
  emailRequired: false,
};

class ContactPersonModal extends Component {
  handleChange = event => {
    event.persist();
    this.props.onChange({ [event.target.name]: event.target.value });
  };

  scrollRef = React.createRef();

  handleSubmit = () => {
    const { PhoneNumberDirect, PhoneNumberMobile, Email } = this.props;
    if ([PhoneNumberMobile, PhoneNumberDirect, Email].join('').trim() === '') {
      toast.error(
        Translate({
          content: 'validator.mustHaveValue',
          params: {
            field: `${Translate({
              content: 'singleAssignment.email',
            })},${Translate({
              content: 'singleAssignment.phoneNumberDirect',
            })}, ${Translate({
              content: 'singleAssignment.phoneNumberMobile',
            })}`,
          },
        }),
      );
    } else {
      this.props.onHandleConfirm();
      this.props.onHandleModal();
    }
  };

  EmailValidations() {
    const validations = ['isEmail'];
    if (this.props.emailRequired) {
      validations.push('required');
    }
    return validations;
  }

  render() {
    const {
      showModal,
      validatorListener,
      editMode,
      onHandleModal,
      FirstName,
      LastName,
      Email,
      Title,
      PhoneNumberDirect,
      PhoneNumberMobile,
      emailRequired,
    } = this.props;

    return (
      <React.Fragment>
        <Modal
          className="tv-workassignment__modal"
          onCloseClicked={() => {
            onHandleModal();
          }}
          onOverlayClick={() => {
            onHandleModal();
          }}
          isVisible={showModal}
          headerClassName={`tv-workassignment__modal-header ${
            isIE ? 'ie-fix-width' : ''
          }`}
          headerText={Translate({
            content: editMode
              ? 'singleAssignment.editContactPerson'
              : 'singleAssignment.createNewContactPerson',
          })}
          ieOptionClassName={isIE ? 'ie-fix-height' : ''}
        >
          <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
            <div className="d-flex flex-column  border-top-0">
              {/* <Scrollbars ref={this.scrollRef}> */}

              <div className="quick-order-cont text-left align-content-around d-flex h-100 w-100 flex-wrap pr-2">
                {!editMode && (
                  <>
                    {' '}
                    <div className="form-group col-12 col-md-6 mb-2">
                      <label htmlFor="FirstName">
                        {`${Translate({
                          content: 'singleAssignment.firstName',
                        })} *`}
                      </label>
                      <div className=" tv-single-assign-form__input">
                        <TextField
                          validatorListener={validatorListener}
                          onChange={this.handleChange}
                          placeholder={Translate({
                            content: 'singleAssignment.firstNamePlaceHolder',
                          })}
                          className="tv-single-assign-form__control"
                          name="FirstName"
                          id="FirstName"
                          value={FirstName}
                          validators={['required']}
                          maxLength={300}
                          errorMessages={[
                            Translate({
                              content: 'validator.isRequired',
                              params: {
                                name: Translate({
                                  content: 'singleAssignment.firstName',
                                }),
                              },
                            }),
                          ]}
                          autoFocus
                        />
                      </div>
                    </div>
                    <div className="form-group col-12 col-md-6 mb-2">
                      <label htmlFor="LastName">
                        {`${Translate({
                          content: 'singleAssignment.lastName',
                        })} *`}
                      </label>
                      <div className=" tv-single-assign-form__input">
                        <TextField
                          validatorListener={validatorListener}
                          onChange={this.handleChange}
                          placeholder={Translate({
                            content: 'singleAssignment.lastNamePlaceHolder',
                          })}
                          className="tv-single-assign-form__control"
                          name="LastName"
                          id="LastName"
                          value={LastName}
                          validators={['required']}
                          maxLength={300}
                          errorMessages={[
                            Translate({
                              content: 'validator.isRequired',
                              params: {
                                name: Translate({
                                  content: 'singleAssignment.lastName',
                                }),
                              },
                            }),
                          ]}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="form-group col-12 col-md-6 mb-2">
                  <label htmlFor="Email">
                    <Translate content="singleAssignment.contactPersonEmail" />
                  </label>
                  <div className=" tv-single-assign-form__input">
                    <TextField
                      validatorListener={validatorListener}
                      onChange={this.handleChange}
                      placeholder=""
                      value={Email}
                      validators={this.EmailValidations()}
                      maxLength={300}
                      errorMessages={[
                        Translate({
                          content: 'validator.isNotValid',
                          params: {
                            name: Translate({
                              content: 'singleAssignment.email',
                            }),
                          },
                        }),
                        Translate({
                          content: 'validator.isRequired',
                          params: {
                            name: Translate({
                              content: 'singleAssignment.email',
                            }),
                          },
                        }),
                      ]}
                      className="tv-single-assign-form__control"
                      name="Email"
                      id="Email"
                    />
                  </div>
                </div>
                {!editMode && (
                  <>
                    {' '}
                    <div className="form-group col-12 col-md-6 mb-2">
                      <label htmlFor='Title'>
                        <Translate content="singleAssignment.title" />
                      </label>
                      <div className=" tv-single-assign-form__input">
                        <TextField
                          validatorListener={validatorListener}
                          onChange={this.handleChange}
                          placeholder={Translate({
                            content: 'singleAssignment.titlePlaceHolder',
                          })}
                          className="tv-single-assign-form__control"
                          name="Title"
                          id="Title"
                          value={Title}
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="form-group col-12 col-md-6 mb-2">
                  <label htmlFor='PhoneNumberDirect'>
                    <Translate content="singleAssignment.phoneNumberDirect" />
                  </label>
                  <div className=" tv-single-assign-form__input">
                    <TextField
                      validatorListener={validatorListener}
                      onChange={this.handleChange}
                      placeholder={Translate({
                        content:
                          'singleAssignment.phoneNumberDirectPlaceHolder',
                      })}
                      validators={['matchRegexp:^[0-9 ()+-]+$']}
                      className="tv-single-assign-form__control"
                      name="PhoneNumberDirect"
                      id="PhoneNumberDirect"
                      value={PhoneNumberDirect}
                      maxLength={30}
                      errorMessages={[
                        Translate({
                          content: 'validator.matchRegexp:^[0-9 ()+-]+$',
                          params: {
                            name: Translate({
                              content: 'singleAssignment.phoneNumberMobile',
                            }),
                          },
                        }),
                      ]}
                    />
                  </div>
                </div>
                <div className="form-group col-12 col-md-6 mb-2">
                  <label htmlFor='PhoneNumberMobile'>
                    <Translate content="singleAssignment.phoneNumberMobile" />
                  </label>
                  <div className=" tv-single-assign-form__input">
                    <TextField
                      validatorListener={validatorListener}
                      onChange={this.handleChange}
                      placeholder={Translate({
                        content:
                          'singleAssignment.phoneNumberMobilePlaceHolder',
                      })}
                      validators={['matchRegexp:^[0-9 ()+-]+$']}
                      className="tv-single-assign-form__control"
                      name="PhoneNumberMobile"
                      id="PhoneNumberMobile"
                      value={PhoneNumberMobile}
                      maxLength={30}
                      errorMessages={[
                        Translate({
                          content: 'validator.matchRegexp:^[0-9 ()+-]+$',
                          params: {
                            name: Translate({
                              content: 'singleAssignment.phoneNumberMobile',
                            }),
                          },
                        }),
                      ]}
                    />
                  </div>
                </div>
                <div className="form-group text-center tv-samll-text col-12  mb-2">
                  <IfComponent
                    condition={emailRequired}
                    whenTrue={
                      <div className="contact-description">
                        *{' '}
                        <Translate content="singleAssignment.contactPersonEmailRequiredText" />
                      </div>
                    }
                    whenFalse={
                      <div className="contact-description">
                        *{' '}
                        <Translate content="singleAssignment.contactPersonDescriptionText" />
                      </div>
                    }
                  />
                </div>
              </div>
              {/* </Scrollbars> */}
            </div>
            <div className="d-flex flex-column">
              <div className="tv-workassignment__detail-buttons tv-display-flex">
                <TFVButton
                  type="secondary"
                  buttonType="submit"
                  className="mx-auto"
                  text={Translate({
                    content: 'singleAssignment.confirmCreateNewContact',
                  })}
                />
              </div>
            </div>
          </ValidatorForm>
        </Modal>
      </React.Fragment>
    );
  }
}

ContactPersonModal.propTypes = propTypes;
ContactPersonModal.defaultProps = defaultProps;

export default ContactPersonModal;
