import * as actionTypes from '../actions/ActionTypes';
import {
  LOCAL_STORE_TOKEN,
  LOCAL_STORE_CUSTOMER_NUMBER,
  LOCAL_STORE_CUSTOMER_PASSWORD_HASH,
} from '../../Share/constants';

const initToken = localStorage.getItem(LOCAL_STORE_TOKEN);
const initCustomerNumber = localStorage.getItem(LOCAL_STORE_CUSTOMER_NUMBER);
const initPasswordHash = localStorage.getItem(
  LOCAL_STORE_CUSTOMER_PASSWORD_HASH,
);

const INITIAL_STATE = {
  token: initToken,
  loggedIn: initToken !== null,
  customerNumber: initCustomerNumber,
  passwordHash: initPasswordHash,
  displayKPIReports: false,
};

function localRemove() {
  // WARN: should not remove LOCAL_STORE_CUSTOMER_NUMBER
  // this field use for check google calendar if user re-login tfv portal use same email,...
  // ...user no need re-login google account
  // localStorage.removeItem(LOCAL_STORE_CUSTOMER_NUMBER);
  localStorage.removeItem('USER_DATA');
  localStorage.removeItem('SELECTER_CUSTOMER_NUMBER');
  localStorage.removeItem('SELECTED_AGREEMENT');
  localStorage.removeItem(LOCAL_STORE_TOKEN);
  localStorage.removeItem(LOCAL_STORE_CUSTOMER_PASSWORD_HASH);
  localStorage.removeItem(LOCAL_STORE_CUSTOMER_NUMBER);
  localStorage.removeItem('showMessageValue');
}

const authentication = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      localRemove();
      return {
        ...INITIAL_STATE,
        token: null,
        loggedIn: false,
        passwordHash: null,
      };

    case actionTypes.AUTHENTICATE_RESOURCE_SUCCESS: {
      const { customerNumber } = action;
      localStorage.setItem(LOCAL_STORE_CUSTOMER_NUMBER, customerNumber);

      return {
        ...state,
        customerNumber,
      };
    }

    case actionTypes.SET_DISPLAY_KPI_REPORTS: {
      const { displayKPIReports } = action;

      return {
        ...state,
        displayKPIReports,
      };
    }
    case actionTypes.LOGIN_SUCCESS: {
      const { token, customerNumber, passwordHash } = action;

      localStorage.setItem(LOCAL_STORE_TOKEN, token);
      localStorage.setItem(LOCAL_STORE_CUSTOMER_NUMBER, customerNumber);
      localStorage.setItem(LOCAL_STORE_CUSTOMER_PASSWORD_HASH, passwordHash);

      return {
        ...state,
        loggedIn: true,
        token,
        customerNumber,
        passwordHash,
      };
    }

    case actionTypes.LOGIN_FAILURE: {
      localRemove();
      return {
        ...state,
        token: null,
        loggedIn: false,
      };
    }

    case actionTypes.UPDATE_NEW_PASSWORD: {
      const { newPassword } = action;
      return {
        ...state,
        passwordHash: newPassword,
      };
    }

    default:
      return state;
  }
};

export default authentication;
