import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import CloseButton from '../CloseButton';
import IfComponent from '../IfComponent';
import FocusTrap from 'focus-trap-react';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
  style: PropTypes.shape({}),
  ariaLive: PropTypes.string,
  languageFocus: PropTypes.bool,
  contactPersonFocus: PropTypes.bool,
  contactOrderPersonFocus: PropTypes.bool,
  paused: PropTypes.bool,
};

const defaultProps = {
  className: '',
  children: '',
  onClose: () => {},
  isVisible: false,
  style: {},
  ariaLive: 'polite',
  languageFocus: false,
  contactPersonFocus: false,
  contactOrderPersonFocus: false,
  paused: false,
};

const Dialog = ({
  className,
  children,
  onClose,
  isVisible,
  style,
  bodyClassName,
  ariaLive,
  languageFocus,
  contactPersonFocus,
  contactOrderPersonFocus,
  paused,
}) => {
  const trapRef = useRef(null);

  useEffect(() => {
    if (contactPersonFocus || contactOrderPersonFocus || languageFocus) {
      onPause();
    }
  }, [contactPersonFocus, contactOrderPersonFocus, languageFocus]);

  const onPause = () => {
    if (trapRef.current) {
      trapRef.current.focusTrap.pause();
    }
  };

  return (
    <IfComponent
      condition={isVisible}
      whenTrue={
        <React.Fragment>
          <FocusTrap
            focusTrapOptions={{
              escapeDeactivates: false,
              allowOutsideClick: true,
              preventScroll: true,
            }}
            ref={trapRef}
            paused={paused}
          >
            <div
              className={`tk-dialog__wrapper ${className}`}
              style={{
                left: style.left,
                top: style.top,
              }}
              aria-live={ariaLive}
            >
              <span
                className="tk-dialog__wrapper--before"
                style={{
                  borderRightColor: style.borderRightColor,
                  borderLeftColor: style.borderLeftColor,
                  borderBottomColor: style.borderBottomColor,
                  borderTopColor: style.borderTopColor,
                  transform: style.tipTransform,
                  right: style.tipRight,
                  top: style.tipTop,
                }}
              />
              <CloseButton
                onClick={onClose}
                className="tk-dialog__btn-close tv-buttons__type icon-close"
              />
              <div className={`tk-dialog__body ${bodyClassName}`}>
                {children}
              </div>
            </div>
          </FocusTrap>
        </React.Fragment>
      }
    />
  );
};

Dialog.propTypes = propTypes;
Dialog.defaultProps = defaultProps;

export default Dialog;
