import moment from 'moment';
import { Translate } from '../../Share/components';
import * as actionTypes from '../actions/ActionTypes';

const INITIAL_STATE = {
  range: {
    from: moment().subtract(2, 'month').startOf('month').toDate(),
    to: moment().subtract(1, 'month').startOf('month').toDate(),
  },
  widgets: [
    {
      reportId: 2,
      dataSources: 1,
      categoryField: 'interpretationType',
      field: 'noOfAssignments',
      title: Translate({ content: 'report.distributionPerInterpretation' }),
      tableSourceId: 0,
    },
    {
      reportId: 3,
      dataSources: 1,
      categoryField: 'skill',
      field: 'noOfAssignments',
      title: Translate({ content: 'report.distributionPerSkill' }),
      tableSourceId: 0,
    },
    {
      reportId:4,
      dataSources: 1,
      categoryField: 'competenceLevel',
      field: 'noOfAssignments',
      title: Translate({ content: 'report.distributionPerCompetence' }),
      tableSourceId: 0,
    },
    // {
    //   referenceReport: 3,
    //   dataSources: 2,
    //   categoryField: 'clientUnitName',
    //   field: 'noOfAssignments',
    //   type: 2,
    //   title: Translate({ content: 'report.distributionperClientUnit' }),
    //   tableSourceId: 1,
    // },
    

  ],
  displayNewBooking: false,
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      return INITIAL_STATE;
    case actionTypes.SET_DASHBOARD_DATERANGE:
    {
      const { range } = action;
      return {
        ...state,
        range,
      };
    }
    case actionTypes.DISPLAY_NEW_BOOKING:
    {
      const { display } = action;
      return {
        ...state,
        displayNewBooking: display,
      };
    }
    default:
      return state;
  }
};

export default app;
