import React, { Component } from 'react';
import { ValidatorForm } from 'react-form-validator-core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { isEmpty, isArray, clone } from 'lodash/core';
import { unionWith, isEqual } from 'lodash';

import SingleAssignmentForm from './SingleAssignmentForm';
import ContactPersonModal from './ContactPersonModal';
import MultipleAssignmentTab from './MultipleAssignmentTab';
import {
  Translate,
  TFVButton,
  IfComponent,
  RouteLeavingGuard,
} from '../../../Share/components';
import { onUpdateNewAssignmentsTemp } from '../../actions';
import {
  createDefaultAssignment,
  createDefaultContactPerson,
  generateUniqueId,
  isIE,
} from '../../../Share/utils';
import { fetchContactPersons, fetchServices } from '../../actions/user';
import history from '../../../Share/utils/history';
import { MULTI_FORM } from '../../../Share/constants';
import moment from 'moment';
import { toast } from 'react-toastify';

const propTypes = {
  onSubmitAssignment: PropTypes.func,
  assignments: PropTypes.arrayOf(PropTypes.shape({})),
  skills: PropTypes.arrayOf(PropTypes.shape({})),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  contactPersons: PropTypes.arrayOf(PropTypes.shape({})),
  profile: PropTypes.shape({}),
  languages: PropTypes.arrayOf(PropTypes.shape({})),
  executeGetContactPersons: PropTypes.func,
  executeGetServices: PropTypes.func,
};

const defaultProps = {
  onSubmitAssignment: () => {},
  assignments: [],
  skills: [],
  services: [],
  contactPersons: [],
  profile: {},
  languages: [],
  executeGetContactPersons: () => {},
  executeGetServices: () => {},
};

class MultipleAssignment extends Component {
  constructor(props) {
    super(props);
    const { assignments, skills, services, contactPersons, profile } = props;
    // assignments is an list with form of assignment
    const prevPath = props.location.state ? props.location.state.prevPath : '';
    if (!isEmpty(assignments) && isArray(assignments)) {
      this.state = {
        assignments,
        contactPerson: createDefaultContactPerson(),
        localContactPersons: [],
        showModal: false,
        isDirty: true,
      };
    } else {
      this.state = {
        assignments: [
          createDefaultAssignment(
            MULTI_FORM,
            skills,
            services,
            contactPersons,
            profile,
          ),
        ],
        contactPerson: createDefaultContactPerson(),
        localContactPersons: [],
        showModal: false,
        isDirty: true,
      };
    }
    this.formAssignment = null;
  }

  componentDidMount() {
    const { executeGetContactPersons, executeGetServices, languages } =
      this.props;
    executeGetContactPersons();

    const cloneAssignments = [...this.state.assignments];

    const selectedAssignment = cloneAssignments.find(item => item.isShow);

    const { startTime, endTime } = selectedAssignment.form.dateRange;

    var { startDate, endDate } = selectedAssignment.form.dateRange.ranges;

    const { language } = selectedAssignment.form;

    const callbackUpdateServices = (allowSelectServices, _assignment) => {
      selectedAssignment.form.services = allowSelectServices;
      selectedAssignment.form.typeOfAssignment =
        !_assignment.typeOfAssignment && _assignment.typeOfAssignment !== ''
          ? _assignment.typeOfAssignment
          : allowSelectServices[0].ServiceIdentifier;

      if (this.props.newContactPersons.length) {
        selectedAssignment.form.contactPersons = unionWith(
          selectedAssignment.form.contactPersons,
          this.props.newContactPersons,
          isEqual,
        );
      }
      this.setState({
        assignments: cloneAssignments,
      });
    };

    startDate.set({
      hour: startTime.hours(),
      minute: startTime.minutes(),
    });

    endDate.set({ hour: endTime.hours(), minute: endTime.minutes() });

    // Checking the time - is from next day or current day
    var beginningTime = moment({
      h: startTime.hours(),
      m: startTime.minutes(),
    });
    var endingTime = moment({
      h: endTime.hours(),
      m: endTime.minutes(),
    });

    if (endingTime.isBefore(beginningTime)) {
      var endDateHour = endDate.hours();
      var endDateMinute = endDate.minutes();
      endDate = moment(startDate);
      endDate.hours(endDateHour).minutes(endDateMinute);
      endDate.add(1, 'day');
    } else if (endingTime.isSame(beginningTime)) {
      var endDateHour = endDate.hours();
      var endDateMinute = endDate.minutes();
      endDate = moment(startDate);
      endDate.hours(endDateHour).minutes(endDateMinute);
      endDate.add(1, 'day');
    } else {
      var endDateHour = endDate.hours();
      var endDateMinute = endDate.minutes();
      endDate = moment(startDate);
      endDate.hours(endDateHour).minutes(endDateMinute);
    }

    const params = {
      language: language ? language : languages[1].value,
      startTime: startDate.format('YYYY-MM-DD HH:mm:ss'),
      endTime: endDate.format('YYYY-MM-DD HH:mm:ss'),
      assignment: selectedAssignment.form,
    };
    executeGetServices(params, callbackUpdateServices);

    ValidatorForm.addValidationRule('checkNumLength', (value, number) => {
      if (value.toString().length !== Number(number)) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule('alwaysError', (value, number) => {
      return false;
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextAssignments = [];

    const preAssignments = prevState.assignments ? prevState.assignments : [];
    preAssignments.forEach((assign, i) => {
      // update contact
      const contactAssign =
        assign.form.contactPersons.length !== nextProps.contactPersons.length
          ? {
              ...assign,
              form: {
                ...assign.form,
                contactPersons: unionWith(
                  assign.form.contactPersons,
                  nextProps.contactPersons,
                  isEqual,
                ),
              },
            }
          : assign;

      // update profile
      const profileAssign =
        isEmpty(assign.form.profile) &&
        nextProps.profile.customerIdentifier !== '' &&
        nextProps.profile.customerNumber !== '' &&
        !isEmpty(nextProps.profile.customerAddress)
          ? {
              ...contactAssign,
              form: {
                ...assign.form,
                profile: nextProps.profile,
                addressLine:
                  nextProps.profile.customerAddress.AddressRow1 || '',
                city: nextProps.profile.customerAddress.City || '',
                district: nextProps.profile.customerAddress.CareOf || '',
                postalCode:
                  nextProps.profile.customerAddress.PostalCode.toString(),
                orderCompanyName: nextProps.profile.customerName,
                orderLocation: `${
                  !nextProps.profile.customerAddress.CareOf
                    ? ''
                    : `${nextProps.profile.customerAddress.CareOf}, `
                }${nextProps.profile.customerAddress.AddressRow1}, ${
                  nextProps.profile.customerAddress.PostalCode
                }, ${nextProps.profile.customerAddress.City}`,
              },
            }
          : contactAssign;

      nextAssignments.push(profileAssign);
    });

    if (prevState.localContactPersons.length === 0) {
      return {
        localContactPersons: nextProps.contactPersons,
      };
    }

    return {
      assignments: nextAssignments,
    };
  }

  handleSingleSubmit = i => {};

  handleSingleError = (i, e) => {};

  submitCurrentForm = cb => {
    if (this.formAssignment) {
      this.formAssignment.walk(this.formAssignment.childs).then(result => {
        if (cb) {
          cb(result);
        }
        return result;
      });
    }
  };

  hiddenAllForm = assignments => {
    (assignments || []).forEach((assign, i) => {
      assignments[i].isShow = false;
    });
    return assignments;
  };

  ConvergeValidate = assignment => {
    const { profile } = this.props;
    let isValid = true;
    let isValidDateRange = true;
    const isValidContact =
      assignment.form.contactPerson !== '' &&
      [
        assignment.form.contactNumber,
        assignment.form.contactEmail,
        assignment.form.contactDirectNumber,
      ]
        .join('')
        .trim() !== '';
    const isValidContactDetails = !(
      profile.ContactPersonUponInterpretationRequiresEmail &&
      (assignment.form.contactEmail === '' || !assignment.form.contactEmail)
    );
    const isValidCustomer =
      assignment.form.orderContactPerson !== '' &&
      [
        assignment.form.orderContactNumber,
        assignment.form.orderContactDirectNumber,
        assignment.form.orderEmail,
      ]
        .join('')
        .trim() !== '';

    const isValidCustomerDetails = !(
      profile.ContactPersonOrdererRequiresEmail &&
      (assignment.form.orderEmail === '' || !assignment.form.orderEmail)
    );

    const selectedDate =
      assignment.form.dateRange.ranges.startDate.format('DD/MM/YYYY');
    const todayDate = moment().format('DD/MM/YYYY');
    if (selectedDate === todayDate) isValidDateRange = false;

    const isValidLanguage = !(
      assignment.form.language == '' || assignment.form.language == '-'
    );
    isValid =
      isValidContact &&
      isValidCustomer &&
      isValidContactDetails &&
      isValidCustomerDetails &&
      isValidLanguage &&
      isValidDateRange;
    return isValid;
  };

  handleSubmit = () => {
    this.setState({ isDirty: false }, () => {
      this.submitCurrentForm(() => {
        const notValidAssign = [];
        const { assignments } = this.state;
        (assignments || []).forEach(assignment => {
          if (!assignment.isValid || !this.ConvergeValidate(assignment)) {
            notValidAssign.push(assignment);
          }
        });

        if (notValidAssign.length === 0) {
          const { onSubmitAssignment } = this.props;
          onSubmitAssignment(this.state);
          history.push('/preview-advanced-order-form');
        } else {
          const validationErrors = Array.prototype.slice
            .call(document.querySelectorAll('.tv-form__error'))
            .filter(x => x.textContent !== '');
          const firstErrorTab = document.querySelector(
            '.tv-multiple-assign-form__panel-tab--error',
          );
          if (validationErrors.length > 0)
            validationErrors[0].parentElement.children[0].scrollIntoView({
              behavior: 'smooth',
            });
          if (
            firstErrorTab &&
            !firstErrorTab.classList.contains(
              'tv-multiple-assign-form__panel-tab--selected',
            )
          ) {
            if (validationErrors.length === 0) {
              firstErrorTab.scrollIntoView({
                behavior: 'smooth',
              });
            }
            firstErrorTab.classList.add('highlight');
            if (isIE) {
              firstErrorTab.classList.add('thick-hightlight');
            }
            setTimeout(() => {
              firstErrorTab.classList.remove('highlight');
              firstErrorTab.classList.remove('thick-hightlight');
            }, 10000);
          }
        }
      }, 0);
    });
  };

  handleChange = (i, obj) => {
    const { assignments } = this.state;
    const { executeGetServices, languages } = this.props;
    const genderValue = obj && obj.genderValue ? obj.genderValue : '';
    const updateAssignment = assignments[i];
    if (updateAssignment) {
      if (genderValue)
        updateAssignment.form.DoRequireCorrectGender = genderValue !== '0';
      const isChangeLanguage =
        obj.language !== null &&
        obj.language !== undefined &&
        obj.language !== '';

      const isChangeContactNumber =
        obj.contactNumber !== null && obj.contactNumber !== undefined;
      const isChangeContactDirectNumber =
        obj.contactDirectNumber !== null &&
        obj.contactDirectNumber !== undefined;
      const isChangeContactEmail =
        obj.contactEmail !== null && obj.contactEmail !== undefined;
      const isChangeContactPerson =
        obj.contactPerson !== null && obj.contactPerson !== undefined;
      const isChangeContactPersonData =
        isChangeContactPerson ||
        isChangeContactNumber ||
        isChangeContactDirectNumber ||
        isChangeContactEmail;

      const isChangeOrderContactPersonNumber =
        obj.orderContactNumber !== null && obj.orderContactNumber !== undefined;
      const isChangeOrderContactDirectNumber =
        obj.orderContactDirectNumber !== null &&
        obj.orderContactDirectNumber !== undefined;
      const isChangeOrderEmail =
        obj.orderEmail !== null && obj.orderEmail !== undefined;
      const isChangeOrderContactPerson =
        obj.orderContactPerson !== null && obj.orderContactPerson !== undefined;
      const isChangeOrderContactPersonData =
        isChangeOrderContactPerson ||
        isChangeOrderContactDirectNumber ||
        isChangeOrderEmail ||
        isChangeOrderContactPersonNumber;

      if (isChangeLanguage || obj.isChangeSerialDuration) {
        const { startTime, endTime } = updateAssignment.form.dateRange;
        var { startDate, endDate } = updateAssignment.form.dateRange.ranges;

        startDate.set({
          hour: startTime.hours(),
          minute: startTime.minutes(),
        });
        endDate.set({
          hour: endTime.hours(),
          minute: endTime.minutes(),
        });

        // Checking the time - is from next day or current day
        var beginningTime = moment({
          h: startTime.hours(),
          s: startTime.minutes(),
        });
        var endingTime = moment({
          h: endTime.hours(),
          s: endTime.minutes(),
        });

        if (endingTime.isBefore(beginningTime)) {
          var endDateHour = endDate.hours();
          var endDateMinute = endDate.minutes();
          endDate = moment(startDate);
          endDate.hours(endDateHour).minutes(endDateMinute);
          endDate.add(1, 'day');
        } else if (endingTime.isSame(beginningTime)) {
          var endDateHour = endDate.hours();
          var endDateMinute = endDate.minutes();
          endDate = moment(startDate);
          endDate.hours(endDateHour).minutes(endDateMinute);
          endDate.add(1, 'day');
        } else {
          var endDateHour = endDate.hours();
          var endDateMinute = endDate.minutes();
          endDate = moment(startDate);
          endDate.hours(endDateHour).minutes(endDateMinute);
        }

        const callbackUpdateServices = allowSelectServices => {
          let typeOfAssignment =
            allowSelectServices != null
              ? allowSelectServices[0].ServiceIdentifier
              : '';

          if (allowSelectServices != null) {
            allowSelectServices.forEach(allowSelect => {
              if (
                allowSelect.ServiceIdentifier ===
                updateAssignment.form.typeOfAssignment
              ) {
                // keep the same type of Assignment
                typeOfAssignment = updateAssignment.form.typeOfAssignment;
              }
            });
          } else {
            allowSelectServices = [];
          }

          updateAssignment.form = {
            ...updateAssignment.form,
            ...obj,
            services: allowSelectServices,
            typeOfAssignment,
            certificationLevels: undefined,
          };
          this.setState({ assignments });
        };

        const params = {
          language: obj.language
            ? obj.language == '-'
              ? languages[1].value
              : obj.language
            : updateAssignment.form.language,
          startTime: startDate.format('YYYY-MM-DD HH:mm:ss'),
          endTime: endDate.format('YYYY-MM-DD HH:mm:ss'),
        };
        executeGetServices(params, callbackUpdateServices);
      } else if (isChangeContactPersonData || isChangeOrderContactPersonData) {
        // RESUME HERE
        const isSameContactPerson =
          updateAssignment.form.orderContactPerson ===
          updateAssignment.form.contactPerson;
        const isChangeContactPersonInputs =
          !isChangeContactPerson && !isChangeOrderContactPerson;
        if (isSameContactPerson && isChangeContactPersonInputs) {
          const contactPersonValue = { ...obj };
          [
            {
              contactPersonField: 'contactNumber',
              orderContactPersonField: 'orderContactNumber',
            },
            {
              contactPersonField: 'contactDirectNumber',
              orderContactPersonField: 'orderContactDirectNumber',
            },
            {
              contactPersonField: 'contactEmail',
              orderContactPersonField: 'orderEmail',
            },
          ].forEach(j => {
            if (
              obj[j.contactPersonField] !== null &&
              obj[j.contactPersonField] !== undefined
            ) {
              contactPersonValue[j.orderContactPersonField] =
                obj[j.contactPersonField];
            } else if (
              obj[j.orderContactPersonField] !== null &&
              obj[j.orderContactPersonField] !== undefined
            ) {
              contactPersonValue[j.contactPersonField] =
                obj[j.orderContactPersonField];
            }
          });
          updateAssignment.form = {
            ...updateAssignment.form,
            ...contactPersonValue,
          };
          this.setState({ assignments });
        } else {
          updateAssignment.form = {
            ...updateAssignment.form,
            ...obj,
          };
          this.setState({ assignments });
        }
      } else {
        updateAssignment.form = { ...updateAssignment.form, ...obj };
        this.setState({ assignments });
      }
    }
  };

  handleValidatorListener = (i, isValid) => {
    const { assignments } = this.state;
    if (assignments[i]) {
      assignments[i].isValid = isValid && this.ConvergeValidate(assignments[i]);
      this.setState({ assignments });
    }
  };

  handleEditForm = (i, e) => {
    this.submitCurrentForm(() => {
      const assignments = this.hiddenAllForm(this.state.assignments);
      assignments[i].isShow = true;
      this.setState({ assignments }, () => {
        this.formAssignment.submit();
      });
    });
  };

  handleDeleteForm = (i, e) => {
    if (i > -1) {
      const { assignments } = this.state;
      assignments.splice(i, 1);
      this.setState({ assignments });
    }
    e.preventDefault();
    e.stopPropagation();
  };

  handleAddMoreForm = () => {
    const { skills, contactPersons, profile } = this.props;
    const { localContactPersons } = this.state;
    const contacts =
      localContactPersons.length > contactPersons.length
        ? localContactPersons
        : contactPersons;

    const services = [...this.state.assignments[0].form.services];
    this.submitCurrentForm(() => {
      const assignments = this.hiddenAllForm(this.state.assignments);
      assignments.push(
        createDefaultAssignment(
          MULTI_FORM,
          skills,
          services,
          contacts,
          profile,
        ),
      );
      this.setState({ assignments });
    });
  };

  handleModal = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };

  handleChangeContactPerson = obj => {
    const { contactPerson } = this.state;
    this.setState({
      contactPerson: {
        ...contactPerson,
        ...obj,
      },
    });
  };

  handleConfirm = () => {
    const { contactPerson } = this.state;
    contactPerson.value = `newPerson-${generateUniqueId()}`;
    contactPerson.name = `${contactPerson.FirstName} ${
      contactPerson.LastName
    } ${contactPerson.Title ? `- ${contactPerson.Title}` : ''}`;
    const newLocalContactPersons = clone(this.state.localContactPersons);
    newLocalContactPersons.push(contactPerson);
    const nextAssignments = [];
    (this.state.assignments || []).forEach((assign, i) => {
      const contactPersons = clone(assign.form.contactPersons);
      contactPersons.push(contactPerson);
      const contactAssign = {
        ...assign,
        form: {
          ...assign.form,
          contactPersons,
        },
      };
      nextAssignments.push(contactAssign);
    });
    this.setState({
      assignments: nextAssignments,
      localContactPersons: newLocalContactPersons,
      contactPerson: createDefaultContactPerson(),
    });
  };

  render() {
    const { assignments, contactPerson, showModal } = this.state;
    const { languages, profile } = this.props;
    const { isDirty } = this.state;

    return (
      <React.Fragment>
        <RouteLeavingGuard
          when={isDirty}
          navigate={path => history.push(path)}
          shouldBlockNavigation={location => {
            if (isDirty) {
              return true;
            }
            return false;
          }}
        />

        <ContactPersonModal
          {...contactPerson}
          showModal={showModal}
          onChange={this.handleChangeContactPerson}
          onHandleModal={this.handleModal}
          onHandleConfirm={this.handleConfirm}
        />
        <h2 className="tv-multiple-assign-form__title">
          <Translate content="multipleAssignment.createMultipleAssignments" />
        </h2>
        <div className="tv-multiple-assign-form__wrapper tv-display-flex">
          {/* <div className="tv-multiple-assign-form__panel-left">
                        {assignments.map((assignment, i) => (
                            <MultipleAssignmentTab
                                {...assignment.form}
                                key={`tab_${generateUniqueId()}`}
                                total={assignments.length}
                                isSelected={assignment.isShow}
                                isError={!assignment.isValid}
                                onDeleteClick={(e) =>
                                    this.handleDeleteForm(i, e)
                                }
                                onClick={(e) => this.handleEditForm(i, e)}
                            />
                        ))}
                        <button
                            type="button"
                            className="tv-multiple-assign-form__button-add-more"
                            onClick={this.handleAddMoreForm}
                        >
                            <Translate content="multipleAssignment.addMoreAssignment" />
                        </button>
                    </div> */}
          <div className="tv-multiple-assign-form__panel-right tv-right-content">
            {assignments.map((assignment, i) => (
              <IfComponent
                key={`assignment_${i}`}
                condition={assignment.isShow}
                whenTrue={
                  <ValidatorForm
                    ref={r => {
                      this.formAssignment = r;
                    }}
                    onSubmit={() => this.handleSingleSubmit(i)}
                    onError={() => this.handleSingleError(i)}
                  >
                    <SingleAssignmentForm
                      {...assignment.form}
                      showOtherRequirementSection={true}
                      showCerfiticate={true}
                      validatorListener={v =>
                        this.handleValidatorListener(i, v)
                      }
                      onChange={v => this.handleChange(i, v)}
                      onHandleModal={this.handleModal}
                      languages={languages}
                      createdFrom="multipleAssignment"
                      ContactPersonOrdererRequiresEmail={
                        profile.ContactPersonOrdererRequiresEmail
                      }
                      ContactPersonUponInterpretationRequiresEmail={
                        profile.ContactPersonUponInterpretationRequiresEmail
                      }
                    />
                  </ValidatorForm>
                }
              />
            ))}

            <div className="tv-single-assign-form__action-container tv-display-flex">
              <TFVButton
                type="primary"
                buttonType="submit"
                className="tv-single-assign-form__button-preview"
                onClick={this.handleSubmit}
                text={Translate({
                  content: 'singleAssignment.previewAssignment',
                })}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

MultipleAssignment.propTypes = propTypes;
MultipleAssignment.defaultProps = defaultProps;

const mapStateToProps = state => {
  const assignments = state.workAssignment.assignmentTemp;
  const skills = state.skill.skills || [];
  const contactPersons = state.user.contactPersons || [];
  const newContactPersons = state.workAssignment.contactPersons || [];

  const profile = state.user.profile || {};
  const languages = skills.map((item, i) => ({
    value: item.SkillIdentifier,
    name: item.SkillName,
  }));
  return {
    assignments,
    skills,
    contactPersons,
    newContactPersons,
    profile,
    languages,
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmitAssignment: state => {
    dispatch(onUpdateNewAssignmentsTemp(state.assignments));
  },
  executeGetContactPersons: () => {
    dispatch(fetchContactPersons());
  },
  executeGetServices: (params, callback) => {
    dispatch(fetchServices(params, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MultipleAssignment);
