/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate, Modal, Row, Col } from '../../../Share/components';
import { isIE, removeContextualClass } from '../../../Share/utils';
const CancelModal = ({
  isVisible,
  onCloseClicked,
  onModalCloseClicked,
  onOverlayClick,
  onSubmitClick,
}) => {
  const modalCloseClick = () => {
    onSubmitClick();
  };

  const modalSubmitClick = () => {
    removeContextualClass();
    onCloseClicked();
  };

  return (
    <Modal
      isVisible={isVisible}
      className="tv-booknow__modal tv-booknow__modal-cancel feedBack__modal"
      onCloseClicked={onModalCloseClicked}
      onOverlayClick={onOverlayClick}
      headerClassName={`tv-booknow__modal-cancel-header ${
        isIE ? 'ie-fix-width' : ''
      }`}
      headerText={Translate({ content: 'bookNow.bookAnInterpreterToday' })}
      ieOptionClassName={isIE ? 'ie-fix-height' : ''}
    >
      <div className="tv-booknow__modal-cancel__container">
        <Row>
          <Col md={12} sm={12}>
            <div className="title">
              {Translate({ content: 'bookNow.cancelBooking' })}
            </div>
          </Col>
          <Col md={12} sm={12}>
            <div className="text">
              Är du säker på att du vill avbryta denna bokning?
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="pr-2">
            <button
              onClick={modalCloseClick}
              className="tv-booknow__modal-actions-button_cancel"
            >
              <Translate content="bookNow.cancelCancellationModal" />
            </button>
          </Col>
          <Col className="pl-2">
            <button
              onClick={modalSubmitClick}
              className="tv-booknow__modal-actions-button_submit"
            >
              <Translate content="bookNow.closeCancellationModal" />
            </button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default CancelModal;
