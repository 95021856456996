import React from 'react';
import './style.scss';

const CopyBooking = ({
  onClose,
  fieldChange,
  copyOrder,
  assignmentDetail,
  fieldList,
}) => {
  return (
    <>
      <div className="tv-modal__wrapper tv-display-flex tv-route-leaving-guard-modal">
        <div className="tv-modal__overlay" aria-label='modal overlay'></div>
        <div className="tv-copy-modal__container">
          <div className="tv-copy-modal__container__header">
            <div className="tv-copy-modal__container__header-title">
              Select fields that you want to copy
            </div>
            <button
              className="tv-buttons__type icon-close"
              onClick={onClose}
            ></button>
          </div>
          <hr className="tv-copy-modal__container__divider" />
          <div className="tv-copy-modal__container__body">
            {!assignmentDetail.isMessageServiceAssignment
              ? fieldList.map((item, index = 0) =>
                  !item.isMessageService ? (
                    <div className="tv-copy-modal__container__body-item">
                      <input
                        type="checkbox"
                        onChange={e =>
                          fieldChange(e, item.name, item.label, index)
                        }
                        value={item.value}
                      />
                      <div className="tv-copy-modal__container__body__field-name">
                        {item.label}
                      </div>
                    </div>
                  ) : null,
                )
              : fieldList.map((item, index = 0) =>
                  item.isMessageService ? (
                    <div className="tv-copy-modal__container__body-item">
                      <input
                        type="checkbox"
                        onChange={e =>
                          fieldChange(e, item.name, item.label, index)
                        }
                        value={item.value}
                      />
                      <div className="tv-copy-modal__container__body__field-name">
                        {item.label}
                      </div>
                    </div>
                  ) : null,
                )}
          </div>

          <div className="tv-route-leaving-guard-modal__buttons tv-display-flex">
            <button
              type="button"
              className="tv-buttons__element tv-buttons__element--line-primary  tv-route-leaving-guard-modal-area-backBtn"
            >
              Close
            </button>
            <button
              className="tv-buttons__element tv-buttons__element--secondary  tv-route-leaving-guard-modal-area-submitBtn"
              onClick={copyOrder}
            >
              Continue Order
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyBooking;
