import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IfComponent, Translate, ArrowButton } from '../../../Share/components';

const propTypes = {
  visibleNextPreviousButtons: PropTypes.bool,
  disablePreviousButton: PropTypes.bool,
  disableNextButton: PropTypes.bool,
  onPreviousNextClicked: PropTypes.func,
};

const defaultProps = {
  visibleNextPreviousButtons: true,
  disablePreviousButton: false,
  disableNextButton: false,
  onPreviousNextClicked: val => {},
};

const NavigationButtons = ({
  visibleNextPreviousButtons,
  disablePreviousButton,
  disableNextButton,
  onPreviousNextClicked,
}) => {
  return (
    <div className="tv-workassignment__detail-footer">
      <IfComponent
        condition={visibleNextPreviousButtons}
        whenTrue={
          <ArrowButton
            Text={Translate({
              content: 'taskDetail.previousButton',
            })}
            title={Translate({
              content: 'taskDetail.previousButton',
            })}
            onClick={e => {
              onPreviousNextClicked(-1);
            }}
            disabled={disablePreviousButton}
            className={
              disablePreviousButton
                ? 'tv-workassignment__drawer--previous tv-buttons__type icon-previous disabled ml-2'
                : 'tv-workassignment__drawer--previous tv-buttons__type icon-previous ml-2'
            }
          />
        }
      />
      <IfComponent
        condition={visibleNextPreviousButtons}
        whenTrue={
          <ArrowButton
            Text={Translate({
              content: 'taskDetail.nextButton',
            })}
            title={Translate({
              content: 'taskDetail.nextButton',
            })}
            onClick={() => {
              onPreviousNextClicked(+1);
            }}
            disabled={disableNextButton}
            className={
              disableNextButton
                ? 'tv-workassignment__drawer--next tv-buttons__type icon-next disabled mr-2'
                : 'tv-workassignment__drawer--next tv-buttons__type icon-next mr-2'
            }
          />
        }
      />
    </div>
  );
};

NavigationButtons.propTypes = propTypes;
NavigationButtons.defaultProps = defaultProps;

export default NavigationButtons;
