import { toast } from 'react-toastify';
import history from '../../Share/utils/history';
import * as api from '../api';
import { showLoadingOverlay, hiddenLoadingOverlay } from './app';
import { broadCast } from './eventBus';
import {
  tfvLog,
  showToastMessage,
  redirectToDashBoard,
  logApiLevel,
  consoleLog,
  redirectToRequestedPath,
} from '../../Share/utils';
import generateHashPassword from '../../Share/utils/hashUtil';
import { Translate } from '../../Share/components';
// import privilegedKPICustomers from './../static/kpiPrivilegedClient.txt';

import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_APP,
  AUTHENTICATE_RESOURCE_SUCCESS,
  SET_DISPLAY_KPI_REPORTS,
  GET_USER_DATA,
  SET_USER_AGREEMENT,
  UPDATE_INDIVIDUAL_USER_LOGGED,
  UPDATE_NEW_PASSWORD,
} from './ActionTypes';
import { DEFAULT_REDIRECT_PATH } from '../../Share/constants';
import { authenticateCustomerUser } from '../api/multisoftApi';

export function logout() {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_APP,
    });
    dispatch({
      type: UPDATE_INDIVIDUAL_USER_LOGGED,
      user: {},
    });
  };
}

async function CheckToken(
  customerNumber,
  passwordHash,
  customerEmail,
  dispatch,
) {
  await checkCustomerKPIPrivilege(customerNumber, dispatch);
  const authResponse = await api.validateCustomerPassword(
    customerNumber,
    passwordHash,
    customerEmail,
  );
  const accessToken = authResponse.data.Token;
  const e = new Error(Translate({ content: 'error.tokenErrorClientPortal' }));
  e.name = 'LoginError';
  if (!accessToken || accessToken === '') throw e;
  return accessToken;
}

async function checkCustomerKPIPrivilege(customerNumber, dispatch) {
  try {
    // const response = await fetch(privilegedKPICustomers);
    // const kPICustomersText = await response.text();
    // let kPICustomers = kPICustomersText ? kPICustomersText.split('\n') : [];
    // kPICustomers = kPICustomers.map(x => x.trim());
    // dispatch({
    //   type: SET_DISPLAY_KPI_REPORTS,
    //   displayKPIReports: kPICustomers.includes(customerNumber),
    // });
  } catch (e) {
    consoleLog(e);
  }
}

export function setCustomerKPIReportsDispalyRight() {
  return async (dispatch, getState) => {
    const { customerNumber } = getState().authentication;
    await checkCustomerKPIPrivilege(customerNumber, dispatch);
  };
}

export function login(customerNumber, password, customerEmail) {
  return async (dispatch, getState) => {
    try {
      dispatch(showLoadingOverlay());

      const customerNumberLoginBefore =
        getState().authentication.customerNumber;
      if (customerNumberLoginBefore !== customerNumber) {
        broadCast('userLoginWithAnotherAccount', [dispatch, {}]);
      }

      // after validateCustomerPassword, Multisoft send an token to user
      // after that user will redirect to dashboard
      const passwordHash = generateHashPassword(password);
      const accessToken = await CheckToken(
        customerNumber,
        passwordHash,
        customerEmail,
        dispatch,
      );

      dispatch({
        type: AUTHENTICATE_RESOURCE_SUCCESS,
        customerNumber,
      });

      dispatch({
        type: LOGIN_SUCCESS,
        token: accessToken,
        customerNumber,
        passwordHash,
      });
      const defaultRedirectPath = localStorage.getItem(DEFAULT_REDIRECT_PATH);
      if (defaultRedirectPath) {
        redirectToRequestedPath(defaultRedirectPath);
      } else {
        redirectToDashBoard();
      }
    } catch (error) {
      tfvLog(error, logApiLevel.error);
      if (error.name === 'LoginError') toast.error(error.message);
      dispatch({ type: LOGIN_FAILURE, error });
      dispatch(hiddenLoadingOverlay());
    }
    try {
      await getReportUserData(customerNumber, dispatch, getState);
    } catch (error) {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function createToken(customerNumber, passwordHash) {
  return async (dispatch, getState) => {
    try {
      const accessToken = await CheckToken(
        customerNumber,
        passwordHash,
        dispatch,
      );

      dispatch({
        type: LOGIN_SUCCESS,
        token: accessToken,
        customerNumber,
        passwordHash,
      });

      broadCast('newTokenCreated', [dispatch, {}]);
      redirectToDashBoard();
    } catch (error) {
      tfvLog(error, logApiLevel.error);
      if (error.name === 'LoginError') toast.error(error.message);
      dispatch({ type: LOGIN_FAILURE, error });
      history.push('/login');
    }
  };
}

export const autoCreateNewToken = () => (dispatch, getState) =>
  Promise.resolve().then(async () => {
    const state = getState();
    const { customerNumber, passwordHash } = state.authentication;

    if (customerNumber && passwordHash) {
      tfvLog(`autoCreateNewToken ${customerNumber}`);
      try {
        const accessToken = await CheckToken(
          customerNumber,
          passwordHash,
          dispatch,
        );

        dispatch({
          type: LOGIN_SUCCESS,
          token: accessToken,
          customerNumber,
          passwordHash,
        });

        broadCast('newTokenCreated', [dispatch, {}]);
        redirectToDashBoard();
      } catch (error) {
        tfvLog(error, logApiLevel.error);
        if (error.name === 'LoginError') toast.error(error.message);
        dispatch({ type: LOGIN_FAILURE, error });
        history.push('/login');
      }
    } else {
      dispatch(logout());
      history.push('/login');
    }
  });

export function resetPassword(plainTextPassword) {
  return async (dispatch, getState) => {
    try {
      dispatch(showLoadingOverlay());
      const state = getState();
      const { token } = state.authentication;

      const hash = generateHashPassword(plainTextPassword);
      await api.setCustomerPassword(token, hash);

      history.push('/'); // Go to dashboard
      showToastMessage(
        'success',
        Translate({ content: 'resetPassword.resetSuccessfull' }),
      );
      dispatch({
        type: UPDATE_NEW_PASSWORD,
        newPassword: hash,
      });
    } catch (error) {
      tfvLog(error, logApiLevel.error);
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function setNewPassword(customerNumber, plainTextPassword, accessToken) {
  return async (dispatch, getState) => {
    try {
      dispatch(showLoadingOverlay());

      // Commented out as a security concern is raised allowing users to login first time
      // let accessToken = null;
      // try {
      //   accessToken = await CheckToken(customerNumber, null, dispatch);
      // } catch (error) {
      //   throw error;
      // }

      const hash = generateHashPassword(plainTextPassword);
      await api.setCustomerPassword(accessToken, hash);

      history.push('/'); // Go to dashboard
      showToastMessage(
        'success',
        Translate({ content: 'resetPassword.setSuccessful' }),
      );
    } catch (error) {
      // if (error.name === 'LoginError') toast.error(error.message);
      if (error.name === 'LoginError')
        showToastMessage(
          'error',
          Translate({ content: 'resetPassword.setFailed' }),
        );
      tfvLog(error, logApiLevel.error);
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}
export function validateCurrentPassword(password, callback) {
  return async (dispatch, getState) => {
    try {
      dispatch(showLoadingOverlay());

      const { customerNumber } = getState().authentication;

      const passwordHash = generateHashPassword(password);
      await CheckToken(customerNumber, passwordHash, dispatch);
      // When validate password successfully
      if (callback) {
        callback();
      }
    } catch (error) {
      if (error.name === 'LoginError') toast.error(error.message);
      tfvLog(error);
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function forgotPassword(customerNumber, email, isContactPerson) {
  return async (dispatch, getState) => {
    try {
      dispatch(showLoadingOverlay());
      await authenticateCustomerUser(customerNumber, email, isContactPerson);
      showToastMessage(
        'success',
        Translate({ content: 'forgotPassword.successMessage' }),
      );
    } catch (error) {
      if (error.name === 'LoginError') toast.error(error.message);
      tfvLog(error);
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

const getReportUserData = async (customerNumber, dispatch, getState) => {
  const state = getState();
  const userName = state.user.report.name;
  // dispatch(showLoadingOverlay());
  // const response = await api.getUserData(customerNumber);
  // const userData = response.data;
  // userData.agreements = [{ id: 0, name: 'Alla' }, ...userData.agreements];
  // dispatch(hiddenLoadingOverlay());
  // dispatch({
  //   type: GET_USER_DATA,
  //   userData,
  // });
  // localStorage.setItem('SELECTER_CUSTOMER_NUMBER', userData.referenceId);

  // localStorage.setItem('USER_DATA', JSON.stringify(userData));
  // localStorage.setItem(
  //   'SELECTED_AGREEMENT',
  //   JSON.stringify(userData.agreements[0]),
  // );
  // dispatch({
  //   type: SET_USER_AGREEMENT,
  //   selectedAgreement: userData.agreements[0],
  // });
};
