import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { numberFormat, summationBasedOnKey } from '../../../Share/utils/reportUtil';
import { ThreeDotLoader, Translate } from '../../../Share/components';
import propTypes from 'prop-types';

const InvoicedAssignmentWidget = ({ monthlyInterpretations, dataReadyFlag}) => (
  <div className="exact-col-2">
    <SmallDetailedCard
      heading={Translate({ content: 'report.totalRevenue' })}
      dataReadyFlag={dataReadyFlag}
      formatedContent={`${numberFormat(summationBasedOnKey(monthlyInterpretations, 'invoicedAmount'), 0)} kr`}
      actualContent={numberFormat(summationBasedOnKey(monthlyInterpretations, 'invoicedAmount'), 0)}
    />
    <SmallDetailedCard
      heading={Translate({ content: 'report.totalAssignment' })}
      dataReadyFlag={dataReadyFlag}
      formatedContent={numberFormat(summationBasedOnKey(monthlyInterpretations, 'noOfAssignments'), 0)}
      actualContent={numberFormat(summationBasedOnKey(monthlyInterpretations, 'noOfAssignments'), 0)}
    />
  </div>
);
InvoicedAssignmentWidget.defaultProps={
  dataReadyFlags:false,
  monthlyInterpretations:[],
}
InvoicedAssignmentWidget.propTypes = {
  dataReadyFlags: PropTypes.bool,
  monthlyInterpretations: propTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state, props) => {
  const dataReadyFlags = [state.reportDataSources[1].dataReadyFlags[0], state.reportDataSources[10].dataReadyFlags[1]];
  return ({
    dataReadyFlag: dataReadyFlags[0] || false,
    monthlyInterpretations: [...state.reportDataSources[1].crossFilterData[0] || []],
  });
};
const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicedAssignmentWidget);

const SmallDetailedCard = ({
  heading, formatedContent, actualContent, style, dataReadyFlag,
}) => (
  <React.Fragment>
    <div className="dashboard__samll-display-card round-border" style={{ ...style }}>
      <div className="dashboard_samll-detail-card">

        <div className="dashboard_samll-detail-card-header">
          <span style={{ float: 'left' }} title={heading}>
            {heading}
          </span>
        </div>
        <div className="dashboard_samll-detail-card-content" style={{ fontSize: '1.2rem', textAlign: 'end' }}>
          <span title={actualContent}>

            {dataReadyFlag ? formatedContent : <ThreeDotLoader />}
          </span>

        </div>
      </div>
    </div>
  </React.Fragment>);
SmallDetailedCard.propTypes = {
  style: PropTypes.shape({}),
  heading: PropTypes.string.isRequired,
  formatedContent: PropTypes.string.isRequired,
  actualContent: PropTypes.string.isRequired,
};
