import React, { Component } from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import './style.scss';

const propTypes = {};

const defaultProps = {};

const INITAL_STATES = {
  isChecked: false,
};

class TFVDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = INITAL_STATES;
  }
  handleToggleSwitchChange = () => {
    const { isChecked } = this.state;
    this.setState({
      isChecked: !isChecked,
    });
  };
  render() {
    return (
      <DatePicker
        defaultValue={new Date()}
        onChange={this.props.onChangeDate}
      />
    );
  }
}

TFVDatePicker.propTypes = propTypes;
TFVDatePicker.defaultProps = defaultProps;

export default TFVDatePicker;
