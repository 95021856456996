import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { ValidatorForm } from 'react-form-validator-core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Translate,
  TFVButton,
  TextField,
  DropdownList,
} from '../../../Share/components';
import {
  getComplaintReasonList,
  createDeviation,
  sendDeviationEmail,
  fetchContactPersons,
  setOrderRating,
} from '../../actions';
import './style.scss';
import { hiddenLoadingOverlay, showLoadingOverlay } from '../../actions/app';

const defaultContactPerson = {
  name: 'Välj',
  value: '',
};

const getContactPerson = (props, assignment) => {
  if (assignment.ContactPerson) {
    // #region get all props of `assignment.ContactPerson` which has value;
    const equalComparers = [
      { field: 'ContactPersonEmail', refField: 'Email' },
      {
        field: 'ContactPersonDirectPhoneNumber',
        refField: 'PhoneNumberDirect',
      },
      {
        field: 'ContactPersonMobilePhoneNumber',
        refField: 'PhoneNumberMobile',
      },
      { field: 'ContactPersonFirstName', refField: 'FirstName' },
      { field: 'ContactPersonLastName', refField: 'LastName' },
      { field: 'ContactPersonTitle', refField: 'Title' },
    ].filter(c => assignment.ContactPerson[c.field]);
    // #endregion

    // #region find an item of `props.contactPersons` which has same value as `assignment.ContactPerson`
    if (equalComparers.length > 0) {
      const person = props.contactPersons.filter(cp => {
        let isMatch = true;
        equalComparers.forEach(ec => {
          if (cp[ec.refField] !== assignment.ContactPerson[ec.field]) {
            isMatch = false;
          }
        });
        return isMatch;
      })[0];
      if (person) return person;
    }
    // #endregion
  }
  return {};
};

const propTypes = {
  assignmentList: PropTypes.arrayOf(PropTypes.shape({})),
  complaintReasons: PropTypes.arrayOf(PropTypes.shape({})),
  contactPersons: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  handleOrderRate: PropTypes.func,
  getContactPersons: PropTypes.func.isRequired,
  validatorListener: PropTypes.func,
  onGetComlaintReasonList: PropTypes.func,
  onCreateDeviation: PropTypes.func,
  onSendDeviationEmail: PropTypes.func,
  onCloseClicked: PropTypes.func,
  onDeviationSuccess: PropTypes.func,
};

const defaultProps = {
  assignmentList: [],
  complaintReasons: [],
  contactPersons: [],
  onChange: () => {},
  handleOrderRate: () => {},
  validatorListener: () => {},
  onGetComlaintReasonList: () => {},
  onCreateDeviation: () => {},
  onSendDeviationEmail: () => {},
  onCloseClicked: () => {},
  onDeviationSuccess: () => {},
};

class CreateDeviation extends Component {
  constructor(props) {
    super(props);
    const assignment =
      props.assignment && props.assignment.OrderNumber ? props.assignment : {};
    if (props.contactPersons.length === 0) props.getContactPersons();
    const contactPerson = getContactPerson(props, assignment);
    const isGeneral = props.isGeneral || false;

    const hasReasonList = !!(
      this.props.complaintReasons && this.props.complaintReasons.length > 0
    );
    this.state = {
      rateData: { rate: 0, comment: '' },
      topicValue: hasReasonList ? { name: 'Välj', value: '' } : '',
      descriptionValue: '',
      assignment,
      contactPersons: props.contactPersons,
      contactPerson: isGeneral ? defaultContactPerson : contactPerson,
      replyEmail: '',
      orderNumber:
        props.assignment && props.assignment.OrderNumber
          ? props.assignment.OrderNumber
          : '',
      iWantAReply: false,
      isGeneral,
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.contactPersons.length !== nextProps.contactPersons.length) {
      return {
        ...prevState,
        contactPersons: nextProps.contactPersons,
        contactPerson: prevState.isGeneral
          ? defaultContactPerson
          : getContactPerson(nextProps, prevState.assignment),
      };
    }

    return null;
  }
  componentDidMount() {
    this.executeGetReasonList();
  }

  executeGetReasonList = () => {
    this.props.onGetComlaintReasonList();
  };

  handleCallbackSubmit = returnData => {
    const { onCloseClicked } = this.props;
    if (returnData && returnData.IsSuccess) {
      onCloseClicked();
      // toast.success(
      //   `${Translate({
      //     content: 'reportQuality.createDeviationSucessfullMessage',
      //   })}`,
      // );
      this.props.onDeviationSuccess(returnData.DeviationNumber);
      setTimeout(() => {
        this.goBack();
      }, 3000);
    } else toast.error(`${Translate({ content: 'error.commonError' })}`);
  };

  handleSubmit = event => {
    event.preventDefault();
    const {
      onCreateDeviation,
      onSendDeviationEmail,
      // handleOrderRate,
    } = this.props;
    const {
      topicValue,
      assignment,
      descriptionValue,
      contactPerson,
      isGeneral,
      iWantAReply,
      replyEmail,
      orderNumber,
    } = this.state;

    let complaintReasonDeliveryName = '';
    this.props.complaintReasons.forEach(item => {
      if (item.ComplaintReasonDeliveryIdentifier === topicValue.value) {
        complaintReasonDeliveryName = item.ComplaintReasonDeliveryName;
      }
    });

    const data = {
      ComplaintReasonDeliveryIdentifier: topicValue.value
        ? topicValue.value
        : '',
      ComplaintReasonDeliveryName: complaintReasonDeliveryName,
      DeviationDescription:
        iWantAReply && replyEmail && !isGeneral
          ? `${descriptionValue}||${replyEmail}`
          : descriptionValue,
      ContactPersonIdentifier:
        contactPerson && contactPerson.ContactPersonIdentifier !== '-1'
          ? contactPerson.ContactPersonIdentifier
          : null,
      OrderIdentifier: assignment ? assignment.OrderIdentifier : null,
      ...(iWantAReply && isGeneral && replyEmail
        ? { ReplyEmail: replyEmail }
        : {}),
      ...(isGeneral && orderNumber ? { OrderNumber: orderNumber } : {}),
      ...(isGeneral && contactPerson.ContactPersonIdentifier !== '-1'
        ? { ContactPersonData: contactPerson }
        : {}),
    };

    if (!isGeneral) {
      // Incase have contact person we submit to multisoft api
      onCreateDeviation(data, this.handleCallbackSubmit);
      // if (assignment.CanBeRated) {
      //   const { rate, comment } = this.state.rateData;
      //   handleOrderRate(assignment.OrderIdentifier, rate, comment);
      // }
    } else {
      // when have not contact person we will submit to mail-service api
      onSendDeviationEmail(data, this.handleCallbackSubmit);
    }
  };

  goBack = () => {
    const { onCloseClicked } = this.props;
    onCloseClicked();
  };

  handleChange = name => event => {
    if (name === 'iWantAReply') {
      const iWantAReply = event.target.checked || false;
      this.setState({ iWantAReply });
    } else if (name === 'contacPerson') {
      const contacPersonId = event.target.value;
      const contactPerson =
        contacPersonId === '-1'
          ? defaultContactPerson
          : this.props.contactPersons.find(
              c => c.ContactPersonIdentifier === contacPersonId.value,
            );
      this.setState({ contactPerson });
    } else {
      const { value } = event.target;
      this.setState({ [name]: value });
    }
  };

  handleRateChange = rateValue => {
    const { rateData } = this.state;
    this.setState({
      ...this.state,
      rateData: { ...rateData, ...rateValue },
    });
  };
  getValueFromList = (filterText, list) => {
    if (filterText === null || filterText === '') return null;
    const filteredItems = list.filter(x => x.value === filterText);
    return filteredItems ? filteredItems[0] : null;
  };

  generateCompliantReasons = () => {
    const { complaintReasons } = this.props;
    return complaintReasons.map(item => ({
      name: item.ComplaintReasonDeliveryName,
      value: item.ComplaintReasonDeliveryIdentifier,
    }));
  };

  generateContactPersons = () => {
    const { contactPersons } = this.props;
    return contactPersons.map(item => ({
      name: item.name,
      value: item.value,
    }));
  };

  render() {
    const { validatorListener } = this.props;
    const {
      topicValue,
      descriptionValue,
      assignment,
      contactPerson,
      orderNumber,
      isGeneral,
      contactPersons,
    } = this.state;
    return (
      <React.Fragment>
        <div className="tv-reportquality-form__paragraph">
          Här fyller du i så många uppgifter om ärendet som möjligt och vad som
          har skett. En avvikelse kommer att skapas och skickas till TFV
          kvalitetsavdelning för utredning och åtgärd. Återkoppling sker inom
          fem arbetsdagar. Under Avvikelser kan du följa ditt ärende
        </div>
        <div className="row mt-3 m-0 tv-reportquality__wrapper tv-display-flex">
          <div className="tv-reportquality__container">
            <div className="tv-reportquality__content tv-display-flex">
              <ValidatorForm
                className="tv-reportquality-form"
                onSubmit={this.handleSubmit}
                ref="form"
              >
                {isGeneral ? (
                  <React.Fragment>
                    <div className="form-group col-lg-12 col-12 mr-lg-2">
                      <div className="tv-reportquality-form__label">
                        <label htmlFor="orderNumber">
                          <Translate content="reportQuality.bookingNumber" />
                        </label>
                      </div>
                      <div className="tv-reportquality-form__input">
                        <TextField
                          validatorListener={validatorListener}
                          onChange={this.handleChange('orderNumber')}
                          className="tv-reportquality-form__textfield tv-reportquality-form__control tv-single-assign-form__control"
                          name="orderNumber"
                          id="orderNumber"
                          validators={['required']}
                          errorMessages={[
                            Translate({
                              content: 'validator.isRequired',
                              params: {
                                name: Translate({
                                  content: 'reportQuality.bookingNumber',
                                }),
                              },
                            }),
                          ]}
                          value={orderNumber}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="form-group col-lg-12 col-12 mr-lg-2">
                      <div className="tv-reportquality-form__label">
                        <label>
                          <Translate content="reportQuality.bookingNumber" />
                        </label>
                      </div>
                      <div className="tv-reportquality-form__input">
                        <div className="tv-reportquality-form__textfield tv-reportquality-form__control tv-single-assign-form__control">
                          {assignment.OrderNumber}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className="mx-0 row">
                  <div className="form-group col-lg-6 col-12 ">
                    <div className=" tv-reportquality-form__label">
                      <label>
                        <Translate content="reportQuality.cause" />
                      </label>
                    </div>
                    <div className=" tv-reportquality-form__input">
                      <div className="tv-col-12 tv-reportquality-form__input-arrow">
                        <DropdownList
                          onChange={this.handleChange('topicValue')}
                          name="ratingReason"
                          className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                          options={this.generateCompliantReasons()}
                          value={this.getValueFromList(
                            topicValue.value,
                            this.generateCompliantReasons(),
                          )}
                          validators={['required']}
                          errorMessages={[
                            Translate({
                              content: 'validator.isRequired',
                              params: {
                                name: Translate({
                                  content: 'reason.reasonForAddedRating',
                                }),
                              },
                            }),
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  {isGeneral ? (
                    <React.Fragment>
                      <div className="form-group col-lg-6 col-12">
                        <div className="tv-reportquality-form__label">
                          <label>
                            <Translate content="reportQuality.contactPersonFieldLabel" />
                          </label>
                        </div>
                        <div className="tv-reportquality-form__input">
                          <div className="tv-reportquality-form__input-arrow">
                            <DropdownList
                              onChange={this.handleChange('contacPerson')}
                              name="contactPerson"
                              value={this.getValueFromList(
                                contactPerson.value,
                                this.generateContactPersons(),
                              )}
                              validators={['required']}
                              errorMessages={[
                                Translate({
                                  content: 'validator.isRequired',
                                  params: {
                                    name: Translate({
                                      content:
                                        'reportQuality.contactPersonFieldLabel',
                                    }),
                                  },
                                }),
                              ]}
                              className="tv-reportquality-form__dropdownlist tv-reportquality-form__control tv-single-assign-form__control"
                              options={this.generateContactPersons()}
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    contactPerson.value !== defaultContactPerson.value && (
                      <>
                        <div className="form-group col-lg-6 col-12 ">
                          <div className="tv-reportquality-form__label">
                            <label>
                              <Translate content="reportQuality.contactPersonFieldLabel" />
                            </label>
                          </div>
                          <div className="tv-reportquality-form__input">
                            <div className="tv-reportquality-form__textfield tv-reportquality-form__control tv-single-assign-form__control">
                              {contactPerson.name || defaultContactPerson.name}
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  )}
                </div>
                <div className="form-group col-lg-12 col-12 mr-lg-2">
                  <div className="tv-reportquality-form__label">
                    <label htmlFor="descriptionValue">
                      <Translate content="reportQuality.descriptionFieldLabel" />
                    </label>
                  </div>
                  <div className="tv-reportquality-form__input">
                    <TextField
                      validatorListener={validatorListener}
                      placeholder={Translate({
                        content: 'reportQuality.descriptionFieldPlaceholder',
                      })}
                      onChange={this.handleChange('descriptionValue')}
                      cols="10"
                      rows="3"
                      maxLength="1000"
                      multiline
                      className="ellipsis pl-3 py-1 tv-single-assign-form__control label-height"
                      name="descriptionValue"
                      id="descriptionValue"
                      value={descriptionValue}
                      aria-label="Description field"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className="tv-workassignment__detail-buttons tv-reportquality-form__buttons justify-content-between tv-display-flex">
                    <TFVButton
                      type="secondary"
                      buttonType="submit"
                      text={Translate({
                        content: 'reportQuality.submitButton',
                      })}
                    />
                    <TFVButton
                      type="line-primary"
                      text={Translate({
                        content: 'reportQuality.cancelButton',
                      })}
                      onClick={() => {
                        this.goBack();
                      }}
                    />
                  </div>
                </div>
              </ValidatorForm>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CreateDeviation.propTypes = propTypes;
CreateDeviation.defaultProps = defaultProps;

const mapStateToProps = state => {
  const complaintReasons =
    state && state.reason && state.reason.complaintReasons
      ? state.reason.complaintReasons
      : [];
  const contactPersons =
    state && state.user && state.user.contactPersons
      ? state.user.contactPersons
      : [];
  const props = {
    // ...defaultProps,
    complaintReasons,
    contactPersons,
  };
  return props;
};

const mapDispatchToProps = dispatch => ({
  onGetComlaintReasonList: () => {
    dispatch(getComplaintReasonList());
  },
  handleOrderRate: (orderIdentifier, rate, comment) => {
    dispatch(setOrderRating(orderIdentifier, rate, comment));
  },
  onCreateDeviation: (data, callback) => {
    dispatch(createDeviation(data, callback));
  },
  getContactPersons: () => {
    dispatch(showLoadingOverlay());
    dispatch(fetchContactPersons(() => dispatch(hiddenLoadingOverlay())));
  },
  onSendDeviationEmail: (data, callback) => {
    dispatch(sendDeviationEmail(data, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDeviation);
