import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { connect } from 'react-redux';
import { ChangeReportDatePeriod, ChangeDashboardDatePeriod, setDatasourceCrossFilter, removeDatasourceCrossFilter, onGetDataFromScource } from '../../actions';
import { Translate } from '../../../Share/components';
import { AgreementFilter } from '..';
import FulFillmentChartWidget from './FulFillmentChartWidget';
import OrderPatternWidgets from './OrderPattern';
import DeliveryReliabilityWidget from './DeliveryReliabilityWidget';
import AvailabilityToDeliver from './AvailabilityTodeliver';
import { generateUniqueId } from '../../../Share/utils';
import DashboardWidget from './DashboardWidget';
import InvoicedAssignmentWidget from './InvoicedAssignmentWidget';
import history from '../../../Share/utils/history';


const propTypes = {
  OnDateRangeChange: PropTypes.func,
  onDashboardDateChange: PropTypes.func.isRequired,
  handleGetDataSource: PropTypes.func.isRequired,
  range: PropTypes.shape({
    from: PropTypes.shape({}),
    to: PropTypes.shape({}),
  }),
  dashboardFilter: PropTypes.shape({}).isRequired,
  OnChartFiltered: PropTypes.func.isRequired,
  onRemoveChartFilter: PropTypes.func.isRequired,
  reportDetail: PropTypes.shape({}).isRequired,
};

const defaultProps = {
  OnDateRangeChange: () => { },
  range: {},
};

const INITAL_STATES = {
  reports: [2,10], // [2, 5, 10],
  dateRange: {
    start: moment().subtract(1, 'month').startOf('month').toDate(),
    end: moment().startOf('month').toDate(),
  },
};


class KPIDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITAL_STATES,
      dateRange: {
        start: props.range.from,
        end: props.range.to,
      },
    };
    
    this.dateChange();
  }


  handleChartItemclicked = (e) => {
    const {
      OnChartFiltered, onRemoveChartFilter, dashboardFilter,
    } = this.props;
    const { reports } = this.state;
    if (dashboardFilter.key !== undefined) {
      if (dashboardFilter.key === (e.categoryField || e.series.categoryField || e.target.props.seriesType) && dashboardFilter.value === (e.category || e.text || e.value)) {
        onRemoveChartFilter();
      } else {
        onRemoveChartFilter();
        reports.forEach(reportId => OnChartFiltered(reportId, { key: (e.categoryField || e.series.categoryField || e.target.props.seriesType), value: (e.category || e.text || e.value) }));
      }
    } else {
      onRemoveChartFilter();
      reports.forEach(reportId => OnChartFiltered(reportId, { key: (e.categoryField || e.series.categoryField || e.target.props.seriesType), value: (e.category || e.text || e.value) }));
    }
  }
  dateChange = () => {
    const { OnDateRangeChange, onDashboardDateChange, handleGetDataSource } = this.props;
    const { dateRange, reports } = this.state;
    onDashboardDateChange(dateRange);
    handleGetDataSource(8, dateRange.start, dateRange.end);
    reports.forEach(reportId => OnDateRangeChange(dateRange, reportId));
  }

  handleDateChange = (event) => {
    this.setState({
      ...this.state,
      dateRange: event.target.value,
    });
  }
   labelContent = (props) => {
     const formatedNumber = Number(props.percentage).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });
     return `${props.category} : ${formatedNumber}`;
   };
   render() {
     const {
       reportDetail, widgets,
     } = this.props;

     return (
       <React.Fragment>

         <div className="dashboard__filter-holder" >
           <DateRangePicker
             format="yyyy-MM-dd"
             value={this.state.dateRange}
             defaultValue={this.state.dateRange}
             onChange={this.handleDateChange}
             startDateInputSettings={ {label: Translate({content:"report.start"})}}
            endDateInputSettings={ {label:  Translate({content:"report.end"})}}
           />
           <AgreementFilter dataSources={[1, 2, 7]} />
           <button
             title="Report Period"
             style={{ marginLeft: '10px',    gridRow: '1/auto', gridColumn:" 3/6" }}
             className="tv-buttons__element tv-buttons__element--primary"
             onClick={this.dateChange}
           >
             <Translate content="report.extractReport" />
           </button>
         </div>
         <div className="dashboard__chart-holder">
           <InvoicedAssignmentWidget key="1_InvoicedAssignmentWidget" />
         </div>
         <div className="dashboard__chart-holder">
           <OrderPatternWidgets key="1_OrderPatternWidgets"/>
         </div>

         <div className="dashboard__chart-holder">
           <FulFillmentChartWidget labelContent={this.labelContent} key="1_FulFillmentChartWidget" />
         </div>
         
         {widgets.map(widgetItem => (
           <DashboardWidget key={`${widgetItem.title}_dc_${generateUniqueId()}`} onDashboardChartItemclicked={this.handleChartItemclicked} {...widgetItem} />
         ))}
       </React.Fragment>
     );
   }
}

KPIDashboard.propTypes = propTypes;
KPIDashboard.defaultProps = defaultProps;

const mapStateToProps = (state, props) => ({
  widgets: state.dashboard.widgets,
  user: state.user,
  dashboardFilter: state.app.dashboardFilter,
  range: state.dashboard.range,
  reportDetail: state.reportDetails,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  OnDateRangeChange: (dateRange, reportId) => {
    dispatch(ChangeReportDatePeriod(dateRange, reportId));
  },
  handleGetDataSource: (source, start, end) => {
    dispatch(onGetDataFromScource(source, start, end, true));
  },
  onDashboardDateChange: (dateRange) => {
    dispatch(ChangeDashboardDatePeriod(dateRange));
  },
  OnChartFiltered: (reportId, filter) => {
    dispatch(setDatasourceCrossFilter(reportId, filter));
  },
  onRemoveChartFilter: (reportId) => {
    dispatch(removeDatasourceCrossFilter(reportId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(KPIDashboard);





