import moment from 'moment';
import { clone, groupBy } from 'lodash';
import * as actionTypes from '../actions/ActionTypes';
import sortByDate from '../../Share/utils/helpers';
import {
  status,
  FILTER_TYPES,
  APP_PORTAL,
  ENV_CLIENT,
  RATING_VALUES,
} from '../../Share/constants';

const INITIAL_STATE = {
  createNewOrderError: [],
  assignmentListSource: [],
  assignmentList: [],
  assingmentPageList: [],
  searchedAssignmentList: [],
  WorkassignmentRange: {
    from: '',
    to: '',
  },
  availableRequestList: [],
  availableRequests: {
    count: 0,
    items: [],
  },
  filters: '',
  otherFilter: '',
  searchOrder: '',
  assignmentTemp: [],
  assignmentQuickOrder: [],
  assignmentSummaryList: [],
  contactPersons: [],
  ratingReasons: [{ name: 'Välj', value: '' }],
  statusFilter: [
    status.available.name,
    status.accepted.name,
    status.fullfilled.name,
    status.cancelled.name,
    status.late_cancelled.name,
    status.rejected.name,
  ],
  rateFilter: [
    RATING_VALUES.NOT_RATED,
    RATING_VALUES.ONE,
    RATING_VALUES.TWO,
    RATING_VALUES.THREE,
    RATING_VALUES.FOUR,
    RATING_VALUES.FIVE,
  ],
  statusFilterItems: [],
  rateFilterItems: [],
  typeFilterItems: [],
  types: [],
  availableResourcesAutoAssign: {},
  availableResourcesAutoAssignPending: false,
  returnedOrder: {},
  bookDirectOpeningHours: {},
  assignOrderError: [],
  selectedType: { name: 'Välj', value: '' },
};

const groupAssignmentList = assignmentList => {
  const isClientPortal = APP_PORTAL === ENV_CLIENT;
  const sortedItems = clone(assignmentList);
  if (isClientPortal) {
    let groupItems = [];
    const groups = groupBy(sortedItems, i => i.groupNumber);
    const tempGroups = [];
    Object.entries(groups).forEach(([groupKey, itemsInGroup]) => {
      itemsInGroup.sort((a, b) => {
        const aName = a.ContactPerson.ContactPersonName.trim().toLowerCase();
        const bName = b.ContactPerson.ContactPersonName.trim().toLowerCase();
        // eslint-disable-next-line no-nested-ternary
        return aName === bName
          ? ((_a, _b) => {
              const timediff =
                moment(_a.DatetimeFrom, 'YYYY-MM-DD HH:mm:ss') -
                moment(_b.DatetimeFrom, 'YYYY-MM-DD HH:mm:ss');
              // eslint-disable-next-line no-nested-ternary
              return timediff === 0 ? 0 : timediff > 0 ? -1 : 1;
            })(a, b)
          : aName > bName
          ? 1
          : -1;
      });
      tempGroups.push(itemsInGroup);
    });

    tempGroups.sort((g1, g2) => {
      const name1 = g1[0].ContactPerson.ContactPersonName.trim().toLowerCase();
      const name2 = g2[0].ContactPerson.ContactPersonName.trim().toLowerCase();
      return name1 === name2
        ? ((_g1, _g2) => {
            const timediff =
              moment(_g1[0].DatetimeFrom, 'YYYY-MM-DD HH:mm:ss') -
              moment(_g2[0].DatetimeFrom, 'YYYY-MM-DD HH:mm:ss');
            return timediff === 0 ? 0 : timediff > 0 ? -1 : 1;
          })(g1, g2)
        : name1 > name2
        ? 1
        : -1;
    });

    tempGroups.forEach(group => {
      groupItems = groupItems.concat(group);
    });
    return groupItems;
  }
};

const getAssignmentTypes = dataSet => {
  const types = [];
  dataSet.map(item => {
    if (item.IsTranslation === false) {
      if (!types.some(element => element.name === item.ArticleName)) {
        types.push({
          name: item.ArticleName,
          value: item.ArticleName,
        });
      }
      return null;
    }
  });
  return types;
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      return INITIAL_STATE;

    case actionTypes.UPDATE_MY_ASSIGNMENT_LIST: {
      const { assignmentList } = action;
      return {
        ...state,
        types: getAssignmentTypes(groupAssignmentList(assignmentList)),
        assignmentListSource: groupAssignmentList(assignmentList),
        assignmentList: groupAssignmentList(assignmentList).sort(sortByDate),
        assingmentPageList: groupAssignmentList(assignmentList),
      };
    }

    case actionTypes.UPDATE_MY_SEARCH_ASSIGNMENT_LIST: {
      const { assignmentList } = action;
      return {
        ...state,
        searchedAssignmentList: assignmentList,
      };
    }

    case actionTypes.UPDATE_MY_ASSIGNMENT_FILTERS: {
      const { filterType, value } = action;

      switch (filterType) {
        case FILTER_TYPES.STATUS_FILTER:
          return {
            ...state,
            statusFilter: [...value],
          };
        case FILTER_TYPES.RATING_FILTER:
          return {
            ...state,
            rateFilter: [...value],
          };
        case FILTER_TYPES.TYPE_FILTER:
          return {
            ...state,
            selectedType: value,
          };
        default:
          break;
      }
      return {
        ...state,
      };
    }

    case actionTypes.SEARCH_ORDER: {
      const { searchOrder } = action;
      return {
        ...state,
        searchOrder,
      };
    }

    case actionTypes.RESET_MY_ASSIGNMENT_FILTER: {
      return {
        assignmentList: [],
        availableRequestList: [],
        availableRequests: {
          count: 0,
          items: [],
        },
        filters: {
          status: '',
          datetimeFrom: new Date(),
          datetimeTo: new Date(),
        },
        otherFilter: '',
      };
    }

    case actionTypes.UPDATE_ASSIGNMENTS_RATINGS: {
      const { orderIdentifier, rate, comment } = action;
      const initalAssisgnmentList = state.assignmentList.map(item => ({
        ...item,
        RatingCustomer:
          item.OrderIdentifier === orderIdentifier ? rate : item.RatingCustomer,
        RatingCustomerComment:
          item.OrderIdentifier === orderIdentifier
            ? comment
            : item.RatingCustomerComment,
        CanBeRated:
          item.OrderIdentifier === orderIdentifier
            ? rate == null
            : item.CanBeRated,
      }));

      const initialAssignmentSummaryList = state.assignmentSummaryList.map(
        item => ({
          ...item,
          RatingCustomer:
            item.OrderIdentifier === orderIdentifier
              ? rate
              : item.RatingCustomer,
          RatingCustomerComment:
            item.OrderIdentifier === orderIdentifier
              ? comment
              : item.RatingCustomerComment,
          CanBeRated:
            item.OrderIdentifier === orderIdentifier
              ? rate == null
              : item.CanBeRated,
        }),
      );

      return {
        ...state,
        assignmentList: [...initalAssisgnmentList],
        assignmentSummaryList: [...initialAssignmentSummaryList],
      };
    }
    case actionTypes.UPDATE_NEW_ASSIGNMENTS: {
      const { assignmentTemp } = action;
      return {
        ...state,
        assignmentTemp,
      };
    }
    case actionTypes.UPDATE_SUBMITTED_ASSIGNMENT: {
      const { assignmentTemp } = action;
      return {
        ...state,
        assignmentTemp,
      };
    }
    case actionTypes.UPDATE_ASSIGNMENT_SUMMARY: {
      const { assignmentSummaryList } = action;
      return {
        ...state,
        assignmentSummaryList,
      };
    }

    case actionTypes.UPDATE_ASSIGNMENT_DATE_RANGE: {
      const { WorkassignmentRange } = action;
      return {
        ...state,
        WorkassignmentRange,
      };
    }

    case actionTypes.UPDATE_CONTACT_PERSONS: {
      const { contactPersons } = action;
      return {
        ...state,
        contactPersons,
      };
    }

    case actionTypes.UPDATE_RATING_REASONS: {
      const { ratingReasons } = action;
      return {
        ...state,
        ratingReasons,
      };
    }
    case actionTypes.GET_AVAILABLE_RESOURCE_AUTO_ASSIGN: {
      const { availableResourcesAutoAssign } = action;
      return {
        ...state,
        availableResourcesAutoAssign,
      };
    }
    case actionTypes.CREATE_NEW_AUTO_ASSIGN_ORDER: {
      const { returnedOrder } = action;
      return {
        ...state,
        returnedOrder,
      };
    }
    case actionTypes.CREATE_NEW_AUTO_ASSIGN_ORDER_ERROR: {
      const { assignOrderError } = action;
      return {
        ...state,
        assignOrderError,
      };
    }
    case actionTypes.GET_AUTO_ASSIGN_OPENING_HOURS: {
      const { bookDirectOpeningHours } = action;
      return {
        ...state,
        bookDirectOpeningHours,
      };
    }
    case actionTypes.CREATE_NEW_ORDER_ERROR: {
      const { errors } = action;
      return {
        ...state,
        createNewOrderError: [...errors],
      };
    }

    default:
      return state;
  }
};

export default app;
