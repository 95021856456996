import React from 'react';
import PropTypes from 'prop-types';
import { ValidatorComponent } from 'react-form-validator-core';
import classNames from 'classnames';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import IfComponent from '../IfComponent';
import ReactDOM from 'react-dom';

const propTypes = {
  errorMessages: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  validators: PropTypes.arrayOf(PropTypes.string),
  validatorListener: PropTypes.func,
  value: PropTypes.shape([]),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  optionWithAction: PropTypes.shape({}),
  className: PropTypes.string,
  defaultValue: PropTypes.shape([]),
  rest: PropTypes.shape({}),
  autoClose: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

const defaultProps = {
  errorMessages: [],
  optionWithAction: {
    actionValue: '',
    actionText: '',
    visible: false,
  },
  defaultValue: [],
  validators: ['matchRegexp:(.*)'],
  validatorListener: () => {},
  value: [],
  options: [],
  className: '',
  footer: null,
  rest: {},
  autoClose: false,
  ariaLabel: '',
};

class MultiSelectDropdown extends ValidatorComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.dropdownRef = React.createRef();
  }

  componentDidUpdate() {
    this.resolveDropdownAccissibility();
  }

  resolveDropdownAccissibility() {
    const dropdownNode = ReactDOM.findDOMNode(this.dropdownRef.current);
    if (dropdownNode) {
      const inputElement = dropdownNode.querySelector(
        'span > div > span > input',
      );
      const ulElement = dropdownNode.querySelector('span > div > ul');
      if (ulElement) {
        ulElement.setAttribute('aria-label', 'selected list');
      }

      if (inputElement) {
        const attribute = inputElement.getAttribute('aria-expanded');
        inputElement.setAttribute('role', 'combobox');

        if (attribute && attribute === 'false') {
          inputElement.setAttribute('aria-activedescendant', '');
          if (ulElement) {
            const ulElementID = ulElement.getAttribute('id');
            inputElement.setAttribute('aria-describedby', ulElementID);
          } else {
            inputElement.setAttribute('aria-describedby', '');
          }
        }
      }
    }
  }

  render() {
    const {
      errorMessages,
      validators,
      validatorListener,
      optionWithAction,
      value,
      options,
      defaultValue,
      className,
      footer,
      ariaLabel,
      ...rest
    } = this.props;

    const { isValid } = this.state;
    const controlClassName = classNames({
      'tv-form__control': true,
      'tv-form__control--error': !isValid && this.getErrorMessage().length,
      [className]: !!className,
    });
    return (
      <React.Fragment>
        <MultiSelect
          ref={this.dropdownRef}
          defaultValue={defaultValue}
          className={controlClassName}
          name={rest.name}
          data={options}
          textField="name"
          dataItemKey="value"
          value={value}
          onChange={rest.onChange}
          footer={footer}
          ariaLabelledBy={rest.name}
        />

        <IfComponent
          condition={this.getErrorMessage().length > 0}
          whenTrue={
            <span className="tv-form__error">
              {typeof isValid === 'boolean' && !isValid
                ? this.getErrorMessage()
                : ''}
            </span>
          }
        />
        <span id={rest.name} style={{ display: 'none' }}>
          {ariaLabel}
        </span>
      </React.Fragment>
    );
  }
}

MultiSelectDropdown.propTypes = propTypes;
MultiSelectDropdown.defaultProps = defaultProps;

export default MultiSelectDropdown;
