import React from 'react';
import PropTypes from 'prop-types';
import Translate from '../Translate';

const propTypes = {
  date: PropTypes.shape({}),
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  children: PropTypes.node,
};

const defaultProps = {
  date: PropTypes.shape({}),
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  children: null,
};


const Header = ({
  date, onPrevious, onNext, children,
}) => {
  const { week,displayWeek, year,displayYear } = date;
  return (
    <div className="tk-weekly-calendar__header tk-calendar__header">
      <div className="tk-calendar__title">
        <button
          aria-label='förra vecka'
          onClick={onPrevious}
          className="tk-calendar__button tk-calendar__button--previous"
          tabIndex={0}
        />
        <span>
          <Translate content="calendar.week" /> {displayWeek} {displayYear}
        </span>
        <button
          aria-label='nästa vecka'
          onClick={onNext}
          className="tk-calendar__button tk-calendar__button--next"
          tabIndex={0}
        />
      </div>
      {(children)}
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
