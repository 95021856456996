import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import InformationItem from './InformationItem';
import PrintableAssignmentDetail from './PrintableAssignmentDetail';
import AssignmentFullDetailed from './AssignmentFullDetailed';
import CreateDeviation from '../CreateDeviation';
import DeviationSuccessMessage from '../DeviationSuccessMessage';
import FeedBack from '../FeedBack';
import TimeSheet from '../TimeSheet';
import history from '../../../Share/utils/history';
import {
  IfComponent,
  AssignmentStatus,
  TFVButton,
  Attachment,
  Translate,
  Rating,
  ArrowButton,
  LeftDrawerSlide,
  Modal,
  Dialog,
} from '../../../Share/components';
import {
  assignmentTimeFormat,
  getDateFormated,
  iconForTheServiceType,
  shouldDisplayRatingElement,
  statusStringTraslate,
  isIE,
  checkOnGoingAssignment,
  isSpaceOrEnterPressed,
} from '../../../Share/utils';

import { useHistory, useLocation } from 'react-router';
import { CancelAssignment } from '..';
import NavigationButtons from './NavigationButtons';
import { subStatus, PAGE_TYPES } from '../../../Share/constants';
import { subscribe, unSubscribe } from '../../actions/eventBus';
import StarComponent from '../../../Share/components/StarComponent';
import { convertStringToDate } from '../../../Share/utils/dateUtil';
import { useSelector } from 'react-redux';

const propTypes = {
  assignment: PropTypes.shape({}),
  status: PropTypes.shape({}),
  handleClickTimeReport: PropTypes.func,
  handleOrderRate: PropTypes.func,
  handleClickCancel: PropTypes.func,
  handleGetAttachment: PropTypes.func,
  ratingReasons: PropTypes.array,
  visibleNextPreviousButtons: PropTypes.bool,
  disablePreviousButton: PropTypes.bool,
  disableNextButton: PropTypes.bool,
  onPreviousNextClicked: PropTypes.func,
  onCopyOrder: PropTypes.func,
  from: PropTypes.string,
  onClickMoreNotification: PropTypes.func,
  onEditOrder: PropTypes.func,
  onCopyBooking: PropTypes.func,
};

const defaultProps = {
  assignment: {},
  status: {},
  handleClickTimeReport: () => {},
  handleOrderRate: () => {},
  handleGetAttachment: () => {},
  ratingReasons: [],
  visibleNextPreviousButtons: true,
  disablePreviousButton: false,
  disableNextButton: false,
  onPreviousNextClicked: () => {},
  onCopyOrder: () => {},
  from: '',
  onClickMoreNotification: () => {},
  onEditOrder: () => {},
  onCopyBooking: () => {},
};

const FilterDeviation = (onFilterDeviation, assignment) => {
  const redirectToDeviationReport = ({ data }) => {
    history.push({
      pathname: '/report-quality',
      state: {
        deviation: data[0],
        filtered: true,
      },
    });
  };
  const { OrderIdentifier } = assignment;
  onFilterDeviation({ OrderIdentifier }, redirectToDeviationReport);
};

const FEEDBACK_DUMMY_DATA = {
  1: [
    { name: 'Test rate 1', value: 'Test rate 1' },
    { name: 'Test rate 1', value: 'Test rate 1' },
    { name: 'Test rate 1', value: 'Test rate 1' },
  ],
  2: [
    { name: 'Test rate 2', value: 'Test rate 2' },
    { name: 'Test rate 2', value: 'Test rate 2' },
  ],
  3: [
    { name: 'Test rate 3', value: 'Test rate 3' },
    { name: 'Test rate 3', value: 'Test rate 3' },
  ],
  4: [
    { name: 'Test rate 4', value: 'Test rate 4' },
    { name: 'Test rate 4', value: 'Test rate 4' },
  ],
  5: [
    { name: 'Test rate 5', value: 'Test rate 5' },
    { name: 'Test rate 5', value: 'Test rate 5' },
  ],
};

const AssignmentDetail = ({
  assignment,
  status,
  handleOrderRate,
  handleGetAttachment,
  handleClickTimeReport,
  ratingReasons,
  onClose,
  onEditOrder,
  isVisible,
  onCopyOrder,
  visibleNextPreviousButtons,
  disablePreviousButton,
  disableNextButton,
  from,
  onPreviousNextClicked,
  onFilterDeviation,
  onClickMoreNotification,
  onCopyBooking,
}) => {
  const componentRef = useRef();
  const [showModal, setshowModal] = useState(false);
  const [showDeviationModal, setshowDeviationModal] = useState(false);
  const [showFeedBackModal, setShowFeedBackModal] = useState(false);
  const [showDeviationSuccessModal, setshowDeviationSuccessModal] =
    useState(false);
  const [cancelledOrder, setcancelledOrder] = useState(false);

  const [showTimeReportModal, setshowTimeReportModal] = useState(false);
  const location = useLocation();
  const [showCancelModal, setshowCancelModal] = useState(false);
  const [statusColor, setStatusColor] = useState(status.name);
  const [statusName, setStatusName] = useState(
    assignment.ConsolidatedOrderStatus.StatusName,
  );
  const skills = useSelector(state => state.orderTranslation.translationSkills);
  let targetSkill = skills.find(
    skill => skill.SkillIdentifier === assignment.SkillIdentifierTarget,
  );

  const navigateOnDeviationSuccess = () => {
    if (from === PAGE_TYPES.DASHBOARD) {
      window.location.href = '/';
    } else if (from === PAGE_TYPES.ASSIGNMENT) {
      window.location.href = '/my-assignment';
    }
    return null;
  };
  const isShowInterpreterElement =
    (assignment.OrderStatus === 'fullfilled' ||
      assignment.OrderStatus === 'cancelled' ||
      assignment.OrderStatus === 'late-cancelled' ||
      assignment.OrderStatus === 'accepted') &&
    (assignment.Resource.ResourceFullName !== null ||
      assignment.Resource.ResourceNumber !== null ||
      assignment.PhoneNumberOrder !== null);

  const interpreterElement = (
    <IfComponent
      condition={isShowInterpreterElement}
      whenTrue={
        <React.Fragment>
          <InformationItem
            data={assignment.Resource.ResourceFullName}
            label={Translate({ content: 'taskDetail.interpreterFullName' })}
            icon="user"
          />
        </React.Fragment>
      }
    />
  );

  const handleClickCancel = () => {
    setshowCancelModal(true);
  };

  const contactPersonElement = (
    <React.Fragment>
      <InformationItem
        data={assignment.ContactPerson.ContactPersonName}
        label={Translate({ content: 'taskDetail.fullName' })}
        icon="portrait"
      />
      <InformationItem
        data={assignment.ContactPerson.ContactPersonEmail}
        label=""
        icon="envelope"
      />
      {assignment.ContactPerson.ContactPersonDirectPhoneNumber && (
        <InformationItem
          data={assignment.ContactPerson.ContactPersonDirectPhoneNumber}
          label=""
          icon="handset-2"
        />
      )}
      {assignment.ContactPerson.ContactPersonMobilePhoneNumber !==
      assignment.ContactPerson.ContactPersonDirectPhoneNumber ? (
        <InformationItem
          data={assignment.ContactPerson.ContactPersonMobilePhoneNumber}
          label=""
          icon="mobile-phone"
        />
      ) : null}
      <InformationItem
        data={assignment.CustomerOrderNumber}
        label={Translate({
          content: 'taskDetail.referenceNumber',
        })}
        icon="certificate"
      />
    </React.Fragment>
  );
  const secondaryMessage = (
    <>
      {/* {assignment.subStatusIdentifier && (
        <div
          className={`tv-workassignment__detail-sub-status tv-workassignment__detail-sub-status--${assignment.subStatusIdentifier}`}
        >
          {
            assignment.ConsolidatedOrderStatus
              .ConsolidatedOrderStatusMessages[0].StatusMessage
          }
        </div>
      )} */}
    </>
  );
  const handleModal = () => {
    setshowModal(!showModal);
  };
  const handleTimeReportModal = () => {
    setshowTimeReportModal(!showTimeReportModal);
  };

  const handleFeedBackModal = () => {
    setShowFeedBackModal(!showFeedBackModal);
  };

  const attachments = assignment.Attachments || [];

  const attachmentElement = attachments.map(item => (
    <Attachment
      key={item.AttachmentReference}
      attachmentId={item.AttachmentReference}
      fileName={item.AttachmentFilename}
      type={item.AttachmentContentType}
      onGetAttachment={handleGetAttachment}
    />
  ));

  const isCancelableAssigment =
    moment(assignment.DatetimeFrom, 'YYYY-MM-DD HH:mm:ss') -
      moment(new Date()) >
    0;
  const orderDateTimeRender = assignment => {
    const dateTimeFrom = moment(assignment.DatetimeFrom);
    const dateTimeTo = moment(assignment.DatetimeTo);

    const startDate = getDateFormated(
      convertStringToDate(dateTimeFrom),
      'YYYY-MM-DD',
    );
    const endDate = getDateFormated(
      convertStringToDate(dateTimeTo),
      'YYYY-MM-DD',
    );
    const startTimeStr = moment(dateTimeFrom).format('HH:mm');
    const endTimeStr = moment(dateTimeTo).format('HH:mm');

    let orderDateTimeStr = `${startTimeStr} - ${endTimeStr}, ${startDate}`;
    if (startDate !== endDate) {
      orderDateTimeStr = `${startTimeStr}, ${startDate} - ${endTimeStr}, ${endDate}`;
    }
    // let orderDateTimeStr = `${dateTimeFrom.format(
    //   'HH:mm',
    // )} - ${dateTimeTo.format('HH:mm, DD MMMM YYYY')}`;
    // if (!dateTimeFrom.isSame(dateTimeTo, 'day')) {
    //   orderDateTimeStr = `${dateTimeFrom.format(
    //     'HH:mm, DD MMMM',
    //   )} - ${dateTimeTo.format('HH:mm, DD MMMM YYYY')}`;
    // }
    return orderDateTimeStr;
  };

  const showNewBooking =
    (assignment.DatetimeSubmitted != null ||
      status.titleButtonCancelClient == '') &&
    assignment.subStatusIdentifier !== subStatus.deviationRegistered &&
    assignment.subStatusIdentifier !== subStatus.unexpectedError;

  let timer;

  useEffect(() => {
    const originalTitle = document.title;
    const updateStatusColor = () => {
      const checkonGoingOrder = checkOnGoingAssignment(assignment);
      if (checkonGoingOrder) {
        if (statusColor !== 'onGoing') {
          setStatusColor('onGoing');
          setStatusName('Tolkning pågår');
        }
      } else {
        setStatusColor(status.name);
        setStatusName(assignment.ConsolidatedOrderStatus.StatusName);
      }
      timer = setTimeout(updateStatusColor, 1000);
    };

    updateStatusColor();

    const handleEscapePress = event => {
      if (event.key === 'Escape') {
        if (isVisible) {
          onClose();
        }
      }
    };

    document.addEventListener('keydown', handleEscapePress);
    document.title = assignment.OrderNumber + ' - TFV Kundportal';
    return () => {
      document.removeEventListener('keydown', handleEscapePress);
      if (!isVisible) {
        document.title = ' Översikt - TFV Kundportal';
      }
      document.title = originalTitle;
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [assignment, isVisible, onClose]);

  const onEscapePress = e => {
    if (e.key === 'Escape') {
      e.preventDefault();
      if (showModal) {
        setshowModal(false);
      } else if (showTimeReportModal) {
        setshowTimeReportModal(false);
      } else if (showFeedBackModal) {
        setShowFeedBackModal(false);
      } else if (showDeviationModal) {
        setshowDeviationModal(false);
      } else if (showDeviationSuccessModal) {
        setshowDeviationSuccessModal(false);
      } else if (isVisible) {
        onClose();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onEscapePress);
    return () => {
      document.removeEventListener('keydown', onEscapePress);
    };
  }, [
    showModal,
    showTimeReportModal,
    showFeedBackModal,
    showDeviationModal,
    showDeviationSuccessModal,
    isVisible,
  ]);

  return (
    <>
      {showModal && (
        <AssignmentFullDetailed
          assignment={assignment}
          onHandleModal={handleModal}
          handleClickCancel={handleClickCancel}
          isShowInterpreterElement={isShowInterpreterElement}
          handleClickTimeReport={handleClickTimeReport}
          showModal={showModal}
          isCancelableAssigment={isCancelableAssigment}
          status={status}
          onEditOrder={onEditOrder}
          secondaryMessage={secondaryMessage}
        />
      )}

      {showTimeReportModal && (
        <TimeSheet
          assignmentDetail={assignment}
          onHandleModal={handleTimeReportModal}
          from={from}
          showModal={showTimeReportModal}
        />
      )}
      {showFeedBackModal && (
        <FeedBack
          assignmentDetail={assignment}
          from={from}
          history={history}
          onHandleModal={handleFeedBackModal}
          showModal={showFeedBackModal}
          feedbackData={FEEDBACK_DUMMY_DATA}
        />
      )}
      {showDeviationModal && (
        <Modal
          className="tv-reportquality__modal tv-reportquality_align-unset"
          onCloseClicked={() => {
            setshowDeviationModal(false);
          }}
          onOverlayClick={() => {
            setshowDeviationModal(false);
          }}
          isVisible={showDeviationModal}
          headerClassName={`tv-workassignment__modal-header ${
            isIE ? 'ie-fix-width' : ''
          }`}
          headerText={Translate({
            content: 'report.sendDeviation',
          })}
          ieOptionClassName={isIE ? 'ie-fix-height' : ''}
        >
          {/* Issue : Below "onCloseClick" and "onDeviationSuccess" Props are not fired from child component */}
          <CreateDeviation
            assignment={assignment}
            isGeneral={false}
            onCloseClicked={() => {
              setshowDeviationModal(false);
            }}
            onDeviationSuccess={() => {
              setshowDeviationModal(false);
              setshowDeviationSuccessModal(true);
            }}
          />
        </Modal>
      )}
      {showDeviationSuccessModal && (
        <DeviationSuccessMessage
          isVisible={showDeviationSuccessModal}
          onCloseClicked={() => {
            navigateOnDeviationSuccess();
            setshowDeviationSuccessModal(false);
          }}
          onOverlayClick={() => {
            navigateOnDeviationSuccess();
            setshowDeviationSuccessModal(false);
          }}
        />
      )}
      {showCancelModal && (
        <CancelAssignment
          onClose={() => {
            setshowCancelModal(false);
          }}
          assignmentDetail={assignment}
          isVisible={showCancelModal}
        />
      )}
      {isVisible && (
        <LeftDrawerSlide
          isVisible={isVisible}
          hideClose
          hideExternalScroll
          onClose={onClose}
          className="tk-assignment-detail__drawer"
        >
          <Dialog
            className="tk-assignment-detail__container"
            isVisible
            onClose={onClose}
          >
            <div className="tk-assignment-list-box_header text-center">
              {assignment.OrderNumber}
            </div>
            <div className="tk-assignment-list-box_body p-2">
              <div className="tv-workassignment__detail-wrapper d-flex flex-column h-100">
                <React.Fragment>
                  {assignment.UnreadNotifications != null && (
                    <div className="tv-workassignment__notification">
                      <div className="">
                        <div className="tv-workassignment__notification__title">
                          {assignment.UnreadNotifications[0].Headline}
                        </div>
                        <div
                          role="button"
                          tabIndex={0}
                          onKeyPress={() => {}}
                          onClick={() =>
                            onClickMoreNotification(
                              assignment.UnreadNotifications,
                            )
                          }
                          className="tv-workassignment__notification__more"
                        >
                          Visa historik
                        </div>
                      </div>
                      <div className="tv-workassignment__notification__new-label">
                        {Translate({ content: 'notification.new' })}
                      </div>
                    </div>
                  )}
                  <AssignmentStatus
                    className="tv-workassignment__detail-status tv-display-flex"
                    status={statusColor}
                    statusName={
                      status.name === 'late-cancelled'
                        ? 'Sent avbokad'
                        : statusName
                    }
                  >
                    <div className="d-flex">{`${assignment.ArticleName} ${
                      assignment.IsSpecialAssignment ? '(specialuppdrag)' : ''
                    }`}</div>
                  </AssignmentStatus>
                  <hr />
                  <ul className="tv-workassignment__info-wrapper" role="list">
                    {
                      <>
                        {assignment.IsTranslation === false && (
                          <InformationItem
                            data={assignment.Skill}
                            label=""
                            icon="globe"
                            className={
                              assignment.SkillSubstituteIdentifier &&
                              !assignment.Resource.IsBasedOnSkillSubstitute &&
                              assignment.OrderStatus !== 'available' &&
                              'labelled'
                            }
                          />
                        )}
                        {assignment.IsTranslation === true && (
                          <>
                            <InformationItem
                              data={assignment.Skill}
                              label="Källspråk"
                              icon="globe"
                              className={
                                assignment.SkillSubstituteIdentifier &&
                                !assignment.Resource.IsBasedOnSkillSubstitute &&
                                assignment.OrderStatus !== 'available' &&
                                'labelled'
                              }
                            />
                            <InformationItem
                              data={targetSkill.SkillName}
                              label="Målspråk"
                              icon="language"
                              className={
                                assignment.SkillSubstituteIdentifier &&
                                !assignment.Resource.IsBasedOnSkillSubstitute &&
                                assignment.OrderStatus !== 'available' &&
                                'labelled'
                              }
                            />
                            <InformationItem
                              data={assignment.ModeOfTransport}
                              label=""
                              icon="plane"
                            />
                          </>
                        )}
                        {assignment.SkillSubstituteIdentifier && (
                          <InformationItem
                            data={`Alternativt språk: ${assignment.SkillSubstitute}`}
                            label=""
                            className={
                              assignment.Resource.IsBasedOnSkillSubstitute &&
                              assignment.OrderStatus !== 'available' &&
                              'labelled'
                            }
                          />
                        )}
                        <InformationItem
                          data={assignment.CompetenceLevel}
                          label=""
                          icon="briefcase"
                        />
                        <li className="tv-workassignment__info-item d-flex">
                          <div className="w-100">
                            <span className="col-6 px-0">
                              <i className="fa fa-clock" />
                              {orderDateTimeRender(assignment)}
                            </span>
                          </div>
                        </li>
                        {interpreterElement}

                        {(assignment.MeetingDetails.MeetingPhoneNumber ||
                          assignment.PhoneNumberOrder) && (
                          <InformationItem
                            label={Translate({
                              content: 'taskDetail.phoneNumber',
                            })}
                            icon="handset-2"
                            data={
                              assignment.MeetingDetails.MeetingPhoneNumber ||
                              assignment.PhoneNumberOrder ||
                              '-'
                            }
                          />
                        )}
                        {assignment.MeetingDetails.MeetingUrl && (
                          <InformationItem
                            label=""
                            icon="video"
                            data={assignment.MeetingDetails.MeetingUrl}
                          />
                        )}
                        {assignment.MeetingDetails.MeetingUrl &&
                          assignment.MeetingDetails
                            .DoRequireBankIdVerification === 'True' &&
                          assignment.MeetingDetails
                            .DoRequireBankIdVerification && (
                            <li className="tv-workassignment__info-item d-flex">
                              <span className="tv-workassignment__info-bank-id" />
                              BankID krävs
                            </li>
                          )}
                        {secondaryMessage}
                        {assignment.clientAddress && (
                          <InformationItem
                            data={'address'}
                            label=""
                            icon="home"
                          />
                        )}
                        {assignment.Message && (
                          <InformationItem
                            data={assignment.Message}
                            label={Translate({
                              content: 'singleAssignment.message',
                            })}
                            icon="comment"
                          />
                        )}
                        {assignment.MessageReceiver && (
                          <InformationItem
                            data={assignment.MessageReceiver}
                            label={Translate({
                              content: 'singleAssignment.recipientName',
                            })}
                            icon="user"
                          />
                        )}
                        {assignment.MessageTelephone && (
                          <InformationItem
                            data={assignment.MessageTelephone}
                            label={Translate({
                              content: 'singleAssignment.recipientPhoneNumber',
                            })}
                            icon="phone"
                          />
                        )}
                        {assignment.CustomerOrderAdditionalServices && (
                          <InformationItem
                            data={assignment.AdditionalServicesSummary}
                            label=""
                            icon="bell"
                          />
                        )}
                        {/* {assignment.CustomerOrderAdditionalServices && (
                          <li className="additional-services-added">
                            Om du avbokar tolken efter att SMS-påminnelsen har
                            skickats ut kommer även ett SMS på valt tolkspråk om
                            avbokad tid skickas till patient/klient.
                          </li>
                        )} */}
                        <li>
                          <hr className="mb-0" />
                        </li>

                        <InformationItem
                          data={
                            assignment.ContactPersonOrderer
                              .ContactPersonOrdererName
                          }
                          label={Translate({
                            content: 'taskDetail.orderedPerson',
                          })}
                          icon="user"
                        />
                        {contactPersonElement}
                        {!assignment.CanBeRatedByCustomer && (
                          <InformationItem
                            data={assignment.CanBeRatedByCustomerExplanation}
                            label=""
                            icon="exclamation-circle"
                          />
                        )}
                        {assignment.CustomerInvoiceIdentifier && (
                          <InformationItem
                            data={assignment.CustomerInvoiceName}
                            label=""
                            icon="medkit"
                          />
                        )}
                        <IfComponent
                          condition={assignment.IsPerformedRemotely}
                          whenFalse={
                            <InformationItem
                              data={
                                assignment.IsDeviatingAddressPerforming
                                  ? `OBS! ${assignment.AddressPerformingEffective}`
                                  : assignment.AddressPerformingEffective
                              }
                              label=""
                              className={
                                assignment.IsDeviatingAddressPerforming
                                  ? 'red-text'
                                  : ''
                              }
                              icon="map-marked"
                            />
                          }
                        />
                        <IfComponent
                          condition={assignment.IsHomeAddress}
                          whenTrue={
                            <InformationItem
                              data={Translate({
                                content: 'taskDetail.homeAddress',
                              })}
                              label=""
                              icon="home"
                            />
                          }
                        />
                        {assignment.RatingCustomer != null && (
                          <>
                            {' '}
                            <hr className="mt-0" />
                            <li className="d-flex flex-column tv-workassignment__info-item ">
                              <span>Betyg</span>
                              <div className="tv-workassignment__item-text mt-3">
                                <StarComponent
                                  className="stars"
                                  style={{
                                    '--rating': `${(
                                      (assignment.RatingCustomer
                                        ? assignment.RatingCustomer
                                        : 0) * 20
                                    ).toFixed(1)}%`,
                                  }}
                                  value={
                                    assignment.RatingCustomer
                                      ? assignment.RatingCustomer
                                      : 0
                                  }
                                />
                              </div>
                            </li>
                          </>
                        )}
                        {assignment.CustomerOrderEmailAddress && (
                          <InformationItem
                            data={assignment.CustomerOrderEmailAddress}
                            label=""
                            icon="comments"
                          />
                        )}
                        {assignment.LMANumber && (
                          <InformationItem
                            data={assignment.LMANumber}
                            label=""
                            icon="briefcase"
                          />
                        )}
                      </>
                    }
                    <li
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        marginBottom: '10px',
                      }}
                    >
                      {attachmentElement}
                    </li>
                  </ul>

                  {!assignment.isMessageServiceAssignment && (
                    <div style={{ display: 'none' }}>
                      <div id="print-content">
                        <PrintableAssignmentDetail
                          ref={componentRef}
                          contactPersonElement={contactPersonElement}
                          interpreterElement={interpreterElement}
                          assignment={assignment}
                          status={status}
                          assignmentTimeFormat={assignmentTimeFormat}
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
                <div className="d-flex flex-column mt-auto tv-workassignment__modal-footer">
                  {assignment.IsTranslation === false && (
                    <>
                      <div className="tv-workassignment__detail-buttons d-flex d-lg-none flex-column mb-2 mt-auto pr-2">
                        <TFVButton
                          type="line-primary"
                          text={Translate({ content: 'taskDetail.moreInfo' })}
                          onClick={handleModal}
                          className="tv_assignment_detail__button w-100"
                        />

                        <IfComponent
                          condition={
                            !!(
                              assignment.TimeReport &&
                              assignment.TimeReport.DatetimeOriginalTo &&
                              assignment.TimeReport.DatetimeOriginalFrom
                            )
                          }
                          whenTrue={
                            <TFVButton
                              type="line-primary"
                              text={Translate({
                                content: 'status.timeReport',
                              })}
                              onClick={handleTimeReportModal}
                              className="tv_assignment_detail__button  w-100 mt-1"
                            />
                          }
                        />
                        {/* {!assignment.CanBeRated &&
                          !assignment.HasDeviation &&
                          (assignment.CanBeUpdated ||
                            assignment.CanCancelOrder) && (
                            <TFVButton
                              type="line-primary"
                              text={Translate({
                                content: 'reportQuality.sendDeviation',
                              })}
                              onClick={() => setshowDeviationModal(true)}
                              className="tv_assignment_detail__button  w-100 mt-1"
                            />
                          )} */}
                      </div>
                      <div className=" tv-display-flex tv-workassignment__detail-buttons mt-1 mt-lg-auto mb-5 mb-lg-2 flex-row">
                        <IfComponent
                          condition={
                            assignment.CanCancelOrder &&
                            !shouldDisplayRatingElement(
                              status.name,
                              assignment.DatetimeFrom,
                            )
                          }
                          whenTrue={
                            <TFVButton
                              type="line-primary"
                              text={status.titleButtonCancelClient}
                              onClick={handleClickCancel}
                              className="tv_assignment_detail__button"
                              disabled={
                                !isCancelableAssigment ||
                                !assignment.CanCancelOrder
                              }
                            />
                          }
                        />
                        {assignment.CanBeRated && (
                          <TFVButton
                            type="line-primary"
                            text={Translate({
                              content: 'calendar.feedBack',
                            })}
                            onClick={handleFeedBackModal}
                            className="tv_assignment_detail__button"
                          />
                        )}
                        {assignment.CanBeUpdated && (
                          <TFVButton
                            type="default"
                            text={Translate({
                              content: 'general.editOrder',
                            })}
                            onClick={() => onEditOrder(assignment)}
                            className="tv_assignment_detail__button tv-buttons__element-primary"
                          />
                        )}
                        <IfComponent
                          condition={showNewBooking}
                          whenTrue={
                            <TFVButton
                              type="default"
                              text={Translate({
                                content: 'calendar.copyBooking',
                              })}
                              onClick={() => onCopyOrder(assignment)}
                              className="tv_assignment_detail__button tv-buttons__element-primary"
                            />
                          }
                        />
                        {assignment.HasDeviation && (
                          <TFVButton
                            type="default"
                            text={Translate({
                              content: 'reportQuality.goToDeviation',
                            })}
                            onClick={() =>
                              FilterDeviation(onFilterDeviation, assignment)
                            }
                            className="tv_assignment_detail__button tv-buttons__element-primary"
                          />
                        )}
                        {/* {!assignment.CanBeRated &&
                      !assignment.HasDeviation &&
                      !(
                        assignment.CanBeUpdated || assignment.CanCancelOrder
                      ) && (
                        <TFVButton
                          type="line-primary"
                          text={Translate({
                            content: 'reportQuality.sendDeviation',
                          })}
                          onClick={() => setshowDeviationModal(true)}
                          className="tv_assignment_detail__button "
                        />
                      )} */}
                      </div>
                      {!showNewBooking && (
                        <div className=" tv-display-flex tv-workassignment__detail-buttons mt-1 mt-lg-auto mb-5 mb-lg-2">
                          <TFVButton
                            type="default"
                            text={Translate({
                              content: 'calendar.copyBooking',
                            })}
                            onClick={() => onCopyOrder(assignment)}
                            className="tv_assignment_detail__button tv-buttons__element-primary"
                          />
                        </div>
                      )}
                      {!assignment.isMessageServiceAssignment && (
                        <div className="tv-workassignment__detail-links tv-display-mobile-hide">
                          <div
                            className="tv-workassignment__detail-links-item mx-auto"
                            onClick={handleModal}
                            role="button"
                            tabIndex={0}
                            onKeyDown={e =>
                              isSpaceOrEnterPressed(e, handleModal)
                            }
                          >
                            {Translate({ content: 'taskDetail.moreInfo' })}
                          </div>

                          <IfComponent
                            condition={
                              !!(
                                assignment.TimeReport &&
                                assignment.TimeReport.DatetimeOriginalTo &&
                                assignment.TimeReport.DatetimeOriginalFrom
                              )
                            }
                            whenTrue={
                              <div
                                className="tv-workassignment__detail-links-item mx-auto"
                                onClick={handleTimeReportModal}
                                role="button"
                                tabIndex={0}
                                onKeyDown={e =>
                                  isSpaceOrEnterPressed(
                                    e,
                                    handleTimeReportModal,
                                  )
                                }
                              >
                                {Translate({
                                  content: 'status.timeReport',
                                })}
                              </div>
                            }
                          />
                          {/* {!assignment.CanBeRated &&
                        !assignment.HasDeviation &&
                        (assignment.CanBeUpdated ||
                          assignment.CanCancelOrder) && (
                          <div
                            className="tv-workassignment__detail-links-item mx-auto"
                            onClick={() => setshowDeviationModal(true)}
                          >
                            {Translate({
                              content: 'reportQuality.sendDeviation',
                            })}
                          </div>
                        )} */}
                        </div>
                      )}
                    </>
                  )}
                  <NavigationButtons
                    visibleNextPreviousButtons={visibleNextPreviousButtons}
                    disablePreviousButton={disablePreviousButton}
                    disableNextButton={disableNextButton}
                    onPreviousNextClicked={onPreviousNextClicked}
                  />
                </div>
              </div>
            </div>
          </Dialog>
        </LeftDrawerSlide>
      )}
    </>
  );
};

AssignmentDetail.propTypes = propTypes;
AssignmentDetail.defaultProps = defaultProps;

export default AssignmentDetail;
