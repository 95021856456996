/* eslint-disable */
import React from 'react';
import { Row, Col, TFVButton } from '../../../Share/components';
import FocusTrap from 'focus-trap-react';

const ConfirmUnavailableLanguage = ({ onCloseClicked, textShow }) => {
  return (
    <FocusTrap
      focusTrapOptions={{
        escapeDeactivates: false,
        allowOutsideClick: true,
      }}
    >
      <div
        className={`tv-modal__wrapper tv-display-flex tv-booknow__modal mt-5`}
      >
        <div
          className="tv-modal__overlay"
          onClick={() => {}}
          role="button"
          tabIndex="-1"
          aria-label="modal overlay"
        />
        <div
          className="tv-modal__holder mt-5"
          style={{ minWidth: '22vw', borderRadius: '5px', width: '460px' }}
        >
          <div
            className="tv-booknow__modal-cancel__container"
            style={{ paddingTop: '20px', paddingBottom: '30px' }}
          >
            <Row>
              <Col md={12} sm={12}>
                <div className="text pt-4">{textShow}</div>
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12}>
                <TFVButton
                  type="secondary"
                  buttonType="submit"
                  className="w-100  ml-1"
                  text="Ok"
                  onClick={onCloseClicked}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};

export default ConfirmUnavailableLanguage;
