import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '../../../Share/components';

const propTypes = {
  text: PropTypes.oneOfType([PropTypes.string,PropTypes.node]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  value: PropTypes.string,
  labelColor: PropTypes.string,
  textColor: PropTypes.string,
  checked: PropTypes.bool,
  role: PropTypes.string,
  textTransform: PropTypes.string,
};

const defaultProps = {
  text: null,
  value: '',
  onClick: () => {},
  className: 'tv-status-filter-list',
  labelClassName: '',
  labelColor: 'gray',
  textColor: 'black',
  checked: false,
  role: "list",
  textTransform: '',
};

const FilterItem = ({
  text,
  onClick,
  className,
  labelClassName,
  value,
  labelColor,
  textColor,
  checked,
  role,
  textTransform,
}) => (
  <div className={className}>
    {/* <button className="tv-buttons__type" value={value} onClick={() => onClick(value)}>{text}</button> */}
    <Checkbox
      onClick={onClick}
      className={labelClassName}
      label={text}
      value={value}
      labelColor={labelColor}
      textColor={textColor}
      defaultChecked={checked}
      textTransform={textTransform}
    />
  </div>
);

FilterItem.propTypes = propTypes;
FilterItem.defaultProps = defaultProps;

export default FilterItem;
