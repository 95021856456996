import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Translate, IfComponent } from '../../../Share/components';
import FilterItem from './FilterItem';
import { status } from '../../../Share/constants';
import { isSpaceOrEnterPressed } from '../../../Share/utils';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  selectedFilter: PropTypes.arrayOf(PropTypes.string),
  isDeviation: PropTypes.bool,
};

const defaultProps = {
  selectedFilter: [],
  isDeviation: false,
};

const StatusFilter = props => {
  const { onClick, selectedFilter, isDeviation } = props;

  let selectedStatusItems = selectedFilter;
  const handleFilterChange = event => {
    const { value } = event.target;

    if (selectedStatusItems.includes(value)) {
      const index = selectedStatusItems.indexOf(value);
      selectedStatusItems.splice(index, 1);
    } else {
      selectedStatusItems.push(value);
    }

    if (value !== status.all.name) {
      if (!selectedStatusItems.length) {
        selectedStatusItems.push(status.all.name);
      } else {
        const index = selectedStatusItems.indexOf(status.all.name);
        if (index !== -1) {
          selectedStatusItems.splice(index, 1);
        }
      }
    } else {
      const index = selectedStatusItems.indexOf(value);
      if (index !== -1) {
        selectedStatusItems = [status.all.name];
      }
    }
    if (!selectedStatusItems.length) {
      onClick([]);
    } else {
      onClick(selectedStatusItems);
    }
  };

  const setAllItems = () => {
    if (isDeviation) {
      if (selectedStatusItems.includes(status.all.name)) {
        selectedStatusItems = [
          status.newregistered.name,
          status.underInvestigation.name,
          status.completed.name,
        ];
      } else {
        selectedStatusItems = [status.all.name];
      }
    } else if (selectedStatusItems.includes(status.all.name)) {
      selectedStatusItems = [
        status.available.name,
        status.accepted.name,
        status.fullfilled.name,
        status.cancelled.name,
        status.late_cancelled.name,
        status.rejected.name,
      ];
    } else {
      selectedStatusItems = [status.all.name];
    }
    if (!selectedStatusItems.length) {
      onClick([], 'status');
    } else {
      onClick(selectedStatusItems, 'status');
    }
  };

  const checkItem = item => selectedFilter.includes(item);

  return (
    <div className="tv-status-filter">
      <div className="tv-status-filter__title__wrapper">
        <div className="tv-status-filter__title">
          {Translate({ content: 'dashboard.status' })}
        </div>
        <div
          className="sub"
          onClick={setAllItems}
          role="button"
          tabIndex={0}
          onKeyDown={e => isSpaceOrEnterPressed(e, setAllItems)}
        >
          {selectedStatusItems.includes(status.all.name)
            ? Translate({ content: 'status.allStatus' })
            : Translate({ content: 'status.clearAll' })}
        </div>
      </div>
      <div className="tv-status-filter__area filter-border">
        <IfComponent
          condition={isDeviation}
          whenFalse={
            <>
              <FilterItem
                onClick={handleFilterChange}
                text={Translate({
                  content: 'status.waitingAccept',
                })}
                labelClassName={`label--${status.available.name}`}
                value={status.available.name}
                textColor={status.available.color}
                labelColor="#FFF"
                checked={checkItem(status.available.name)}
                textTransform="none"
              />
              <FilterItem
                onClick={handleFilterChange}
                text={Translate({
                  content: 'status.accepted',
                })}
                labelClassName={`label--${status.accepted.name}`}
                value={status.accepted.name}
                textColor={status.accepted.color}
                labelColor="#FFF"
                checked={checkItem(status.accepted.name)}
                textTransform="none"
              />
              <FilterItem
                onClick={handleFilterChange}
                text={Translate({
                  content: 'status.fullfilled',
                })}
                labelClassName={`label--${status.fullfilled.name}`}
                value={status.fullfilled.name}
                textColor={status.fullfilled.color}
                labelColor="#FFF"
                checked={checkItem(status.fullfilled.name)}
                textTransform="none"
              />
              <FilterItem
                onClick={handleFilterChange}
                text={Translate({
                  content: 'status.cancelled',
                })}
                labelClassName={`label--${status.cancelled.name}`}
                value={status.cancelled.name}
                textColor={status.cancelled.color}
                labelColor="#FFF"
                checked={checkItem(status.cancelled.name)}
                textTransform="none"
              />
              <FilterItem
                onClick={handleFilterChange}
                text={Translate({
                  content: 'status.lateCancelled',
                })}
                labelClassName={`label--${status.late_cancelled.name}`}
                value={status.late_cancelled.name}
                textColor={status.late_cancelled.color}
                labelColor="#FFF"
                checked={checkItem(status.late_cancelled.name)}
                textTransform="none"
              />

              <FilterItem
                onClick={handleFilterChange}
                text={Translate({
                  content: 'status.rejected',
                })}
                labelClassName={`label--${status.rejected.name}`}
                value={status.rejected.name}
                textColor={status.rejected.color}
                labelColor="#FFF"
                checked={checkItem(status.rejected.name)}
                textTransform="none"
              />
            </>
          }
          whenTrue={
            <>
              <FilterItem
                onClick={handleFilterChange}
                text={Translate({
                  content: 'status.received',
                })}
                labelClassName={`label--${status.newregistered.name}`}
                value={status.newregistered.name}
                textColor={status.newregistered.color}
                labelColor="#FFF"
                checked={checkItem(status.newregistered.name)}
              />
              <FilterItem
                onClick={handleFilterChange}
                text={Translate({
                  content: 'status.underInvestigation',
                })}
                labelClassName={`label--${status.underInvestigation.name.replace(
                  /\s/g,
                  '',
                )}`}
                value={status.underInvestigation.name}
                textColor={status.underInvestigation.color}
                labelColor="#FFF"
                checked={checkItem(status.underInvestigation.name)}
              />
              <FilterItem
                onClick={handleFilterChange}
                text={Translate({
                  content: 'status.completed',
                })}
                labelClassName={`label--${status.completed.name}`}
                value={status.completed.name}
                textColor={status.completed.color}
                labelColor="#FFF"
                checked={checkItem(status.completed.name)}
              />
            </>
          }
        />
      </div>
    </div>
  );
};

StatusFilter.propTypes = propTypes;
StatusFilter.defaultProps = defaultProps;

export default StatusFilter;
