import React, { useState, useRef, useEffect } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import Scrollbars from 'react-custom-scrollbars';
import {
  Row,
  Col,
  Container,
  TFVButton,
  Translate,
} from '../../../Share/components';
import { TFV_EMAIL_INFO } from '../../../Share/constants';
import './style.scss';
import faq from './faq';

const ContactTFV = props => {
  const [showAccordian, setAccordianState] = useState({
    'rowOne-columnOne': false,
    'rowOne-columnTwo': false,
    'rowTwo-columnOne': false,
    'rowTwo-columnTwo': false,
    'rowThree-columnOne': false,
    'rowThree-columnTwo': false,
  });
  useEffect(() => {
    document.title = `Kontakta oss - TFV Kundportal
    `;
  }, []);

  const handleShowAccordian = id => {
    setAccordianState({
      [id]: !showAccordian[id],
    });
  };

  const handleMailClick = e => {
    e.preventDefault();
    window.location.href = `mailto:${TFV_EMAIL_INFO}`;
  };

  const displayIcons = id => {
    if (showAccordian[id]) {
      return <IoIosArrowUp />;
    }
    return <IoIosArrowDown />;
  };
  const scrollRef = useRef(null);
  return (
    <>
      <div className="page_content">
        <div className="page-title">
          <div className="title" aria-label="Kontakta oss">
            Kontakta oss
          </div>
          <div className="contact_now_button_wrapper">
            <TFVButton
              type="line-primary"
              text={Translate({
                content: 'settings.sendTFVAnEmail',
              })}
              className="contact_now_button"
              onClick={handleMailClick}
            />
          </div>
        </div>
        <div className="contact_card_wrapper">
          <img
            src="/images/contact-cover.png"
            className="contact_card_wrapper__heading-image"
            aria-label="contact-page-cover"
          />
          <div className="contact_card_wrapper__details">
            <div>
              <div className="page-title__new">Telefon</div>
              <div className="page-section-body__new">0770-813 000</div>
            </div>
            <div>
              <div className="page-title__new">Postadress</div>
              <div className="page-section-body__new">Tolkförmedling Väst</div>
              <div className="page-section-body__new">Box 113 05</div>
              <div className="page-section-body__new">404 27 Göteborg</div>
            </div>
            <div>
              <div className="page-title__new">Besöksadress</div>
              <div className="page-section-body__new">
                Johan på Gårdas gata 5A, 4 tr
              </div>
              <div className="page-section-body__new">412 50 Göteborg</div>
            </div>
            <div className="page-title__new">
              <a
                role="link"
                tabindex={0}
                href="https://www.tolkformedlingvast.se/ovrigt/kontakta-oss.html"
                target="_blank"
                aria-label="Övrig kontaktinformation"
              >
                Övrig kontaktinformation
              </a>
            </div>
          </div>

          <div className="page-title" aria-label="Frågor och svar">
            Frågor och svar
          </div>
          <div className="question-and-answer-text">
            <a
              role="link"
              tabindex={0}
              href="https://www.tolkformedlingvast.se/vara-tjanster/spraktolkning/vanliga-fragor-och-svar.html"
              target="_blank"
              aria-label="Vanliga frågor och svar"
            >
              Vanliga frågor och svar
            </a>
          </div>
          {/* <div className="faq_wrapper">
            {Object.keys(faq.faqData).map(row => (
              <Row key={row}>
                {faq.faqData[row].map(column => (
                  <Col md={6} sm={12} key={column}>
                    <div className="drop_accordian">
                      <div className="question_content" role="">
                        <div
                          className="heading"
                          tabindex={0}
                          onClick={() => handleShowAccordian(column.id)}
                          onKeyPress={e =>
                            e.key === 'Enter' || e.key === ' '
                              ? handleShowAccordian(column.id)
                              : ''
                          }
                          role="button"
                          aria-expanded={
                            showAccordian[column.id] ? 'true' : 'false'
                          }
                        >
                          <div className="single-question">
                            {column.question}
                          </div>
                          <div className="icon_wrapper">
                            {displayIcons(column.id)}
                          </div>
                        </div>
                        <div
                          className="body"
                          style={{
                            display: showAccordian[column.id] ? 'flex' : 'none',
                          }}
                          aria-live="polite"
                          id={column}
                        >
                          {column.answer &&
                            column.answer.split('||').map(x => <p>{x}</p>)}
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            ))}
          </div> */}
        </div>
        <div className="page-footer">
          <div className="d-flex mt-auto">
            <img
              width={190}
              height={40}
              src="/images/logo.png"
              alt="footer-image"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactTFV;

const ContactCard = ({
  addressLine,
  imageUrl,
  place,
  addressCityPostCode,
  customerServiceNumber,
}) => (
  <>
    <div className="w-100 contact_card__item">
      <div className="generic_card">
        <div className="contact_image">
          <img src={imageUrl} alt={place} />
        </div>
        <div className="d-flex flex-column">
          <div className="contact_city">{place}</div>
          <div className="contact_address_wrapper">
            <div className="contact_address_wrapper_element">{addressLine}</div>
            <div className="contact_address_wrapper_element">
              {addressCityPostCode}
            </div>
          </div>
          <div className="contact_method_wrapper">
            <div className="mr-2 contact_method_wrapper-name">Kundtjänst:</div>
            <div className="contact_method_wrapper-number">
              <a href={`tel:${customerServiceNumber}`}>
                {customerServiceNumber}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);
