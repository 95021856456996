import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-form-validator-core';
import {
  Translate,
  TFVButton,
  TextField,
  DropdownList,
} from '../../../Share/components';
import { login } from '../../actions';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerNumber: '',
      password: '',
      submitDisabled: true,
      customerEmail: '',
      selected: 0,
    };
  }
  componentDidMount() {
    document.title = 'Logga in - TFV Kundportal';
    window.addEventListener('load', this.handleLoad);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.handleLoad);
  }

  handleLoad = () => {
    document.title = 'Logga in - TFV Kundportal';
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { customerNumber, password, customerEmail } = this.state;
    this.props.onLogin(customerNumber, password, customerEmail);
  };

  handleValidatorListener = () => {
    this.form.isFormValid().then(isValid => {
      this.setState({ submitDisabled: !isValid });
    });
  };

  handleSelect = e => {
    this.setState({ selected: e.selected });
  };

  render() {
    const { selected } = this.state;

    return (
      <>
        <div className="tv-login__wrapper tv-display-flex">
          <div className="tv-login__form tv-login__form-wrapper">
            <h3 className="tv-login__title">
              <Translate content="login.title" />
            </h3>
            <TabStrip selected={selected} onSelect={this.handleSelect}>
              <TabStripTab title="Enhetsinloggning">
                <ValidatorForm
                  ref={node => (this.form = node)}
                  onSubmit={this.handleSubmit}
                  onError={this.handleValidatorListener}
                  className="tv-login__form"
                >
                  <label
                    htmlFor="customerNumber"
                    className="tv-login__form-email-lablel tv-login__form-label"
                  >
                    <Translate content="general.customerNumber" />
                  </label>
                  <TextField
                    validatorListener={this.handleValidatorListener}
                    onChange={this.handleChange}
                    className="tx-input-text tv-login__form-email-input tv-login__controller"
                    aria-label={Translate({
                      content: 'general.customerNumber',
                    })}
                    name="customerNumber"
                    id="customerNumber"
                    maxLength={300}
                    value={this.state.customerNumber}
                    placeholder={Translate({
                      content: 'login.enterYourCustomerNumber',
                    })}
                    validators={['required', 'isNumber']}
                    errorMessages={[
                      Translate({ content: 'login.customerNumberIsRequire' }),
                      Translate({ content: 'login.customerNumberIsNotValid' }),
                    ]}
                    autoFocus
                  />
                  <label
                    htmlFor="password"
                    className="tv-login__form-interpreter-id-label tv-login__form-label"
                  >
                    <Translate content="general.password" />
                  </label>
                  <TextField
                    type="password"
                    validatorListener={this.handleValidatorListener}
                    onChange={this.handleChange}
                    className="tx-input-text tv-login__form-interpreterId-input tv-login__controller"
                    name="password"
                    id="password"
                    aria-label={Translate({ content: 'general.password' })}
                    value={this.state.password}
                    placeholder={Translate({
                      content: 'login.enterYourPassword',
                    })}
                    validators={['minStringLength:8']}
                    errorMessages={[
                      Translate({ content: 'login.minLengthMessage' }),
                    ]}
                  />

                  <TFVButton
                    type="primary"
                    buttonType="submit"
                    text={Translate({ content: 'login.login' })}
                    className="tv-login__form-submit"
                    onClick={this.handleSubmit}
                    disabled={this.state.submitDisabled}
                  />
                </ValidatorForm>
              </TabStripTab>
              <TabStripTab title="Personlig inloggning">
                <ValidatorForm
                  ref={node => (this.form = node)}
                  onSubmit={this.handleSubmit}
                  onError={this.handleValidatorListener}
                  className="tv-login__form"
                >
                  <label
                    htmlFor="customerNumber"
                    className="tv-login__form-email-lablel tv-login__form-label"
                  >
                    <Translate content="general.customerNumber" />
                  </label>
                  <TextField
                    validatorListener={this.handleValidatorListener}
                    onChange={this.handleChange}
                    className="tx-input-text tv-login__form-email-input tv-login__controller"
                    aria-label={Translate({
                      content: 'general.customerNumber',
                    })}
                    name="customerNumber"
                    id="customerNumber"
                    maxLength={300}
                    value={this.state.customerNumber}
                    placeholder={Translate({
                      content: 'login.enterYourCustomerNumber',
                    })}
                    validators={['required', 'isNumber']}
                    errorMessages={[
                      Translate({ content: 'login.customerNumberIsRequire' }),
                      Translate({ content: 'login.customerNumberIsNotValid' }),
                    ]}
                    autoFocus
                  />
                  <label
                    htmlFor="customerEmail"
                    className="tv-login__form-interpreter-id-label tv-login__form-label"
                  >
                    <Translate content="general.customerEmail" />
                  </label>
                  <TextField
                    validatorListener={this.handleValidatorListener}
                    onChange={this.handleChange}
                    className="tx-input-text tv-login__form-email-input tv-login__controller"
                    aria-label={Translate({ content: 'general.customerEmail' })}
                    name="customerEmail"
                    id="customerEmail"
                    maxLength={300}
                    value={this.state.customerEmail}
                    placeholder="Ange E-postadress"
                    validators={['isEmail']}
                    errorMessages={['Customer email is not valid']}
                  />
                  <label
                    htmlFor="password"
                    className="tv-login__form-interpreter-id-label tv-login__form-label"
                  >
                    <Translate content="general.password" />
                  </label>
                  <TextField
                    type="password"
                    validatorListener={this.handleValidatorListener}
                    onChange={this.handleChange}
                    className="tx-input-text tv-login__form-interpreterId-input tv-login__controller"
                    name="password"
                    id="password"
                    aria-label={Translate({ content: 'general.password' })}
                    value={this.state.password}
                    placeholder={Translate({
                      content: 'login.enterYourPassword',
                    })}
                    validators={['minStringLength:8']}
                    errorMessages={[
                      Translate({ content: 'login.minLengthMessage' }),
                    ]}
                  />

                  <TFVButton
                    type="primary"
                    buttonType="submit"
                    text={Translate({ content: 'login.login' })}
                    className="tv-login__form-submit"
                    onClick={this.handleSubmit}
                    disabled={this.state.submitDisabled}
                  />
                </ValidatorForm>
              </TabStripTab>
            </TabStrip>
          </div>
        </div>

        <NavLink
          exact
          activeClassName="nav-active"
          to="/forgot-password"
          className="nav-item tv-login__reset-password"
        >
          <Translate content="login.resetPasswordLink" />
        </NavLink>
        
          <a className="tv-login__accessibility-statement-link"
            role="link"
            tabindex={0}
            href="https://tolkformedlingvast.se/om-tfv/om-webbplatsen/tillganglighetsredogorelse.html"
            target="_blank"
            aria-label={Translate({ content: 'login.accessibilityStatementLink' })}
          >
            <Translate content="login.accessibilityStatementLink" />
          </a>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLogin: (customerNumber, password, customerEmail) => {
    dispatch(login(customerNumber, password, customerEmail));
  },
});

export default connect(null, mapDispatchToProps)(Login);
