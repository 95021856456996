import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.any,
  labelColor: PropTypes.string,
  textColor: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  textTransform: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
const defaultProps = {
  label: null,
  className: '',
  value: '',
  labelColor: '',
  textColor: '',
  defaultChecked: false,
  disabled: false,
  onClick: () => {},
  textTransform: '',
  tooltip: null,
};

class Checkbox extends Component {
  onClickHandler = event => {
    this.props.onClick(event);
  };

  render() {
    const {
      label,
      value,
      labelColor,
      textColor,
      className,
      defaultChecked,
      disabled,
      handleChange,
      textTransform,
      tooltip,
    } = this.props;
    return (
      <div className="tv-control tv-checkbox">
        <input
          className="custom-control-input"
          checked={defaultChecked}
          disabled={disabled}
          id={`check-${value}`}
          type="checkbox"
          value={value}
          onClick={this.onClickHandler}
          onChange={handleChange}
          aria-label={typeof label === 'string' ? label : `${value} Stjärna`}
        />
        <label
          className={`custom-control-label ${
            defaultChecked ? 'tv-checkbox-active' : 'tv-checkbox-inactive'
          } ${className}`}
          style={{
            background: labelColor,
            color: textColor,
            textTransform: textTransform,
          }}
          htmlFor={`check-${value}`}
        >
          {label} {tooltip}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
