import React, { useRef, useEffect } from 'react';
import './style.scss';

const SkipLinks = ({
  firstLinkFocused,
  secondLinkFocused,
  handleEnterKeyPress,
}) => {
  const firstLinkRef = useRef(null);
  const secondLinkRef = useRef(null);

  useEffect(() => {
    if (firstLinkFocused) {
      firstLinkRef.current.focus();
    } else if (secondLinkFocused) {
      secondLinkRef.current.focus();
    }
  }, [firstLinkFocused, secondLinkFocused]);

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (firstLinkFocused) {
        handleEnterKeyPress('navigation');
      } else if (secondLinkFocused) {
        handleEnterKeyPress('main-content');
      }
    }
  };

  const handleEnterPress = event => {
    if (firstLinkFocused) {
      handleEnterKeyPress('navigation');
    } else if (secondLinkFocused) {
      handleEnterKeyPress('main-content');
    }
  };

  return (
    <div className="skip-links-container">
      <button
        ref={firstLinkRef}
        onClick={handleEnterPress}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        className="skip-link"
      >
        Gå till navigering
      </button>
      <button
        ref={secondLinkRef}
        onClick={handleEnterPress}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        className="skip-link"
      >
        Gå till huvudinnehåll
      </button>
      {/* <a
        ref={firstLinkRef}
        // href="#navigation"
        onClick={handleKeyDown}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        className="skip-link"
      >
        Navigation
      </a>
      <a
        ref={secondLinkRef}
        // href="#main-content"
        onKeyDown={handleKeyDown}
        onClick={handleKeyDown}
        tabIndex={0}
        className="skip-link"
      >
        Main content
      </a> */}
    </div>
  );
};

export default SkipLinks;
