import FocusTrap from 'focus-trap-react';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Translate } from '../../../Share/components';
import { createNewOrderTranslation } from '../../actions/OrderTranslation';
import { useEffect } from 'react';

function PreviewDetail({
  setShowPreview,
  setShowSuccessModal,
  setShowTranslationForm,
  formData,
  setShowCancelTranslationModal,
  onCreateNewOrder,
}) {
  const displaySuccessModal = useSelector(
    state => state.orderTranslation.showResultModal,
  );

  useEffect(() => {
    if (displaySuccessModal) {
      setShowPreview(false);
      setShowSuccessModal(true);
    }
  }, [displaySuccessModal]);

  const handleSubmit = () => {
    onCreateNewOrder(formData);
  };

  return (
    <>
      <div className="tv-modal__wrapper tv-display-flex tv-route-leaving-guard-modal">
        <div className="tv-modal__overlay"></div>
        <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
          <div className="tv-modal__container" aria-live="polite">
            <div className="tv-modal__container__header">
              <div className="tv-modal__container__header-title">
                <Translate content="emailTranslation.orderInformation" />
              </div>
              <button
                aria-label={Translate({ content: 'general.close' })}
                className="tv-buttons__type icon-close"
                onClick={() => setShowCancelTranslationModal(true)}
              ></button>
            </div>
            <hr className="tv-modal__container__divider" />
            <div className="tv-modal__container__body">
              <div className="tv-modal__container__section-2">
                <div className="tv-modal__container__card">
                  <div className="tv-modal__container__card-label">
                    <i class="fa fa-globe" aria-hidden="true" />
                    <Translate content="singleEmailTranslation.sourceLanguage" />
                  </div>
                </div>
                <div className="tv-modal__container__card right-column">
                  <div className="tv-modal__container__card-value">
                    {formData.sourceLanguage.name}
                  </div>
                </div>
              </div>
              <div className="tv-modal__container__section-2">
                <div className="tv-modal__container__card">
                  <div className="tv-modal__container__card-label">
                    <i class="fa fa-language" aria-hidden="true" />
                    <Translate content="singleEmailTranslation.targetLanguages" />
                  </div>
                </div>
                <div className="tv-modal__container__card right-column">
                  <div className="tv-modal__container__card-value-2">
                    {formData.targetLanguages.map(item => (
                      <div style={{ marginBottom: '2px' }}>{item.name}</div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="tv-modal__container__section-2">
                <div className="tv-modal__container__card">
                  <div className="tv-modal__container__card-label">
                    <i class="fa fa-globe" aria-hidden="true" />
                    <Translate content="singleEmailTranslation.contactPersonOrderer" />
                  </div>
                </div>
                <div className="tv-modal__container__card right-column">
                  <div className="tv-modal__container__card-value">
                    {formData.contactPersonIdentifierOrderer.name}
                  </div>
                </div>
              </div>
              <div className="tv-modal__container__section-2">
                <div className="tv-modal__container__card">
                  <div className="tv-modal__container__card-label">
                    <i class="fa fa-paper-plane" aria-hidden="true" />
                    <Translate content="singleEmailTranslation.deliveryPlans" />
                  </div>
                </div>
                <div className="tv-modal__container__card right-column">
                  <div className="tv-modal__container__card-value">
                    {formData.deliveryPlan.name}
                  </div>
                </div>
              </div>
              {formData.deliveryPlan.value ==
                'Leveransdatum enligt överenskommelse' && (
                <div className="tv-modal__container__section-2">
                  <div className="tv-modal__container__card">
                    <div className="tv-modal__container__card-label">
                      <i class="fa fa-calendar" aria-hidden="true" />
                      <Translate content="singleEmailTranslation.deliveryDate" />
                    </div>
                  </div>
                  <div className="tv-modal__container__card right-column">
                    <div className="tv-modal__container__card-value">
                      {formData.deliveryDate.ranges.startDate.format(
                        'YYYY-MM-DD',
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="tv-modal__container__section-2">
                <div className="tv-modal__container__card">
                  <div className="tv-modal__container__card-label">
                    <i class="fa fa-plane" aria-hidden="true" />
                    <Translate content="singleEmailTranslation.modeOfDelivery" />
                  </div>
                </div>
                <div className="tv-modal__container__card right-column">
                  <div className="tv-modal__container__card-value">
                    {formData.modeoFDelivery.name}
                  </div>
                </div>
              </div>
              {formData.description && (
                <div className="tv-modal__container__section-2">
                  <div className="tv-modal__container__card">
                    <div className="tv-modal__container__card-label">
                      <i class="fa fa-comment" aria-hidden="true" />
                      <Translate content="singleEmailTranslation.description" />
                    </div>
                  </div>
                  <div className="tv-modal__container__card right-column">
                    <div className="tv-modal__container__card-value">
                      {formData.description}
                    </div>
                  </div>
                </div>
              )}
              <div className="tv-modal__container__section-2">
                <div className="tv-modal__container__card">
                  <div className="tv-modal__container__card-label">
                    <i class="fa fa-quote-left" aria-hidden="true" />
                    <Translate content="singleEmailTranslation.needAQuote" />
                  </div>
                </div>
                <div className="tv-modal__container__card right-column">
                  <div className="tv-modal__container__card-value">
                    {formData.needAQuote == false ? (
                      <div>Nej</div>
                    ) : (
                      <div>Ja</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="tv-modal__container__section-2">
                <div className="tv-modal__container__card">
                  <div className="tv-modal__container__card-label">
                    <i class="fa fa-file" aria-hidden="true" />
                    <Translate content="singleEmailTranslation.files" />
                  </div>
                </div>
                <div className="tv-modal__container__card right-column">
                  {formData.attachments.length > 0 ? (
                    formData.attachments.map(item => (
                      <div className="tv-modal__container__single-attachment">
                        <div className="tv-modal__container__attachment">
                          <div className="tv-modal__container__attachment__file-path">
                            {item.name}
                          </div>
                          <div className="tv-modal__container__attachment__text">
                            <Translate content="singleEmailTranslation.isDocumentSubjectToConfidentiality" />{' '}
                            :
                            {item.isDocumentSubjectToConfidentiality ==
                            false ? (
                              <div> Nej</div>
                            ) : (
                              <div> Ja</div>
                            )}
                          </div>
                          <div className="tv-modal__container__attachment__text">
                            <Translate content="singleEmailTranslation.requestForStampedDocumentPreview" />{' '}
                            :
                            {item.requestForStampedDocument == false ? (
                              <div> Nej</div>
                            ) : (
                              <div> Ja</div>
                            )}
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <div className="tv-modal__container__card-value">
                      <Translate content="singleEmailTranslation.noFiles" />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="tv-route-leaving-guard-modal__buttons tv-display-flex px-2">
              <button
                type="button"
                onClick={() => {
                  setShowPreview(false);
                  setShowTranslationForm(true);
                }}
                className="tv-buttons__element tv-buttons__element--line-primary  tv-route-leaving-guard-modal-area-backBtn"
              >
                <Translate content="emailTranslation.goBack" />
              </button>
              <button
                className="tv-buttons__element tv-buttons__element--secondary  tv-route-leaving-guard-modal-area-submitBtn"
                onClick={handleSubmit}
              >
                <Translate content="emailTranslation.confirm" />
              </button>
            </div>
          </div>
        </FocusTrap>
      </div>
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  onCreateNewOrder: translationOrder => {
    return dispatch(createNewOrderTranslation(translationOrder));
  },
});

export default connect(null, mapDispatchToProps)(PreviewDetail);
