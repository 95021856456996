import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  value: PropTypes.bool,
};

const defaultProps = {
  text: '',
  onChange: () => {},
  className: '',
  disabled: false,
  checked: false,
  value: false,
};

const TFVCheckbox = ({
  text,
  onChange,
  className,
  disabled,
  checked,
  value,
}) => (
  <label className={`tv-form__checkbox-container ${className}`}>
    <input
      disabled={disabled}
      type="checkbox"
      onChange={onChange}
      defaultChecked={checked}
      value={value}
    />
    <span className="tv-form__checkbox-checkmark" />
    {text}
  </label>
);

TFVCheckbox.propTypes = propTypes;
TFVCheckbox.defaultProps = defaultProps;

export default TFVCheckbox;
