import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Chart, ChartLegend, ChartSeries, ChartSeriesItem, ChartSeriesLabels } from '@progress/kendo-react-charts';
import { ThreeDotLoader, Translate } from '../../../Share/components';
import { kFormatter, numberFormat, summationBasedOnKey } from '../../../Share/utils/reportUtil';
import { connect } from 'react-redux';

const FulFillmentChartWidget = ({ fulfillmentData, dataReadyFlag }) => {
  const totalFullfiled = summationBasedOnKey(fulfillmentData, 'fullfiled');
  const totalAcceptedAndCancelled = summationBasedOnKey(fulfillmentData, 'acceptedAndCancelled');
  const totalAcceptedNotCancelledNotCompleted = summationBasedOnKey(fulfillmentData, 'acceptedNotCancelledNotCompleted');
  const totalNotAccepted = summationBasedOnKey(fulfillmentData, 'notAccepted');
  const totalAccepted = summationBasedOnKey(fulfillmentData, 'accepted');
  const totalNotFulFilled = summationBasedOnKey(fulfillmentData, 'notFulFilled');
  const totalCanceledByCustomer = summationBasedOnKey(fulfillmentData, 'cancelledByCustomer');
  const totalAcceptedNotfulfilled = summationBasedOnKey(fulfillmentData, 'acceptedNotFullfiled');


  const chartData = [
    { category: 'Accepterad', value: totalAccepted },
    { category: 'Inte accepterad', value: totalNotAccepted }];
  return (
    <div className="auto-row-2">
      <div className="exact-col-1" style={{ height: '40px' }}>
        {/* <NavLink to="/reports/10" style={{}}>
          <span
            className="k-icon k-i-more-horizontal"
            style={{
              float: 'right',
              paddingTop: '10px',
              color: '#322878',
              fontSize: '1.3rem',
            }}
          />
        </NavLink> */}
      </div>
      <div className="exact-col-2">
        <div className="dashboard__display-card round-border">
          <div className="dashboard__display-card-header" style={{ float: 'left' }}>
            <span style={{ float: 'left' }} >{Translate({ content: 'report.orderDistributionByStatus' })}</span>
            <span
              style={{ float: 'right', paddingRight: '10px', fontSize: '1.2rem' }}
              title={(summationBasedOnKey(fulfillmentData,'noOfAssignments'))}
            >
              {kFormatter(summationBasedOnKey(fulfillmentData,'noOfAssignments'))}
            </span>
          </div>

          <div style={{ display: 'grid', width: '100%' }}>
            <div style={{
              width: '100%', float: 'left', alignSelf: 'center', backgroundColor: 'transparent',
            }}
            />
            {dataReadyFlag ?
            <React.Fragment>
              <Chart style={{
                width: '100%', float: 'left', alignSelf: 'center', backgroundColor: 'transparent',
              }}
              >
                <ChartLegend position="bottom" orientation="verticle" labels={{ content: labelContent }} />
                <ChartSeries>
                  <ChartSeriesItem
                    type="pie"
                    overlay={{
                      gradient: 'roundedBevel',
                    }}
                    tooltip={{ visible: true }}
                    data={chartData}
                    categoryField="category"
                    field="value"
                  />

                </ChartSeries>
              </Chart>
</React.Fragment>

              : <ThreeDotLoader />}
          </div>
        </div>
        <div >
          {dataReadyFlag ?
            <React.Fragment>
              <SmallDetailedCard
                style={{ marginBottom: '15px' }}
                heading={Translate({ content: 'report.accepted' })}
                formatedContent={`${numberFormat(totalAccepted)}`}
                actualContent={totalAccepted}
              />
              <SmallDetailedCard
                heading={Translate({ content: 'report.canceledByCustomer' })}
                formatedContent={`${numberFormat(totalCanceledByCustomer)}`}
                actualContent={totalCanceledByCustomer}
              />
              <div className="dashboard__chart-holder" style={{ border: '1px solid #efefef' }}>
                <div className="auto-row-2">
                  <div style={{ padding: '15px 15px 15px 15px' }}>
                    <span style={{ float: 'left', fontSize: '1.2rem' }} >Totalt beställda men ej utförda</span>
                    <span style={{ float: 'right', paddingRight: '10px', fontSize: '1.2rem' }}>
                      {numberFormat(totalNotFulFilled)}
                    </span>
                  </div>
                  <div className="exact-col-2">
                    <SmallDetailedCard
                      heading={Translate({ content: 'report.notAccepted' })}
                      formatedContent={`${numberFormat(totalNotAccepted)}`}
                      actualContent={totalNotAccepted}
                    />
                    <SmallDetailedCard
                      heading={Translate({ content: 'report.acceptedNotfullfilled' })}
                      formatedContent={`${numberFormat(totalAcceptedNotfulfilled)}`}
                      actualContent={totalAcceptedNotfulfilled}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
            : <ThreeDotLoader style={{ marginTop: '60px' }} />}
        </div>
      </div>
    </div>
  );
};

FulFillmentChartWidget.propTypes = {

};
const mapStateToProps = (state, props) => {
  const dataReadyFlags = [state.reportDataSources[1].dataReadyFlags[0], state.reportDataSources[10].dataReadyFlags[1]];
  return ({
    dataReadyFlag: dataReadyFlags[1] || false,
    fulfillmentData: [...state.reportDataSources[10].crossFilterData[1] || []],
  });
};
export default connect(mapStateToProps, null)(FulFillmentChartWidget);

const labelContent = (props) => {
  const formatedNumber = Number(props.percentage).toLocaleString('se-SV', { style: 'percent', minimumFractionDigits: 1 });
  return `${props.text} : ${formatedNumber}`;
};

const SmallDetailedCard = ({
  heading, formatedContent, actualContent, style,
}) => (
  <React.Fragment>
    <div className="dashboard__samll-display-card round-border" style={{ ...style }}>
      <div className="dashboard_samll-detail-card">

        <div className="dashboard_samll-detail-card-header">
          <span style={{ float: 'left' }} title={heading}>
            {heading}
          </span>
        </div>
        <div className="dashboard_samll-detail-card-content" style={{ fontSize: '1.2rem', textAlign: 'end' }}>
          <span title={actualContent}>

            {formatedContent}
          </span>

        </div>
      </div>
    </div>
  </React.Fragment>);

SmallDetailedCard.propTypes = {
  style: PropTypes.shape({}),
  heading: PropTypes.string.isRequired,
  formatedContent: PropTypes.string.isRequired,
  actualContent: PropTypes.number.isRequired,
};
