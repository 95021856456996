import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const propTypes = {
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  component: PropTypes.string,
  onKeyUp: PropTypes.func,
};

const defaultProps = {
  placeholder: '',
  onBlur: () => {},
  onKeyUp: () => {},
  component: 'search',
};

const SearchBox = React.forwardRef(
  ({ placeholder, onBlur, component, className, onKeyUp }, ref) => (
    <div className={`tv-search-box ${className}`}>
      <input
        onBlur={(e) => {
          if (ref) {
            ref.current.classList.remove('search-box-focus')
          }
          onBlur(e);
        }}
        placeholder={placeholder}
        type="text"
        onKeyUp={e => {
          if (e.key === 'Tab') {
            ref.current.classList.add('search-box-focus')
          }
          onKeyUp(e);
        }}
        ref={ref}
        aria-label="Sök bokning"
        aria-autocomplete="both"
      />
      {component === 'search' && <i className="fa fa-search" />}
    </div>
  ),
);

SearchBox.propTypes = propTypes;
SearchBox.defaultProps = defaultProps;

export default SearchBox;
