import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, NavLink } from 'react-router-dom';
import {
  Translate,
  TFVButton,
  ReasonList,
  Modal,
  DropdownList,
  Checkbox,
} from '../../../Share/components';
import { getReasonList, cancelOrder } from '../../actions';
import './style.scss';
import { property } from 'lodash';
import { isIE } from '../../../Share/utils';
import ValidatorForm from 'react-form-validator-core/lib/ValidatorForm';

const propTypes = {
  reasonList: PropTypes.arrayOf(PropTypes.shape({})),
  onGetReasonList: PropTypes.func,
  assignmentDetail: PropTypes.shape({}),
  onCancelAssignment: PropTypes.func,
  onClose: PropTypes.func,
  isVisble: property.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      assignmentDetail: PropTypes.shape({}),
    }),
  }),
};

const defaultProps = {
  reasonList: [],
  onGetReasonList: () => {},
  onClose: () => {},
  isVisble: true,
  assignmentDetail: {},
  onCancelAssignment: () => {},
  location: {
    state: {
      assignmentDetail: {},
    },
  },
};

const INITAL_STATES = {
  showModal: false,
  reasonChoose: '',
  hasReasons: false,
  doCancelAdditionalServices: false,
};

class CancelAssignment extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITAL_STATES };
  }
  componentDidMount() {
    this.executeGetReasonList();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.reasonList.length > 0) {
      return {
        hasReasons: true,
      };
    }
    return null;
  }

  executeGetReasonList = () => {
    const { onGetReasonList } = this.props;
    onGetReasonList();
  };

  executeCancelAssignment = OrderIdentifier => {
    const { reasonList, assignmentDetail } = this.props;
    const { reasonChoose, doCancelAdditionalServices } = this.state;
    const { onCancelAssignment } = this.props;
    onCancelAssignment(
      reasonChoose === '' ? reasonList[0].identifier : reasonChoose.identifier,
      OrderIdentifier,
      assignmentDetail.CustomerOrderAdditionalServices
        ? doCancelAdditionalServices
        : null,
    );
  };

  handleShowModal = () => {
    this.setState({
      showModal: true,
    });
  };

  handleHideModal = () => {
    const { onClose } = this.props;
    onClose();
  };

  saveReason = e => {
    const { reasonList } = this.props;
    const selectedReason = reasonList.find(
      item => item.identifier === e.target.value,
    );
    this.setState({
      reasonChoose: selectedReason,
    });
  };

  handleChangeCancelAddistionalServices = () => {
    const { doCancelAdditionalServices } = this.state;
    this.setState({
      doCancelAdditionalServices: !doCancelAdditionalServices,
    });
  };

  render() {
    const { reasonList, isVisble, onClose, assignmentDetail } = this.props;
    const { hasReasons, reasonChoose, showModal, doCancelAdditionalServices } =
      this.state;

    const getValuefromList = (filterText, list) => {
      if (filterText == null) return null;
      const filteredItems = list.filter(x => x.identifier === filterText);
      return filteredItems
        ? {
            name: filteredItems[0].content,
            value: filteredItems[0].identifier,
          }
        : null;
    };

    return (
      <React.Fragment>
        <Modal
          className="tv-cancelassignment__modal"
          onCloseClicked={() => {
            onClose();
          }}
          isVisible={isVisble}
          ieOptionClassName={isIE ? 'ie-fix-height' : ''}
        >
          {assignmentDetail.CanCancelOrder && (
            <>
              {' '}
              <div className="tv-cancelassignment__container flex-column">
                <div className="tv-cancelassignment__title tv-display-flex flex-column">
                  <div className="mb-3" style={{ fontSize: '1.75rem' }}>
                    <Translate content="cancelAssignment.heading" />
                  </div>
                  <div
                    className="tv-cancelassignment__title-text"
                    style={{ height: '20px' }}
                  >
                    <Translate content="cancelAssignment.modal.cancelMessage" />
                  </div>
                  <div
                    className="tv-cancelassignment__title-text"
                    style={{ height: '30px' }}
                  >
                    <Translate content="cancelAssignment.modal.cancelMessageSecondLine" />
                  </div>
                </div>
                {assignmentDetail.CustomerOrderAdditionalServices && (
                  <div className="tv-cancelassignment__actions tv-display-flex">
                    <Checkbox
                      label="Skicka SMS om avbokning till patient/klient på bokat språk."
                      className={`label--checked`}
                      defaultChecked={!doCancelAdditionalServices}
                      value="doCancelAdditionalServices"
                      handleChange={this.handleChangeCancelAddistionalServices}
                    />
                  </div>
                )}
                <div className="tv-cancelassignment__buttons tv-display-flex"></div>
              </div>
              <div className="d-flex flex-column">
                <div className="tv-workassignment__detail-buttons tv-display-flex">
                  <TFVButton
                    type="secondary"
                    text={Translate({
                      content: 'cancelAssignment.confirm',
                    })}
                    onClick={() => {
                      this.executeCancelAssignment(
                        assignmentDetail.OrderIdentifier,
                      );
                      onClose();
                    }}
                  />
                  <TFVButton
                    type="line-primary"
                    className="tv-buttons__element-link"
                    text={Translate({
                      content: 'cancelAssignment.goback',
                    })}
                    onClick={onClose}
                  />
                </div>
              </div>
            </>
          )}

          {!assignmentDetail.CanCancelOrder && (
            <>
              <div className="tv-cancelassignment__container flex-column tv-workassignment__modal-container">
                <div className="tv-cancelassignment__title tv-display-flex flex-column h-100">
                  <h3 className="mb-3">
                    <Translate content="cancelAssignment.unableToCancelHeading" />
                  </h3>
                  <div className="tv-cancelassignment__title-text">
                    <Translate content="cancelAssignment.modal.cantCancelMessage" />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="tv-workassignment__detail-buttons tv-display-flex justify-content-around">
                  <TFVButton
                    type="line-primary"
                    onClick={onClose}
                    text={Translate({
                      content: 'cancelAssignment.close',
                    })}
                  />
                </div>
              </div>
            </>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

CancelAssignment.propTypes = propTypes;
CancelAssignment.defaultProps = defaultProps;

const mapStateToProps = state => {
  const { reasonList } = state.reason;
  return {
    reasonList,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetReasonList: () => {
    dispatch(getReasonList());
  },
  onCancelAssignment: (
    reasons,
    workAssignmentId,
    doCancelAdditionalServices,
  ) => {
    dispatch(
      cancelOrder(reasons, workAssignmentId, doCancelAdditionalServices),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelAssignment);
