import * as actionTypes from '../actions/ActionTypes';
import {
  LOCAL_STORE_SKILLS,
  LOCAL_STORE_SUBSTITUE_SKILLS,
} from '../../Share/constants';

const INITIAL_STATE = {
  skills: JSON.parse(localStorage.getItem(LOCAL_STORE_SKILLS)),
  substituteSkills: JSON.parse(
    localStorage.getItem(LOCAL_STORE_SUBSTITUE_SKILLS),
  ),
  availableSkills: [],
  availableSubstituteSkills: [],
  educationSkills: [],
  educationSubstituteSkills: []
};

function localRemove() {
  localStorage.removeItem(LOCAL_STORE_SKILLS);
  localStorage.removeItem(LOCAL_STORE_SUBSTITUE_SKILLS);
}

const skill = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      localRemove();
      return INITIAL_STATE;

    case actionTypes.UPDATE_SKILLS: {
      const { skills } = action;
      localStorage.setItem(LOCAL_STORE_SKILLS, JSON.stringify(skills));
      return {
        ...state,
        skills,
      };
    }

    case actionTypes.UPDATE_SUBSTITUTE_SKILLS: {
      const { substituteSkills } = action;
      localStorage.setItem(
        LOCAL_STORE_SKILLS,
        JSON.stringify(substituteSkills),
      );
      return {
        ...state,
        substituteSkills,
      };
    }

    case actionTypes.UPDATE_AVAILABLE_SKILLS: {
      const { skills } = action;
      return {
        ...state,
        availableSkills: skills,
      };
    }

    case actionTypes.UPDATE_SUBSTITUTE_AVAILABLE_SKILLS: {
      const { substituteSkills } = action;
      return {
        ...state,
        availableSubstituteSkills: substituteSkills,
      };
    }

    case actionTypes.UPDATE_EDUCATION_SKILLS: {
      const { skills } = action;
      return {
        ...state,
        educationSkills: skills,
      };
    }

    case actionTypes.UPDATE_SUBSTITUTE_EDUCATION_SKILLS: {
      const { substituteSkills } = action;
      return {
        ...state,
        educationSubstituteSkills: substituteSkills,
      };
    }

    default:
      return state;
  }
};

export default skill;
