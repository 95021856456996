import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOverlay, Translate, Logo } from '../../Share/components';
import { useEffect } from 'react';
import { showChat } from '../../Share/utils';

const propTypes = {
  children: PropTypes.element.isRequired,
};

const defaultProps = {};

const LoginLayout = props => {
  useEffect(() => {
    showChat();
  }, []);
  return (
    <React.Fragment>
      <div className="tv-bg-login-page">
        <div className="d-flex w-100 px-4 mt-4">
          <Logo />
        </div>
        <div className="tv-login-page__form-container">{props.children}</div>
      </div>
      <LoadingOverlay />
    </React.Fragment>
  );
};

LoginLayout.propTypes = propTypes;
LoginLayout.defaultProps = defaultProps;

export default LoginLayout;
