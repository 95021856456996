import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Dialog,
  LeftDrawerSlide,
  Modal,
  Row,
  TFVButton,
  Translate,
} from '../../../Share/components';
import { FILTER_TYPES, status } from '../../../Share/constants';
import { iconForTheReportType, isIE } from '../../../Share/utils';
import {
  createDeviation,
  getDeviations,
  sendDeviationEmail,
  updateDeviationFilters,
} from '../../actions';
import CreateDeviationForm from '../CreateDeviation';
import StatusFilter from '../StatusFilter';
import DeviationList from './DeviationList';
import './style.scss';

const propTypes = {};

const defaultProps = {};

class ReportQuality extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetail: false,
      showStatusFilter: false,
      showCreateDeviation: false,
      reportDetail: {},
      showCreateDeviationModal: false,
      showCreateDeviationSidebar: false,
      dateTimeSorted: false,
      isDirty: false,
      visibleRouteLeavingModal: false,
    };
    this.executeGetDeviationList();

    // if (this.props.location.state && this.props.location.state.filtered) {
    //   const { deviation } = this.props.location.state;
    //   this.setState({
    //     ...this.state,
    //     showDetail: true,
    //     reportDetail: deviation,
    //   });
    // }
  }

  componentDidMount() {
    document.title = 'Synpunkter - TFV Kundportal';
    if (this.props.location.state && this.props.location.state.filtered) {
      const { deviation } = this.props.location.state;
      this.setState({
        ...this.state,
        showDetail: true,
        reportDetail: deviation,
      });
    }
    document.addEventListener('keydown', this.onEscapePress);
  }
  componentDidUpdate(prevProps) {
    const { reportDetail } = this.state;
    if (reportDetail && reportDetail.OrderNumber) {
      document.title = `Synpunkt ${reportDetail.OrderNumber} - TFV Kundportal`;
    } else {
      document.title = 'Synpunkter - TFV Kundportal';
    }
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEscapePress);
  }

  executeGetDeviationList = () => {
    this.props.onGetDeviations();
  };

  handleStatusFilterDrawerClose = () => {
    this.setState({
      showStatusFilter: false,
    });
  };

  onHandleModal = val => {
    this.setState({
      showCreateDeviationModal: val,
    });
  };
  sortDateTime = () => {
    const { deviations } = this.props;

    const sortedArray = deviations.sort((a, b) => {
      if (this.state.dateTimeSorted) {
        return moment(b.DateDeviationArrived).diff(a.DateDeviationArrived);
      }
      return moment(a.DateDeviationArrived).diff(b.DateDeviationArrived);
    });
    this.setState({
      dateTimeSorted: !this.state.dateTimeSorted,
      deviations: sortedArray,
    });
  };

  RouteLeavingModalHandler = value => {
    this.setState({
      visibleRouteLeavingModal: value,
    });
  };

  onEscapePress = e => {
    if (e.key === 'Escape' && this.state.showDetail) {
      this.setState({
        showDetail: false,
      });
    }
  };

  render() {
    const { deviations, statusFilter } = this.props;
    const {
      showDetail,
      reportDetail,
      showCreateDeviationModal,
      showCreateDeviationSidebar,
      showStatusFilter,
      dateTimeSorted,
      isDirty,
      visibleRouteLeavingModal,
    } = this.state;
    const onRowClick = item => {
      this.setState({
        ...this.state,
        showDetail: true,
        reportDetail: item,
      });
    };
    const closeSidebar = () => {
      alert('closeSidebar');
      this.setState({
        showCreateDeviationSidebar: false,
      });
    };

    const handleFilter = value => {
      const { updateDeviationFilter } = this.props;

      updateDeviationFilter(FILTER_TYPES.STATUS_FILTER, value);
    };
    return (
      <React.Fragment>
        <div className="tv-reportquality__wrapper">
          <Row className="mt-3">
            <Col lg="3" className="d-none d-md-none d-lg-block">
              {/* <TFVButton
                type="secondary"
                className="w-100 mb-3"
                text={Translate({ content: 'report.sendDeviation' })}
                onClick={() => {
                  this.onHandleModal(true);
                }}
              /> */}

              <StatusFilter
                isDeviation
                onClick={handleFilter}
                selectedFilter={statusFilter}
              />
            </Col>
            <Col lg="9" sm="12">
              <div className="tv-reportquality-drawer-title tv-reportquality-drawer-mobile-only">
                {Translate({ content: 'report.title' })}
              </div>
              <DeviationList
                filterParamater={statusFilter}
                items={deviations}
                selectedDeviation={reportDetail}
                onRowClick={onRowClick}
                sortDateTime={this.sortDateTime}
                dateTimeSorted={dateTimeSorted}
              />
            </Col>
          </Row>
          <div className="tv-reportquality__list__mobile-fixed-bottom">
            {/* <TFVButton
              type="secondary"
              className="w-100 mr-2"
              text={Translate({ content: 'report.sendDeviation' })}
              onClick={() => {
                this.setState({
                  showCreateDeviationSidebar: true,
                });
              }}
            /> */}
            <TFVButton
              type="line-primary"
              className="w-100 "
              text={Translate({ content: 'report.filter' })}
              onClick={() => {
                this.setState({
                  showStatusFilter: true,
                });
              }}
            />
          </div>

          {showCreateDeviationSidebar && (
            <LeftDrawerSlide
              isVisible={showCreateDeviationSidebar}
              hideExternalScroll
              onClose={() => {
                this.setState({
                  showCreateDeviationSidebar: false,
                });
              }}
            >
              <div>
                <p className="tv-reportquality-form-mobiletitle">
                  {Translate({ content: 'report.sendDeviation' })}
                </p>
                <CreateDeviationForm
                  isGeneral
                  {...this.props}
                  onCloseClicked={this.closeSidebar}
                />
              </div>
            </LeftDrawerSlide>
          )}
          {showDetail && (
            <LeftDrawerSlide
              isVisible={showDetail}
              hideClose
              hideExternalScroll
              onClose={() => {
                this.setState({
                  showDetail: false,
                });
              }}
              className="tv-reportquality-drawer-zoom"
            >
              <Dialog
                className="tv-reportquality-drawer__container"
                isVisible
                onClose={() => {
                  this.setState({
                    showDetail: false,
                    reportDetail: {},
                  });
                }}
              >
                <div className="tv-reportquality-drawer_title">
                  {reportDetail.OrderNumber}
                </div>
                <div className="tv-reportquality-drawer_body">
                  <div className="align-items-baseline d-flex justify-content-between w-100">
                    <span className="tv-reportquality-drawer-label ml-3">
                      <div>
                        <i
                          className={`tv-workassignment__detail-article-icon mr-2  tv-workassignment__detail-article-icon--${
                            status.name
                          } ${iconForTheReportType(reportDetail.ArticleName)}`}
                        />{' '}
                        {reportDetail.ArticleName}
                      </div>
                    </span>
                    <span
                      className={`tv-reportquality__status tv-reportquality__status--default tv-reportquality__item-status--${reportDetail.DeviationStatusId} `}
                    >
                      {reportDetail.DeviationStatus}
                    </span>
                  </div>
                  <hr />
                  <div className="tv-reportquality-drawer-content">
                    <div className="tv-reportquality-drawer-label">Datum</div>
                    <div className="tv-reportquality-drawer-value">
                      {` ${moment(reportDetail.DateDeviationArrived)
                        .locale('sv')
                        .format('DD MMMM')}`}
                    </div>
                  </div>
                  {/* <div className="tv-reportquality-drawer-content">
                    <div className="tv-reportquality-drawer-label">
                      <Translate content="report.responsibleForTFV" />
                    </div>
                    <div className="tv-reportquality-drawer-value">
                      {reportDetail.Responsible &&
                      reportDetail.Responsible.includes('Webbtjänst')
                        ? ''
                        : reportDetail.Responsible}
                    </div>
                  </div> */}
                  <div className="tv-reportquality-drawer-content">
                    <div className="tv-reportquality-drawer-label">
                      <Translate content="report.cause" />
                    </div>
                    <div className="tv-reportquality-drawer-value">
                      {reportDetail.ComplaintReasonDelivery}
                    </div>
                  </div>
                  {/* <hr /> */}
                  {/* <div className="tv-reportquality-drawer-content">
                    <div className="tv-reportquality-drawer-label">
                      <Translate content="report.message" />
                    </div>
                    <div className="tv-reportquality-drawer-value">
                      {reportDetail.DeviationDescription}
                    </div>
                  </div> */}
                  {/* <div className="tv-reportquality-drawer-content">
                    <div className="tv-reportquality-drawer-label">
                      <Translate content="report.responseFromTFV" />
                    </div>
                    <div className="tv-reportquality-drawer-value">
                      {reportDetail.Answer}
                    </div>
                  </div> */}
                </div>
              </Dialog>
            </LeftDrawerSlide>
          )}
          {/*   <RouteLeavingModal
            isVisible={visibleRouteLeavingModal}
            onCloseClicked={() => {
              this.RouteLeavingModalHandler(false);
            }}
            onOverlayClick={() => {
              this.RouteLeavingModalHandler(false);
            }}
            onConfirmClick={() => {
              this.onHandleModal(false);
              this.RouteLeavingModalHandler();
            }}
          /> */}
          {showCreateDeviationModal && (
            <Modal
              className="tv-reportquality__modal"
              onCloseClicked={() => {
                this.onHandleModal(false);
              }}
              onOverlayClick={() => {
                this.onHandleModal(false);
              }}
              isVisible={showCreateDeviationModal}
              headerClassName={`tv-workassignment__modal-header ${
                isIE ? 'ie-fix-width' : ''
              }`}
              headerText={Translate({
                content: 'report.sendDeviation',
              })}
              ieOptionClassName={isIE ? 'ie-fix-height' : ''}
            >
              <CreateDeviationForm
                {...this.props}
                isGeneral
                onCloseClicked={() => {
                  this.onHandleModal(false);
                }}
              />
            </Modal>
          )}
          {showStatusFilter && (
            <LeftDrawerSlide
              isVisible={showStatusFilter}
              onClose={() => {
                this.setState({
                  showStatusFilter: false,
                });
              }}
              hideClose
              hideExternalScroll
              className="tk-report-quality-mobile-filter-wrapper-zoom"
            >
              <Dialog
                className="tk-assignment-creation__container tk-report-quality-mobile-filter-wrapper"
                isVisible
                onClose={() => {
                  this.setState({
                    showStatusFilter: false,
                  });
                }}
              >
                <div className="tv-drawer__title-mobile">
                  {Translate({ content: 'report.filterReports' })}
                </div>
                <StatusFilter
                  isDeviation
                  onClick={handleFilter}
                  selectedFilter={statusFilter}
                />
                <div className="row tv-drawer_buttons">
                  <div
                    className="col-6"
                    onClick={this.handleStatusFilterDrawerClose}
                  >
                    <button className="tv-buttons__element tv-buttons__element--line-primary  w-100 ">
                      {Translate({ content: 'dashboard.cancel' })}
                    </button>
                  </div>
                  <div
                    className="col-6"
                    onClick={this.handleStatusFilterDrawerClose}
                  >
                    <button className="tv-buttons__element tv-buttons__element--secondary  w-100 mr-2">
                      {Translate({ content: 'dashboard.done' })}
                    </button>
                  </div>
                </div>
              </Dialog>
            </LeftDrawerSlide>
          )}
        </div>
      </React.Fragment>
    );
  }
}

ReportQuality.propTypes = propTypes;
ReportQuality.defaultProps = defaultProps;

const mapStateToProps = state => {
  const { deviations } = state.reportDetails;

  const props = {
    ...defaultProps,
    deviations,
    statusFilter: state.reportDetails.statusFilter,
  };
  return props;
};

const mapDispatchToProps = dispatch => ({
  onGetDeviations: (data, callback) => {
    dispatch(getDeviations(data, callback));
  },
  onCreateDeviation: (data, callback) => {
    dispatch(createDeviation(data, callback));
  },
  onSendDeviationEmail: (data, callback) => {
    dispatch(sendDeviationEmail(data, callback));
  },
  updateDeviationFilter: (e, filterType) => {
    dispatch(updateDeviationFilters(e, filterType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportQuality);
