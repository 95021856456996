import { isEmpty } from 'lodash';
import React, { createRef, useEffect, useState } from 'react';
import { RouteLeavingModal } from '../../../Share/components';
import { createDefaultTranslationOrder } from '../../../Share/utils';
import './Modal.scss';
import NewTranslationForm from './NewTranslationForm';
import PreviewDetail from './PreviewDetail';
import ResultModal from './ResultModal';
import { connect } from 'react-redux';
import { displaySuccessModal } from '../../actions/OrderTranslation';

const NewTranslation = ({ onClose, onHideSuccessModal }) => {
  const [attachments, setAttachments] = useState([]);
  const [showTranslationForm, setShowTranslationForm] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCancelTranslationModal, setShowCancelTranslationModal] =
    useState(false);
  const [formData, setFormData] = useState(createDefaultTranslationOrder());
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    const originalTitle = document.title;

    document.title = 'Beställ översättning - TFV Kundportal';

    return () => {
      document.title = originalTitle;
    };
  }, []);
  useEffect(() => {
    setFormData({ ...formData, attachments });
    if (attachments.length > 0) {
      setErrorMessage(false);
    }
  }, [attachments]);

  useEffect(() => {
    onHideSuccessModal();
  }, []);

  const emailTranslationRef = createRef();

  const handleSubmit = () => {
    if (isEmpty(formData.attachments)) {
      setErrorMessage(true);
    } else {
      setShowTranslationForm(false);
      setShowPreview(true);
    }
  };

  useEffect(() => {
    const onEscapePress = () => {
      if (showSuccessModal) {
        onClose(false);
      } else if (showCancelTranslationModal) {
        setShowCancelTranslationModal(false);
      } else if (showPreview) {
        setShowCancelTranslationModal(true);
      } else if (showTranslationForm) {
        setShowCancelTranslationModal(true);
      }
    };

    const onKeyDown = e => {
      if (e.key === 'Escape') {
        onEscapePress();
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [
    showSuccessModal,
    showPreview,
    showCancelTranslationModal,
    showTranslationForm,
  ]);

  return (
    <>
      {showTranslationForm && (
        <NewTranslationForm
          emailTranslationRef={emailTranslationRef}
          onClose={onClose}
          handleSubmit={handleSubmit}
          setFormData={setFormData}
          MODEOFDELIVERY={MODEOFDELIVERY}
          DELEVEYYPLANS={DELEVEYYPLANS}
          formData={formData}
          setShowTranslationForm={setShowTranslationForm}
          setAttachments={setAttachments}
          setShowPreview={setShowPreview}
          attachments={attachments}
          setShowCancelTranslationModal={setShowCancelTranslationModal}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      {showPreview && (
        <PreviewDetail
          onClose={onClose}
          setShowPreview={setShowPreview}
          setShowSuccessModal={setShowSuccessModal}
          setShowTranslationForm={setShowTranslationForm}
          formData={formData}
          setShowCancelTranslationModal={setShowCancelTranslationModal}
        />
      )}

      <RouteLeavingModal
        isVisible={showCancelTranslationModal}
        onCloseClicked={() => {
          setShowCancelTranslationModal(false);
        }}
        onConfirmClick={() => {
          setShowCancelTranslationModal(false);
          setShowTranslationForm(false);
          onClose(false);
        }}
      />

      {showSuccessModal && (
        <ResultModal
          onClose={onClose}
          setShowPreview={setShowPreview}
          setShowTranslationForm={setShowTranslationForm}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onHideSuccessModal: () => {
    return dispatch(displaySuccessModal(false));
  },
});

export default connect(null, mapDispatchToProps)(NewTranslation);

const MODEOFDELIVERY = [
  { name: 'Post', value: 'Post' },
  { name: 'E-post', value: 'E-post' },
  { name: 'Mina sidor', value: 'Mina sidor' },
];
const DELEVEYYPLANS = [
  {
    name: 'Express (inom 3 arbetsdagar med max 2 500 ord, 100% prispåslag)',
    value: 'Express (inom 3 arbetsdagar med max 2 500 ord, 100% prispåslag)',
  },
  {
    name: 'Standard (inom 6 arbetsdagar med max 2 500 ord)',
    value: 'Standard (inom 6 arbetsdagar med max 2 500 ord)',
  },
  {
    name: 'Leveransdatum enligt överenskommelse',
    value: 'Leveransdatum enligt överenskommelse',
  },
];
