import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { truncateFileName } from '../../utils/fileUtil';
import { acceptedFileType } from '../../constants/index';

const propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  fileName: PropTypes.string,
  attachmentId: PropTypes.string,
  onGetAttachment: PropTypes.func,
};

const defaultProps = {
  className: '',
  type: '',
  fileName: '',
  attachmentId: '',
  onGetAttachment: () => {},
};

class Attachment extends Component {
  handleDownload = () => {
    const { attachmentId } = this.props;
    this.props.onGetAttachment(attachmentId);
  };

  shorterFileName = fileName => {
    const EXPECT_LENGTH = 20;
    return truncateFileName(fileName, EXPECT_LENGTH, '...');
  };

  onKeyDown = e => {
    if (e.key === 'Enter' || e.key === ' ') {
      this.handleDownload();
    }
  };

  render() {
    const { className, type, fileName } = this.props;
    const fileTypeLowerCase = type ? type.toLowerCase() : type;
    const extension = acceptedFileType[fileTypeLowerCase];

    return (
      <div className="tv-single-assign-form__file-container tv-single-assign-form__file-container-secondary">
        <div>
          <div
            style={{ marginBottom: '17px', fontSize: '12px', display: 'flex' }}
          >
            <i
              className="tv-single-assign-form__file-container-icon fa fa-file"
              style={{
                color: '#74737b',
                marginRight: '12px',
                fontSize: '14px',
                minWidth: '14px',
              }}
            />{' '}
            {this.shorterFileName(fileName)}
            <span
              onClick={this.handleDownload}
              className="ml-3"
              title="Ladda ner"
              tabIndex={0}
              onKeyDown={this.onKeyDown}
              role="button"
            >
              <i className="fa fa-download" />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

Attachment.propTypes = propTypes;
Attachment.defaultProps = defaultProps;

export default Attachment;
