import moment, { lang } from 'moment';
import { toast } from 'react-toastify';
import { filter, sortBy, isEmpty } from 'lodash/core';
import {
  status,
  MULTI_FORM,
  GLOBAL_DATE_FORMAT,
  availabilityStatus,
  APP_PORTAL,
  SINGLE_FORM,
  DEFAULT_REDIRECT_PATH,
} from '../constants';
import { currentDate, getNearest5MinutesInterval } from './dateUtil';
import history from './history';
import { Translate } from '../components';
import { serviceLog, logApiLevel } from './logUtil';

export { logApiLevel } from './logUtil';

export const isDebugMode = localStorage.getItem('debug') === 'true';

export function consoleLog(message, type = 'log') {
  if (isDebugMode) {
    const fn = console[type];
    if (console && fn) {
      fn(message);
    }
  }
}

export const isIE =
  !!document.documentMode ||
  window.navigator.userAgent.indexOf('Edge') > -1 ||
  false;

export function tfvLog(message, type = logApiLevel.info) {
  if (type === logApiLevel.info) {
    consoleLog(message, 'log');
  } else if (type === logApiLevel.error) {
    serviceLog(message, type);
  }
}

export function getCurrentDomain() {
  const { protocol, hostname } = window.location;
  const port = window.location.port ? `:${window.location.port}` : '';

  return `${protocol}//${hostname}${port}`;
}

export function redirectToDashBoard() {
  if (window.location.href.indexOf('/login') > -1) {
    history.push('/');
  }
}

export function redirectToRequestedPath(path) {
  if (window.location.href.indexOf('/login') > -1) {
    history.push(path);
    localStorage.removeItem(DEFAULT_REDIRECT_PATH);
  }
}

// type: success, info, warn, error,
export function showToastMessage(type, message) {
  toast[type](message);
}
export function getDateFormated(dateTime, template = GLOBAL_DATE_FORMAT) {
  return moment(dateTime).locale('sv').format(template);
}

export function assignmentTimeFormat(
  from,
  to,
  format = '{from} - {to} - {day}',
  withPrefix = true,
  date_format = GLOBAL_DATE_FORMAT,
  hideDate = false,
) {
  const time_format = 'HH:mm';
  const fromdt = moment(from);
  const todt = moment(to);
  const allday =
    todt.clone().startOf('day').diff(fromdt.clone().startOf('day'), 'days') > 0;

  let reFormat = '';
  if (!withPrefix) {
    reFormat = allday
      ? ' {from} - ({dayTo}) {to} ' // ` {from} {dayFrom} -  {to} {dayTo}`
      : ' {from} - {to}';
  } else {
    reFormat = allday ? ' {from}  -  {to} ' : ' {from} - {to}';
  }
  if (hideDate) {
    return allday
      ? reFormat
          .replace(/{from}/g, fromdt.format(time_format).replace(' ', ''))
          .replace(/{dayFrom}/g, '')
          .replace(/{to}/g, todt.format(time_format).replace(' ', ''))
          .replace(/{dayTo}/g, '')
      : reFormat
          .replace(/{from}/g, fromdt.format(time_format).replace(' ', ''))
          .replace(/{to}/g, todt.format(time_format).replace(' ', ''));
  }
  return allday
    ? reFormat
        .replace(/{from}/g, fromdt.format(time_format).replace(' ', ''))
        .replace(/{dayFrom}/g, fromdt.format(date_format))
        .replace(/{to}/g, todt.format(time_format).replace(' ', ''))
        .replace(/{dayTo}/g, todt.format(date_format))
    : reFormat
        .replace(/{from}/g, fromdt.format(time_format).replace(' ', ''))
        .replace(/{to}/g, todt.format(time_format).replace(' ', ''));
}

export function getRangeOfDates(start, end, key, arr = [start.startOf(key)]) {
  if (start.isAfter(end)) throw new Error('start must precede end');
  const next = moment(start).add(1, key).startOf(key);
  if (next.isAfter(end, key)) return arr;
  return getRangeOfDates(next, end, key, arr.concat(next));
}

export function getDateRangeFromWeek(
  week = moment().week(),
  year = moment().year(),
) {
  const newWeek = moment().year(year).week(week);
  const clone = newWeek.clone();
  return {
    end: newWeek.endOf('isoWeek'),
    start: clone.startOf('isoWeek'),
  };
}

export function getDates(startDate, endDate, notWeekend = false) {
  let dates = [];
  let currentDate = startDate;
  const addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  if (notWeekend) {
    dates = dates.filter(x => moment(x).day() !== 0 && moment(x).day() !== 6);
  }
  return dates;
}

export function get24HoursInday() {
  const hours = [];
  const hoursPerDay = 24;
  for (let i = 0; i < hoursPerDay; i += 1) {
    const formattedTime = moment(i, 'hh').format('H:00');
    hours.push(formattedTime);
  }
  return hours;
}

export function generateUniqueId() {
  return (
    Math.random().toString(36).substring(2) + new Date().getTime().toString(36)
  );
}
export function generateEditOrderDateSession(from, to) {
  return {
    ranges: {
      startDate: from != null ? moment(from) : moment().add(1, 'days'),
      endDate: to != null ? moment(to) : moment().add(1, 'days'),
      key: generateUniqueId(),
    },
    startTime:
      from != null ? moment(from) : moment().hours(8).minutes(0).seconds(0),
    endTime:
      to != null
        ? moment(to)
        : moment().hours(8).minutes(0).seconds(0).add(60, 'minutes'),
    durationDifference: 30,
  };
}
export function generateDefaultSession(date) {
  return {
    ranges: {
      startDate: date != null ? date.clone() : null,
      endDate: date != null ? date.clone() : null,
      key: generateUniqueId(),
    },
    startTime:
      moment().hours() > 8
        ? getNearest5MinutesInterval().add(5, 'minutes')
        : moment().hours(8).minutes(0).seconds(0),
    endTime:
      moment().hours() > 8
        ? getNearest5MinutesInterval().add(35, 'minutes')
        : moment().hours(8).minutes(0).seconds(0).add(30, 'minutes'),
    durationDifference: 30,
  };
}

export function generateDefaultTranslationSession() {
  return {
    ranges: {
      startDate: moment().add(6, 'days'),
      endDate: moment().add(6, 'days'),
      key: generateUniqueId(),
    },
  };
}

export function filterEventInDay(events, date, makeSort = true) {
  const result = filter(events, e => moment(e.from).isSame(date, 'day'));
  return result.length > 0
    ? makeSort
      ? sortBy(result, e => moment.duration(e.from.diff(e.to)).asMinutes())
      : result
    : result;
}

export function filterEventInWeek(events, range) {
  const result = filter(events, e =>
    moment(e.from).isBetween(range[0], range[range.length - 1], null, '()'),
  );
  return result.length > 0
    ? sortBy(result, e => moment.duration(e.from.diff(e.to)).asMinutes())
    : result;
}

export function filterAvailabilityInDay(availabilities, date) {
  const result = availabilities.filter(
    x =>
      moment(x.DatetimeFrom).isSame(date, 'day') ||
      date.isBetween(moment(x.DatetimeFrom), moment(x.DatetimeTo), 'day', '[]'),
  );
  // filter(availabilities, a => moment(a.DateTimeFrom).isSame(date, 'day'));
  return result.length > 0
    ? sortBy(result, e =>
        moment
          .duration(moment(e.DatetimeFrom).diff(moment(e.DatetimeTo)))
          .asMinutes(),
      )
    : result;
}

export function preventScrollWhenVisible(isVisible) {
  const htmlDom = document.getElementsByTagName('html')[0];
  if (isVisible && !htmlDom.classList.contains('tv-tv-is-clipped')) {
    htmlDom.classList.add('tv-is-clipped');
    setTimeout(() => {
      htmlDom.classList.add('js-slide-in');
    }, 10);
  }
}

export function removeContextualClass() {
  const htmlDom = document.getElementsByTagName('html')[0];
  if (htmlDom.classList.contains('tv-is-clipped')) {
    htmlDom.classList.remove('tv-is-clipped');
    htmlDom.classList.remove('js-slide-in');
  }
}

export function removeContextualClassForBooknow() {
  const htmlDom = document.getElementsByTagName('html')[0];
  if (htmlDom.classList.contains('js-slide-in')) {
    htmlDom.classList.remove('tv-is-clipped');
    htmlDom.classList.remove('js-slide-in');
  }
}

export function Capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function CapitalizeAndLowerOther(str) {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
}

export function mergeAssignmentStatusWithLocalStatus(
  assignmentList,
  localWorkAssignment,
) {
  const assignmentListWithLocalStatus = [];
  assignmentList.forEach(item => {
    const assignmentId = item.WorkAssignmentIdentifier;

    const cloneItem = { ...item };
    cloneItem.status = localWorkAssignment[assignmentId]
      ? localWorkAssignment[assignmentId].status
      : status.accepted.name;

    if (item.DatetimeSubmitted || item.CanBeTimeReported === false) {
      cloneItem.status = status.fullfilled.name;
    }

    // Assignment over time should have status fullfiled
    if (
      moment().diff(item.DatetimeOrderTo, 'seconds') > 0 &&
      cloneItem.status !== status.submitted.name
    ) {
      cloneItem.status = status.fullfilled.name;
    }

    if (item.DatetimeCompleted) {
      cloneItem.status = status.done.name;
    }

    if (item.CancellationStatus) {
      cloneItem.status = status.cancelled.name;
    }

    assignmentListWithLocalStatus.push(cloneItem);
  });

  return assignmentListWithLocalStatus;
}

export function endWith(target, search) {
  if (search.length > target.length) return false;
  return (
    target.substring(target.length - search.length, target.length) === search
  );
}
export function selectDefaultService(services) {
  const filteredTeleService = services.filter(x =>
    x.ServiceName.includes('Tele'),
  );
  if (filteredTeleService.length === 0) {
    return {
      name: services[0].ServiceName,
      value: services[0].ServiceIdentifier,
      isMessageService: services[0].IsMessageService,
      doAllowLMANumberOnConnectedContract:
        services[0].DoAllowLMANumberOnConnectedContract,
      isPerformedRemotely: services[0].IsPerformedRemotely,
      additionalServices: services[0].AdditionalServices,
    };
  }
  return {
    name: filteredTeleService[0].ServiceName,
    value: filteredTeleService[0].ServiceIdentifier,
    isMessageService: filteredTeleService[0].IsMessageService,
    doAllowLMANumberOnConnectedContract:
      filteredTeleService[0].DoAllowLMANumberOnConnectedContract,
    isPerformedRemotely: filteredTeleService[0].IsPerformedRemotely,
    additionalServices: filteredTeleService[0].AdditionalServices,
  };
}
export function createDefaultAssignment(
  type,
  skills = [],
  service = [],
  contactPersons = [],
  profile = {},
  date = null,
  confirmationEmail,
  currentContactPerson,
  validCustomerInvoices,
) {
  let newEmail = null;
  if (profile.ContactPersonUponInterpretationRequiresEmail) {
    newEmail = currentContactPerson ? currentContactPerson.Email : '';
  } else if (profile.ContactPersonOrdererRequiresEmail) {
    newEmail = currentContactPerson ? currentContactPerson.Email : '';
  } else {
    newEmail = profile.CustomerEmail;
  }
  const initSkill = skills.length > 0 ? skills[0].SkillIdentifier : '';
  const initService =
    service.length > 0
      ? selectDefaultService(service)
      : { name: null, value: null };
  const initAddress = profile
    ? {
        addressLine: !isEmpty(profile.customerAddress)
          ? profile.customerAddress.AddressRow1
          : '',
        city: !isEmpty(profile.customerAddress)
          ? profile.customerAddress.City
          : '',
        postalCode: !isEmpty(profile.customerAddress)
          ? profile.customerAddress.PostalCode
          : '',
        orderCompanyName: !isEmpty(profile.customerName)
          ? profile.customerName
          : '',
        orderLocation: !isEmpty(profile.customerAddress)
          ? `${
              !profile.customerAddress.CareOf
                ? ''
                : `${profile.customerAddress.CareOf},`
            } ${profile.customerAddress.AddressRow1}, ${
              profile.customerAddress.PostalCode
            }, ${profile.customerAddress.City}`
          : '',
      }
    : {
        addressLine: '',
        city: '',
        postalCode: '',
        orderCompanyName: '',
        orderLocation: '',
      };

  let validCustomerInvoice = { name: null, value: null };
  if (validCustomerInvoices?.length === 1) {
    validCustomerInvoice = {
      name: validCustomerInvoices[0].name,
      value: validCustomerInvoices[0].value,
    };
  }

  const form = {
    serialDateRanges: [],
    dateRange: generateDefaultSession(date),
    certificationLevels: undefined,
    isAllowLowerLevel: false,
    isAllowOnlyLevel: false,
    yourOrderNumber: '',
    yourReferenceNumber: '',
    typeOfAssignment: initService,
    // addressLine: initAddress.addressLine,
    addressLine: '',
    district: '',
    // city: initAddress.city,
    city: '',
    // postalCode: initAddress.postalCode,
    postalCode: '',
    isHomeAddress: false,
    language: { name: null, value: null },
    alternativeLanguage: { name: null, value: null },
    meetingPlatform: { name: null, value: null },
    languages: skills,
    otherInformation: '',
    genderRequirement: false,
    skillEducationRequirement: false,
    genderValue: null,
    requestedInterpreterPreferedLevel: 2,
    contactPerson: currentContactPerson ? currentContactPerson.value : null,
    contactNumber: '',
    assignmentDescription: '',
    orderCompanyName: initAddress.orderCompanyName,
    orderContactPerson: currentContactPerson
      ? currentContactPerson.value
      : null,
    orderContactNumber: '',
    orderContactDirectNumber: '',
    orderLocation: initAddress.orderLocation,
    orderWebsite: '',
    orderEmail: '',
    isSameAbove: false,
    isAddressEdited: false,
    isSameProfile: true,
    profile: {},
    files: [],
    services: service,
    contactPersons,
    contactDirectNumber: '',
    contactEmail: '',
    customerOrderEmailAddress: [],
    attachmentFileNames: [],
    newEmail: newEmail,
    lmaNumber: '',
    message: '',
    messageReceiver: '',
    messageTelephone: '',
    isDeviatingAddressPerforming: false,
    sendHoursBeforeBookingOverride: 48,
    doIncludeAdditionalServices: false,
    isPatientConsentGivenin: false,
    messageReceiverAdditionalService: '',
    validCustomerInvoice,
  };
  date = null;
  if (type === MULTI_FORM) {
    return {
      form,
      isValid: true,
      isShow: true,
      isSubmitted: false,
    };
  }
  return form;
}

export function convertAssignmentToOrder(assignment, profile, skills) {
  let attachmentFileNames = [];
  if (assignment.Attachments && assignment.Attachments.length > 0) {
    attachmentFileNames = assignment.Attachments.map(
      ({ AttachmentFilename }) => ({ name: AttachmentFilename }),
    );
  }

  const OrderDefault = createDefaultAssignment(
    SINGLE_FORM,
    skills,
    [],
    [],
    profile,
    null,
  );
  let confirmationEmail = [];
  let lmaNumber;
  let messageInfoForInterpreter;

  let isDeviatingAddressPerforming = assignment.IsDeviatingAddressPerforming;
  confirmationEmail = assignment.confirmationEmail;
  lmaNumber = assignment.lmaNumber;
  const address = assignment.AddressPerformingEffective
    ? assignment.AddressPerformingEffective.split(',')
    : [];
  let [district, addressLine, postalCode, city] = new Array(4).fill('');
  const originalLanguage = skills?.filter(
    item => item.SkillIdentifier === assignment.SkillIdentifier,
  );
  const originalAlternativeLanguage = skills?.filter(
    item => item.SkillIdentifier === assignment.SkillSubstituteIdentifier,
  );
  let language = { name: null, value: null };
  let alternativeLanguage = { name: null, value: null };
  originalLanguage.map(item => {
    language = {
      name: item.SkillName,
      value: item.SkillIdentifier,
    };
    return null;
  });
  originalAlternativeLanguage.map(item => {
    alternativeLanguage = {
      name: item.SkillName,
      value: item.SkillIdentifier,
    };
    return null;
  });
  // if (assignment.PostalAddressPerforming) {
  //   [district, addressLine, postalCode, city] = [
  //     '',
  //     assignment.PostalAddressPerforming.AddressRow1,
  //     assignment.PostalAddressPerforming.PostalCode,
  //     assignment.PostalAddressPerforming.City,
  //   ];
  // } else if (address.length > 0) {
  //   [district, addressLine, postalCode, city] = address;
  // }
  if (assignment.IsDeviatingAddressPerforming) {
    [district, addressLine, postalCode, city] = [
      '',
      assignment.PostalAddressPerforming.AddressRow1,
      assignment.PostalAddressPerforming.PostalCode,
      assignment.PostalAddressPerforming.City,
    ];
  }
  if (assignment.isMessageServiceAssignment) {
    language = {
      name: 'Albanska',
      value: '2f26ecc0-ec8c-4119-a81f-87beddf2dd19',
    };
    messageInfoForInterpreter = assignment.messageInfoForInterpreter;
  }

  let validCustomerInvoice = { name: null, value: null };
  if (assignment.CustomerInvoiceIdentifier) {
    validCustomerInvoice = {
      name: assignment.CustomerInvoiceName,
      value: assignment.CustomerInvoiceIdentifier,
    };
  }

  return {
    ...OrderDefault,
    confirmationEmail,
    lmaNumber,
    isDeviatingAddressPerforming,
    messageInfoForInterpreter,
    language,
    alternativeLanguage,
    message: assignment.message,
    customerOrderEmailAddress: assignment.customerOrderEmailAddress,
    messageReceiver: assignment.messageReceiver,
    messageTelephone: assignment.messageTelephone,
    genderRequirement: assignment.DoRequireCorrectGender || false,
    skillEducationRequirement: assignment.DoRequireSkillEducation || false,
    genderValue: assignment.GenderIdPreferred
      ? {
          name: '',
          value: assignment.GenderIdPreferred.toString(),
        }
      : { name: '', value: '' },
    contactPerson: assignment.ContactPerson.ContactPersonIdentifier || null,
    contactNumber:
      assignment.ContactPerson.ContactPersonMobilePhoneNumber || '',
    orderContactPerson:
      assignment.ContactPersonOrderer.ContactPersonOrdererIdentifier || null,
    orderContactDirectNumber:
      assignment.ContactPersonOrderer.ContactPersonOrdererDirectPhoneNumber ||
      '',
    orderEmail: assignment.ContactPersonOrderer.ContactPersonOrdererEmail || '',
    contactEmail: assignment.ContactPerson.ContactPersonEmail || '',
    meetingPlatform: {
      name: assignment.MeetingDetails.DigitalMeetingPlatformName,
      value: assignment.MeetingDetails.DigitalMeetingPlatformIdentifier,
    },
    meetingPassCode: assignment.MeetingDetails.MeetingPassCode || '',
    phoneNumber: assignment.MeetingDetails.MeetingPhoneNumber || '',
    meetingLink: assignment.MeetingDetails.MeetingUrl || null,
    isBankIDRequired:
      assignment.MeetingDetails.DoRequireBankIdVerification == 'True',
    contactDirectNumber:
      assignment.ContactPerson.ContactPersonDirectPhoneNumber || '',
    certificationLevels: assignment.SkillEducationPreferredIdentifier
      ? { name: '', value: assignment.SkillEducationPreferredIdentifier }
      : { name: '', value: '' },
    isAllowLowerLevel: assignment.DoRequireCompetenceLevelOrHigher || false,
    isAllowOnlyLevel: assignment.DoRequireExactCompetenceLevel || false,
    requestedInterpreterPreferedLevel: assignment.PreferredResourceLevel || 2,
    requestedInterpreter: assignment.PreferredResourceCustomer || '',
    yourReferenceNumber: assignment.CustomerReference || '',
    yourOrderNumber: assignment.CustomerOrderNumber || '',
    additionalRequirements: assignment.additionalRequirements || '',
    typeOfAssignment:
      (assignment.ServiceIdentifier
        ? {
            name: assignment.ArticleName,
            value: assignment.ServiceIdentifier,
            isMessageService: assignment.IsMessageService,
            doAllowLMANumberOnConnectedContract:
              assignment.DoAllowLMANumberOnConnectedContract,
            isPerformedRemotely: assignment.IsPerformedRemotely,
            additionalServices: assignment.AdditionalServices,
          }
        : { name: '', value: null }) || OrderDefault.typeOfAssignment,
    addressLine: addressLine || OrderDefault.addressLine,
    district: district || OrderDefault.district,
    city: city || OrderDefault.city,
    postalCode: postalCode || OrderDefault.postalCode,
    isHomeAddress: assignment.IsHomeAddress || false,
    assignmentDescription: assignment.assignmentDescription || '',
    attachmentFileNames,
    validCustomerInvoice,
  };
}

export function convertEvent(googleEvents, phone) {
  const events = googleEvents.result.items;
  const tfvEvent = events.map(event => {
    const from = event.start.dateTime
      ? moment(event.start.dateTime)
      : moment(event.start.date, 'YYYY-MM-DD');
    const to = event.end.dateTime
      ? moment(event.end.dateTime)
      : moment(event.end.date, 'YYYY-MM-DD');
    const title = event.summary;
    const { location, description } = event;
    const googleEventWithoutTime =
      !event.start.dateTime || !event.start.dateTime;
    return {
      type: 'custom',
      from,
      to,
      title,
      location,
      description,
      googleEventWithoutTime,
      AvailabilityType: availabilityStatus.AVAILABLE,
      DatetimeFrom: from,
      DatetimeTo: to,
      id: event.id,
    };
  });

  return tfvEvent;
}

export function createDefaultContactPerson() {
  const form = {
    FirstName: null,
    LastName: null,
    PhoneNumberDirect: null,
    PhoneNumberMobile: null,
    Title: null,
    Email: null,
    emailRequired: false,
    identifier: '',
    editMode: false,
  };
  return form;
}

export function mergeAvailability(availabilitList, selectedCalendarDay) {
  const availabilities = availabilitList.map(x => ({
    ...x,
    timeFrom: `${selectedCalendarDay.format('YYYY-MM-DD')} ${moment(
      x.DatetimeFrom,
    ).format('HH:mm:ss')}`,
    timeTo: `${selectedCalendarDay.format('YYYY-MM-DD')} ${moment(
      x.DatetimeTo,
    ).format('HH:mm:ss')}`,
    noOfDays: moment(x.DatetimeTo).diff(moment(x.DatetimeFrom), 'day') + 1,
  }));
  const mergedAvailabilities = [];
  let mergedAvaialabilityIdentifieres = [];
  availabilities.map(x => {
    if (mergedAvaialabilityIdentifieres.indexOf(x.identifier) < 0) {
      const listOfAvailabilitiesWithinTimePeriod =
        availabilities.filter(
          y =>
            x.identifier !== y.identifier &&
            moment(y.timeFrom) <= moment(x.timeTo) &&
            moment(x.timeFrom) <= moment(y.timeFrom) &&
            moment(y.timeTo) <= moment(x.timeTo) &&
            x.availableFor === y.availableFor,
        ) || [];
      mergedAvaialabilityIdentifieres = [
        ...mergedAvaialabilityIdentifieres,
        ...listOfAvailabilitiesWithinTimePeriod.map(y => y.identifier),
      ];
      x.mergedAvailabilities = listOfAvailabilitiesWithinTimePeriod || [];
      mergedAvailabilities.push(x);
    }
  });

  return mergedAvailabilities.sort(
    (a, b) => moment(b.timeFrom).diff(moment(a.timeFrom)) < 0,
  );
}

export function shouldDisplayRatingElement(statusName, DatetimeTo) {
  const timeDifference = moment(DatetimeTo, 'YYYY-MM-DD HH:mm:ss').diff(
    moment(),
    'minutes',
  );
  return (
    statusName === 'fullfilled' ||
    (statusName === 'accepted' && timeDifference < 1)
  );
}

export function statusStringTraslate(status) {
  let item = Translate({ content: 'status.status' });

  switch (status) {
    case 'all':
      item = Translate({ content: 'status.status' });
      break;
    case 'accepted':
      item = Translate({ content: 'status.accepted' });
      break;
    case 'working':
      item = Translate({ content: 'status.working' });
      break;
    case 'fullfilled':
      item = Translate({ content: 'status.fullfilled' });
      break;
    case 'cancelled':
      item = Translate({ content: 'status.cancelled' });
      break;
    case 'late-cancelled':
      item = Translate({ content: 'status.lateCancelled' });
      break;
    case 'available':
      item = Translate({ content: 'status.available' });
      break;
    case 'submitted':
      item = Translate({ content: 'status.submitted' });
      break;
    case 'done':
      item = Translate({ content: 'status.done' });
      break;
    case 'rejected':
      item = Translate({ content: 'status.rejected' });
      break;
    case 'losted':
      item = Translate({ content: 'status.losted' });
      break;
    case 'onGoing':
      item = Translate({ content: 'status.onGoing' });
      break;
    default:
      break;
  }

  // Override text for available status for client-portal
  if (APP_PORTAL === 'client-portal' && status === 'available') {
    item = Translate({ content: 'status.waitingAccept' });
  }
  return item.toLowerCase();
}

export function iconForTheServiceType(assignment) {
  let iconClass = 'fa fa-phone-alt';
  if (assignment) {
    if (assignment.IsPerformedRemotely === false) {
      iconClass = 'fa fa-walking';
    }
    if (assignment.IsMessageService === true) {
      iconClass = 'fa fa-comment';
    }
    if (assignment.IsTranslation === true) {
      iconClass = 'fa fa-envelope';
    }
    if (assignment.MeetingDetails.DigitalMeetingPlatformIdentifier != null) {
      iconClass = 'fa fa-video';
    }
  }
  return iconClass;
}

export function iconForTheReportType(serviceName) {
  let iconClass = 'fa fa-phone-alt';
  if (serviceName.includes('Tele')) {
    iconClass = 'fa fa-phone-alt';
  }
  if (serviceName.includes('Platsto')) {
    iconClass = 'fa fa-walking';
  }
  if (serviceName.includes('Skärmt')) {
    iconClass = 'fa fa-video';
  }
  if (serviceName.includes('Meddela')) {
    iconClass = 'fa fa-comment';
  }
  if (serviceName.includes('Övers')) {
    iconClass = 'fa fa-envelope';
  }
  return iconClass;
}

export const shouldDisplayscrollTop = e => {
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0,
  );
  return e && e.current?.offsetTop > vh;
};

export function isAddressEdited(assignment, profile) {
  return (
    !(
      assignment.addressLine === profile?.customerAddress?.AddressRow1 &&
      assignment.city === profile?.customerAddress?.City &&
      assignment.postalCode == profile?.customerAddress?.PostalCode.toString()
    ) || assignment?.isEdit
  );
}

export function isContactPersonValid(
  isProfileRequierEmail,
  contactPerson,
  contactPersonList,
) {
  const filteredContactPerson = contactPersonList.filter(
    x => x.ContactPersonIdentifier === contactPerson,
  );
  if (!filteredContactPerson.length > 0) return true;
  const {
    Email: contactEmail,
    PhoneNumberDirect: contactDirectNumber,
    PhoneNumberMobile: contactNumber,
  } = filteredContactPerson[0];
  if (!(contactPerson && contactPerson != '')) return false;

  if (isProfileRequierEmail) {
    return !!(contactEmail && contactEmail != '');
  } else {
    return (
      [contactNumber, contactEmail, contactDirectNumber].join('').trim() !== ''
    );
  }
}

export function messageFieldValidate(value) {
  if (value.length > 300) {
    return { isValid: false, errorMessage: 'Maximal teckenlängd är 300' };
  }
  return { isValid: true, errorMessage: '' };
}

export function confirmationEmailValidate(value) {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!value.match(validRegex)) {
    return { isValid: false, errorMessage: 'Invalid email type' };
  }
  return { isValid: true, errorMessage: '' };
}

export function passwordPolicyCheck(value) {
  if (value.length === 0) {
    return { isValid: false, errorMessage: 'Nytt lösenord är obligatoriskt' };
  }
  // if (value.length < 8 || value.length > 8) {
  //   return { isValid: false, errorMessage: 'Måste innehålla exakt 8 tecken' };
  // }
  if (value.length < 8) {
    return { isValid: false, errorMessage: 'Måste innehålla minst 8 tecken.' };
  }
  // if (value.search(/[A-Z]/) < 0) {
  //   return {
  //     isValid: false,
  //     errorMessage: 'Måste innehålla minst en stor bokstav.',
  //   };
  // }
  // if (value.search(/[a-z]/) < 0) {
  //   return {
  //     isValid: false,
  //     errorMessage: 'Måste innehålla minst ett gement tecken',
  //   };
  // }
  // if (value.search(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/) < 0) {
  //   return {
  //     isValid: false,
  //     errorMessage: 'Måste innehålla minst ett specialtecken',
  //   };
  // }
  // if (value.search(/[0-9]/) < 0) {
  //   return { isValid: false, errorMessage: 'Måste innehålla minst en siffra.' };
  // }
  if (value.length > 256) {
    return { isValid: false, errorMessage: 'Maximal teckenlängd är 256' };
  }

  return { isValid: true, errorMessage: '' };
}

export function createDefaultTranslationOrder() {
  const form = {
    sourceLanguage: { name: null, value: null },
    contactPersonIdentifierOrderer: { name: null, value: null },
    targetLanguages: [],
    deliveryPlan: { name: null, value: null },
    deliveryDate: generateDefaultTranslationSession(),
    modeoFDelivery: { name: null, value: null },
    description: '',
    needAQuote: false,
  };

  return form;
}

export function showChat() {
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement('script'),
      s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/5e298ea5daaca76c6fcf7e7b/default';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();
}

export function checkOnGoingAssignment(item) {
  let isOnGoingStatus = false;
  let isStatusMatchForOnGoingStatus = false;
  let isReportMatchForOnGoingStatus = false;
  let isTimeMatchForOnGoingStatus = false;
  if (item.OrderStatus === 'accepted' || item.OrderStatus === 'fullfilled') {
    isStatusMatchForOnGoingStatus = true;
  }
  if (
    !item.TimeReport.DatetimeOriginalFrom &&
    !item.TimeReport.DatetimeOriginalTo
  ) {
    isReportMatchForOnGoingStatus = true;
  }
  if (
    moment(item.DatetimeFrom) < moment() &&
    moment() < moment(item.DatetimeTo)
  ) {
    isTimeMatchForOnGoingStatus = true;
  }
  if (
    isStatusMatchForOnGoingStatus &&
    isReportMatchForOnGoingStatus &&
    isTimeMatchForOnGoingStatus
  ) {
    isOnGoingStatus = true;
  }
  return isOnGoingStatus;
}

export const isSpaceOrEnterPressed = (event, callback) => {
  const { key } = event;

  if (key === 'Enter' || key === ' ') {
    event.preventDefault();
    if (callback) {
      callback();
    } else {
      return true;
    }
  }
  return false;
};
