import * as actionTypes from '../actions/ActionTypes';

const INITIAL_STATE = {
  notificationList: [],
  assignmenNotificationList: [],
  unreadNotificationList: [],
};

const notification = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      return INITIAL_STATE;

    case actionTypes.UPDATE_NOTIFICATION_LIST: {
      const { notificationList } = action;
      return {
        ...state,
        notificationList,
        unreadNotificationList: notificationList.filter(
          item => !item.IsRead,
        ),
      };
    }
    case actionTypes.UPDATE_ASSIGNMENT_NOTIFICATION: {
      const { notificationList } = action;
      return {
        ...state,
        assignmenNotificationList: notificationList,
      };
    }

    default:
      return state;
  }
};

export default notification;
