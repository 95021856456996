import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ChartContainer } from '../Charts';
import { generateUniqueId } from '../../../Share/utils';

const DashboardWidget = ({ data, dataReadyFlag, ...rest }) => {
  const [chartData, setChartData] = useState(data);
  useEffect(() => {
    if (data.length !== chartData.length) {
      setChartData(data);
    }
  }, [ data]);
  return (
    <ChartContainer dashboard key={`${rest.key}_dc_${generateUniqueId()}`} data={data} {...rest} dataReadyFlag={dataReadyFlag} />
  );
};

DashboardWidget.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  dataReadyFlag: PropTypes.bool,
};
DashboardWidget.defaultProps = {
  data: [],
  dataReadyFlag: false,
};
const mapStateToProps = (state, props) => {
  const data = state.reportDataSources[props.reportId].crossFilterData[props.tableSourceId];
  const dataReadyFlag = state.reportDataSources[props.reportId].dataReadyFlags[props.tableSourceId];
  return ({
    data: data || [],
    dataReadyFlag: dataReadyFlag || false,
  });
};


export default connect(mapStateToProps, null)(DashboardWidget);
