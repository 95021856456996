import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Translate } from '..';
import { LOCAL_STORE_LANGUAGE } from '../../constants/localStorage';
import { APP_PORTAL } from '../../constants';


let lang = localStorage.getItem(LOCAL_STORE_LANGUAGE);
switch (lang) {
  case 'en':
    lang = 'en';
    break;
  case 'se':
    lang = 'sv-SE';
    break;
  default:
    lang = 'sv-SE';
    break;
}

const propTypes = {
  date: PropTypes.shape({}),
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onNewOrderClick: PropTypes.func,
  children: PropTypes.node,
};

const defaultProps = {
  date: PropTypes.shape({}),
  onPrevious: PropTypes.func,
  onNewOrderClick: PropTypes.func,
  onNext: PropTypes.func,
  children: null,
};

const convertMonthtoSwediss = (enName, lang) => {
  if (lang === 'en') return enName;
  let swName = '';
  switch (enName) {
    case 'January': {
      swName = 'Januari';
      break;
    }
    case 'February': {
      swName = 'Februari';
      break;
    }
    case 'March': {
      swName = 'Mars';
      break;
    }
    case 'April': {
      swName = 'April';
      break;
    }
    case 'May': {
      swName = 'Maj';
      break;
    }
    case 'June': {
      swName = 'Juni';
      break;
    }
    case 'July': {
      swName = 'Juli';
      break;
    }
    case 'August': {
      swName = 'Augusti';
      break;
    }
    case 'September': {
      swName = 'September';
      break;
    }
    case 'October': {
      swName = 'Oktober';
      break;
    }
    case 'November': {
      swName = 'November';
      break;
    }
    case 'December': {
      swName = 'December';
      break;
    }
    default: break;
  }
  return swName;
};

const Header = ({
  date, onPrevious, onNext, children,onNewOrderClick,
}) => {
  const { month, year } = date;
  const currentMonth = (moment().year(year).month(month));
  return (
    <div className="tk-monthly-calendar__header  tk-calendar__header">
      {APP_PORTAL === 'interpreter-portal' ?
        <div className="tv-display-flex" style={{flexDirection:'column', position:"absolute",left:'5px',top: '5px', lineHeight:'1.2rem', fontSize:'0.675rem'}}>
          <div className="tv-display-flex"><span className="tk-monthly-calendar__item-availability-icon" />Tillgänglig</div>
          <div className="tv-display-flex"><span className="tk-monthly-calendar__item-unavailability-icon" />Ej tillgänglig</div>
        </div>
        : null
      }
      <div className="tk-calendar__title">
        <button
          aria-label='förra månad'
          onClick={onPrevious}
          className="tk-calendar__button tk-calendar__button--previous"
          tabIndex={0}
        />
        <span >
          {` ${convertMonthtoSwediss(currentMonth.format('MMMM'), lang)} `}
          {year}
        </span>
        <button
          aria-label='nästa månad'
          onClick={onNext}
          className="tk-calendar__button tk-calendar__button--next"
          tabIndex={0}
        />
      </div>
      {(children)}
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
