import React, { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import ValidatorForm from 'react-form-validator-core/lib/ValidatorForm';
import { PreviewAssignment } from '.';
import { Modal, Translate, TFVButton } from '../../../Share/components';
import {
  isAddressEdited,
  isContactPersonValid,
  isIE,
} from '../../../Share/utils';
import { currentDate } from '../../../Share/utils/dateUtil';
import { subscribe } from '../../actions/eventBus';
import ConfirmDuplicateOrder from './ConfirmDuplicateOrder';
import SingleAssignmentForm from './SingleAssignmentForm';
import SuccessMessage from './SuccessMessage';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createNewOrder, hideNewBooking, updateOrder } from '../../actions';
import { setEmptyCreateNewOrderErrors } from '../../actions/workAssignment';

ValidatorForm.addValidationRule('urlformat', value => {
  if (value && value == '') return true;
  if (value && (value.startsWith('http://') || value.startsWith('https://'))) {
    return true;
  }
  return false;
});
ValidatorForm.addValidationRule('validEmail', value => {
  if ((value && value == '') || !value) return true;
  let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (value.match(mailformat)) {
    return true;
  }
  return false;
});

const propTypes = {
  onCreateNewOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
  onUpdateCreatedOrderErrors: PropTypes.func,
  customerDefaultAddress: PropTypes.shape({}),
  onHideNewBooking: PropTypes.func,
  isDefaultDateUpdated: PropTypes.bool,
  updateDate: PropTypes.func,
  setDefaultDate: PropTypes.func,
};
const defaultProps = {
  onCreateNewOrder: () => {},
  onUpdateOrder: () => {},
  onUpdateCreatedOrderErrors: () => {},
  customerDefaultAddress: {},
  onHideNewBooking: () => {},
  isDefaultDateUpdated: false,
  updateDate: () => {},
  setDefaultDate: () => {},
};

const AdvancedAssigmentWizard = ({
  assignment,
  handleValidatorListener,
  handleChange,
  handleModal,
  languages,
  profile,
  onCloseAdvancedModal,
  onCloseBooking,
  onCreateNewDefaultForm,
  onSwitchToDetailOrder,
  visible,
  isAlternativeAddressAdded,
  addAlternativeAddress,
  handleNewEmailChange,
  onCreateNewOrder,
  onUpdateOrder,
  onUpdateCreatedOrderErrors,
  customerDefaultAddress,
  onHideNewBooking,
  isDefaultDateUpdated,
  updateDate,
  setDefaultDate,
  isDateValid,
  onDateInvalid,
}) => {
  const [showStep, setShowStep] = useState(
    assignment?.typeOfAssignment?.name?.includes('Video')
      ? {
          showFirstStep: true,
          showSecondStep: false,
        }
      : {
          showFirstStep: false,
          showSecondStep: true,
        },
  );
  const [showDuplicateOrderError, setShowDuplicateOrderError] = useState('');
  const [successFulOrder, setSuccessFulOrder] = useState({
    showMessage: false,
    messageDetail: [],
  });

  const [showPreview, setShowPreview] = useState(false);
  const [showConfirmDuplicateOrder, setShowConfirmDuplicateOrder] =
    useState(false);
  const [duplicateAssignmentDetails, setDuplicateAssignmentDetails] = useState(
    {},
  );
  const scrollRef = useRef(null);
  const formRef = useRef(null);
  const [secondStepFormState, setSecondStateFormState] = useState({
    allowFileUpload:
      (assignment.files && assignment.files.length > 0) ||
      (assignment.attachmentFileNames &&
        assignment.attachmentFileNames.length > 0)
        ? true
        : false,
    validation: { error: null },
  });

  const handleFormError = e => {
    if (e.length > 0) {
      e[0].input.scrollIntoView
        ? e[0].input.scrollIntoView({ behavior: 'smooth' })
        : e[0].input._element.scrollIntoView({ behavior: 'smooth' });
    } else {
      e.input.scrollIntoView
        ? e.input.scrollIntoView({ behavior: 'smooth' })
        : e.input._element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const ConvergeValidate = () => {
    if (!assignment.dateRange.ranges.startDate) {
      onDateInvalid();
    }
    let isValid = true;
    const selectedDate =
      assignment.dateRange.ranges.startDate.format('DD/MM/YYYY');
    const todayDate = currentDate().format('DD/MM/YYYY');
    if (selectedDate === todayDate && !assignment.isEdit) isValid = false;
    return isValid;
  };

  const handleSubmit = () => {
    let isValid = true;
    let validContactPersonUponInterpretation = true;
    let validContactPersonOrderer = true;
    if (ConvergeValidate()) {
      if (
        !isContactPersonValid(
          profile.ContactPersonUponInterpretationRequiresEmail,
          assignment.contactPerson,
          assignment.contactPersons,
        )
      ) {
        validContactPersonUponInterpretation = false;
      }
      if (
        !isContactPersonValid(
          profile.ContactPersonOrdererRequiresEmail,
          assignment.orderContactPerson,
          assignment.contactPersons,
        )
      ) {
        validContactPersonOrderer = false;
      }
      isValid =
        validContactPersonUponInterpretation && validContactPersonOrderer;
      onSwitchToDetailOrder({
        ...assignment,
        isAddressEdited: isAddressEdited(assignment, profile),
      });
      if (isValid) {
        setShowPreview(true);
      }
    }
  };

  useEffect(() => {
    subscribe('SuccessFullOrderCreation', ({ detail, assignmentDetails }) => {
      if (assignmentDetails) {
        setShowPreview(false);
        setSuccessFulOrder({
          showMessage: true,
          messageDetail: assignmentDetails,
        });
      }
    });
  }, []);

  const ModalHeaderRender = () => (
    <div
      className={`tv-workassignment__modal-header row ml-0 ${
        isIE ? 'ie-fix-width' : ''
      }`}
    >
      <span className="col-5 pl-0">
        {Translate({
          content:
            // eslint-disable-next-line no-nested-ternary
            showStep.showFirstStep || showStep.showSecondStep
              ? assignment.isEdit
                ? 'dashboard.editAssignment'
                : 'singleAssignment.assigmentBooking'
              : !successFulOrder.showMessage
              ? 'singleAssignment.assignmentDetail'
              : 'singleAssignment.successModalHeader',
        })}
      </span>
      <span className="secondary-heading">
        {showStep.showFirstStep && (
          <Translate content="singleAssignment.wizardFirstStep" />
        )}
        {showStep.showSecondStep && (
          <Translate content="singleAssignment.wizardSecondStep" />
        )}
      </span>
    </div>
  );

  const onClickSubmit = e => {
    if (assignment.typeOfAssignment.value != '1') {
      if (showStep.showSecondStep) {
        if (secondStepFormState.allowFileUpload) {
          if (
            (assignment.files && assignment.files.length > 0) ||
            (assignment.attachmentFileNames &&
              assignment.attachmentFileNames.length > 0)
          ) {
            handleSubmit(e);
            setShowStep({
              showSecondStep: false,
              showFirstStep: false,
            });
          } else {
            setSecondStateFormState({
              ...secondStepFormState,
              validation: { error: 'Välj filerna' },
            });
          }
        } else {
          handleSubmit(e);
          setShowStep({
            showSecondStep: false,
            showFirstStep: false,
          });
        }
      } else {
        setShowStep({
          showSecondStep: true,
          showFirstStep: false,
        });
      }
    } else {
      handleSubmit(e);
      setShowStep({
        showSecondStep: false,
        showFirstStep: false,
      });
    }
  };

  const handleConfirmDuplicateOrder = (
    assignment,
    orderEmail,
    errorMessage,
  ) => {
    assignment.DoForce = 1;
    setShowConfirmDuplicateOrder(true);
    setDuplicateAssignmentDetails({ ...assignment, orderEmail });
    setShowDuplicateOrderError(errorMessage);
  };

  const handleSubmitDuplicateOrder = () => {
    if (duplicateAssignmentDetails.isEdit) {
      onUpdateOrder({
        ...duplicateAssignmentDetails,
      });
    } else {
      onCreateNewOrder({
        ...duplicateAssignmentDetails,
      });
    }
    setShowConfirmDuplicateOrder(false);
  };

  const handleCloseDuplicateOrder = () => {
    setShowConfirmDuplicateOrder(false);
    onUpdateCreatedOrderErrors();
    // setShowPreview(false);
  };

  const handleDuplicateOrderIcon = () => {
    setShowConfirmDuplicateOrder(false);
    onUpdateCreatedOrderErrors();
    // setShowPreview(false);
  };

  return (
    <>
      <Modal
        className="tv-workassignment__modal"
        onCloseClicked={() => {
          if (successFulOrder.showMessage) {
            onCloseBooking();
            onHideNewBooking();
          } else {
            onCloseAdvancedModal();
          }
        }}
        isVisible={visible}
        headerRender={<ModalHeaderRender />}
        ieOptionClassName={isIE ? 'ie-fix-height' : ''}
        ariaLabel="off"
      >
        {successFulOrder.showMessage && (
          <SuccessMessage
            onClose={() => {
              onCloseBooking();
              onHideNewBooking();
            }}
            mode={assignment.isEdit ? 'edit' : 'assignment'}
            onContinueBooking={() => {
              onCreateNewDefaultForm();
              setSuccessFulOrder({
                showMessage: false,
                messageDetail: {},
              });
              setShowStep({
                showSecondStep: false,
                showFirstStep: true,
              });
              setDefaultDate();
            }}
            visible={successFulOrder.showMessage}
            assignmentDetails={successFulOrder.messageDetail}
          />
        )}
        {(showStep.showFirstStep || showStep.showSecondStep) && (
          <ValidatorForm
            ref={formRef}
            onSubmit={onClickSubmit}
            onError={handleFormError}
          >
            <div
              className="d-flex flex-column border-top-0 mb-5 mb-md-0 assignment__modal_body"
              style={{
                overflowY: 'auto',
                height: 'auto',
                overflowX: 'hidden',
              }}
            >
              {/* <Scrollbars
                ref={scrollRef}
                className="tv-advanced-order__scroll-container"
              > */}
              <SingleAssignmentForm
                {...assignment}
                secondStepFormState={secondStepFormState}
                setSecondStateFormState={setSecondStateFormState}
                showOtherRequirementSection
                validatorListener={handleValidatorListener}
                showFirstStep={showStep.showFirstStep}
                showSecondStep={showStep.showSecondStep}
                onChange={handleChange}
                onHandleModal={handleModal}
                languages={languages}
                createdFrom="singleAssignment"
                ContactPersonOrdererRequiresEmail={
                  profile.ContactPersonOrdererRequiresEmail
                }
                ContactPersonUponInterpretationRequiresEmail={
                  profile.ContactPersonUponInterpretationRequiresEmail
                }
                isSingle
                isAlternativeAddressAdded={isAlternativeAddressAdded}
                addAlternativeAddress={addAlternativeAddress}
                handleNewEmailChange={handleNewEmailChange}
                customerDefaultAddress={customerDefaultAddress}
                isDefaultDateUpdated={isDefaultDateUpdated}
                updateDate={updateDate}
                isDateValid={isDateValid}
              />
              {/* </Scrollbars> */}
            </div>
            <div className="d-flex flex-column tv-workassignment__modal-footer">
              <div className="tv-workassignment__detail-buttons justify-content-between tv-display-flex">
                {showStep.showFirstStep && (
                  <>
                    <TFVButton
                      type="secondary"
                      buttonType="submit"
                      className="w-50 ml-1"
                      text={
                        assignment.typeOfAssignment.value != '1'
                          ? Translate({
                              content: 'singleAssignment.gotoSecondStep',
                            })
                          : Translate({
                              content: 'singleAssignment.previewAssignment',
                            })
                      }
                    />
                    <TFVButton
                      type="line-primary"
                      buttonType="button"
                      className="w-50 ml-1"
                      text={Translate({
                        content: 'general.close',
                      })}
                      onClick={onCloseAdvancedModal}
                    />
                  </>
                )}
                {showStep.showSecondStep && (
                  <>
                    <TFVButton
                      type="secondary"
                      buttonType="submit"
                      className="tv-single-assign-form__button-preview"
                      text={Translate({
                        content: 'singleAssignment.previewAssignment',
                      })}
                    />
                    <TFVButton
                      type="line-primary"
                      buttonType="button"
                      className="w-50 ml-1"
                      text={Translate({
                        content: 'singleAssignment.goBack',
                      })}
                      onClick={e => {
                        setShowStep({
                          showFirstStep: true,
                          showSecondStep: false,
                        });
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </ValidatorForm>
        )}
        {showPreview && (
          <PreviewAssignment
            onClose={() => {
              setShowPreview(false);
              if (assignment.typeOfAssignment.value != '1') {
                setShowStep({
                  showSecondStep: true,
                  showFirstStep: false,
                });
              } else {
                setShowStep({
                  showSecondStep: false,
                  showFirstStep: true,
                });
              }
            }}
            showPreview={showPreview}
            handleConfirmDuplicateOrder={handleConfirmDuplicateOrder}
            customerDefaultAddress={customerDefaultAddress}
          />
        )}

        {showConfirmDuplicateOrder && (
          <ConfirmDuplicateOrder
            onSubmit={handleSubmitDuplicateOrder}
            onClose={handleCloseDuplicateOrder}
            onCloseIcon={handleDuplicateOrderIcon}
            errorMessage={showDuplicateOrderError}
          />
        )}
      </Modal>
    </>
  );
};

AdvancedAssigmentWizard.propTypes = propTypes;
AdvancedAssigmentWizard.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => ({
  onCreateNewOrder: assignment => {
    dispatch(createNewOrder(assignment));
  },
  onUpdateOrder: assignment => {
    dispatch(updateOrder(assignment));
  },
  onUpdateCreatedOrderErrors: () => {
    dispatch(setEmptyCreateNewOrderErrors());
  },
  onHideNewBooking: () => {
    dispatch(hideNewBooking());
  },
});

export default connect(null, mapDispatchToProps)(AdvancedAssigmentWizard);
