import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './Modal.scss';
import { Translate } from '../../../Share/components';

function StyledDropzone({
  attachments,
  setAttachments,
  errorMessage,
  setErrorMessage,
}) {
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    getSelectedAttachments(acceptedFiles);
  }, [acceptedFiles]);

  async function getSelectedAttachments(attachmentList) {
    let files = await Promise.all(
      attachmentList.map(attachment => {
        return readAsDataURL(attachment);
      }),
    );
    setAttachments([...attachments, ...files]);
  }

  function readAsDataURL(file) {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = function () {
        return resolve({
          data: fileReader.result.replace(
            new RegExp('^data:.*;base64,', 'gm'),
            '',
          ),
          name: file.name,
          size: file.size,
          type: file.type,
          isDocumentSubjectToConfidentiality: false,
          requestForStampedDocument: false,
        });
      };
      fileReader.readAsDataURL(file);
    });
  }

  const isDocumentSubjectToConfidentialityHandler = (e, i) => {
    attachments[i].isDocumentSubjectToConfidentiality = e.target.checked;
    setAttachments([...attachments]);
  };

  const requestForStampedDocumentHandler = (e, i) => {
    attachments[i].requestForStampedDocument = e.target.checked;
    setAttachments([...attachments]);
  };

  const deleteAttachment = index => {
    attachments.splice(index, 1);
    setAttachments([...attachments]);
    if (attachments.length === 0) {
      setErrorMessage(true);
    }
  };

  const files = attachments.map((file, index = 0) => (
    <div key={index} className="tv-dropzone__uploader">
      <div className="tv-dropzone__file">
        <div
          className="tv-dropzone__file__details"
          style={{ alignItems: 'center', cursor: 'pointer' }}
        >
          <i
            className="fa fa-file mr-2"
            aria-hidden="true"
            style={{ color: '#74737b' }}
          />
          <div className="tv-dropzone__file__details-path">{file.name}</div>
        </div>
        <div
          tabIndex={0}
          role="button"
          className="tv-dropzone__file__delete"
          onClick={() => deleteAttachment(index)}
          onKeyPress={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              deleteAttachment(index);
            }
          }}
          aria-label={`${file.name} ${Translate({ content: 'emailTranslation.fileRemove' })}`}
        >
          <span className="k-icon k-i-delete" />
        </div>
      </div>
      <div className="tv-dropzone__option">
        <div className="tv-modal__container__full-box center">
          <span>
            <input
              aria-label={Translate({
                content:
                  'singleEmailTranslation.isDocumentSubjectToConfidentiality',
              })}
              checked={
                file.isDocumentSubjectToConfidentiality == true
                  ? 'checked'
                  : null
              }
              name="isDocumentSubjectToConfidentiality"
              type="checkbox"
              onChange={e =>
                isDocumentSubjectToConfidentialityHandler(e, index)
              }
            />
          </span>
          <div className="tv-modal__container__full-box-label">
            <Translate content="singleEmailTranslation.isDocumentSubjectToConfidentiality" />
          </div>
        </div>
        <div className="tv-modal__container__full-box center">
          <span>
            <input
              aria-label={Translate({
                content: 'singleEmailTranslation.requestForStampedDocument',
              })}
              checked={
                file.requestForStampedDocument == true ? 'checked' : null
              }
              type="checkbox"
              onChange={e => requestForStampedDocumentHandler(e, index)}
            />
          </span>
          <div className="tv-modal__container__full-box-label">
            <Translate content="singleEmailTranslation.requestForStampedDocument" />
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <div className="tv-dropzone__container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div className="tv-dropzone__text">
            <Translate content="singleEmailTranslation.dragAndDropFiles" />
          </div>
          <div className="tv-dropzone__text align-center">
            <Translate content="singleEmailTranslation.or" />
          </div>
          <button
            className="tv-dropzone__upload-btn"
            type="button"
            onClick={open}
            aria-label={Translate({content: 'singleEmailTranslation.dragAndDropFilesOrBrowseFiles'})}
          >
            <Translate content="singleEmailTranslation.browseFiles" />
          </button>
        </div>
      </div>
      {errorMessage && (
        <div className="error-message-attachments">
          Lägg till minst en bilaga
        </div>
      )}
      <div className="tv-dropzone__list">{files}</div>
    </>
  );
}

export default StyledDropzone;
