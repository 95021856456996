import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Translate } from '../../../Share/components';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';

const setNavLinkIsActive = (match, location) => {
  return match || /submit-time/.test(location.pathname);
};

function SideBar({ showKPIDashboard, reports }) {
  const reportTestIds = [...Object.values(reports)];
  const scrollContainer = useRef();
  return (
    <nav id="sidebar" className="tv-left-pannel__sidebar">
      <NavLink
        isActive={setNavLinkIsActive}
        exact
        to="/"
        className="tv-left-pannel__sidebar-item"
      >
        <i className="icon-home tv-left-pannel__sidebar-icon tv-icon-fixed-width" />
        <span>
          <Translate content="sideBar.dashboard" />
        </span>
      </NavLink>
      {showKPIDashboard ? (
        <NavLink
          to={`/reports-dashboard/`}
          className="tv-left-pannel__sidebar-item"
        >
          <i className="icon-schedule tv-left-pannel__sidebar-icon tv-icon-fixed-width" />
          <span>
            {Translate({
              content: 'report.statistics',
            })}
          </span>
        </NavLink>
      ) : null}
      <NavLink to="/my-assignment" className="tv-left-pannel__sidebar-item">
        <i className="icon-browse-assignments tv-left-pannel__sidebar-icon tv-icon-fixed-width" />
        <span>
          <Translate content="sideBar.myAssignment" />
        </span>
      </NavLink>
      <NavLink
        to={{
          pathname: '/report-quality',
          state: { isGeneral: true },
        }}
        className="tv-left-pannel__sidebar-item"
      >
        <i className="icon-available-request tv-left-pannel__sidebar-icon tv-icon-fixed-width" />
        <span>
          <Translate content="sideBar.reportQuality" />
        </span>
      </NavLink>
      {/* {showKPIDashboard ? (
                <React.Fragment>
                    <PanelBar>
                        <PanelBarItem
                            title={Translate({ content: "report.statistics" })}
                            iconclassName="icon-schedule tv-left-pannel__sidebar-icon "
                            className="tv-left-pannel__header-menu"
                        >
                            <Scrollbars
                                ref={scrollContainer}
                                className="report___sidemenu-scroll"
                            >
                                <NavLink
                                    to={`/reports-dashboard/`}
                                    className="tv-left-pannel__sidebar-item"
                                    style={{ fontSize: "0.8rem" }}
                                >
                                    <i className="icon-browse-assignments tv-left-pannel__sidebar-icon tv-icon-fixed-width" />
                                    <span>
                                        {Translate({
                                            content: "report.reportDashboard",
                                        })}
                                    </span>
                                </NavLink>
                                {reportTestIds.map((x) => (
                                    <NavLink
                                        to={`/reports/${x.id}`}
                                        className="tv-left-pannel__sidebar-item"
                                        style={{ fontSize: "0.8rem" }}
                                        key={x.id}
                                    >
                                        <i className="icon-browse-assignments tv-left-pannel__sidebar-icon tv-icon-fixed-width" />
                                        <span>
                                            {Translate({ content: x.title })}
                                        </span>
                                    </NavLink>
                                ))}
                            </Scrollbars>
                        </PanelBarItem>
                    </PanelBar>
                </React.Fragment>
            ) : null} */}
      <NavLink to="/contact-tfv" className="tv-left-pannel__sidebar-item">
        <i className="icon-contact tv-left-pannel__sidebar-icon tv-icon-fixed-width" />
        <span>
          <Translate content="sideBar.contactTFV" />
        </span>
      </NavLink>
    </nav>
  );
}
SideBar.propTypes = {
  showKPIDashboard: PropTypes.bool,
  reports: PropTypes.arrayOf(PropTypes.shape({})),
};
SideBar.defaultProps = {
  showKPIDashboard: false,
  reports: [],
};
const mapStateToProps = state => ({
  reports: state.reportDetails.reports,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
