import React from 'react';
import { truncateFileName } from '../../../Share/utils/fileUtil';

const AttachedFileList = ({ files, onRemove, isPreviewMode }) => {
  if (!files || files?.length <= 0) return null;
  return (
    <div
      className={`tv-single-assign-form__file-list ${
        isPreviewMode
          ? 'tv-single-assign-form__file-list-container-secondary'
          : 'tv-single-assign-form__file-list-container-primary'
      }`}
    >
      {files.map(({ name }, index) => (
        <AttachedFile
          fileName={name}
          onRemove={() => onRemove(index)}
          isPreviewMode={isPreviewMode}
        />
      ))}
    </div>
  );
};

const AttachedFile = ({ fileName, onRemove, isPreviewMode }) => {
  const handleOnRemoveFile = () => {
    onRemove();
  };

  return (
    <div className="tv-single-assign-form__file-container">
      <div className="item">
        <i className="tv-single-assign-form__file-container-icon fa fa-file"></i>{' '}
        {truncateFileName(fileName, 10, '...')}
      </div>
      {!isPreviewMode && (
        <div
          onClick={handleOnRemoveFile}
          className="tv-single-assign-form__file-container-rm item"
          tabIndex={0}
          onKeyPress={handleOnRemoveFile}
          role='button'
          aria-label={`${fileName} Ta bort`}
        >
          Ta bort
        </div>
      )}
    </div>
  );
};

export default AttachedFileList;
