import FocusTrap from 'focus-trap-react';
import React from 'react';
import { Translate } from '../../../Share/components';

function ResultModal({ onClose }) {
  return (
    <>
      <div className="tv-modal__wrapper tv-display-flex tv-route-leaving-guard-modal">
        <div
          className="tv-modal__overlay"
          onClick={() => onClose(false)}
          aria-label="modal overlay"
        ></div>
        <FocusTrap focusTrapOptions={{ escapeDeactivates: false }}>
          <div className="tv-modal__container" aria-live="polite">
            <div className="tv-modal__container__header">
              <div className="tv-modal__container__header-title">
                <Translate content="emailTranslation.successModalHeader" />
              </div>
              <button
                className="tv-buttons__type icon-close"
                onClick={() => onClose(false)}
                aria-label={Translate({ content: 'general.close' })}
              />
            </div>
            <hr className="tv-modal__container__divider" />
            <div className="tv-modal__container__header">
              <div className="tv-modal__container__header-text">
                <Translate content="emailTranslation.resultModalText" />
              </div>
            </div>
            <div className="tv-route-leaving-guard-modal__buttons tv-display-flex">
              <button
                type="button"
                onClick={() => onClose(false)}
                className="tv-buttons__element tv-buttons__element--line-primary  tv-route-leaving-guard-modal-area-backBtn"
              >
                <Translate content="emailTranslation.continueOrder" />
              </button>
              <button
                className="tv-buttons__element tv-buttons__element--secondary  tv-route-leaving-guard-modal-area-submitBtn"
                onClick={() => {
                  onClose(false);
                }}
              >
                <Translate content="emailTranslation.clear" />
              </button>
            </div>
          </div>
        </FocusTrap>
      </div>
    </>
  );
}

export default ResultModal;
