import React, { useRef, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import TranslateSearchBar from './TranslateSearchBar';
import StatusFilter from '../StatusFilter';
import ValidatorForm from 'react-form-validator-core/lib/ValidatorForm';
import {
  LeftDrawerSlide,
  Dialog,
  AssignmentList,
  Translate,
  ArrowButton,
  IfComponent,
  DropdownList,
  RateFilter,
  Row,
  Col,
} from '../../../Share/components';
import moment from 'moment';
import './style.scss';
import TranslationList from './TranslationList';
import NewTranslation from './NewTranslation';
import DetailPanel from './DetailPanel';
import {
  fetchContactPersons,
  fetchTranslationSkills,
  getAttachment,
} from '../../actions';
import { FILTER_TYPES } from '../../../Share/constants';
import {
  getOrderTranslationData,
  updateOrderTranslationFilters,
} from '../../actions/OrderTranslation';
import { isEmpty, isEqual } from 'lodash';
import SearchBox from '../SearchBox/SearchBox';
import DateRangeFilter from '../MyAssignment/DateRangeFilter';
import { useLocation } from 'react-router';

const propTypes = {
  fetchTranslationSkills: PropTypes.func,
  executeGetContactPersons: PropTypes.func,
  updateOrderTranslationFilters: PropTypes.func,
  getOrderTranslationData: PropTypes.func,
  onGetAttachment: PropTypes.func,
};

const defaultProps = {
  fetchTranslationSkills: () => {},
  executeGetContactPersons: () => {},
  updateOrderTranslationFilters: () => {},
  getOrderTranslationData: () => {},
  onGetAttachment: () => {},
};

const Translation = ({
  fetchTranslationSkills,
  executeGetContactPersons,
  updateOrderTranslationFilters,
  getOrderTranslationData,
  onGetAttachment,
}) => {
  const [modal, setModal] = useState(false);
  const [showStatusFilter, setShowStatusFilter] = useState(false);
  const [showDetailPanel, setShowDetailPanel] = useState(false);
  const [translationDetail, setTranslationDetail] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [searchOrderValue, setSearchOrderValue] = useState('');
  const [keyPressFired, setKeyPressFired] = useState(false);
  const statusFilter = useSelector(
    state => state.orderTranslation.statusFilter,
  );
  const translationList = useSelector(
    state => state.orderTranslation.translationList,
  );
  const skills = useSelector(state => state.orderTranslation.translationSkills);
  const contactPersons = useSelector(state => state.user.contactPersons);
  const dateRange = useSelector(state => state.orderTranslation.duration);
  const [dateRangePicker, setDateRangePicker] = useState({
    selection: {
      startDate: dateRange.from,
      endDate: dateRange.to,
      key: 'selection',
    },
  });

  const location = useLocation();

  useEffect(() => {
    if (isEmpty(translationList)) {
      getOrderTranslationData(
        null,
        dateRangePicker.selection.startDate,
        dateRangePicker.selection.endDate,
      );
    }
    if (isEmpty(skills)) {
      fetchTranslationSkills();
    }
    if (isEmpty(contactPersons)) {
      executeGetContactPersons();
    }
    if (location.pathname === '/translation/new-translation') {
      createNewTranslation();
    }
  }, []);
  useEffect(() => {
    document.title = `Översättningar - TFV Kundportal`;
  }, []);

  const handleStatusFilter = value => {
    updateOrderTranslationFilters(FILTER_TYPES.STATUS_FILTER, value);
  };

  const searchHandler = event => {
    const searchValue = event.target.value;
    if (!isEmpty(searchValue)) {
      getOrderTranslationData(
        searchValue,
        dateRangePicker.selection.startDate,
        dateRangePicker.selection.endDate,
      );
    } else {
      if (!isEqual(searchValue, searchOrderValue)) {
        getOrderTranslationData(
          null,
          dateRangePicker.selection.startDate,
          dateRangePicker.selection.endDate,
        );
      }
    }
    setSearchOrderValue(searchValue);
  };

  const onKeyPress = event => {
    const { keyCode } = event;
    if (keyCode === 13) {
      setKeyPressFired(true);
      searchHandler(event);
    }
  };

  const onBlur = event => {
    if (!keyPressFired) {
      searchHandler(event);
    }
    setKeyPressFired(false);
  };

  let filteredAssignmnets = [];
  for (let i = 0; i <= statusFilter.length; i++) {
    let list = translationList.filter(
      item => item.OrderStatus === statusFilter[i],
    );
    if (list.length > 0) {
      filteredAssignmnets.push(...list);
    }
  }

  const handleRangeChange = payload => {
    payload.selection.endDate = moment(payload.selection.endDate).endOf('day');
    setDateRangePicker(payload);
  };

  const handleFilter = () => {
    getOrderTranslationData(
      searchOrderValue,
      dateRangePicker.selection.startDate,
      dateRangePicker.selection.endDate,
    );
  };

  const createNewTranslation = () => {
    setIsSelected(false);
    setModal(true);
    setShowDetailPanel(false);
  };

  const handleAllFilter = () => {
    setShowStatusFilter(false);
  };

  const searchRef = useRef(null);
  const inputRef = useRef(null);

  return (
    <div className="tv_translation__wrapper">
      <div className="tv_translation__main-panel w-100 row mt-3 mt-md-0 mx-0">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 px-0 pr-xl-3 tv-display-mobile-hide tabs d-flex flex-column">
          {
            <div>
              <SearchBox
                placeholder={Translate({
                  content: 'myfilter.searchTranslation',
                })}
                className="position-relative"
                onBlur={onBlur}
                onKeyUp={onKeyPress}
                ref={inputRef}
              />
              <DateRangeFilter
                ranges={dateRangePicker.selection}
                onChange={handleRangeChange}
                onClick={handleFilter}
              />
              <StatusFilter
                onClick={handleStatusFilter}
                selectedFilter={statusFilter}
              />
              {/* <RatingSummary /> */}
              <div className="tk-calendar__main-title mt-auto">
                <div>{Translate({ content: 'dashboard.welcome' })}</div>
                <CurrentTime />
              </div>
            </div>
          }
          {/* {showDetailPanel && (
            <DetailPanel
              setShowDetailPanel={setShowDetailPanel}
              translationDetail={translationDetail}
              setIsSelected={setIsSelected}
              setTranslationDetail={setTranslationDetail}
              handleGetAttachment={onGetAttachment}
            />
          )} */}
        </div>

        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 px-0 pl-1 mb-1">
          <div className="tv_translation__list-wrapper">
            <div className="tv_translation__list-header tv-display-mobile-hide">
              <div className="tv_translation__list-header-title">
                <Translate content="emailTranslation.translation" />
              </div>
              <button
                className="tv_translation__add-button"
                onClick={createNewTranslation}
              >
                <i className="fa fa-plus" />{' '}
                {Translate({
                  content: 'emailTranslation.addNew',
                })}
              </button>
            </div>

            <div className="tv_translation__list-header tv-display-mobile-show">
              <SearchBox
                placeholder={Translate({
                  content: 'myfilter.searchTranslation',
                })}
                className="position-relative"
                onBlur={onBlur}
                onKeyUp={onKeyPress}
                ref={searchRef}
              />
            </div>

            <TranslationList
              onClick={item => {
                setShowDetailPanel(true);
                setTranslationDetail(item);
                setIsSelected(true);
              }}
              isSelected={isSelected}
              translationDetail={translationDetail}
              translationList={filteredAssignmnets}
            />

            <div className="tv-translation__list__mobile-fixed-bottom">
              <button
                className="tv_translation__add-button"
                onClick={createNewTranslation}
              >
                <i className="fa fa-plus" />{' '}
                {Translate({
                  content: 'emailTranslation.addNew',
                })}
              </button>
              <button
                onClick={() => {
                  // this.setState({
                  //   showStatusFilter: true,
                  // });
                  setShowStatusFilter(true);
                }}
                className="tk-calendar__btn-filter mx-auto"
              >
                {Translate({ content: 'calendar.filter' })}
              </button>
            </div>
          </div>
        </div>
      </div>
      {modal && <NewTranslation onClose={setModal} />}
      {showDetailPanel && (
        <LeftDrawerSlide
          isVisible={showDetailPanel}
          onClose={() => {
            setShowDetailPanel(false);
          }}
          hideExternalScroll
          hideClose
          className="tv_translation__detail-container"
        >
          <DetailPanel
            setShowDetailPanel={setShowDetailPanel}
            translationDetail={translationDetail}
            setIsSelected={setIsSelected}
            setTranslationDetail={setTranslationDetail}
            handleGetAttachment={onGetAttachment}
          />
        </LeftDrawerSlide>
      )}
      {showStatusFilter && (
        <LeftDrawerSlide
          isVisible={showStatusFilter}
          onClose={() => {
            // this.setState({
            //   showStatusFilter: false,
            // });
            setShowStatusFilter(false);
          }}
          hideExternalScroll
          hideClose
          className="tv_translation__filter-drawer"
        >
          <Dialog
            className="tk-assignment-creation__container"
            isVisible
            onClose={() => {
              // this.setState({
              //   showStatusFilter: false,
              // });
              setShowStatusFilter(false);
            }}
          >
            <div className="tk-assignment-list-box_header">
              Filtrera uppdrag
            </div>
            <div className="tk-assignment-list-box_body ">
              <div className="quick-order-cont side ">
                <DateRangeFilter
                  ranges={dateRangePicker.selection}
                  onChange={handleRangeChange}
                  onClick={handleFilter}
                />

                <StatusFilter
                  // key={generateUniqueId()}
                  onClick={handleStatusFilter}
                  selectedFilter={statusFilter}
                />

                <div style={{ marginBottom: '100px' }} />
                <div className="d-flex tk-assigment-list-filter-footer">
                  <button
                    className="tv-buttons__element tv-buttons__element--line-primary mr-2 w-100"
                    onClick={handleAllFilter}
                  >
                    Rensa filter
                  </button>
                  <button
                    className="tv-buttons__element tv-buttons__element--secondary w-100"
                    onClick={handleAllFilter}
                  >
                    Filtrera
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
        </LeftDrawerSlide>
      )}
    </div>
  );
};

Translation.propTypes = propTypes;
Translation.defaultProps = defaultProps;

const mapStateToProps = state => {
  const {
    filters,
    assingmentPageList,
    otherFilter,
    ratingReasons,
    statusFilter,
    rateFilter,
    types,
    selectedType,
  } = state.workAssignment;
  const { notificationList } = state.notification;
  const defaultDateRange = state.user.calendar.range;
  const skills = state.skill.skills || [];
  return {
    filters,
    skills,
    otherFilter,
    assingmentPageList,
    defaultDateRange,
    ratingReasons,
    statusFilter,
    rateFilter,
    notificationList,
    types,
    selectedType,
    assignmentNotifications: state.notification.assignmenNotificationList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchTranslationSkills: () => {
    dispatch(fetchTranslationSkills());
  },
  executeGetContactPersons: () => {
    dispatch(fetchContactPersons());
  },
  updateOrderTranslationFilters: (e, filterType) => {
    dispatch(updateOrderTranslationFilters(e, filterType));
  },
  getOrderTranslationData: (searchValue, startDate, endDate) => {
    dispatch(getOrderTranslationData(searchValue, startDate, endDate));
  },
  onGetAttachment: attachmentIdentifier => {
    dispatch(getAttachment(attachmentIdentifier));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Translation);

const CurrentTime = ({ className }) => {
  const timeRef = useRef();
  useEffect(() => {
    const getTime = setInterval(() => {
      timeRef.current.innerText = moment().format('HH:mm');
    }, 3600);
    return () => {
      clearInterval(getTime);
    };
  }, []);
  return (
    <>
      <div className={`mt-2 ${className}`}>
        <i className="fa fa-clock mr-2" />
        <span ref={timeRef}>{moment().format('HH:mm')}</span>
      </div>
    </>
  );
};
