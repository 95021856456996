import React from 'react';
import { Translate } from '../../../Share/components';
import './Modal.scss';

function ConfirmDuplicateOrder({onSubmit, onClose, onCloseIcon, errorMessage}) {
  return (
    <>
      <div className="tv-modal__wrapper tv-display-flex tv-route-leaving-guard-modal">
        <div
          className="tv-modal__overlay"
          onClick={() => {}}
          aria-label="modal overlay"
        ></div>
        <div className="tv-modal__container">
          <div className="tv-modal__container__header">
            <div className="tv-modal__container__header-title">
            Dubblett Beställning
            </div>
            <button
              className="tv-buttons__type icon-close"
              onClick={onCloseIcon}
            ></button>
          </div>
          <hr className="tv-modal__container__divider" />
          <div className="tv-modal__container__header">
            <div className="tv-modal__container__header-text">
              {errorMessage}
            </div>
          </div>
          <div className="tv-route-leaving-guard-modal__buttons tv-display-flex">
            <button
              className="tv-buttons__element tv-buttons__element--line-primary  tv-route-leaving-guard-modal-area-backBtn"
              onClick={onClose}
            >
              <Translate content="emailTranslation.clear" />
            </button>
            <button
              type="button"
              onClick={onSubmit}
              className="tv-buttons__element tv-buttons__element--secondary  tv-route-leaving-guard-modal-area-submitBtn"
            >
              <Translate content="emailTranslation.continueOrder" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmDuplicateOrder;
