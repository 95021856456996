import React from 'react';
import './style.scss';
import TranslationItem from './TranslationItem';
import { Translate } from '../../../Share/components';

const TranslationList = ({
  onClick,
  isSelected,
  translationDetail,
  translationList,
}) => {
  return (
    <>
      <div className="tv_translation_list-container">
        <div className="row tv-translation-header__list-item tv-reportquality__list-item-header d-none d-lg-flex mb-0">
          <div className="col-12   col-md  col-lg col-xl">
            <div className="tv-translation-header__item-text">
              <Translate content="singleEmailTranslation.orderNumber" />
            </div>
          </div>

          <div className="col-12   col-md  col-lg col-xl">
            <div className="tv-translation-header__item-text">
              <Translate content="singleEmailTranslation.modeOfDelivery" />
            </div>
          </div>

          <div className="col-12   col-md  col-lg col-xl">
            <div className="tv-translation-header__item-text">
              <Translate content="singleEmailTranslation.deliveryDate" />
            </div>
          </div>

          <div className="col-12   col-md  col-lg col-xl">
            <div className="tv-translation-header__item-text">
              <Translate content="singleEmailTranslation.sourceLanguage" />
            </div>
          </div>

          <div className="col-12   col-md  col-lg col-xl">
            <div className="tv-translation-header__item-text">
              <Translate content="singleEmailTranslation.targetLanguage" />
            </div>
          </div>

          <div className="col-12   col-md  col-lg col-xl">
            <div className="tv-translation-header__item-text">
              <Translate content="singleEmailTranslation.contactPersonOrderer" />
            </div>
          </div>

          <div className="col-12   col-md  col-lg col-xl text-right">
            <Translate content="singleEmailTranslation.status" />
          </div>
        </div>

        {translationList.length > 0 ? (
          translationList.map(item => (
            <TranslationItem
              onClick={onClick}
              isSelected={
                translationDetail.OrderNumber == item.OrderNumber && isSelected
              }
              item={item}
              key={item?.OrderIdentifier}
            />
          ))
        ) : (
          <div className="tv-translation__empty-text">
            Inga översättningsdata tillgängliga
          </div>
        )}
      </div>
    </>
  );
};

export default TranslationList;
