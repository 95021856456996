import React from 'react';
import PropTypes from 'prop-types';
import IfComponent from '../IfComponent';

const propTypes = {
  children: PropTypes.node,
  button: PropTypes.node,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  ariaControls: PropTypes.string,
};

const defaultProps = {
  children: null,
  button: null,
  className: 'tv-filter-list__element',
  onBlur: () => {},
  tabIndex: 0,
  disabled: false,
  ariaLabel: '',
  ariaControls: '',
};

class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMenu: false };
  }

  componentDidMount() {
    this.ismounted = true;
    document.addEventListener('keydown', this.onEscapePress);
  }

  componentWillUnmount() {
    this.ismounted = false;
    document.removeEventListener('keydown', this.onEscapePress);
  }

  onEscapePress = e => {
    if (e.key === 'Escape' && this.state.showMenu) {
      this.setState({
        showMenu: false,
      });
    }
  };

  onHiddenMenu = () => {
    if (this.ismounted) {
      this.setState({
        showMenu: false,
      });
    }
  };

  onToggleMenu = () => {
    const { showMenu } = this.state;
    this.setState({
      showMenu: !showMenu,
    });
  };

  onBlur = event => {
    const { currentTarget } = event;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.onHiddenMenu();
      }
    }, 250);
    const { onBlur } = this.props;
    onBlur();
  };

  render() {
    const { showMenu } = this.state;
    const {
      children,
      button,
      className,
      disabled,
      tabIndex,
      ariaLabel,
      ariaControls,
    } = this.props;

    return (
      <span onBlur={this.onBlur} className={className}>
        <div
          role="button"
          tabIndex={tabIndex}
          onClick={disabled ? '' : this.onToggleMenu}
          onKeyPress={e => {
            if (disabled) return;
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              this.onToggleMenu();
            }
          }}
          className="tv-form__element-focus dropdown-button"
        >
          {button}
        </div>
        <IfComponent condition={showMenu} whenTrue={children} />
      </span>
    );
  }
}

DropdownMenu.propTypes = propTypes;
DropdownMenu.defaultProps = defaultProps;

export default DropdownMenu;
