import React, { Component } from 'react';
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { acceptedFileType } from '../../../Share/constants';
import { IfComponent, Translate, TFVButton } from '../../../Share/components';
import AttachedFileList from './AttachedFileList';
import { truncateFileName } from '../../../Share/utils/fileUtil';

const initialAttachmentData = null;
const initValidation = {
  error: null,
};

const FileUpload = ({
  files,
  onAttach,
  onRemove,
  isPreviewMode,
  setSecondStateFormState,
  secondStepFormState,
  disabled,
}) => {
  const [attachmentData, setAttachmentData] = useState(initialAttachmentData);
  const [validation, setValidation] = useState(initValidation);
  const fileRef = useRef(null);

  const handleOnFileSelect = event => {
    const targetFiles = event.target.files;
    const reader = new FileReader();
    const file = targetFiles[0];
    reader.onloadend = async () => {
      const attachment = {
        name: file.name,
        type: file.type,
        data: reader.result.replace(new RegExp('^data:.*;base64,', 'gm'), ''),
        size: file.size,
      };
      setAttachmentData(attachment);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setValidation({ error: null });
  };

  const handleOnAddToList = () => {
    if (!attachmentData) {
      setValidation({ error: 'Välj filen först' });
    } else {
      setSecondStateFormState({
        ...secondStepFormState,
        validation: { error: null },
      });
      onAttach(attachmentData);
      fileRef.current.value = null;
      setAttachmentData(initialAttachmentData);
    }
  };

  return (
    <>
      {!isPreviewMode && (
        <>
          <div className="tv-single-assign-form__file__buttons">
            <>
              <input
                ref={fileRef}
                name="attach-file"
                type="file"
                id="attach-file"
                onChange={handleOnFileSelect}
                disabled={disabled}
              />
              <label
                for="attach-file"
                title="Ladda upp"
                tabIndex={0}
                onKeyPress={() => fileRef.current.click()}
                role='button'
              >
                <i className="tv-single-assign-form__file-container-icon fa fa-paperclip"></i>{' '}
                {attachmentData
                  ? truncateFileName(attachmentData.name, 10, '...')
                  : 'Ladda upp'}
              </label>
            </>
            <TFVButton
              type="line-primary"
              text="Välj fil"
              onClick={handleOnAddToList}
              disabled={disabled}
            />
          </div>
          {validation.error && (
            <div className="tv-single-assign-form__file__err-label">
              * {validation.error}
            </div>
          )}
        </>
      )}
      <div className="tv-single-assign-form__file-list-container">
        <AttachedFileList
          files={files}
          onRemove={onRemove}
          isPreviewMode={isPreviewMode}
        />
      </div>
    </>
  );
};

export default FileUpload;
