import React from 'react';
import './style.scss';
import { Translate } from '../../../Share/components';

const ContactPersonItem = ({ item, onEdit }) => (
  <div className="row  custom tv-reportquality__list-item  mb-0">
    <div className="row px-0 mx-0 w-100 tv-display-mobile-hide ">
      <div className="col-12 col-xs-4  col-sm-4 col-md  col-lg col-xl">
        <div className=" tv-contactperson__item-text">
          {' '}
          {item.FirstName} {item.LastName}
        </div>
      </div>
      <div className="col-12 col-xs-4  col-sm-4 col-md  col-lg col-xl ">
        <div className=" tv-contactperson__item-text">
          {item.Email ? item.Email : '-'}
        </div>
      </div>
      <div className="col-12 col-xs-4  col-sm-4 col-md  col-lg col-xl ">
        <div className=" tv-contactperson__item-text">
          {item.PhoneNumberDirect || item.PhoneNumberMobile || '-'}
        </div>
      </div>
      <div
        className="col-12 col-xs-6  col-sm-6 col-md col-lg col-xl edit-contact"
        onClick={() => onEdit(item)}
        tabIndex={0}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onEdit(item);
          }
        }}
        role="button"
      >
        <div
          className="tv-contactperson__item-edit   text-center"
          title="kommer snart"
        >
          {' '}
          <Translate content="manageUsers.edit" />
        </div>
      </div>
    </div>
    <div className="tv-contactperson-item--mobile flex-column mx-0 px-2 tv-display-mobile-show w-100 h-100">
      <div className="tv-reportquality__item-text tv-contactperson__item-text mt-2 pt-1 justify-content-between">
        <b className="tv-workassignment__detail-status text-capitalize">
          {item.FirstName} {item.LastName}
        </b>
        <div
          onClick={() => onEdit(item)}
          tabIndex={0}
          role="button"
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onEdit(item);
            }
          }}
          className="tv-contactperson__item-text-focus"
        >
          {' '}
          <div
            className="tv-contactperson__item-edit   text-center"
            title="kommer snart"
          >
            {' '}
            <Translate content="manageUsers.edit" />
          </div>
        </div>
      </div>

      <div className="tv-workassignment__item-text">
        <div className="tv-workassignment__item-text-label">
          <i className="fa fa-envelope mr-1" /> Email:
        </div>
        {item.Email ? item.Email : '-'}
      </div>
      <div className="tv-workassignment__item-text">
        <div className="tv-workassignment__item-text-label">
          <i className="fa fa-phone-alt mr-1" /> Telefon:{' '}
        </div>
        {item.PhoneNumberDirect ? item.PhoneNumberDirect : '-'}
      </div>
    </div>

    {/* <div className="tv-contactperson__item-status tv-display-flex  justify-content-between">
        <span className="tv-contactperson__status tv-contactperson__status--losted">
          AVBÖJT
        </span>
      </div> */}
  </div>
);
export default ContactPersonItem;
