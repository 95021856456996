import React from 'react';
import PropTypes from 'prop-types';
import { ValidatorComponent } from 'react-form-validator-core';
import classNames from 'classnames';
import { DropDownList as DropDown } from '@progress/kendo-react-dropdowns';
import IfComponent from '../IfComponent';
import ReactDOM from 'react-dom';

const propTypes = {
  errorMessages: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  validators: PropTypes.arrayOf(PropTypes.string),
  validatorListener: PropTypes.func,
  value: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  optionWithAction: PropTypes.shape({}),
  className: PropTypes.string,
  defaultItem: PropTypes.shape({}),
  rest: PropTypes.shape({}),
  disabled: PropTypes.bool,
  filterable: PropTypes.bool,
  filterChange: PropTypes.func,
  ariaLabel: PropTypes.string,
};

const defaultProps = {
  errorMessages: [],
  optionWithAction: {
    actionValue: '',
    actionText: '',
    visible: false,
  },
  defaultItem: { name: 'Välj', value: '' },
  validators: ['matchRegexp:(.*)'],
  validatorListener: () => {},
  value: '',
  options: [],
  className: '',
  footer: null,
  rest: {},
  disabled: false,
  filterable: false,
  filterChange: () => {},
  ariaLabel: '',
};

class DropdownList extends ValidatorComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    this.resolveDropdownAccissibility();
    this.configure();
  }

  componentDidUpdate(prevProps, prevState) {
    this.resolveDropdownAccissibility();
    // Taken from super component
    if (this.instantValidate && this.props.value !== prevState.value) {
      this.validateDebounced(
        this.props.value,
        this.props.withRequiredValidator,
      );
    }
  }

  componentWillUnmount() {
    this.context.form.detachFromForm(this);
    this.validateDebounced.cancel();
  }

  resolveDropdownAccissibility() {
    const dropdownNode = ReactDOM.findDOMNode(this.dropdownRef.current);
    if (dropdownNode) {
      const spanElement = dropdownNode.querySelector('span > span');
      const selectElement = dropdownNode.querySelector('span > span > select');
      if (selectElement) {
        selectElement.setAttribute('role', 'select');
      }
      if (spanElement) {
        const attribute = spanElement.getAttribute('aria-expanded');
        if (attribute && attribute === 'false') {
          spanElement.setAttribute('aria-activedescendant', '');
        }
        spanElement.setAttribute('role', 'combobox');
      }
    }
  }

  render() {
    const {
      errorMessages,
      validators,
      validatorListener,
      optionWithAction,
      value,
      options,
      defaultItem,
      className,
      footer,
      disabled,
      itemRender,
      onOpen,
      onClose,
      opened,
      popupSettings,
      filterable,
      filterChange,
      ariaLabel,
      ...rest
    } = this.props;

    const { isValid } = this.state;
    const controlClassName = classNames({
      'tv-form__control': true,
      'tv-form__control--error': !isValid && this.getErrorMessage().length,
      [className]: !!className,
      'tv-form__element-focus': true,
    });
    return (
      <React.Fragment>
        <DropDown
          ref={this.dropdownRef}
          defaultItem={defaultItem}
          className={controlClassName}
          name={rest.name}
          data={options}
          textField="name"
          dataItemKey="value"
          value={value}
          onChange={rest.onChange}
          footer={footer}
          disabled={disabled}
          itemRender={itemRender}
          onOpen={onOpen}
          onClose={onClose}
          opened={opened}
          popupSettings={popupSettings}
          filterable={filterable}
          onFilterChange={filterChange}
          ariaLabelledBy={rest.name}
        />

        <IfComponent
          condition={this.getErrorMessage().length > 0}
          whenTrue={
            <span className="tv-form__error">
              {typeof isValid === 'boolean' && !isValid
                ? this.getErrorMessage()
                : ''}
            </span>
          }
        />
        <span id={rest.name} style={{ display: 'none' }}>
          {ariaLabel}
        </span>
      </React.Fragment>
    );
  }
}

DropdownList.propTypes = propTypes;
DropdownList.defaultProps = defaultProps;

export default DropdownList;
