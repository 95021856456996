import { toast } from 'react-toastify';
import axios from 'axios';
import { tfvLog } from '../../Share/utils';

import { Translate } from '../../Share/components';
import { errorCode, LOCAL_STORE_TOKEN } from '../../Share/constants';
import store from '../store';
import { autoCreateNewToken } from '../actions/authentication';

const ignoreErrorNotifyRequests = ['GetTimeReportSignature'];

let isRefreshing = false;
const refreshSubscribers = [];

const subscribeTokenRefresh = cb => {
  refreshSubscribers.push(cb);
};

const onRrefreshed = () => {
  refreshSubscribers.forEach(cb => cb());
};

export const responseInterceptor = async response => {
  // Because multisoft api always return status 200 even when response error
  // But axios library base on response status code to decide error or not
  // Therefore we can not catch error on `catch` block on pattern axios.get(...).then(...).catch(...)
  // So we base on response.data.Errors of Multisoft to decide response is error or not
  let hideErrorMessage = false;
  if (response.data.Errors) {
    response.data.Errors.forEach(element => {
      if (element.ErrorCode === 1082) {
        hideErrorMessage = true;
      }
      if (element.ErrorCode === 1062) {
        hideErrorMessage = true;
      }
    });
    if (hideErrorMessage) return response;
    const { config } = response;
    const originalRequest = config;

    if (response.data.Errors[0].ErrorCode === errorCode.invalidToken) {
      if (!isRefreshing) {
        isRefreshing = true;
        await store.dispatch(autoCreateNewToken());
        isRefreshing = false;
        setTimeout(() => {
          onRrefreshed();
        }, 0);
      }

      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh(() => {
          const initToken = localStorage.getItem(LOCAL_STORE_TOKEN);
          originalRequest.data = JSON.parse(originalRequest.data);
          originalRequest.data.Token = initToken;
          originalRequest.data = JSON.stringify(originalRequest.data);
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    }
    response.data.Errors.forEach(element => {
      const isIgnoreNotify =
        ignoreErrorNotifyRequests.filter(
          r => originalRequest.url.indexOf(r) !== -1,
        ).length > 0;
      if (!isIgnoreNotify) toast.error(element.ErrorMessage);
    });
    return Promise.reject(response.data.Errors);
  }
  return response;
};

export const errorInterceptor = error => {
  toast.error(Translate({ content: 'error.commonError' }));
  tfvLog('error interceptor');
  return Promise.reject(error);
};
