import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { groupBy } from 'lodash';
import NotificationIcon from './NotificationIcon';
import NotificationMenuDropdown from './NotificationMenuDropdown';
import GeneralNotification from './GeneralNotification';
import { IsMobileView, Translate } from '../../../Share/components';
import { convertStringToDate } from '../../../Share/utils/dateUtil';
import history from '../../../Share/utils/history';
import useWindowSize from '../../../Share/hooks/useWindowSize';
import './style.scss';

import { setNotificationReadStatus, getNotificationList } from '../../actions';

function NotificationContainer({
  allNotifications,
  unreadNotifications,
  isPage,
  className,
  onSetNotificationReadStatus,
  GetNotifications,
}) {
  const { isMobileView } = useWindowSize();

  const handleCardClick = (
    title,
    WorkAssignmentIdentifiers,
    notificationId,
  ) => {
    history.push(`/my-assignment?notification_id=${notificationId}`);
  };
  const [visibleNotificationDropdown, setVisibleNotificationDropdown] =
    useState(null);

  const handleCheckbox = (notification, isRead) => {
    const { NotificationIdentifier } = notification;
    onSetNotificationReadStatus(NotificationIdentifier, isRead);
  };

  const clipNotification = () => {
    const clippedNotifications = allNotifications.filter(
      item => moment(item.DatetimeSent) > moment().subtract(3, 'days'),
    );
    const limitedNotifications =
      clippedNotifications.length > 30
        ? clippedNotifications.slice(0, 30)
        : clippedNotifications;
     const totalUnreadNotifications = limitedNotifications.filter(item => item.IsRead === false).length;
    return {
      limitedNotifications,
      totalUnreadNotifications,
    };
};

  const groupedNotifications = () => {
    const sorted = allNotifications.sort((a, b) =>
      convertStringToDate(b.DatetimeSent).diff(a.DatetimeSent),
    );
    const grouped = groupBy(sorted, item =>
      convertStringToDate(item.DatetimeSent).format('YYYY-MM-DD'),
    );
    return Object.entries(grouped).map(([key, value]) => ({
      date: key,
      notifications: value,
    }));
  };

  const redirectToNotification = () => {
    setVisibleNotificationDropdown(false);
    history.push('/notifications');
  };

  return (
    <>
      {!isPage && (
        <NotificationMenuDropdown
          visible={visibleNotificationDropdown}
          setVisible={setVisibleNotificationDropdown}
          button={
            <NotificationIcon
              count={clipNotification().totalUnreadNotifications}
              onClick={() => {}}
              ariaExpanded={visibleNotificationDropdown ? 'true' : 'false'}
              ariaControls="notification-container"
            />
          }
        >
          <div className="tv-notification__container" id='notification-container'>
            <div className="tv-notification__list">
              {clipNotification().limitedNotifications
                .sort((a, b) =>
                  convertStringToDate(b.DatetimeSent).diff(a.DatetimeSent),
                )
                .map(
                  (item, index) =>
                    item.Headline !== null && (
                      <>
                        <GeneralNotification
                          key={item.NotificationIdentifier}
                          index={index}
                          title={item.Headline}
                          notificationIdentifier={item.NotificationIdentifier}
                          message={item.Message}
                          date={item.DatetimeSent}
                          isNew={!item.IsRead}
                          item={item}
                          handleCardClick={handleCardClick}
                          visibleMarkAsRead={!item.IsRead || false}
                          visibleButtons={item.visibleButtons || false}
                          handleCheck={value => handleCheckbox(item, value)}
                        />
                      </>
                    ),
                )}
            </div>
            {/* <NavLink to="/notifications"> */}
            <button
              onClick={() => redirectToNotification()}
              type="button"
              className="tv-notification__button"
              tabIndex={0}
            >
              {Translate({ content: 'notification.goToNotificationPage' })}
            </button>
            {/* </NavLink> */}
          </div>
        </NotificationMenuDropdown>
      )}
      {isPage && (
        <div className={`tv-notification__page ${className.pageClass}`}>
          <h2 className="tv-notification__page__title">
            {Translate({ content: 'notification.title' })}
          </h2>
          <div className="tv-notification__container-fluid  ">
            <div className="">
              {groupedNotifications().map(item => (
                <div key={item.NotificationIdentifier}>
                  <div className="tv-notification__date">{item.date}</div>
                  {item.notifications.map(
                    (notification, index) =>
                      notification.Headline !== null && (
                        <GeneralNotification
                          index={index}
                          title={notification.Headline}
                          notificationIdentifier={item.NotificationIdentifier}
                          message={notification.Message}
                          date={notification.DatetimeSent}
                          isNew={!notification.IsRead}
                          isPage={isPage}
                          item={notification}
                          handleCardClick={handleCardClick}
                          visibleMarkAsRead={!notification.IsRead || false}
                          visibleButtons={notification.visibleButtons || false}
                          handleCheck={value =>
                            handleCheckbox(notification, value)
                          }
                        />
                      ),
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

NotificationContainer.propTypes = {
  allNotifications: PropTypes.shape(PropTypes.array),
  unreadNotifications: PropTypes.shape(PropTypes.array),
  isPage: PropTypes.bool,
  className: PropTypes.shape({ iconClass: '', pageClass: '' }),
  onSetNotificationReadStatus: PropTypes.func,
  GetNotifications: PropTypes.func,
};
NotificationContainer.defaultProps = {
  allNotifications: [],
  unreadNotifications: [],
  isPage: false,
  className: { iconClass: '', pageClass: '' },
  onSetNotificationReadStatus: () => {},
  GetNotifications: () => {},
};

const mapStateToProps = (state, props) => ({
  allNotifications: state.notification.notificationList,
  unreadNotifications: state.notification.unreadNotificationList,
});

const mapDispatchToProps = dispatch => ({
  onSetNotificationReadStatus: (
    notificationIdentifier,
    isRead,
    successCallback = () => {},
  ) => {
    dispatch(
      setNotificationReadStatus(
        notificationIdentifier,
        isRead,
        successCallback,
      ),
    );
  },
  GetNotifications: (orderIdentifier = null) => {
    dispatch(getNotificationList(orderIdentifier));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationContainer);
