import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartTitle,
  ChartTooltip,
  ChartSeriesItemTooltip,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisTitle,
  ChartLegend,
} from '@progress/kendo-react-charts';
import SmallDetailedCard from '../Report/SmallDetailCard';
import { kFormatter, numberFormat } from '../../../Share/utils/reportUtil';
import { ThreeDotLoader, Translate } from '../../../Share/components';

const ChartContainer = ({ data, dashboard, chartType, ...rest }) =>
  dashboard ? (
    <DashboardChart data={data} chartType={chartType} {...rest} />
  ) : (
    <ChartRender data={data} chartType={chartType} {...rest} />
  );

const labelContent = props => {
  const formatedNumber = Number(props.percentage).toLocaleString('se-SV', {
    style: 'percent',
    minimumFractionDigits: 1,
  });
  return `${props.text} : ${formatedNumber}`;
};
const DashboardChart = ({
  data,
  reportId,
  categoryField,
  field,
  title,
  onChartItemclicked,
  type,
  dataReadyFlag,
}) => (
  <React.Fragment>
    <div className="dashboard__chart-holder">
      <div className="auto-row-2" style={{ position: 'relative' }}>
        <div className="exact-col-1" style={{ height: '40px' }}>
          {/* <NavLink to={`/reports/${reportId}`} style={{}}>
            <span
              className="k-icon k-i-more-horizontal"
              style={{
                float: 'right',
                paddingTop: '10px',
                color: '#322878',
                fontSize: '1.2rem',
              }}
            />
          </NavLink> */}
        </div>
        {(() => {
          switch (type) {
            case 2:
              return (
                <React.Fragment>
                  <div
                    className="dashboard__display-card-header"
                    style={{
                      float: 'left',
                    }}
                  >
                    <span style={{ float: 'left' }}>{title}</span>
                  </div>
                  {!dataReadyFlag ? (
                    <ThreeDotLoader />
                  ) : (
                    <div className="exact-col-2">
                      {data
                        .sort((a, b) => b[field] - a[field])
                        .slice(0, 8)
                        .map(x => (
                          <SmallDetailedCard
                            onSeriesClick={onChartItemclicked}
                            categoryField={categoryField}
                            key={x[categoryField]}
                            heading={x[categoryField]}
                            content={kFormatter(x[field])}
                            rawContent={numberFormat(x[field])}
                          />
                        ))}
                    </div>
                  )}
                </React.Fragment>
              );
            default:
              return (
                <React.Fragment>
                  <div className="exact-col-2">
                    <div className="dashboard__display-card round-border">
                      <div
                        className="dashboard__display-card-header"
                        style={{
                          float: 'left',
                        }}
                      >
                        <span style={{ float: 'left' }}>{title}</span>
                        <span
                          style={{
                            float: 'right',
                            paddingRight: '10px',
                          }}
                        ></span>
                      </div>

                      <div
                        style={{
                          display: 'grid',
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            float: 'left',
                            alignSelf: 'center',
                            backgroundColor: 'transparent',
                          }}
                        >
                          {dataReadyFlag ? (
                            data?.length != 0 ? (
                              <Chart
                                renderAs="canvas"
                                style={{ width: '100%' }}
                                onSeriesClick={e => onChartItemclicked(e)}
                                onLegendItemClick={e => onChartItemclicked(e)}
                              >
                                <ChartLegend
                                  position="bottom"
                                  orientation="verticle"
                                  labels={{ content: labelContent }}
                                />
                                <ChartSeries>
                                  <ChartSeriesItem
                                    visual={e => {
                                      return e.createVisual();
                                    }}
                                    size={20}
                                    type="pie"
                                    overlay={{
                                      gradient: 'roundedBevel',
                                    }}
                                    tooltip={{ visible: true }}
                                    data={[
                                      ...data
                                        .sort((a, b) => b[field] - a[field])
                                        .slice(0, 8),
                                      data.length > 8
                                        ? {
                                            [categoryField]: 'Övrig',
                                            [field]: data
                                              .sort(
                                                (a, b) => b[field] - a[field],
                                              )
                                              .slice(8)
                                              .reduce(
                                                (acc, current) =>
                                                  acc +
                                                  (current[field]
                                                    ? current[field]
                                                    : 0),
                                                0,
                                              ),
                                          }
                                        : {},
                                    ]}
                                    categoryField={categoryField}
                                    field={field}
                                  >
                                    {/* <ChartSeriesLabels color="#554444" background="none" /> */}
                                  </ChartSeriesItem>
                                </ChartSeries>
                              </Chart>
                            ) : (
                              <div className="dashboard__display-card_nodata">
                                <Translate content="report.noDataAvailableForTime" />
                              </div>
                            )
                          ) : (
                            <ThreeDotLoader />
                          )}
                        </div>
                      </div>
                    </div>
                    {!dataReadyFlag ? (
                      <ThreeDotLoader style={{ marginTop: '60px' }} />
                    ) : (
                      <div className="exact-col-2">
                        {data
                          .sort((a, b) => b[field] - a[field])
                          .slice(0, 8)
                          .map(x => (
                            <SmallDetailedCard
                              onSeriesClick={onChartItemclicked}
                              categoryField={categoryField}
                              key={x[categoryField]}
                              heading={x[categoryField]}
                              content={numberFormat(x[field])}
                              rawContent={numberFormat(x[field])}
                            />
                          ))}
                      </div>
                    )}
                  </div>
                </React.Fragment>
              );
          }
        })(type)}
      </div>
    </div>
  </React.Fragment>
);

function simpleChartRender(
  categoryField,
  data,
  fields,
  title,
  chartType,
  onChartItemclicked,
) {
  const mainKey = categoryField;
  const yAxisValues = {};
  const xAxisValues = data.map(obj => obj[categoryField]);
  let keyOriginal =
    data.length > 0
      ? Object.keys(data[0]).filter(type => type !== categoryField)
      : [];
  if (fields !== '') {
    keyOriginal = [fields];
  }
  keyOriginal.forEach(key => {
    const tempYAxisValues = [];
    data.forEach(obj => {
      tempYAxisValues.push(obj[key] ? Number(obj[key]) : 0);
    });
    yAxisValues[key] = [...tempYAxisValues];
  });
  return (
    <React.Fragment>
      <div />
      <div style={{ display: 'flex', width: '100%' }}>
        {keyOriginal.map(key => {
          const yValue = yAxisValues[key];
          return (
            <Chart
              pannable
              seriesType={categoryField}
              onSeriesClick={e => onChartItemclicked(e)}
              onLegendItemClick={e => onChartItemclicked(e)}
              style={{
                width: '100%',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              <ChartLegend position="right" orientation="verticle" />
              <ChartTitle text={Translate({ content: `report.${key}` })} />
              <ChartTooltip format="{0}" />
              <ChartSeries>
                <ChartSeriesItem
                  type={chartType}
                  labels={{ content: e => ({ ...e, category: key }) }}
                  name={Translate({ content: `report.${key}` })}
                  gap={3}
                  spacing={0.25}
                  data={yValue}
                >
                  <ChartSeriesItemTooltip background="green" />
                </ChartSeriesItem>
              </ChartSeries>
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  labels={{ rotation: 'auto' }}
                  categories={xAxisValues}
                  max={20}
                  maxDivisions={20}
                >
                  <ChartCategoryAxisTitle
                    text={Translate({ content: `report.${mainKey}` })}
                  />
                </ChartCategoryAxisItem>
              </ChartCategoryAxis>
            </Chart>
          );
        })}
      </div>
    </React.Fragment>
  );
}
function groupedColumnChartRender(
  categoryField,
  data,
  fields,
  title,
  chartType,
  onChartItemclicked,
) {
  const mainKey = categoryField;
  const xAxisValues = data.map(obj => obj[categoryField]);
  const yAxisTypes = [];
  data.forEach(obj => {
    const types = Object.keys(obj).filter(type => type !== categoryField);
    types.forEach(type => {
      if (yAxisTypes.indexOf(type) < 0) yAxisTypes.push(type);
    });
  });
  const yAxisValues = {};

  yAxisTypes.forEach(type => {
    const tempYAxisValues = [];
    data.forEach(obj => {
      tempYAxisValues.push(obj[type] ? Number(obj[type]) : 0);
    });
    yAxisValues[type] = [...tempYAxisValues];
  });
  return (
    <React.Fragment>
      <div style={{ display: 'flex', width: '100%' }}>
        <Chart
          pannable={{ lock: 'y' }}
          zoomable={{ mousewheel: { lock: 'y' }, selection: { lock: 'y' } }}
          style={{
            width: '100%',
            fontFamily: "'Source Sans Pro', sans-serif",
          }}
          seriesType={categoryField}
          onSeriesClick={e => onChartItemclicked(e)}
          onLegendItemClick={e => {
            e.preventDefault();
          }}
        >
          <ChartLegend position="right" orientation="verticle" />
          <ChartTitle text={Translate({ content: `report.${fields}` })} />
          <ChartTooltip format="{0}" />
          <ChartSeries>
            {yAxisTypes.map(x => (
              <ChartSeriesItem
                type={chartType}
                gap={3}
                spacing={0.25}
                name={x}
                data={yAxisValues[x]}
              >
                <ChartSeriesItemTooltip background="green" />
              </ChartSeriesItem>
            ))}
          </ChartSeries>
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              labels={{ rotation: 'auto' }}
              categories={xAxisValues}
              max={20}
              maxDivisions={20}
            >
              <ChartCategoryAxisTitle
                text={Translate({ content: `report.${mainKey}` })}
              />
            </ChartCategoryAxisItem>
          </ChartCategoryAxis>
        </Chart>
      </div>
    </React.Fragment>
  );
}
function subGroupedColumnChartRender(
  categoryField,
  data,
  fields,
  title,
  chartType,
  onChartItemclicked,
) {
  const mainKey = categoryField;
  const xAxisValues = data.map(obj => obj[categoryField]);
  const yAxisTypes = [];
  data.forEach(obj => {
    const types = Object.keys(obj).filter(type => type !== categoryField);
    types.forEach(type => {
      if (yAxisTypes.indexOf(type) < 0) yAxisTypes.push(type);
    });
  });
  return (
    <React.Fragment>
      <div style={{ display: 'flex', width: '100%' }}>
        {fields.map(field => {
          const tempYAxisTypes = [
            ...yAxisTypes.filter(type => type.includes(field)),
          ];
          const yAxisValues = {};

          tempYAxisTypes.forEach(type => {
            const tempYAxisValues = [];
            data.forEach(obj => {
              tempYAxisValues.push(obj[type] ? Number(obj[type]) : 0);
            });
            yAxisValues[type] = [...tempYAxisValues];
          });
          return (
            <React.Fragment>
              <Chart
                pannable={{ lock: 'y' }}
                zoomable={{
                  mousewheel: { lock: 'y' },
                  selection: { lock: 'y' },
                }}
                seriesType={categoryField}
                onSeriesClick={e => onChartItemclicked(e)}
                onLegendItemClick={e => {
                  e.preventDefault();
                }}
                style={{
                  width: '100%',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                <ChartLegend position="bottom" orientation="horizontal" />
                <ChartTitle text={Translate({ content: `report.${field}` })} />
                <ChartTooltip format="{0}" />
                <ChartSeries>
                  {tempYAxisTypes.map(x => (
                    <ChartSeriesItem
                      type={chartType}
                      gap={3}
                      spacing={0.25}
                      name={x.split('-')[0]}
                      data={yAxisValues[x]}
                    >
                      <ChartSeriesItemTooltip background="green" />
                    </ChartSeriesItem>
                  ))}
                </ChartSeries>
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    labels={{ rotation: 'auto' }}
                    categories={xAxisValues}
                    max={2}
                    maxDivisions={20}
                  >
                    <ChartCategoryAxisTitle
                      text={Translate({ content: `report.${mainKey}` })}
                    />
                  </ChartCategoryAxisItem>
                </ChartCategoryAxis>
              </Chart>
            </React.Fragment>
          );
        })}
      </div>
    </React.Fragment>
  );
}
function simpleAreaChartRender(
  categoryField,
  data,
  fields,
  title,
  chartType = 'area',
  onChartItemclicked,
) {
  const key = '';

  const mainKey = '';
  return (
    <React.Fragment>
      <div />
      <div style={{ display: 'flex', width: '100%' }}>
        <Chart
          pannable
          style={{
            width: '100%',
            fontFamily: "'Source Sans Pro', sans-serif",
          }}
        >
          <ChartLegend position="right" orientation="verticle" />
          <ChartTitle text={Translate({ content: `chart.${title}` })} />
          <ChartTooltip format="{0}" />
          <ChartSeries>
            <ChartSeriesItem
              type={chartType}
              data={data}
              field={fields}
              categoryField={categoryField}
              line={{ style: 'smooth' }}
            />
          </ChartSeries>
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              baseUnit="hours"
              maxDivisions={10}
              labels={{ rotation: 'auto' }}
            />
          </ChartCategoryAxis>
        </Chart>
      </div>
    </React.Fragment>
  );
}
const ChartRender = ({
  data,
  chartType,
  title,
  categoryField,
  field,
  type,
  onChartItemclicked,
}) => {
  switch (type) {
    case 1: {
      return groupedColumnChartRender(
        categoryField,
        data,
        field,
        title,
        chartType,
        onChartItemclicked,
      );
    }
    case 2: {
      return subGroupedColumnChartRender(
        categoryField,
        data,
        field,
        title,
        chartType,
        onChartItemclicked,
      );
    }
    case 4: {
      return simpleAreaChartRender(
        categoryField,
        data,
        field,
        title,
        chartType,
        onChartItemclicked,
      );
    }
    default:
      return simpleChartRender(
        categoryField,
        data,
        field,
        title,
        chartType,
        onChartItemclicked,
      );
  }
};

export default ChartContainer;
