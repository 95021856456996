import { tfvLog } from '../../Share/utils';

// https://developers.google.com/calendar/quickstart/js

export function isSignInGoogle() {
  tfvLog('Check sign in google');
  return window.gapi.auth2.getAuthInstance().isSignedIn.get();
}

export function signIn() {
  tfvLog('Start google sign in');
  return window.gapi.auth2.getAuthInstance().signIn();
}

export function signOut() {
  tfvLog('Google sign out');
  return window.gapi.auth2.getAuthInstance().signOut();
}

export function getGoogleEvents(from, to) {
  tfvLog(`Get google event from ${from} to ${to}`);
  return window.gapi.client.calendar.events.list({
    calendarId: 'primary',
    timeMin: from.toISOString(),
    timeMax: to.toISOString(),
    showDeleted: false,
    singleEvents: true,
    orderBy: 'startTime',
  });
}
