import React from 'react';
import PropTypes from 'prop-types';
import Translate from '../Translate';

const propTypes = {
  className: PropTypes.string,
  selected: PropTypes.string,
  onTabClick: PropTypes.func,
};

const defaultProps = {
  className: 'tk-calendar__tabs-wrapper',
  selected: 'individual',
  onTabClick: () => { },
};

class AssignmentTabs extends React.Component {
  constructor(props) {
    super(props);
    this.tabs = [
      { key: 'individual', name: 'My Assignments' },
      { key: 'all', name: 'All Assignments' },
    ];
  }

  render() {
    const { className, selected, onTabClick } = this.props;
    return (
      <ul className={className} style={{width: '200px'}}>
        {
          this.tabs.map((tab) => (
            <li key={tab.key}>
              <button className={`tk-calendar__tabs-button ${selected === tab.key ? 'selected' : ''}`}
                onClick={() => { onTabClick(tab.key) }}>
                {tab.name}
              </button>
            </li>
          ))
        }
      </ul>
    );
  }
}

AssignmentTabs.propTypes = propTypes;
AssignmentTabs.defaultProps = defaultProps;

export default AssignmentTabs;