import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Translate, TFVButton } from '../../../Share/components';
import moment from 'moment';

function AvailableInterpreters({
  onGoBack,
  onSubmit,
  data,
  visibleRetryMessage,
}) {
  const [selectedSlot, setSelectedSlot] = useState({});
  const [enableBookNowButton, setEnableBookNowButton] = useState(true);
  const selectSlot = slot => {
    setSelectedSlot(slot);
    setEnableBookNowButton(false);
  };
  const handleSubmit = () => {
    onSubmit(selectedSlot);
  };

  const filteredData =
    !isEmpty(data) &&
    data.filter(item => item.NumberOfAvailableResources !== 0);

  return (
    <div className="tv-available-interpreters__container">
      {!isEmpty(filteredData) && !visibleRetryMessage && (
        <div className="tv-available-interpreters__text">
          Tyvärr finns det ingen ledig tolk den önskade tiden. Se tillgängliga
          tolkar på närliggande tider nedan. Markera den tid du önskar och tryck
          på Boka nu.
        </div>
      )}
      {(isEmpty(filteredData) || visibleRetryMessage) && (
        <>
          <h1 className="tv-available-interpreters__title booking">
            Tyvärr finns det ingen tillgänglig tolk
          </h1>
          <div className="tv-available-interpreters__message">
            Tyvärr finns ingen tillgänglig tolk din önskade tid eller angränsade
            tider, försök gärna senare.
          </div>
          <div className="tv-available-interpreters__message">
            För hjälp från en tolkförmedlare, ring oss på{' '}
            <b className="tele-number">0770-813 000</b> vardagar kl. 08.00 -
            17.00.
          </div>
        </>
      )}
      {!isEmpty(filteredData) && (
        <div className="tv-available-interpreters__header">
          <div>Välj tillgänglig tid</div>
          <div>Antal lediga tolkar</div>
        </div>
      )}
      <div className="tv-available-interpreters__list">
        {!isEmpty(filteredData) &&
          filteredData.map(slot => (
            <div
              onClick={() => selectSlot(slot)}
              onKeyPress={() => selectSlot(slot)}
              tabIndex={0}
              role="button"
              className={`tv-available-interpreters__row ${
                slot === selectedSlot
                  ? 'tv-available-interpreters__row-active'
                  : ''
              }`}
            >
              <div>{slot.TimeFrom}</div>
              <div>{moment(slot.DatetimeFrom).format('YYYY-MM-DD')}</div>
              <div>
                <i className="fa fa-user" />
                {slot.NumberOfAvailableResources}
              </div>
            </div>
          ))}
      </div>
      <div className="d-flex flex-column">
        <div className="tv-booknow__form__buttons justify-content-between tv-display-flex">
          <TFVButton
            type="line-primary"
            text={Translate({
              content: 'bookNow.goBack',
            })}
            onClick={onGoBack}
          />
          {!isEmpty(filteredData) && (
            <TFVButton
              type="secondary"
              disabled={enableBookNowButton}
              text={Translate({
                content: 'bookNow.submitButton',
              })}
              onClick={() => {
                handleSubmit();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

AvailableInterpreters.propTypes = {
  onSubmit: PropTypes.func,
  onGoBack: PropTypes.func,
  data: PropTypes.shape([]),
  visibleRetryMessage: PropTypes.bool,
};
AvailableInterpreters.defaultProps = {
  onSubmit: () => {},
  onGoBack: () => {},
  data: [],
  visibleRetryMessage: false,
};

export default AvailableInterpreters;
