import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { checkOnGoingAssignment } from '../../../Share/utils';
import './style.scss';
import { AssignmentStatus, Translate } from '../../../Share/components';

const TranslationItem = ({ onClick, isSelected = false, item }) => {
  const [statusColor, setStatusColor] = useState(item.OrderStatus);
  const [statusName, setStatusName] = useState(
    item.ConsolidatedOrderStatus.StatusName,
  );
  const skills = useSelector(state => state.orderTranslation.translationSkills);
  let targetSkill = {};
  if (skills) {
    targetSkill = skills.find(
      skill => skill.SkillIdentifier === item.SkillIdentifierTarget,
    );
  }

  let timer;
  useEffect(() => {
    const updateStatusColor = () => {
      const checkonGoingOrder = checkOnGoingAssignment(item);
      if (checkonGoingOrder) {
        setStatusColor('onGoing');
        setStatusName('Tolkning pågår');
      } else {
        setStatusColor(item.OrderStatus);
        setStatusName(item.ConsolidatedOrderStatus.StatusName);
      }
      timer = setTimeout(updateStatusColor, 1000);
    };
    updateStatusColor();
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [item]);

  const onKeyDown = e => {
    if (e.key === 'Enter' || e.key === ' ') {
      onClick(item);
    }
  };

  return (
    <>
      <div
        className={`tv-workassignment__list-item ${
          isSelected ? 'selected text-light' : ''
        }`}
        onClick={() => onClick(item)}
        role="button"
        tabIndex={0}
        onKeyDown={onKeyDown}
      >
        <div className="row px-0 mx-0 w-100 tv-display-mobile-hide align-items-center">
          <div className="col-12 col-md col-lg col-xl order-0">
            <div className="tv-translation__item-text">{item.OrderNumber}</div>
          </div>

          <div className="col-12 col-md col-lg col-xl order-0">
            <div className="tv-translation__item-text">
              {item.ModeOfTransport}
            </div>
          </div>
          <div className="col-12 col-md col-lg col-xl order-0">
            <div className="tv-translation__item-text">
              {moment(item.DateDelivery).format('YYYY-MM-DD')}
            </div>
          </div>
          <div className="col-12 col-md col-lg col-xl order-0">
            <div className="tv-translation__item-text">{item.Skill}</div>
          </div>
          <div className="col-12 col-md col-lg col-xl order-0">
            <div className="tv-translation__item-text">
              {targetSkill && targetSkill.SkillName}
            </div>
          </div>
          <div className="col-12 col-md col-lg col-xl order-0">
            <div className="tv-translation__item-text text-contact-person">
              {item.ContactPersonOrderer.ContactPersonOrdererName}
            </div>
          </div>
          <div className="col-12 col-md col-lg col-xl order-0  d-flex justify-content-end">
            <div
              className={`tv-translation__item-text tv-translation__item-text-status tv-translation__item-text-status-${statusColor}`}
            >
              {statusName}
            </div>
          </div>
        </div>
        <div className="row px-0 mx-0 w-100 tv-display-mobile-show ">
          <AssignmentStatus
            className="tv-workassignment__detail-status tv-display-flex"
            status={statusColor}
            statusName={statusName}
          >
            <div>{item.ArticleName}</div>
          </AssignmentStatus>
          <hr />
          <div className="tv-workassignment__item-text">
            <div className="tv-workassignment__item-text-label">
              <i className="fa fa-plane mr-1" />{' '}
            </div>
            {item.ModeOfTransport}
          </div>
          <div className="tv-workassignment__item-text">
            <div className="tv-workassignment__item-text-label">
              <i className="fa fa-globe mr-1" />{' '}
              <Translate content="singleEmailTranslation.sourceLanguage" />:
            </div>
            {item.Skill}
          </div>
          <div className="tv-workassignment__item-text">
            <div className="tv-workassignment__item-text-label">
              <i className="fa fa-language mr-1" />{' '}
              <Translate content="singleEmailTranslation.targetLanguages" />:
            </div>
            {targetSkill && targetSkill.SkillName}
          </div>
          <div className="tv-workassignment__item-text">
            <div className="tv-workassignment__item-text-label">
              <i className="fa fa-book mr-1" />{' '}
              <Translate content="singleAssignment.orderNumber" />:
            </div>
            {item.OrderNumber}
          </div>
        </div>
      </div>
    </>
  );
};

export default TranslationItem;
