import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';


import { Translate } from '../../../Share/components';
import { GaugeWidget, PercentageBoxWidget } from '../Widgets';
import { connect } from 'react-redux';
import { summationBasedOnKey } from '../../../Share/utils/reportUtil';


const calculateAcceptedNotCancelled = (dataList) => {
  const totalAccepted = summationBasedOnKey(dataList, 'accepted');
  const totalCanceledByCustomer = summationBasedOnKey(dataList, 'cancelledByCustomer');
  return parseFloat(((totalAccepted - totalCanceledByCustomer) / totalAccepted) || 0);
};
const calculateCompletedNotComplaint = (dataList) => {
  const totalCompleted = summationBasedOnKey(dataList, 'completed');
  const totalDeviationCreated = summationBasedOnKey(dataList, 'deviation');
  const totalComplaintCreated = summationBasedOnKey(dataList, 'complaint');
  return parseFloat(((totalCompleted - (totalDeviationCreated + totalComplaintCreated)) / totalCompleted) || 0) * 100;
};
const calculateDeviationCaused = (dataList) => {
  const totalCompleted = summationBasedOnKey(dataList, 'completed');
  const totalDeviationCreated = summationBasedOnKey(dataList, 'deviation');
  return parseFloat(((totalCompleted - totalDeviationCreated) / totalCompleted) || 0) * 100;
};
function AvailabilityTodeliver({ fulfillmentData, dataReadyFlag }) {
  const [completedNotComplaint, setCompletedNotComplaint] = useState(calculateCompletedNotComplaint(fulfillmentData));
  const [acceptedNotCancelled, setAcceptedNotCancelled] = useState(calculateAcceptedNotCancelled(fulfillmentData));
  const [deviationCaused, setDeviationCaused] = useState(calculateDeviationCaused(fulfillmentData));
  useEffect(() => {
    setCompletedNotComplaint(calculateCompletedNotComplaint(fulfillmentData));
    setAcceptedNotCancelled(calculateAcceptedNotCancelled(fulfillmentData));
    setDeviationCaused(calculateDeviationCaused(fulfillmentData));
  }, [fulfillmentData]);
  return (
    <div className="auto-row-2">
      <div className="exact-col-1" style={{ height: '40px' }}>
        <NavLink to="/reports/10" style={{}}>
          <span
            className="k-icon k-i-more-horizontal"
            style={{
              float: 'right',
              paddingTop: '10px',
              color: '#322878',
              fontSize: '1.3rem',
            }}
          />
        </NavLink>
      </div>
      <div className="auto-col-2">
        <div className="dashboard__display-card round-border" style={{ minHeight: '301px' }}>
          <div
            className="dashboard__display-card-header"
            style={{ float: 'left' }}
          >
            <span style={{ float: 'left' }}>{Translate({ content: 'report.deilveryCapacity' })}
            </span>
            <span
              style={{
                float: 'right',
                paddingRight: '10px',
                fontSize: '2.5rem',
              }}
            >
              {/* {(totalCompleted / totalOrders) * 100} */}
            </span>
          </div>
          <div style={{
            display: 'flex', width: '100%', height: 'max-content', maxHeight: '100%', flexDirection: 'row', justifyContent: 'space-around',
          }}
          >
            <GaugeWidget value={acceptedNotCancelled} title={Translate({ content: 'report.deilveryCapacity' })} />
          </div>
        </div>
        <div style={{
          display: 'flex', width: '100%', height: '100%', flexDirection: 'column', justifyContent: 'space-around',
        }}
        >
          <PercentageBoxWidget value={completedNotComplaint} dataReadyFlag={dataReadyFlag} key="7676" title="Uppdrag utan anmärkning" />
          <PercentageBoxWidget value={deviationCaused} dataReadyFlag={dataReadyFlag} key="76762" title="Uppdrag utan avvikelse" />
        </div>
      </div>
    </div>
  );
}

AvailabilityTodeliver.propTypes = {

};

const mapStateToProps = (state, props) => {
  const dataReadyFlags = [state.reportDataSources[1].dataReadyFlags[0], state.reportDataSources[10].dataReadyFlags[1]];
  return ({
    dataReadyFlag: dataReadyFlags[1] || false,
    fulfillmentData: [...state.reportDataSources[10].crossFilterData[1] || []],
  });
};

export default connect(mapStateToProps,null)( AvailabilityTodeliver);

