import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { clone, isEqual, last } from 'lodash/core';
import {
  generateDefaultSession,
  generateUniqueId,
  isContactPersonValid,
  isIE,
} from '../../../Share/utils';
import {
  currentDate,
  getXTimefromSpecificDate,
} from '../../../Share/utils/dateUtil';
import {
  Translate,
  TextField,
  DropdownList,
  TFVDateRangePicker,
  TFVTimePickerV2,
  HelpContext,
  Checkbox,
} from '../../../Share/components';
import { DEFAULT_EMPTY_NAME } from '../../../Share/constants';
import './style.scss';
import InfoModal from './InfoModal';
import { cloneDeep, isEmpty, lte } from 'lodash-es';
import FileUpload from './FileUpload';
import AttachedFileList from './AttachedFileList';
import TagInput from '../../../Share/components/TagInput';
import { connect } from 'react-redux';

const gender = [
  // { value: "0", name: " - " },
  { value: '1', name: Translate({ content: 'general.male' }) },
  { value: '2', name: Translate({ content: 'general.female' }) },
];

const defaultFirstValue = {
  name: DEFAULT_EMPTY_NAME,
  value: undefined,
};

const propTypes = {
  onChange: PropTypes.func,
  validatorListener: PropTypes.func,
  isAllowLowerLevel: PropTypes.bool,
  isAllowOnlyLevel: PropTypes.bool,
  isSameProfile: PropTypes.bool,
  isHomeAddress: PropTypes.bool,
  isSameAbove: PropTypes.bool,
  genderRequirement: PropTypes.bool,
  skillEducationRequirement: PropTypes.bool,
  contactPersons: PropTypes.arrayOf(PropTypes.shape({})),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  dateRange: PropTypes.shape({}),
  contactPerson: PropTypes.string,
  profile: PropTypes.shape({}),
  languages: PropTypes.arrayOf(PropTypes.shape({})),
  files: PropTypes.arrayOf(PropTypes.shape({})),
  ContactPersonOrdererRequiresEmail: PropTypes.bool,
  ContactPersonUponInterpretationRequiresEmail: PropTypes.bool,
  isSingle: PropTypes.bool,
  showOtherRequirementSection: PropTypes.bool,
  showFirstStep: PropTypes.bool,
  showSecondStep: PropTypes.bool,
  handleNewEmailChange: PropTypes.func,
  DoShowLMANumber: PropTypes.bool,
  educationCompietancies: PropTypes.arrayOf(PropTypes.shape({})),
  isDefaultDateUpdated: PropTypes.bool,
  updateDate: PropTypes.func,
};

const defaultProps = {
  onChange: () => {},
  validatorListener: () => {},
  isSameProfile: true,
  isAllowLowerLevel: false,
  isAllowOnlyLevel: false,
  isHomeAddress: false,
  isSameAbove: false,
  genderRequirement: false,
  skillEducationRequirement: false,
  contactPersons: [],
  services: [],
  dateRange: {},
  contactPerson: '',
  profile: {},
  languages: [],
  files: [],
  ContactPersonOrdererRequiresEmail: false,
  ContactPersonUponInterpretationRequiresEmail: false,
  isSingle: false,
  showOtherRequirementSection: false,
  showFirstStep: false,
  showSecondStep: true,
  handleNewEmailChange: () => {},
  DoShowLMANumber: false,
  educationCompietancies: [],
  isDefaultDateUpdated: false,
  updateDate: () => {},
};

class SingleAssignmentForm extends Component {
  constructor(props) {
    super(props);

    const isTodayIsNotSelected = this.validationDatePicker();
    this.state = {
      isSameAbove: props.isSameAbove,
      homeAddress: props.isHomeAddress,
      isSameProfile: props.isSameProfile,
      allowLowerLevel: props.isAllowLowerLevel,
      allowOnlyLevel: props.isAllowOnlyLevel,
      genderRequirement: props.genderRequirement,
      skillEducationRequirement: props.skillEducationRequirement,
      showModal: false,
      isTodayIsNotSelected,
      uploadedFiles: [],
      showAlternativeAddress: false,
      newEmail: '',
      tagInputText: '',
    };
  }

  validationDatePicker = () => {
    let isTodayIsNotSelected = true;
    const todayDate = moment(new Date()).format('DD/MM/YYYY');
    // const selectedDate =
    //   this.props.dateRange.ranges.startDate.format('DD/MM/YYYY');
    // if (selectedDate === todayDate) isTodayIsNotSelected = false;
    return isTodayIsNotSelected;
  };

  closeInfoModal = () => {
    this.setState({ isTodayIsNotSelected: true });
  };

  handleShowModal = () => {
    this.setState({
      showModal: true,
    });
  };

  handleShowAddress = () => {
    this.setState(
      prevState => ({
        isSameProfile: !prevState.isSameProfile,
      }),
      () => {
        const { profile, onChange } = this.props;
        const { isSameProfile } = this.state;
        if (isSameProfile) {
          onChange({
            isSameProfile,
            addressLine: profile.customerAddress
              ? profile.customerAddress.AddressRow1
              : '',
            city: profile.customerAddress ? profile.customerAddress.City : '',
            district: profile.customerAddress
              ? profile.customerAddress.CareOf
              : '',
            postalCode: profile.customerAddress
              ? profile.customerAddress.PostalCode.toString()
              : '',
          });
        } else onChange({ isSameProfile });
      },
    );
  };

  handleHideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleChange = event => {
    const { isBankIDRequired } = this.props;

    const {
      target: { name, value, type },
    } = event;
    if (type === 'checkbox') {
      this.props.onChange({ isBankIDRequired: !isBankIDRequired });
    } else {
      this.handleAssignmentChange(event);
      const eventHandleObj = { [name]: value };
      // when gender and competence level unselected requirment booleans reset to false
      if (name == 'genderValue' && value?.value === '') {
        eventHandleObj.genderRequirement = false;
      } else if (name == 'certificationLevels' && value?.value === '') {
        eventHandleObj.isAllowLowerLevel = false;
        eventHandleObj.isAllowOnlyLevel = false;
      }
      this.props.onChange({ ...eventHandleObj });
    }
  };

  handleSessionChange = (e, key, rowNumber) => {
    const dateRange = clone(this.props.dateRange); // use clone() to fix object reference issue
    dateRange[key] = e;
    const baseStartTime = dateRange ? dateRange.startTime : null;
    const baseEndTime = dateRange ? dateRange.endTime : null;

    if (key !== 'startTime' && key !== 'endTime') {
      const selectedDate = e.startDate.format('DD/MM/YYYY');
      const todayDate = moment(new Date()).format('DD/MM/YYYY');
      if (selectedDate === todayDate) {
        this.setState({ isTodayIsNotSelected: false });
        e.startDate.add('1', 'days');
        e.endDate.add('1', 'days');
      }
    }

    // update start/end time values of this session
    if (key === 'startTime') {
      const { startTime } = clone(dateRange); // use clone() to fix object reference issue
      const { endTime } = dateRange;

      dateRange.endTime = getXTimefromSpecificDate(
        startTime,
        dateRange.durationDifference,
        'minutes',
      );
    } else if (key === 'endTime') {
      const { endTime } = clone(dateRange); // use clone() to fix object reference issue
      const { startTime } = dateRange;

      const durationDiffInMinutes = moment
        .duration(endTime - startTime)
        .asMinutes();
      dateRange.durationDifference = durationDiffInMinutes;
    }

    const isChangeSerialDuration = true;
    this.props.onChange({ dateRange, isChangeSerialDuration });
  };

  handleSessionDateChange = (e, element) => {
    const { dateRange, updateDate } = this.props;
    const { key } = dateRange.ranges;
    element.onToggleMenu();
    if (key && e[key]) {
      const { startDate, endDate } = e[key];
      if (startDate && endDate) {
        this.handleSessionChange(
          {
            startDate: moment(startDate),
            endDate: moment(endDate),
            key,
          },
          'ranges',
          element,
        );
      }
    }
    updateDate();
  };

  handleSameAbove = () => {
    const { contactPerson } = this.props;
    if (contactPerson !== '') {
      const isSameAbove = !this.state.isSameAbove;
      this.setState({ ...this.state, isSameAbove });
      this.props.onChange({ isSameAbove });
      const event = {
        target: {
          value: contactPerson,
        },
      };
      this.changeContactOrderValue(event);
    }
  };

  handleCheckBoxChange = (e, key) => {
    if (key === 'allowLowerLevel') {
      const isAllowLowerLevel = !this.state.isAllowLowerLevel;
      this.setState({ ...this.state, isAllowLowerLevel });
      this.props.onChange({ isAllowLowerLevel });
    }
    if (key === 'homeAddress') {
      const isHomeAddress = !this.state.isHomeAddress;
      this.setState({ ...this.state, isHomeAddress });
      this.props.onChange({ isHomeAddress });
    }
    if (key === 'genderRequirement') {
      const genderRequirement = !this.state.genderRequirement;
      this.setState({ ...this.state, genderRequirement });
      this.props.onChange({ genderRequirement });
    }
    if (key === 'doRequireSameResourceForAllWorkassignments') {
      if (e.target.checked && this.props.dateRange) {
        this.props.onChange({
          OrderGroupRequestData: {
            Description: '',
            DoRequireSameResourceForAllWorkAssignments: true,
          },
        });
      } else {
        this.props.onChange({
          OrderGroupRequestData: {
            Description: '',
            DoRequireSameResourceForAllWorkAssignments: false,
          },
        });
      }
    }
  };

  handleFileDrop = file => {
    if (file) {
      const { files } = this.props;
      files.push(file);
      this.props.onChange({ file });
    }
  };

  handleFileRemove = i => {
    if (i > -1) {
      const files = clone(this.props.files);
      files.splice(i, 1);
      this.props.onChange({ files });
    }
  };

  handleAssignmentChange = e => {
    const {
      target: { value },
    } = e;
    const { services } = this.props;
    const selectedService = services.filter(
      item => item.ServiceIdentifier === value.value,
    );
    if (selectedService.length > 0) {
      const availableCompetences = selectedService[0].AvailableCompetences.map(
        (item, i) => ({
          name: item.CompetenceLevelName,
          value: item.CompetenceLevelIdentifier,
        }),
      );
      this.setState({ ...this.state, availableCompetences });
    }
  };

  changeContactValue = event => {
    const { contactPersons, profile, handleNewEmailChange } = this.props;
    const { isSameAbove } = this.state;
    const selectedContactPerson = event.target.value;

    const contactPersonData = {
      contactPerson: selectedContactPerson ? selectedContactPerson.value : '',
      contactNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberMobile
        : '',
      contactDirectNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberDirect
        : '',
      contactEmail: selectedContactPerson ? selectedContactPerson.Email : '',
    };
    if (profile.ContactPersonUponInterpretationRequiresEmail) {
      handleNewEmailChange(selectedContactPerson.Email);
    } else {
      handleNewEmailChange('test@test.com');
    }

    this.props.onChange(contactPersonData);
    if (isSameAbove && event.target.value !== 'none') {
      const e = {
        target: {
          value: selectedContactPerson.ContactPersonIdentifier,
        },
      };
      this.changeContactOrderValue(e);
    }

    if (isSameAbove && event.target.value === 'none') {
      this.setState({
        ...this.state,
        isSameAbove: !isSameAbove,
      });
    }
  };

  changeContactOrderValue = event => {
    const { contactPersons, profile, handleNewEmailChange } = this.props;
    const selectedContactPerson = event.target.value;

    const contactPersonData = {
      orderContactPerson: selectedContactPerson
        ? selectedContactPerson.value
        : '',
      orderContactNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberMobile
        : '',
      orderContactDirectNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberDirect
        : '',
      orderEmail: selectedContactPerson ? selectedContactPerson.Email : '',
    };
    if (profile.ContactPersonOrdererRequiresEmail) {
      handleNewEmailChange(selectedContactPerson.Email);
    } else {
      handleNewEmailChange('test@test.com');
    }
    this.props.onChange(contactPersonData);
  };

  getCertificationLevels = () => {
    const { services, typeOfAssignment } = this.props;

    let result = [];
    services.forEach(service => {
      if (
        service.ServiceIdentifier === typeOfAssignment.value &&
        service.AvailableCompetences
      ) {
        result = service.AvailableCompetences.map(availableItem => ({
          name: availableItem.CompetenceLevelName,
          value: availableItem.CompetenceLevelIdentifier,
        }));
      }
    });

    return result;
  };

  generateMediaType = () => {
    const { services } = this.props;
    const options = [];
    if (services) {
      services.map(service => {
        if (service.DigitalMeetingPlatforms) {
          service.DigitalMeetingPlatforms.map(item => {
            options.push({
              name: item.DigitalMeetingPlatformName,
              value: item.DigitalMeetingPlatformIdentifier,
            });

            return null;
          });
        }
        return null;
      });
    }

    return options;
  };

  getValueFromList = (filterText, list) => {
    if (filterText === null) return null;
    let filteredItems;
    if (list) {
      list.map(item => {
        if (item.DigitalMeetingPlatforms) {
          filteredItems = item.DigitalMeetingPlatforms.filter(
            x => x.DigitalMeetingPlatformIdentifier === filterText,
          );
        }
        return null;
      });
    }

    return filteredItems ? filteredItems[0] : null;
  };

  handleEmailChange = e => {
    const {
      target: { name, value },
    } = e;
    this.setState({ tagInputText: value });
  };

  handleKeyDown = e => {
    let { customerOrderEmailAddress } = this.props;
    const { key } = e;
    const trimmedInput = this.state.tagInputText.trim();

    let list = [];
    if (
      key === ';' &&
      trimmedInput.length &&
      !(customerOrderEmailAddress || []).includes(trimmedInput)
    ) {
      e.preventDefault();
      list = [...customerOrderEmailAddress, trimmedInput];
      this.setState({ tagInputText: '' });
      this.props.onChange({ customerOrderEmailAddress: list });
    }
    if (
      key === 'Backspace' &&
      !this.state.tagInputText.length &&
      customerOrderEmailAddress.length &&
      this.state.isKeyReleased
    ) {
      const tagsCopy = [...customerOrderEmailAddress];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      list = [...tagsCopy];
      this.setState({ tagInputText: poppedTag });
      this.props.onChange({ customerOrderEmailAddress: list });
    }

    this.setState({ isKeyReleased: false });
  };

  handleKeyUp = () => {
    this.setState({ isKeyReleased: true });
  };

  handleDeleteTagInput = index => {
    const list = this.props.customerOrderEmailAddress.filter(
      (tag, i) => i !== index,
    );
    // this.props.customerOrderEmailAddress = [...list];
    this.props.onChange({ customerOrderEmailAddress: list });
  };

  handleCheckEmail = () => {
    const { tagInputText } = this.state;
    let { customerOrderEmailAddress } = this.props;
    if (!isEmpty(tagInputText)) {
      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (
        tagInputText.match(mailformat) &&
        !(customerOrderEmailAddress || []).includes(tagInputText)
      ) {
        let list = [...customerOrderEmailAddress, tagInputText];
        this.setState({ tagInputText: '' });
        this.props.onChange({ customerOrderEmailAddress: list });
      }
      if ((customerOrderEmailAddress || []).includes(tagInputText)) {
        this.setState({ tagInputText: '' });
      }
    }
  };

  handleChangeIsHomeAddress = () => {
    const { isHomeAddress } = this.props;
    this.props.onChange({
      isHomeAddress: !isHomeAddress,
    });
  };

  generateFieldsOnAssignmentTypeProps = () => {
    const {
      isEdit,
      selectedAssignmentTypeData,
      meetingLink,
      phoneNumber,
      meetingPassCode,
      isBankIDRequired,
      meetingPlatform,
      services,
    } = this.props;
    const renderedFields = [];

    if (selectedAssignmentTypeData) {
      selectedAssignmentTypeData.map(item => {
        if (item.DoRequireMeetingUrl) {
          const meetingField = (
            <div className="form-group col-12 mb-2">
              <label htmlFor="meetingLink">
                <Translate content="singleAssignment.videoLink" />
              </label>
              <TextField
                onChange={this.handleChange}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="meetingLink"
                id="meetingLink"
                maxLength={500}
                value={meetingLink}
                placeholder={Translate({
                  content: 'singleAssignment.videoLinkPlaceHolder',
                })}
                validators={['required', 'urlformat']}
                errorMessages={[
                  Translate({ content: 'singleAssignment.videoLinkRequired' }),
                  'Videolänk måste börja med "http://" eller "https://"',
                ]}
                aria-label="Videolänk"
              />
            </div>
          );
          const meetingPassCodeFiled = (
            <div className="form-group col-12 mb-2">
              <label htmlFor="meetingPassCode">
                <Translate content="singleAssignment.passCodeHeader" />
              </label>
              <TextField
                onChange={this.handleChange}
                className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                  isEdit ? 'color-for-text border-0' : ''
                }`}
                name="meetingPassCode"
                id="meetingPassCode"
                style={{ height: '40px' }}
                maxLength={500}
                value={meetingPassCode}
                placeholder={Translate({
                  content: 'singleAssignment.passCodePlaceHolder',
                })}
                errorMessages={[
                  Translate({ content: 'singleAssignment.passCodeRequired' }),
                ]}
                readOnly={isEdit ? true : false}
              />
            </div>
          );
          const meetingMedia = (
            <div className="form-group col-6 mb-2">
              <label htmlFor="other_details">
                <Translate content="singleAssignment.platform" />
              </label>
              <DropdownList
                onChange={this.handleChange}
                name="meetingPlatform"
                value={meetingPlatform.value === null ? null : meetingPlatform}
                className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                  isEdit ? 'border-0 invisible-icon' : ''
                }`}
                options={this.generateMediaType()}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.platform',
                      }),
                    },
                  }),
                ]}
                disabled={isEdit ? true : false}
                ariaLabel={Translate({
                  content: 'singleAssignment.platform',
                })}
              />
            </div>
          );
          const bankIDRequired = (
            <div className="form-group col-6 mb-2">
              <div className="bank-id" />
              <Checkbox
                label={Translate({
                  content: 'singleAssignment.bankIdRequired',
                })}
                handleChange={this.handleChange}
                defaultChecked={isBankIDRequired}
                disabled={isEdit ? true : false}
              />
            </div>
          );

          renderedFields.push(
            meetingField,
            meetingPassCodeFiled,
            meetingMedia,
            bankIDRequired,
          );
        }
        if (item.DoRequireMeetingPhoneNumber) {
          const phoneNumberField = (
            <div className="form-group col-12 mb-2">
              <label htmlFor="phoneNumber">
                <Translate content="singleAssignment.telephoneNumber" />
              </label>
              <TextField
                onChange={this.handleChange}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="phoneNumber"
                id="phoneNumber"
                maxLength={50}
                value={phoneNumber}
                placeholder={Translate({
                  content: 'singleAssignment.telephonePlaceHolder',
                })}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.telephoneNumber',
                      }),
                    },
                  }),
                ]}
              />
            </div>
          );
          const meetingPassCodeFiled = (
            <div className="form-group col-12 mb-2">
              <label htmlFor="meetingPassCode">
                <Translate content="singleAssignment.passCodeHeader" />
              </label>
              <TextField
                onChange={this.handleChange}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="meetingPassCode"
                id="meetingPassCode"
                style={{ height: '40px' }}
                maxLength={300}
                value={meetingPassCode}
                placeholder={Translate({
                  content: 'singleAssignment.passCodePlaceHolder',
                })}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.passCodeHeader',
                      }),
                    },
                  }),
                ]}
              />
            </div>
          );
          renderedFields.push(phoneNumberField, meetingPassCodeFiled);
        }
      });
      return renderedFields;
    }
  };

  handleRadioButtonChange = (e, controlName) => {
    const contollerValue = e.target.value;
    switch (controlName) {
      case 'genderRequirement':
        {
          let genderRequirement = false;
          if (contollerValue == 2) {
            genderRequirement = true;
          }
          this.setState({ ...this.state, genderRequirement });
          this.props.onChange({ genderRequirement });
        }
        break;

      case 'skillEducationRequirement':
        {
          let skillEducationRequirement = false;
          if (contollerValue == 2) {
            skillEducationRequirement = true;
          }
          this.setState({ ...this.state, skillEducationRequirement });
          this.props.onChange({ skillEducationRequirement });
        }
        break;
      case 'allowLowerLevel':
        {
          let isAllowLowerLevel = false;
          let isAllowOnlyLevel = false;
          if (contollerValue == 2) {
            isAllowLowerLevel = true;
            isAllowOnlyLevel = false;
          }
          this.setState({
            ...this.state,
            allowLowerLevel: isAllowLowerLevel,
            allowOnlyLevel: isAllowOnlyLevel,
          });
          this.props.onChange({ isAllowLowerLevel, isAllowOnlyLevel });
        }
        break;
      case 'allowOnlyLevel':
        if (contollerValue == 3) {
          const isAllowLowerLevel = false;
          const isAllowOnlyLevel = true;
          this.setState({
            ...this.state,
            allowLowerLevel: isAllowLowerLevel,
            allowOnlyLevel: isAllowOnlyLevel,
          });
          this.props.onChange({ isAllowLowerLevel, isAllowOnlyLevel });
        }
        break;
      case 'requestedInterpreterPreferedLevel':
        {
          const requestedInterpreterPreferedLevel = contollerValue;

          this.setState({ ...this.state, requestedInterpreterPreferedLevel });
          this.props.onChange({
            requestedInterpreterPreferedLevel,
          });
        }
        break;
      default:
        break;
    }
  };

  isSameDayTime = sess => {
    const { isDefaultDateUpdated } = this.props;
    let returnValue = true;
    let session = cloneDeep(sess);

    const beginningTime = moment({
      h: session.startTime.hours(),
      m: session.startTime.minutes(),
    });
    const endTime = moment({
      h: session.endTime.hours(),
      m: session.endTime.minutes(),
    });
    if (isDefaultDateUpdated) {
      session.ranges.startDate = session.ranges.startDate.clone();
      session.ranges.endDate = session.ranges.endDate.clone();
      if (
        (beginningTime.isAfter(endTime) || endTime.isSame(beginningTime)) &&
        session.ranges.endDate.isSame(session.ranges.startDate, 'day')
      ) {
        returnValue = false;
      }
    }
    return returnValue;
  };

  render() {
    const {
      validatorListener,
      dateRange,
      certificationLevels,
      yourOrderNumber,
      addressLine,
      district,
      city,
      postalCode,
      language,
      assignmentDescription,
      additionalRequirements,
      assignmentSubskill,
      requestedInterpreter,
      requestedInterpreterPreferedLevel,
      contactPerson,
      contactNumber,
      contactEmail,
      contactDirectNumber,
      orderContactPerson,
      orderContactNumber,
      orderContactDirectNumber,
      orderEmail,
      genderRequirement,
      skillEducationRequirement,
      languages,
      contactPersons,
      services,
      genderValue,
      typeOfAssignment,
      onHandleModal,
      createdFrom,
      ContactPersonOrdererRequiresEmail,
      ContactPersonUponInterpretationRequiresEmail,
      isSingle,
      isEdit,
      showFirstStep,
      showSecondStep,
      files,
      message,
      messageReceiver,
      messageTelephone,
      messageInfoForInterpreter,
      isAlternativeAddressAdded,
      addAlternativeAddress,
      newEmail,
      DoShowLMANumber,
      lmaNumber,
      customerOrderEmailAddress,
      isDeviatingAddressPerforming,
      educationCompietancies,
      customerDefaultAddress,
      isDefaultDateUpdated,
      isDateValid,
    } = this.props;
    const {
      isSameAbove,
      homeAddress,
      allowLowerLevel,
      allowOnlyLevel,
      isSameProfile,
      isTodayIsNotSelected,
      showAlternativeAddress,
    } = this.state;

    const isMessageServiceAssignment =
      typeOfAssignment.isMessageService === true;
    const assignmentTypes = [];
    if (services) {
      services.map(item => {
        assignmentTypes.push({
          name: item.ServiceName,
          value: item.ServiceIdentifier,
          isMessageService: item.IsMessageService,
          doAllowLMANumberOnConnectedContract:
            item.DoAllowLMANumberOnConnectedContract,
          isPerformedRemotely: item.IsPerformedRemotely,
          additionalServices: item.AdditionalServices,
        });
        return null;
      });
    }

    const cerLevels = this.getCertificationLevels();

    const getValuefromList = (filterText, list) => {
      if (filterText === null || filterText === '') return null;
      const filteredItems = list.filter(x => x.value === filterText);
      return filteredItems ? filteredItems[0] : null;
    };

    const eventValuetransformForDropDown = (e, controllerName) => {
      this.handleChange(e);
    };

    const isContactPersonUponInterpretationValid =
      !contactPerson ||
      contactPerson == '' ||
      isContactPersonValid(
        ContactPersonUponInterpretationRequiresEmail,
        contactPerson,
        contactPersons,
      );

    const isContactPersonOrdererValid =
      !orderContactPerson ||
      orderContactPerson == '' ||
      isContactPersonValid(
        ContactPersonOrdererRequiresEmail,
        orderContactPerson,
        contactPersons,
      );

    const disableDateFrom = currentDate().startOf('day').add(1, 'days');
    const disableDateTo = currentDate().endOf('day').add(1, 'days');
    let isDisableTime = false;
    if (
      currentDate() >= currentDate().startOf('day').add(17, 'hours') &&
      dateRange.ranges.startDate >= disableDateFrom &&
      dateRange.ranges.startDate <= disableDateTo
    ) {
      isDisableTime = true;
    }

    return (
      <React.Fragment>
        {showFirstStep && (
          <div className="quick-order-cont text-left pl-2 pr-3 row  align-content-around">
            <div className="form-group col-12 col-lg-6">
              <div className="d-flex flex-row mb-2">
                <label htmlFor="typeOfAssignment">
                  <Translate content="singleAssignment.typeOfAssignment" />
                </label>
                {!isEdit && (
                  <div className="tooltip-icon">
                    <i
                      class="fa fa-question-circle ml-2"
                      aria-hidden="true"
                    ></i>
                    <span className="tooltip-text">
                      <p className="mb-2">Innehåller din bokning,​</p>
                      <ul className="mb-2 pl-4">
                        <li>Material för tolken att läsa in sig på​</li>
                        <li>Tolken förväntas simultantolka</li>
                      </ul>
                      <p className="mb-0">
                        Din bokning kan då vara ett specialuppdrag, ring oss
                        0770 813 000
                      </p>
                    </span>
                  </div>
                )}
              </div>

              <DropdownList
                className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                  isEdit ? 'border-0 invisible-icon' : ''
                }`}
                name="typeOfAssignment"
                options={assignmentTypes}
                value={getValuefromList(
                  typeOfAssignment.value,
                  assignmentTypes,
                )}
                onChange={this.handleChange}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.typeOfAssignment',
                      }),
                    },
                  }),
                ]}
                disabled={isEdit ? true : false}
                ariaLabel={Translate({
                  content: 'singleAssignment.typeOfAssignment',
                })}
              />
            </div>
            {typeOfAssignment.isPerformedRemotely == false && (
              <>
                <div className="form-group col-12 col-lg-6">
                  <label htmlFor="clientAddress">
                    <Translate content="singleAssignment.clientAddress" />
                  </label>
                  <TextField
                    placeholder={Translate({
                      content: 'singleAssignment.clientAddress',
                    })}
                    maxLength={100}
                    className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                      isEdit ? 'color-for-text border-0' : ''
                    }`}
                    name="clientAddress"
                    id="clientAddress"
                    readOnly={true}
                    value={`${customerDefaultAddress.AddressRow1}, ${customerDefaultAddress.PostalCode}, ${customerDefaultAddress.City} `}
                    aria-label="Kundadress"
                  />
                  {!isAlternativeAddressAdded && !isEdit && (
                    <div className="links-add-container">
                      <div
                        className="links-add links-add-secondary"
                        onClick={() => addAlternativeAddress(true)}
                        onKeyDown={e =>
                          e.key === 'Enter' ? addAlternativeAddress(true) : null
                        }
                        tabIndex={0}
                        role="button"
                        aria-label="Ange avvikande adress"
                      >
                        + Ange avvikande adress
                      </div>
                    </div>
                  )}
                </div>
                {isAlternativeAddressAdded && isDeviatingAddressPerforming && (
                  <>
                    <div className="form-group col-12 col-lg-4">
                      <label htmlFor="addressLine">
                        Adressrad
                        {/* <Translate content="singleAssignment.enterADifferentAddress" /> */}
                      </label>
                      <TextField
                        validatorListener={validatorListener}
                        placeholder={Translate({
                          content: 'singleAssignment.addressPlaceHolder',
                        })}
                        className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                          isEdit ? 'color-for-text border-0' : ''
                        }`}
                        name="addressLine"
                        id="addressLine"
                        maxLength={300}
                        onChange={this.handleChange}
                        value={addressLine}
                        readOnly={isEdit ? true : false}
                        validators={['required']}
                        errorMessages={[
                          Translate({
                            content: 'validator.isRequired',
                            params: {
                              name: Translate({
                                content: 'singleAssignment.addressLine',
                              }),
                            },
                          }),
                        ]}
                        aria-label="Avvikande utförandeadress"
                      />
                      <Checkbox
                        label="Utförandeadress avser hemadress"
                        className="label--checked"
                        defaultChecked={this.props.isHomeAddress}
                        value={this.props.isHomeAddress}
                        handleChange={this.handleChangeIsHomeAddress}
                        disabled={isEdit ? true : false}
                      />
                    </div>
                    <div className="form-group col-12 col-lg-4">
                      <label htmlFor="postalCode">
                        <Translate content="singleAssignment.postalNumber" />
                      </label>
                      <TextField
                        validatorListener={validatorListener}
                        type="number"
                        placeholder={Translate({
                          content: 'singleAssignment.postalCodePlaceHolder',
                        })}
                        className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                          isEdit ? 'color-for-text border-0' : ''
                        }`}
                        name="postalCode"
                        id="postalCode"
                        maxLength={300}
                        onChange={this.handleChange}
                        value={postalCode.toString()}
                        readOnly={isEdit ? true : false}
                        validators={[
                          'required',
                          'isNumber',
                          'checkNumLength: 5',
                        ]}
                        errorMessages={[
                          Translate({
                            content: 'validator.isRequired',
                            params: {
                              name: Translate({
                                content: 'singleAssignment.postalCode',
                              }),
                            },
                          }),
                          Translate({
                            content: 'validator.isNotValid',
                            params: {
                              name: Translate({
                                content: 'singleAssignment.postalCode',
                              }),
                            },
                          }),
                          Translate({
                            content: 'validator.requireLength',
                            params: {
                              name: Translate({
                                content: 'singleAssignment.postalCode',
                              }),
                              number: 5,
                            },
                          }),
                        ]}
                        aria-label="Postnummer"
                      />
                    </div>
                    <div className="form-group col-12 col-lg-4">
                      <label htmlFor="city">
                        <Translate content="singleAssignment.cityNew" />
                      </label>
                      <TextField
                        validatorListener={validatorListener}
                        placeholder={Translate({
                          content: 'singleAssignment.cityPlaceHolder',
                        })}
                        className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                          isEdit ? 'color-for-text border-0' : ''
                        }`}
                        maxLength={300}
                        name="city"
                        id="city"
                        onChange={this.handleChange}
                        value={city}
                        readOnly={isEdit ? true : false}
                        validators={['required']}
                        errorMessages={[
                          Translate({
                            content: 'validator.isRequired',
                            params: {
                              name: Translate({
                                content: 'singleAssignment.cityNew',
                              }),
                            },
                          }),
                        ]}
                        aria-label="Stad"
                      />
                      {isAlternativeAddressAdded && !isEdit && (
                        <div className="links-add-container">
                          <div
                            className="links-add links-add-secondary"
                            onClick={() => addAlternativeAddress(false)}
                            onKeyDown={e =>
                              e.key === 'Enter'
                                ? addAlternativeAddress(false)
                                : null
                            }
                            tabIndex={0}
                            role="button"
                            aria-label="Ta bort avvikande adress"
                          >
                            - Ta bort avvikande adress
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}

            <div className="d-flex col-12 col-lg-12 p-0">
              <div className="form-group col-12 col-lg-4">
                <div className="d-flex flex-row">
                  <label>
                    <Translate content="singleAssignment.selectDatePeriod" />
                  </label>
                  {!isEdit && (
                    <div className="tooltip-icon">
                      <i
                        class="fa fa-question-circle ml-2 mt-1"
                        aria-hidden="true"
                      ></i>
                      <span className="tooltip-text">
                        Använd tjänsten Akut tolk vid bokning av tolk
                        innevarande dag samt nästkommande dag fram till kl.
                        08.00.
                      </span>
                    </div>
                  )}
                </div>

                <div
                  className={`tv-single-assign-form__input ${
                    isEdit ? 'invisible-date-border' : ''
                  } ${isDateValid ? '' : 'date-not-valid'}`}
                >
                  <TFVDateRangePicker
                    ranges={dateRange.ranges}
                    displayFormat="YYYY-MM-DD"
                    onChange={this.handleSessionDateChange}
                    disabled={isEdit ? true : false}
                    ariaLabel="Datum"
                    isDateUpdated={isDefaultDateUpdated}
                  />
                </div>
                {!isDateValid && (
                  <div className="tv-form__error">Datum är obligatoriskt</div>
                )}
              </div>
              {!isMessageServiceAssignment && (
                <>
                  <div
                    className={`form-group col-12 col-md-4 ${
                      isEdit ? 'invisible-border' : ''
                    }`}
                  >
                    <label htmlFor="Start">
                      <Translate content="singleAssignment.startTime" />
                    </label>
                    <TFVTimePickerV2
                      key={`${generateUniqueId()}-TFVTimePickerV2-start`}
                      defaultValue={dateRange.startTime}
                      startFromDayBegin
                      handleValueChange={e =>
                        this.handleSessionChange(e, 'startTime')
                      }
                      timeLimit={30}
                      isDisabled={isEdit ? true : false}
                      isDisableTime={isDisableTime}
                      ariaLabel="Start"
                      id="Start"
                      name="Start"
                    />
                  </div>
                  <div
                    className={`form-group col-12 col-md-4 ${
                      isEdit ? 'invisible-border' : ''
                    }`}
                  >
                    <label htmlFor="Stopp">
                      <Translate content="singleAssignment.endTime" />
                    </label>
                    <TFVTimePickerV2
                      key={`${generateUniqueId()}-TFVTimePickerV2-end`}
                      defaultValue={dateRange.endTime}
                      durationCalculate={dateRange.startTime}
                      allowNextDay
                      isNotRanged={false}
                      handleValueChange={e =>
                        this.handleSessionChange(e, 'endTime')
                      }
                      timeLimit={30}
                      isDisabled={isEdit ? true : false}
                      isDisableTime={isDisableTime}
                      ariaLabel="Stopp"
                      id="Stopp"
                      name="Stopp"
                    />
                    {!this.isSameDayTime(dateRange) && (
                      <span className="tv-form__warning">
                        <i className="fa fa-exclamation-circle mr-1"></i>Nästa
                        dag
                      </span>
                    )}
                    {dateRange.ranges.startDate &&
                      dateRange.ranges.startDate.format('DD/MM/YYYY') ===
                        moment(new Date()).format('DD/MM/YYYY') &&
                      !isEdit && (
                        <div className="tv-col-12">
                          <span className="tv-form__error">
                            <Translate content="singleAssignment.dateRangePickerTodayWarning" />
                          </span>
                        </div>
                      )}
                  </div>
                </>
              )}

              {isMessageServiceAssignment && (
                <>
                  <div className="form-group col-12 col-md-4">
                    <div className="d-flex flex-row">
                      <label htmlFor="startTime">
                        <Translate content="singleAssignment.preferredTime" />
                      </label>
                      {!isEdit && (
                        <div className="tooltip-icon">
                          <i
                            class="fa fa-question-circle ml-2 mt-1"
                            aria-hidden="true"
                          ></i>
                          <span className="tooltip-text">
                            Vi kan inte lova leverans enligt detta men kommer
                            att göra vårt bästa för att leverera enligt önskemål
                          </span>
                        </div>
                      )}
                    </div>
                    <TFVTimePickerV2
                      defaultValue={dateRange.startTime}
                      startFromDayBegin
                      allowNextDay
                      handleValueChange={e =>
                        this.handleSessionChange(e, 'startTime')
                      }
                      timeLimit={15}
                      isDisabled={isEdit ? true : false}
                      isDisableTime={isDisableTime}
                      ariaLabel="Önskad tidpunkt"
                      id="startTime"
                      name="startTime"
                    />
                  </div>
                  {/* <div className="form-group col-12 col-md-4">
                  <div className="d-flex flex-row">
                    <label>End Time</label>
                  </div>
                  <TFVTimePickerV2
                    defaultValue={dateRange.endTime}
                    durationCalculate={dateRange.startTime}
                    allowNextDay
                    isNotRanged={false}
                    handleValueChange={e =>
                      this.handleSessionChange(e, 'endTime')
                    }
                    timeLimit={15}
                    isDisabled={isEdit ? true : false}
                  />
                  {!this.isSameDayTime(dateRange) && (
                    <span className="tv-form__warning">
                      <i className="fa fa-exclamation-circle mr-1"></i>Nästa dag
                    </span>
                  )}
                </div> */}
                </>
              )}
            </div>

            <div className="form-group col-12 col-lg-6">
              <label htmlFor="language">
                <Translate content="singleAssignment.language" />
              </label>

              <DropdownList
                onChange={eventValuetransformForDropDown}
                name="language"
                value={getValuefromList(language.value, languages)}
                className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                  isEdit ? 'border-0 invisible-icon' : ''
                }`}
                options={languages}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.language',
                      }),
                    },
                  }),
                ]}
                disabled={isEdit ? true : false}
                ariaLabel={Translate({ content: 'singleAssignment.language' })}
              />
            </div>

            {this.generateFieldsOnAssignmentTypeProps()}

            {isMessageServiceAssignment && (
              <>
                <div className="form-group col-12 col-md-12">
                  <div className="d-flex flex-row">
                    <label htmlFor="message">
                      <Translate content="singleAssignment.message" />
                    </label>
                    <div className="tooltip-icon">
                      <i
                        class="fa fa-question-circle ml-2 mt-1"
                        aria-hidden="true"
                      ></i>
                      <span className="tooltip-text">
                        Meddelandeservice är envägskommunikation, dvs. att det
                        inte innehåller frågor för mottagaren att besvara eller
                        uppmuntra till vidare dialog. Meddelandet ska inte
                        innehålla instruktioner om medicin
                      </span>
                    </div>
                  </div>
                  <TextField
                    onChange={this.handleChange}
                    className={`tv-single-assign-form__textfield tv-single-assign-form__textarea tv-single-assign-form__control ${
                      isEdit ? 'color-for-text' : ''
                    }`}
                    multiline
                    name="message"
                    id="message"
                    style={{ height: '40px' }}
                    // maxLength={300}
                    value={message}
                    isMultiline
                    placeholder={Translate({
                      content: 'singleAssignment.messagePlaceholder',
                    })}
                    validators={['required', 'messageFieldValid']}
                    errorMessages={[
                      Translate({
                        content: 'validator.isRequired',
                        params: {
                          name: Translate({
                            content: 'singleAssignment.message',
                          }),
                        },
                      }),
                      this.state.errorMessage,
                    ]}
                    readOnly={isEdit ? true : false}
                    aria-label="Meddelande"
                  />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="messageReceiver">
                    <Translate content="singleAssignment.recipientName" />
                  </label>
                  <TextField
                    onChange={this.handleChange}
                    className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                      isEdit ? 'color-for-text' : ''
                    }`}
                    name="messageReceiver"
                    id="messageReceiver"
                    style={{ height: '40px' }}
                    maxLength={300}
                    value={messageReceiver}
                    isMultiline
                    placeholder={Translate({
                      content: 'singleAssignment.recipientNamePlaceholder',
                    })}
                    validators={['required']}
                    errorMessages={[
                      Translate({
                        content: 'validator.isRequired',
                        params: {
                          name: Translate({
                            content: 'singleAssignment.recipientName',
                          }),
                        },
                      }),
                    ]}
                    readOnly={isEdit ? true : false}
                    aria-label="Mottagarens namn"
                  />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="messageTelephone">
                    <Translate content="singleAssignment.recipientPhoneNumber" />
                  </label>
                  <TextField
                    onChange={this.handleChange}
                    className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                      isEdit ? 'color-for-text' : ''
                    }`}
                    name="messageTelephone"
                    id="messageTelephone"
                    style={{ height: '40px' }}
                    maxLength={300}
                    value={messageTelephone}
                    isMultiline
                    placeholder={Translate({
                      content:
                        'singleAssignment.recipientPhoneNumberPlaceholder',
                    })}
                    validators={['required']}
                    eerrorMessages={[
                      Translate({
                        content: 'validator.isRequired',
                        params: {
                          name: Translate({
                            content: 'singleAssignment.recipientPhoneNumber',
                          }),
                        },
                      }),
                    ]}
                    readOnly={isEdit ? true : false}
                    aria-label="Mottagarens telefonnummer"
                  />
                </div>
              </>
            )}
            <div className="form-group col-12 col-lg-6">
              <label htmlFor="contactPerson">
                <Translate content="singleAssignment.contactPerson" />
              </label>
              <DropdownList
                className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                  isContactPersonUponInterpretationValid
                    ? ''
                    : 'tv-form__control--error'
                } ${isEdit ? 'border-0 invisible-icon' : ''}`}
                name="contactPerson"
                options={contactPersons}
                value={getValuefromList(contactPerson, contactPersons)}
                onChange={(e, v) => {
                  this.changeContactValue(e);
                }}
                footer={
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      onHandleModal(
                        ContactPersonUponInterpretationRequiresEmail,
                      );
                    }}
                    className="tv-dropdown__footer-conactperson-add"
                  >
                    + lägg till tolkanvändare
                  </span>
                }
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.contactPerson',
                      }),
                    },
                  }),
                ]}
                disabled={isEdit ? true : false}
                ariaLabel={Translate({
                  content: 'singleAssignment.contactPerson',
                })}
              />
              {!isContactPersonUponInterpretationValid && (
                <span class="tv-form__error text-justify">
                  E-post krävs.
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      onHandleModal(
                        ContactPersonUponInterpretationRequiresEmail,
                        'ContactPersonUponInterpretation',
                        contactPerson,
                      );
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.stopPropagation();
                        onHandleModal(
                          ContactPersonUponInterpretationRequiresEmail,
                          'ContactPersonUponInterpretation',
                          contactPerson,
                        );
                      }
                    }}
                    tabIndex={0}
                    role="button"
                    className="contact-person-email-edit"
                  >
                    + Lägg till här
                  </span>
                </span>
              )}
            </div>
            <div className="form-group col-12 col-lg-6">
              <label>
                <Translate content="singleAssignment.contactOrderPerson" />
              </label>

              <DropdownList
                className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                  isContactPersonOrdererValid ? '' : 'tv-form__control--error'
                } ${isEdit ? 'border-0 invisible-icon' : ''}`}
                name="orderContactPerson"
                options={contactPersons}
                footer={
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      onHandleModal(ContactPersonOrdererRequiresEmail);
                    }}
                    className="tv-dropdown__footer-conactperson-add"
                  >
                    + lägg till tolkanvändare
                  </span>
                }
                value={getValuefromList(orderContactPerson, contactPersons)}
                onChange={(e, v) => {
                  this.changeContactOrderValue(e);
                }}
                // disabled={isSameAbove ? 'disabled' : ''}
                disabled={isEdit ? true : false}
                validators={isSameAbove ? [] : ['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.orderContactPerson',
                      }),
                    },
                  }),
                ]}
                ariaLabel={Translate({
                  content: 'singleAssignment.contactOrderPerson',
                })}
              />
              {!isContactPersonOrdererValid && (
                <span className="tv-form__error text-justify">
                  E-post krävs.
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      onHandleModal(
                        ContactPersonOrdererRequiresEmail,
                        'ContactPersonOrderer',
                        orderContactPerson,
                      );
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.stopPropagation();
                        onHandleModal(
                          ContactPersonOrdererRequiresEmail,
                          'ContactPersonOrderer',
                          orderContactPerson,
                        );
                      }
                    }}
                    tabIndex={0}
                    role="button"
                    className="contact-person-email-edit"
                  >
                    + Lägg till här
                  </span>
                </span>
              )}
            </div>

            <div className="form-group col-12 col-lg-6">
              <label htmlFor="newEmail">
                <Translate content="singleAssignment.email" />
              </label>
              <TextField
                placeholder={Translate({
                  content: 'singleAssignment.email',
                })}
                className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control color-for-text ${
                  isEdit ? 'border-0' : ''
                }`}
                readOnly={true}
                value={newEmail}
                name="newEmail"
                id="newEmail"
                aria-label={Translate({ content: 'singleAssignment.email' })}
              />
            </div>

            {DoShowLMANumber && (
              <div className="form-group col-12 col-lg-6">
                <label htmlFor="lmaNumber">
                  <Translate content="singleAssignment.LMANumber" />
                </label>
                <TextField
                  placeholder={Translate({
                    content: 'singleAssignment.lmaNumberPlaceholder',
                  })}
                  maxLength={100}
                  className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                  aria-label="LMA Number"
                  name="lmaNumber"
                  id="lmaNumber"
                  onChange={this.handleChange}
                  value={lmaNumber}
                />
              </div>
            )}

            <div className="form-group col-12 col-lg-6">
              <div className="d-flex flex-row">
                <label htmlFor="customerOrderEmailAddress">
                  <Translate content="singleAssignment.confirmationEmail" />
                </label>
                <div className="tooltip-icon">
                  <i class="fa fa-question-circle ml-2" aria-hidden="true"></i>
                  <span className="tooltip-text">
                    Om du vill att bekräftelsen skall skickas till flera
                    e-postadresser, ange dessa semikolon separerade
                  </span>
                </div>
              </div>
              <TagInput
                placeholder={Translate({
                  content:
                    'singleAssignment.customerOrderEmailAddressPlaceholder',
                })}
                value={this.state.tagInputText}
                name="customerOrderEmailAddress"
                id="customerOrderEmailAddress"
                confirmationEmailList={customerOrderEmailAddress}
                onChange={this.handleEmailChange}
                onKeyDown={this.handleKeyDown}
                onKeyUp={this.handleKeyUp}
                deleteTag={this.handleDeleteTagInput}
                onBlur={this.handleCheckEmail}
                aria-label={Translate({
                  content:
                    'singleAssignment.customerOrderEmailAddressPlaceholder',
                })}
                validators={['validEmail']}
                errorMessages={['Ogiltig e-postadress']}
              />
            </div>

            {/* {isMessageServiceAssignment && (
              <div className="form-group col-12 col-lg-12">
                <label>
                  <Translate content="taskDetail.infoForInterpreter" />
                </label>
                <TextField
                  placeholder={Translate({
                    content: 'singleAssignment.typeNotesForResourceNew',
                  })}
                  multiline
                  className="tv-single-assign-form__textfield tv-single-assign-form__textarea tv-single-assign-form__control"
                  name="assignmentDescription"
                  value={assignmentDescription}
                  onChange={this.handleChange}
                />
              </div>
            )} */}
          </div>
        )}
        {showSecondStep && (
          <div className="quick-order-cont text-left pl-2 pr-3 mh-100 row mb-5 mb-md-0">
            <div
              className={`form-group col-12 col-md-6 col-lg-6 ${
                genderValue &&
                genderValue?.value != '' &&
                genderValue?.value != '0'
                  ? 'mb-4'
                  : ''
              }`}
            >
              <label>
                <Translate content="singleAssignment.genderRequirement" />
              </label>

              <DropdownList
                className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                  isEdit ? 'border-0 invisible-icon' : ''
                }`}
                name="genderValue"
                options={gender}
                value={getValuefromList(
                  genderValue ? genderValue.value : null,
                  gender,
                )}
                onChange={e => {
                  eventValuetransformForDropDown(e, 'genderValue');
                }}
                disabled={isEdit ? true : false}
                ariaLabel={Translate({
                  content: 'singleAssignment.genderRequirement',
                })}
              />
              {genderValue &&
                genderValue?.value != '' &&
                genderValue?.value != '0' && (
                  <div className="d-flex ">
                    <TFVRadioButton
                      text={Translate({
                        content: 'singleAssignment.preferChoice',
                      })}
                      name="genderRequirement"
                      checked={!genderRequirement}
                      value={1}
                      onChange={e =>
                        this.handleRadioButtonChange(e, 'genderRequirement')
                      }
                      className={`tv-form__checkbox-container--small-text tv-form__checkbox-circular-container ${
                        isEdit ? 'tv-form__checkbox-disable' : ''
                      }`}
                      disabled={isEdit ? true : false}
                    />
                    <TFVRadioButton
                      text={Translate({
                        content: 'singleAssignment.choiceRequired',
                      })}
                      onChange={e =>
                        this.handleRadioButtonChange(e, 'genderRequirement')
                      }
                      name="genderRequirement"
                      value={2}
                      checked={genderRequirement}
                      className={`tv-form__checkbox-container--small-text tv-form__checkbox-circular-container ${
                        isEdit ? 'tv-form__checkbox-disable' : ''
                      }`}
                      disabled={isEdit ? true : false}
                    />
                  </div>
                )}
            </div>
            {!isEdit && (
              <div
                className={`form-group col-12 col-md-6 col-lg-6 ${
                  certificationLevels && certificationLevels?.value != ''
                    ? 'mb-4'
                    : ''
                }`}
              >
                <label>
                  <Translate content="singleAssignment.certificationLevel" />
                </label>

                <DropdownList
                  onChange={e =>
                    eventValuetransformForDropDown(e, 'certificationLevels')
                  }
                  name="certificationLevels"
                  value={getValuefromList(
                    certificationLevels ? certificationLevels.value : null,
                    educationCompietancies,
                  )}
                  className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                    isEdit ? 'border-0 invisible-icon' : ''
                  }`}
                  options={educationCompietancies}
                  disabled={isEdit ? true : false}
                  ariaLabel={Translate({
                    content: 'singleAssignment.certificationLevel',
                  })}
                />
                {certificationLevels &&
                  certificationLevels?.value != '' &&
                  certificationLevels?.value != '0' && (
                    <div className="d-flex ">
                      <TFVRadioButton
                        text={Translate({
                          content: 'singleAssignment.preferChoice',
                        })}
                        name="skillEducationRequirement"
                        checked={!skillEducationRequirement}
                        value={1}
                        onChange={e =>
                          this.handleRadioButtonChange(
                            e,
                            'skillEducationRequirement',
                          )
                        }
                        className={`tv-form__checkbox-container--small-text tv-form__checkbox-circular-container ${
                          isEdit ? 'tv-form__checkbox-disable' : ''
                        }`}
                        disabled={isEdit ? true : false}
                      />
                      <TFVRadioButton
                        text={Translate({
                          content: 'singleAssignment.allowOnlyLevel',
                        })}
                        onChange={e =>
                          this.handleRadioButtonChange(
                            e,
                            'skillEducationRequirement',
                          )
                        }
                        name="skillEducationRequirement"
                        value={2}
                        checked={skillEducationRequirement}
                        className={`tv-form__checkbox-container--small-text tv-form__checkbox-circular-container ${
                          isEdit ? 'tv-form__checkbox-disable' : ''
                        }`}
                        disabled={isEdit ? true : false}
                      />
                    </div>
                  )}
              </div>
            )}
            {/* <div
              className={`form-group col-12 col-lg-6 ${
                requestedInterpreter && requestedInterpreter != ''
                  ? 'mb-4'
                  : 'mb-1'
              }`}
            >
              <label>
                <Translate content="singleAssignment.requestedInterpreterLabel" />
              </label>

              <TextField
                validatorListener={validatorListener}
                placeholder={Translate({
                  content: 'singleAssignment.requestedInterpreterPlaceholder',
                })}
                onChange={this.handleChange}
                className=" tv-single-assign-form__control"
                name="requestedInterpreter"
                value={requestedInterpreter}
              />
              {requestedInterpreter && requestedInterpreter != '' && (
                <div className="d-flex ">
                  <TFVRadioButton
                    text={Translate({
                      content: 'singleAssignment.preferChoice',
                    })}
                    name="requestedInterpreterPreferedLevel"
                    value={2}
                    onChange={e =>
                      this.handleRadioButtonChange(
                        e,
                        'requestedInterpreterPreferedLevel',
                      )
                    }
                    checked={
                      requestedInterpreterPreferedLevel == 2 ||
                      !requestedInterpreterPreferedLevel
                    }
                    className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container"
                  />
                  <TFVRadioButton
                    text={Translate({
                      content: 'singleAssignment.choiceRequired',
                    })}
                    value={1}
                    onChange={e =>
                      this.handleRadioButtonChange(
                        e,
                        'requestedInterpreterPreferedLevel',
                      )
                    }
                    checked={requestedInterpreterPreferedLevel == 1}
                    name="requestedInterpreterPreferedLevel"
                    className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container text-nowrap"
                  />
                  <TFVRadioButton
                    text="Vidtalad"
                    value={3}
                    onChange={e =>
                      this.handleRadioButtonChange(
                        e,
                        'requestedInterpreterPreferedLevel',
                      )
                    }
                    checked={requestedInterpreterPreferedLevel == 3}
                    name="requestedInterpreterPreferedLevel"
                    className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container text-nowrap"
                  />
                </div>
              )}
            </div> */}
            {/* <div className="form-group col-12 col-lg-6">
              <label htmlFor="additionalRequirements">
                <Translate content="singleAssignment.additionalRequestedRequirment" />
              </label>
              <TextField
                placeholder={Translate({
                  content:
                    'singleAssignment.additionalRequestedRequirmentPlaceholder',
                })}
                onChange={this.handleChange}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="additionalRequirements"
                maxLength={500}
                value={additionalRequirements}
              />
            </div> */}

            <div className="col-12 form-group ">
              <label htmlFor="yourOrderNumber">
                <Translate content="singleAssignment.yourReferenceNumber" />
                <HelpContext
                  theme="light"
                  style={{}}
                  content={Translate({
                    content: 'helpContent.infoForTFVHelp',
                  })}
                />
              </label>

              <TextField
                aria-label="Eventuell referens"
                validatorListener={validatorListener}
                placeholder={Translate({
                  content: 'singleAssignment.yourReferenceNumberPlaceHolders',
                })}
                maxLength={100}
                onChange={this.handleChange}
                className={`text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control ${
                  isEdit ? 'color-for-text border-0' : ''
                }`}
                name="yourOrderNumber"
                id="yourOrderNumber"
                value={yourOrderNumber}
                readOnly={isEdit ? true : false}
              />
            </div>
            {/* <div className="col-12 form-group mb-4">
              <label htmlFor='assignmentDescription'>
                <Translate content="taskDetail.infoForInterpreter" />
                <HelpContext
                  theme="light"
                  style={{}}
                  content={Translate({
                    content: 'helpContent.infoForInterpreterHelp',
                  })}
                />
              </label>

              <TextField
                aria-label="Information till tolk"
                validatorListener={validatorListener}
                placeholder={Translate({
                  content: 'singleAssignment.typeNotesForResourceNew',
                })}
                onChange={this.handleChange}
                multiline
                className="tv-single-assign-form__textfield tv-single-assign-form__textarea tv-single-assign-form__control"
                name="assignmentDescription"
                id="assignmentDescription"
                value={assignmentDescription}
              />
            </div> */}
            <div className="form-group col-12 mb-3">
              <div className="d-flex flex-row">
                <Checkbox
                  label="Bifoga fil"
                  className="label--checked"
                  defaultChecked={
                    this.props.secondStepFormState.allowFileUpload
                  }
                  value="fileUpload"
                  handleChange={() => {
                    if (this.props.attachmentFileNames.length > 0) return;
                    this.props.setSecondStateFormState({
                      ...this.props.secondStepFormState,
                      validation: { error: null },
                      allowFileUpload:
                        !this.props.secondStepFormState.allowFileUpload,
                    });
                    this.props.onChange({ files: [] });
                  }}
                  disabled={isEdit ? true : false}
                />

                <div className="tooltip-icon tooltip-edit">
                  <i
                    class="fa fa-question-circle ml-2 mt-1"
                    aria-hidden="true"
                  ></i>
                  <span className="tooltip-text">
                    Bifogade filer delas både med tolken och Tolkförmedling
                    Väst.
                  </span>
                </div>
              </div>
              {this.props.secondStepFormState.allowFileUpload && (
                <>
                  <FileUpload
                    isPreviewMode={false}
                    files={files}
                    onAttach={attachment => {
                      const tempFiles = [...files, attachment];
                      this.props.onChange({ files: tempFiles });
                    }}
                    onRemove={fileIndex => {
                      const tempFiles = [...files];
                      tempFiles.splice(fileIndex, 1);
                      this.props.onChange({ files: tempFiles });
                    }}
                    setSecondStateFormState={this.props.setSecondStateFormState}
                    secondStepFormState={this.props.secondStepFormState}
                    disabled={isEdit ? true : false}
                  />
                  <div className="tv-single-assign-form__file-list-container-primary">
                    <AttachedFileList
                      files={this.props.attachmentFileNames}
                      isPreviewMode
                    />
                  </div>
                </>
              )}
            </div>
            {this.props.secondStepFormState.validation.error && (
              <div className="form-group col-12 mb-3 tv-single-assign-form__err-label">
                * {this.props.secondStepFormState.validation.error}
              </div>
            )}
            {/* <div className="form-group col-12  mb-3 ">
              <label>
                <Translate content="singleAssignment.enterADifferentAddress" />
                <HelpContext
                  theme="light"
                  style={{}}
                  content={Translate({
                    content: 'helpContent.enterADifferentAddressHelp',
                  })}
                />
              </label>

              <TextField
                validatorListener={validatorListener}
                onChange={this.handleChange}
                placeholder={Translate({
                  content: 'singleAssignment.addressPlaceHolder',
                })}
                className=" tv-single-assign-form__control"
                name="addressLine"
                value={addressLine}
                validators={isSameProfile ? [] : ['required']}
                maxLength={300}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.addressLine',
                      }),
                    },
                  }),
                ]}
              />
            </div>

            <div className="col-6 col-6 col-md-6 form-group mb-1 mb-md-3">
              <label>
                <Translate content="singleAssignment.postalNumber" />
              </label>
              <TextField
                validatorListener={validatorListener}
                onChange={this.handleChange}
                type="number"
                placeholder={Translate({
                  content: 'singleAssignment.postalCodePlaceHolder',
                })}
                className=" tv-single-assign-form__control"
                name="postalCode"
                maxLength={300}
                value={postalCode.toString()}
                validators={
                  isSameProfile
                    ? []
                    : ['required', 'isNumber', 'checkNumLength: 5']
                }
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.postalCode',
                      }),
                    },
                  }),
                  Translate({
                    content: 'validator.isNotValid',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.postalCode',
                      }),
                    },
                  }),
                  Translate({
                    content: 'validator.requireLength',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.postalCode',
                      }),
                      number: 5,
                    },
                  }),
                ]}
              /> 
            </div>
            <div className="col-6 col-6 col-md-6 form-group mb-1 mb-md-3">
              <label>
                <Translate content="singleAssignment.cityNew" />
              </label>
              <TextField
                validatorListener={validatorListener}
                onChange={this.handleChange}
                placeholder={Translate({
                  content: 'singleAssignment.cityPlaceHolder',
                })}
                className=" tv-single-assign-form__control"
                name="city"
                value={city}
                validators={isSameProfile ? [] : ['required']}
                maxLength={300}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.cityNew',
                      }),
                    },
                  }),
                ]}
              />
            </div> */}
          </div>
        )}
      </React.Fragment>
    );
  }
}

SingleAssignmentForm.propTypes = propTypes;
SingleAssignmentForm.defaultProps = defaultProps;

const mapStateToProps = state => {
  const DoShowLMANumber = state.user.profile.DoShowLMANumber;
  const skills = state.skill.educationSkills || [];
  const educationCompietancies = skills.map((item, i) => ({
    value: item.SkillIdentifier,
    name: item.SkillName,
  }));
  return {
    DoShowLMANumber,
    educationCompietancies,
  };
};

export default connect(mapStateToProps)(SingleAssignmentForm);

const TFVRadioButton = ({
  className,
  name,
  onChange,
  checked,
  value,
  text = '',
  disabled = false,
}) => (
  <label className={`tv-form__checkbox-container ${className}`}>
    <input
      disabled={disabled}
      type="radio"
      name={name}
      onChange={onChange}
      defaultChecked={checked}
      value={value}
      tabIndex={0}
    />
    <span className="tv-form__checkbox-checkmark" />
    {text}
  </label>
);
