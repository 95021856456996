import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty } from 'lodash/core';
import {
  checkOnGoingAssignment,
  iconForTheServiceType,
  shouldDisplayscrollTop,
  statusStringTraslate,
} from '../../utils';
import { useRef } from 'react';
import { truncateCalendarSkillName } from '../../utils/fileUtil';
import { useState } from 'react';

const propTypes = {
  event: PropTypes.shape({}),
  onClick: PropTypes.func,
  date: PropTypes.shape({}),
};

const defaultProps = {
  event: {},
  date: {},
  onClick: () => {},
};

const SingleEvent = props => {
  const { event, onClick, selectedEvent } = props;
  const [statusName, setStatusName] = useState(event.orderStatus);
  const refElement = useRef(null);
  const currentSelectedEvent =
    !isEmpty(selectedEvent) &&
    selectedEvent.OrderIdentifier === event.identifier;
  const timeFromTo = `${moment(event.assignment.DatetimeFrom).format(
    'HH:mm',
  )} - ${moment(event.assignment.DatetimeTo).format('HH:mm')}`;

  let eventClass = '';
  let orderStatusName = event.orderStatus;

  let timer;
  useEffect(() => {
    const updateStatusColor = () => {
      const checkonGoingOrder = checkOnGoingAssignment(event.assignment);
      if (checkonGoingOrder) {
        setStatusName('onGoing');
      } else {
        setStatusName(event.orderStatus);
      }
      timer = setTimeout(updateStatusColor, 1000);
    };
    updateStatusColor();
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [event]);

  switch (statusName) {
    case 'accepted':
      eventClass = 'tk-weekly-calendar__event-accepted';
      break;
    case 'rejected':
      eventClass = 'tk-weekly-calendar__event-rejected';
      break;
    case 'working':
      eventClass = 'tk-weekly-calendar__event-working';
      break;
    case 'fullfilled':
      eventClass = 'tk-weekly-calendar__event-fullfilled';
      break;
    case 'submitted':
      eventClass = 'tk-weekly-calendar__event-submitted';
      break;
    case 'cancelled':
      eventClass = 'tk-weekly-calendar__event-cancelled';
      break;
    case 'late-cancelled':
      eventClass = 'tk-weekly-calendar__event-late-cancelled';
      break;
    case 'available':
      eventClass = 'tk-weekly-calendar__event-available';
      break;
    case 'onGoing':
      eventClass = 'tk-weekly-calendar__event-onGoing';
      break;

    default:
      break;
  }

  const selectedClass = 'tk-weekly-calendar__event-selected';

  const shorterFileName = fname => {
    const EXPECT_LENGTH = 8;
    return truncateCalendarSkillName(fname, EXPECT_LENGTH);
  };
  return (
    <div
      className={
        currentSelectedEvent
          ? `tk-weekly-calendar__event-single position-relative ${eventClass} tk-weekly-calendar__event-single ${selectedClass}`
          : `tk-weekly-calendar__event-single ${eventClass}`
      }
      onClick={e => {
        e.stopPropagation();
        onClick(null, event.assignment.OrderIdentifier);
      }}
      ref={refElement}
      tabIndex={0}
      onKeyDown={e => {
        if(e.key === 'Enter' || e.key === ' ') {
          e.stopPropagation();
          onClick(null, event.assignment.OrderIdentifier);
        }
      }}
    >
      {currentSelectedEvent && shouldDisplayscrollTop(refElement) && (
        <div
          className="position-absolute tv-workassignment__list-scrolltop bounce-4 tv-display-mobile-hide"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            document.querySelector('.tv-right-pannel-wrapper ').scrollIntoView({
              behavior: 'smooth',
            });
          }}
        >
          <i className="fas fa-arrow-alt-circle-up " title="visa detaljer"></i>
        </div>
      )}
      <div className="tk-weekly-calendar__event-single_container1">
        <div className="header-wrapper">
          <div className="skill display-mobile-show" title={event.skill}>
            {event.skill}
          </div>
          <div className="skill display-mobile-hide" title={event.skill}>
            {shorterFileName(event.skill)}
          </div>
          <div className="skill" title={event.assignment.OrderNumber}>
            {event.assignment.OrderNumber}
          </div>
          {event.assignment?.NumberOfUnreadNotifications > 0 && (
            <div className="notification">
              <i className="fa fa-bell" />
            </div>
          )}
        </div>
      </div>
      <div className="tk-weekly-calendar__event-single_container2">
        <div className="fields" title={timeFromTo}>
          <i className="fa mr-2 fa-clock" />
          {timeFromTo}
        </div>
        <div
          className="fields"
          title={event.assignment.ContactPerson.ContactPersonName}
        >
          <i className="fa mr-2 fa-user" />
          {event.assignment.ContactPerson.ContactPersonName}
        </div>
        <div className="fields" title={event.assignment.ArticleName}>
          <i className={` mr-2 ${iconForTheServiceType(event.assignment)}`} />
          {`${event.assignment.ArticleName} ${event.assignment.IsSpecialAssignment ? '(specialuppdrag)' : ''}`}
        </div>
        <div className="fields text-capitalize">
          <i className="fa mr-2 fa-briefcase" />
          {statusStringTraslate(statusName)}
        </div>
      </div>
    </div>
  );
};

SingleEvent.propTypes = propTypes;
SingleEvent.defaultProps = defaultProps;

export default SingleEvent;
