import React, { useEffect, useState } from 'react';
import { Translate, TFVButton, TextField } from '../../../Share/components';
import { ValidatorForm } from 'react-form-validator-core';
import { connect } from 'react-redux';
import { forgotPassword } from '../../actions';
import { NavLink } from 'react-router-dom';

function ForgotPassword({ onForgotPassword }) {
  const [formData, setFormData] = useState({ isContactPerson: false });
  useEffect(() => {
    document.title = `Återställ lösenord - TFV Kundportal
    `;
  }, []);
  const handleSubmit = e => {
    e.preventDefault();
    if (formData.customerNumber !== null) {
      const { customerNumber, email, isContactPerson } = formData;
      onForgotPassword(customerNumber, email, isContactPerson);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'isContactPerson') {
      if (value === '0') {
        setFormData({ ...formData, isContactPerson: false });
      } else {
        setFormData({ ...formData, isContactPerson: true });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <>
      <div className="tv-login__wrapper tv-display-flex">
        <div className="tv-login__form tv-login__form-wrapper">
          <div className="tv-login__title">
            <Translate content="forgotPassword.title" />
          </div>
          <ValidatorForm className="tv-login__form" onSubmit={handleSubmit}>
            <label className="tv-login__form-email-lablel tv-login__form-label" htmlFor="customerNumber">
              <Translate content="forgotPassword.customerNumber" />
            </label>
            <TextField
              className="tx-input-text tv-login__form-email-input tv-login__controller"
              aria-label={Translate({
                content: 'forgotPassword.customerNumber',
              })}
              name="customerNumber"
              id="customerNumber"
              maxLength={300}
              onChange={handleChange}
              value={formData.customerNumber}
              placeholder={Translate({
                content: 'forgotPassword.customerNumberPlaceHolder',
              })}
              validators={['required', 'isNumber']}
              errorMessages={[
                Translate({
                  content: 'validator.isRequired',
                  params: {
                    name: Translate({
                      content: 'forgotPassword.customerNumber',
                    }),
                  },
                }),
                Translate({
                  content: 'forgotPassword.customerNumberIsNotValid',
                }),
              ]}
              autoFocus
            />

            <label className="tv-login__form-email-lablel tv-login__form-label" htmlFor="email">
              <Translate content="forgotPassword.email" />
            </label>
            <TextField
              className="tx-input-text tv-login__form-email-input tv-login__controller"
              aria-label={Translate({
                content: 'forgotPassword.email',
              })}
              name="email"
              id="email"
              onChange={handleChange}
              value={formData.email}
              maxLength={300}
              placeholder={Translate({
                content: 'forgotPassword.emailPlaceHolder',
              })}
              validators={['required']}
              errorMessages={[
                Translate({
                  content: 'validator.isRequired',
                  params: {
                    name: Translate({
                      content: 'forgotPassword.email',
                    }),
                  },
                }),
              ]}
            />

            <label className="tv-login__form-email-lablel tv-login__form-label mt-2" htmlFor="isContactPerson">
              <Translate content="forgotPassword.isContactPerson" />
            </label>
            <div className="tv-login__check-contact-person">
              <TFVRadioButton
                text={Translate({
                  content: 'forgotPassword.false',
                })}
                name="isContactPerson"
                id="isContactPerson"
                className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container"
                value={0}
                checked={!formData.isContactPerson}
                onChange={handleChange}
              />
              <TFVRadioButton
                text={Translate({
                  content: 'forgotPassword.true',
                })}
                name="isContactPerson"
                id="isContactPerson"
                className="tv-form__checkbox-container--small-text tv-form__checkbox-circular-container"
                value={1}
                checked={formData.isContactPerson}
                onChange={handleChange}
              />
            </div>
            {/* <TextField
            className="tx-input-text tv-login__form-email-input tv-login__controller"
            aria-label={Translate({
              content: 'forgotPassword.isContactPerson',
            })}
            name="isContactPerson"
            onChange={handleChange}
            value={formData.isContactPerson}
            maxLength={300}
            placeholder={Translate({
              content: 'forgotPassword.isContactPersonPlaceHolder',
            })}
            validators={['required']}
            errorMessages={[
              Translate({
                content: 'validator.isRequired',
                params: {
                  name: Translate({
                    content: 'forgotPassword.isContactPerson',
                  }),
                },
              }),
            ]}
          /> */}

            <TFVButton
              type="primary"
              buttonType="submit"
              text={Translate({ content: 'forgotPassword.send' })}
              className="tv-login__form-submit"
            />
          </ValidatorForm>
        </div>
      </div>
      <NavLink
        exact
        activeClassName="nav-active"
        to="/login"
        className="nav-item tv-login__reset-password"
      >
        <Translate content="forgotPassword.returnToLogin" />
      </NavLink>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onForgotPassword: (customerNumber, email, isContactPerson) => {
    dispatch(forgotPassword(customerNumber, email, isContactPerson));
  },
});

export default connect(null, mapDispatchToProps)(ForgotPassword);

const TFVRadioButton = ({
  className,
  name,
  onChange,
  checked,
  value,
  text = '',
  disabled = false,
}) => (
  <label className={`tv-form__checkbox-container ${className}`}>
    <input
      disabled={disabled}
      type="radio"
      name={name}
      id={name}
      onChange={onChange}
      defaultChecked={checked}
      value={value}
      tabIndex={0}
    />
    <span className="tv-form__checkbox-checkmark" />
    {text}
  </label>
);
