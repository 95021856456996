import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Translate } from '../../../Share/components';
import { kFormatter } from '../../../Share/utils/reportUtil';
import { ChartContainer } from '../Charts';
import { process } from '@progress/kendo-data-query';
import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import { isEqual, max, min } from 'lodash';
import { useEffect } from 'react';
import { connect } from 'react-redux';

function addSortableNumber(day) {
  switch (day) {
    case 'Monday':
      return 1;
    case 'Tuesday':
      return 2;
    case 'Wednesday':
      return 3;
    case 'Thursday':
      return 4;
    case 'Friday':
      return 5;
    case 'Saturday':
      return 6;
    case 'Sunday':
      return 0;
    default:
      return 7;
  }
}

function extractKeyValue(returnObj, element) {
  if (element.items && element.items.length > 0) {
    element.items.forEach(item => {
      // eslint-disable-next-line no-param-reassign
      returnObj[item.field] = item.value;
      extractKeyValue(returnObj, item);
    });
  }
  return returnObj;
}

function processData(data, dataState) {
  const procesData = process(data, dataState);
  const returnData = [];
  procesData.data.forEach(element => {
    const returnElement = {};
    returnElement[dataState.group[0].field] = element.value;
    extractKeyValue(returnElement, element);
    const aggregatesKeys = [...Object.keys(element.aggregates)];
    aggregatesKeys.forEach(aggrItem => {
      returnElement[aggrItem] = element.aggregates[aggrItem].sum;
    });
    Object.keys(returnElement).forEach(
      key => returnElement[key] === undefined && delete returnElement[key],
    );
    returnData.push(returnElement);
  });
  return returnData;
}

const OrderPatternWidgets = ({ data, startDate }) => {
  const [zoomLevel, setZoomLevel] = useState(0);
  const [perUnit, setPerUnit] = useState('vecka');
  const [chartData, setChartData] = useState({
    processData: [],
    data: data.map(x => ({
      ...x,
      Date: moment()
        .startOf('isoWeek')
        .add(addSortableNumber(x.weekday) - 1, 'day')
        .add(Number(x.hourly.split(':')[0]), 'hour')
        .add(Number(x.hourly.split(':')[1]), 'minute'),
      DateFormated: moment()
        .startOf('isoWeek')
        .add(addSortableNumber(x.weekday) - 1, 'day')
        .add(Number(x.hourly.split(':')[0]), 'hour')
        .add(Number(x.hourly.split(':')[1]), 'minute')
        .toDate(),
    })),
    format: 'E ',
    max: 30,
  });
  const grouping = {
    take: 10,
    group: [
      {
        field: 'DateFormated',
        aggregates: [
          {
            aggregate: 'sum',
            field: 'noOfAssignments',
          },
        ],
      },
    ],
  };

  useEffect(() => {
    const tempChartData = {
      processData: [],
      data: data.map(x => ({
        ...x,
        Date: moment()
          .startOf('isoWeek')
          .add(addSortableNumber(x.weekday) - 1, 'day')
          .add(Number(x.hourly.split(':')[0]), 'hour')
          .add(Number(x.hourly.split(':')[1]), 'minute'),
        DateFormated: moment()
          .startOf('isoWeek')
          .startOf('isoWeek')
          .add(addSortableNumber(x.weekday) - 1, 'day')
          .add(Number(x.hourly.split(':')[0]), 'hour')
          .add(Number(x.hourly.split(':')[1]), 'minute')
          .toDate(),
      })),
      format: 'E hh:mm a ',
      max: moment().startOf('isoWeek').add(18, 'hour').toDate(),
      baseUnit: 'minutes',
      maxDivisions: 24,
      baseUnitSteps: 30,
    };
    setPerUnit(' 30m');
    setChartData({
      ...tempChartData,
      processData: processData(
        tempChartData.data.map(x => ({
          ...x,
        })),
        grouping,
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const labelContent = props => {
    const formatedNumber = Number(props.percentage).toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits: 2,
    });
    return `${props.category} : ${formatedNumber}`;
  };
  const handleZoom = e => {
    const newZoomLevel = Math.min(1, zoomLevel + 1);
    if (newZoomLevel != zoomLevel) {
      switch (newZoomLevel) {
        case 1:
          setChartData({
            ...chartData,
            processData: processData(
              chartData.data.map(x => ({
                ...x,
                DateFormated: x.Date.clone().startOf('day').toDate(),
              })),
              grouping,
            ),
            format: 'E',
            max: moment().startOf('isoWeek').add(5, 'day').toDate(),
            maxDivisions: 7,
            baseUnit: 'days',
            baseUnitSteps: 1,
          });
          setPerUnit(' 1d');

          break;
        case 0:
          setChartData({
            ...chartData,
            processData: processData(
              chartData.data.map(x => ({
                ...x,
                DateFormated: x.Date.clone().toDate(),
              })),
              grouping,
            ),
            format: 'E hh:mm a ',
            max: moment().startOf('isoWeek').add(18, 'hour').toDate(),
            baseUnit: 'minutes',
            maxDivisions: 24,
            baseUnitSteps: 30,
          });
          setPerUnit(' 30m');

          break;
        default:
          setChartData({
            ...chartData,
            format: 'E ',
            max: null,
          });
      }
      setZoomLevel(newZoomLevel === 1 ? -1 : newZoomLevel);
    }
  };
  const interpreterTypes = [
    'Telefontolkning',
    'Callcentertolk',
    'Kontakttolkning',
  ];
  return (
    <div className="auto-row-2">
      <div className="exact-col-1" style={{ height: '40px' }}>
        <span>
          {moment(startDate).add(-6, 'M').format('yyyy-MM-DD')} /{' '}
          {moment(startDate).format('yyyy-MM-DD')}{' '}
        </span>
      </div>
      <div className="exact-col-1">
        <WidgetRender
          handleZoom={handleZoom}
          chartData={chartData}
          title={`${Translate({
            content: 'chart.orderPattern',
          })} ( per${perUnit})`}
          grouping={grouping}
          interpreterTypes={interpreterTypes}
        />
      </div>
      <div />
    </div>
  );
};

OrderPatternWidgets.propTypes = {};
const mapStateToProps = state => ({
  data: state.dataSources.sources[8].crossFilterData || [],
  startDate: state.dashboard.range.to,
});
export default connect(mapStateToProps, null)(OrderPatternWidgets);

const WidgetRender = ({
  interpreterTypes,
  chartData,
  title,
  handleZoom,
  grouping,
  per,
}) => {
  const [displayData, setdisplayData] = useState({
    processData: [],
    format: 'E hh:mm a ',
    max: moment().startOf('isoWeek').add(18, 'hour').toDate(),
    baseUnit: 'minutes',
    maxDivisions: 24,
    baseUnitSteps: 30,
    ...chartData,
  });
  useEffect(() => {
    if (
      !isEqual(
        chartData.processData?.sort(),
        displayData.processData?.sort(),
      ) ||
      displayData.format !== chartData.format
    ) {
      setdisplayData({ ...chartData });
    }
  }, [chartData]);
  return (
    <div className="dashboard__display-card round-border">
      <Chart
        onPlotAreaClick={e => handleZoom(e)}
        pannable={{ lock: 'y' }}
        zoomable={{
          mousewheel: { lock: 'y' },
          selection: { lock: 'y' },
        }}
        style={{
          width: '100%',
          fontFamily: "'Source Sans Pro', sans-serif",
        }}
        key="OrderPatternChart_1"
      >
        <ChartArea background="#eee" margin={30} />
        <ChartLegend visible position="right" orientation="verticle" />
        <ChartTitle text={title} />
        <ChartTooltip format="{0}" />
        <ChartSeries>
          <ChartSeriesItem
            type="area"
            stack
            data={displayData.processData}
            field="noOfAssignments"
            categoryField="DateFormated"
            line={{ style: 'smooth' }}
            color="blue"
            name="All"
            opacity={0.5}
          />
        </ChartSeries>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            max={displayData.max}
            maxDivisions={displayData.maxDivisions || 10}
            baseUnit={displayData.baseUnit || 'minutes'}
            baseUnitStep={displayData.baseUnitSteps || 30}
            labels={{
              rotation: 'auto',
              format: displayData.format,
            }}
          />
        </ChartCategoryAxis>
      </Chart>
    </div>
  );
};
