import * as api from '../api';
import groupBy from 'lodash/groupBy';
import { sortBy, isEqual } from 'lodash/core';
import { tfvLog } from '../../Share/utils';
import { hiddenLoadingOverlay, showLoadingOverlay } from './app';
import {
  DISPLAY_SUCCESS_MODAL,
  UPDATE_ORDER_TRANSLATION_DATA,
  UPDATE_ORDER_TRANSLATION_FILTERS,
  UPDATE_TRANSLATION_SKILLS,
} from './ActionTypes';
import { statusMapping, subStatus } from '../../Share/constants';
import { isEmpty } from 'lodash';

function formaterOrder(translationOrder) {
  const deliveryDate =
    translationOrder.deliveryDate.ranges.startDate.format('YYYY-MM-DD');
  const checkDeliveryDate =
    translationOrder.deliveryPlan.value ===
    'Leveransdatum enligt överenskommelse';
  const ContactPersonIdentifierOrderer =
    translationOrder.contactPersonIdentifierOrderer.value;
  const DateDelivery = checkDeliveryDate ? deliveryDate : null;
  const DeliveryPlan = translationOrder.deliveryPlan.value;
  const Description = translationOrder.description;
  const DoesNeedAQuote = translationOrder.needAQuote;
  const ModeOfDelivery = translationOrder.modeoFDelivery.value;
  const SkillIdentifierSource = translationOrder.sourceLanguage.value;
  const TargetSkills = [];
  translationOrder.targetLanguages.map(language => {
    let skill = { SkillIdentifier: language.value };
    TargetSkills.push(skill);
  });
  const TranslationAttachments = [];
  translationOrder.attachments.map(file => {
    let attachment = {
      Attachment: file.data,
      AttachmentContentType: file.type,
      AttachmentFileName: file.name,
      DoesRequireStamped: file.requestForStampedDocument,
      IsConfidential: file.isDocumentSubjectToConfidentiality,
    };
    TranslationAttachments.push(attachment);
  });

  return {
    ContactPersonIdentifierOrderer,
    DateDelivery,
    DeliveryPlan,
    Description,
    DoesNeedAQuote,
    ModeOfDelivery,
    SkillIdentifierSource,
    TargetSkills,
    TranslationAttachments,
  };
}

export function createNewOrderTranslation(translationOrder) {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    const formattedData = formaterOrder(translationOrder);
    try {
      dispatch(showLoadingOverlay());
      const response = await api.createNewOrderTranslation(
        token,
        formattedData,
      );
      dispatch(displaySuccessModal(true));
    } catch (error) {
      dispatch(displaySuccessModal(false));
      // tfvLog(error, logApiLevel.error);
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

export function displaySuccessModal(display) {
  return dispatch => {
    dispatch({
      type: DISPLAY_SUCCESS_MODAL,
      display: display,
    });
  };
}

export function fetchTranslationSkills() {
  return async dispatch => {
    try {
      // dispatch(showLoadingOverlay());
      const response = await api.getTranslationSkills();
      if (response.data && !response.data.Errors) {
        const sortedSkills = sortBy(response.data.Skills, 'SkillName');

        dispatch({
          type: UPDATE_TRANSLATION_SKILLS,
          skills: sortedSkills,
        });
      } else {
        const errorResponse =
          response.data && response.data.Errors ? response.data.Errors : {};
        throw new Error(
          `getTranslationSkill error ${JSON.stringify(errorResponse)}`,
        );
      }
    } catch (error) {
      tfvLog(error);
    } finally {
      // dispatch(hiddenLoadingOverlay());
    }
  };
}

export function updateOrderTranslationFilters(filterType, value) {
  return dispatch =>
    dispatch({
      type: UPDATE_ORDER_TRANSLATION_FILTERS,
      filterType,
      value,
    });
}

export function getOrderTranslationData(searchValue = '', startDate, endDate) {
  return async (dispatch, getState) => {
    const state = getState();
    const { token } = state.authentication;
    try {
      dispatch(showLoadingOverlay());
      let response = [];
      response = await api.searchOrders(
        token,
        startDate,
        endDate,
        null,
        searchValue,
      );
      const orders = converttoTranslationEvents(response);
      if (orders) {
        let translationList = orders.filter(
          order => order.IsTranslation === true,
        );
        dispatch({
          type: UPDATE_ORDER_TRANSLATION_DATA,
          translationList: translationList,
        });
      }
    } catch (error) {
      tfvLog(error);
    } finally {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

const getOrderStatus = item => {
  if (item.ConsolidatedOrderStatus.StatusIdentifier === 'cancelled') {
    if (item.ConsolidatedOrderStatus.SubStatusIdentifier === 'late-cancelled') {
      return statusMapping[item.ConsolidatedOrderStatus.SubStatusIdentifier];
    }
    return statusMapping[item.ConsolidatedOrderStatus.StatusIdentifier];
  }
  return statusMapping[item.ConsolidatedOrderStatus.StatusIdentifier];
};

function converttoTranslationEvents(response) {
  if (response.data.Orders) {
    const ordersUnGrouped = [];
    response.data.Orders.forEach(item => {
      const groupNumber =
        item.OrderGroup.OrderGroupIdentifier || item.OrderIdentifier;
      const OrderStatus = getOrderStatus(item);
      const subStatusIdentifier =
        item.ConsolidatedOrderStatus?.ConsolidatedOrderStatusMessages &&
        item.ConsolidatedOrderStatus.ConsolidatedOrderStatusMessages.length > 0
          ? item.ConsolidatedOrderStatus.ConsolidatedOrderStatusMessages[0]
              .StatusMessageIdentifier
          : subStatus.unknownStatus;
      ordersUnGrouped.push({
        ...item,
        groupNumber,
        subStatusIdentifier,
        OrderStatus,
      });
    });

    const orders = [];
    const ordersGrouped = groupBy(ordersUnGrouped, value => value.groupNumber);
    ordersUnGrouped.forEach(item => {
      Object.keys(ordersGrouped).forEach(key => {
        if (isEqual(item, ordersGrouped[key][0])) {
          orders.push(...ordersGrouped[key]);
        }
      });
    });
    return orders;
  }
  return [];
}
